import React from 'react'
import { Text, BoxProps } from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
export const FormErrorMessage: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Text color="#E1202A" {...rest}>
      <WarningIcon color="#E1202A" mr={1} pb="1px" />
      {children}
    </Text>
  )
}
