import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useTheme,
  VStack
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { faMapSigns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useAppTourContextProvider } from '../context/app-tour-context'
import { useAuth } from '../context/auth-context'
import { useFeatureFlags } from '../context/feature-flag-context'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { routeStrings } from '../routeStrings'
import { tourTargetStrings } from '../tourStepsArrays'
import { Actions, Roles, useRBAC, useRole } from '../utils/rbac'
import { LogoutIcon } from './icons/icons'
import { UserDisplay } from './UserDisplay'
import { NavLink, useNavigate } from 'react-router-dom'

export const AdminHeader: React.FC = (props) => {
  const {
    dailyFiveEnabled,
    coworkingSettingsEnabled,
    workspaceBookingEnabled
  } = useFeatureFlags()
  const { isInAdminRole, assumeRole, allowedRoles } = useRole()
  const navigate = useNavigate()
  const auth = useAuth()
  const theme: any = useTheme()
  const { platformTenant } = usePlatformTenant()
  const { setRun, setStepIndex, setAdminTourActive } =
    useAppTourContextProvider()
  const canManageMeetingRooms = useRBAC(Actions.AdministerMeetingRooms)
  const canManageWorkspaces = useRBAC(Actions.AdministerWorkspaces)

  const headerContainer = css`
    align-items: center;
    // position: fixed;
    // top: 0;
    // width: 100%;
  ` as any

  const linkContainer = css`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 2rem;
  ` as any

  const Link = styled(NavLink)<any>`
    font-size: 18px;
    line-height: 36px;
    color: #b4bec8;
    transition: all 0.18s ease;
    min-width: max-content;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &.active {
      font-weight: bold;
      color: white;

      :after {
        position: absolute;
        height: 3px;
        content: '';
        width: 24px;
        background: ${theme.colors.brandPrimary};
      }
    }

    &:hover {
      color: white;
      cursor: pointer;
    }
  `
  const Beta = css`
    background: ${theme.colors.brandPrimary};
    line-height: 11px;
    text-align: right;
    right: -79px;
    width: 30px;
    color: white;
    padding: 2px;
    font-size: 11px;
    position: relative;
    border-radius: 3px;
  ` as any

  const handleStartTour = () => {
    setStepIndex(undefined)
    setAdminTourActive(true)
    setRun(true)
  }

  if (!isInAdminRole) return null

  if (platformTenant && !platformTenant.is_onboard_complete) {
    return (
      <Flex height={62} bg={theme.colors.eastBay10} css={headerContainer}>
        <Button
          color={'#fff'}
          bg={theme.colors.eastBay10}
          onClick={() => navigate(routeStrings.logout)}
        >
          Logout
        </Button>
      </Flex>
    )
  }

  return (
    <Flex
      height={62}
      bg={theme.colors.eastBay10}
      css={headerContainer}
      data-testid="admin-header"
    >
      <Flex>
        <NavLink
          id={tourTargetStrings.adminheaderInit}
          style={{ display: 'flex', minWidth: '117px' }}
          to={routeStrings.adminDashboard}
        >
          <Image
            src={theme.logos.logo_long_white}
            width={117}
            pl={3}
            alignSelf="center"
          />
        </NavLink>
      </Flex>
      <Flex
        css={linkContainer}
        overflowX="auto"
        flexDirection="row"
        alignItems="center"
      >
        <Link
          className={tourTargetStrings.adminheaderRequests}
          to={routeStrings.adminDashboardPendingRequests}
        >
          <Text>Requests</Text>
        </Link>
        <Link
          className={tourTargetStrings.adminheaderMembers}
          to={routeStrings.adminDashboardMembers}
        >
          <Text>Members</Text>
        </Link>
        <Link
          className={tourTargetStrings.adminheaderTeams}
          to={routeStrings.adminDashboardTeams}
        >
          <Text>Teams</Text>
        </Link>
        <Link
          className={tourTargetStrings.adminheaderLocations}
          to={routeStrings.adminDashboardLocations}
        >
          <Text>Locations</Text>
        </Link>
        <Link
          className={tourTargetStrings.adminheaderPlans}
          to={routeStrings.AdminDashboardPlans}
        >
          <Text>Plans</Text>
        </Link>
        <Link
          className={tourTargetStrings.adminheaderReports}
          to={routeStrings.adminDashboardReports}
        >
          <Text>Reports</Text>
        </Link>
        {canManageMeetingRooms && (
          <Link
            className={tourTargetStrings.adminheaderMeetingrooms}
            to={routeStrings.adminDashboardMeetingRooms}
          >
            <Text>Meeting Rooms</Text>
          </Link>
        )}
        {workspaceBookingEnabled && canManageWorkspaces && (
          <Link
            className={tourTargetStrings.adminheaderWorkspaces}
            to={routeStrings.adminDashboardWorkspaces}
          >
            <Text css={Beta}>Beta</Text>
            <Text position="relative" top="-7px">
              Workspaces
            </Text>
          </Link>
        )}

        {dailyFiveEnabled && (
          <Link
            className={tourTargetStrings.adminheaderCommunity}
            to={routeStrings.adminDashboardCommunity}
          >
            <Text>Community</Text>
          </Link>
        )}

        {coworkingSettingsEnabled &&
          allowedRoles.includes(Roles.SpaceSuperAdmin) && (
            <Link
              className={tourTargetStrings.adminheaderSettings}
              to={routeStrings.adminDashboardPlatformTenantSettings}
            >
              <Text>Settings</Text>
            </Link>
          )}
      </Flex>
      <Flex ml="auto" color="grey" p="3" align="center">
        <Flex justify="end" alignContent="center" mr={5}>
          <Menu>
            <MenuButton
              id={tourTargetStrings.adminheaderAdminUserMenu}
              as={Button}
              background={'transparent'}
              _hover={{
                bg: 'gray.700'
              }}
              _expanded={{ bg: 'gray.700' }}
              _focus={{ boxShadow: 'outline' }}
            >
              <UserDisplay
                name={auth.me?.user.name}
                src={auth.me?.user.user_profile.profile_picture?.image}
              />
              <ChevronDownIcon color="white" boxSize="20px" />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => navigate(routeStrings.logout)}>
                <LogoutIcon mr="2" h="16px" w="16px" /> Logout
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  assumeRole(Roles.ActiveMember)
                  navigate(routeStrings.memberDashboardHome)
                  const org = auth.me?.organizational_units[0].organization
                  if (org && auth.adminChangeOrganization) {
                    auth.adminChangeOrganization(org)
                  }
                }}
              >
                Swap to member view
              </MenuItem>
              <MenuDivider />
              <MenuGroup title="Switch Teams">
                <Box id="scrollable-list" maxH="500px" overflowY="scroll">
                  {auth.me?.organizational_units.map((ou, i) => (
                    <MenuItem
                      background={
                        auth.currentOrgUnit?.id === ou.id
                          ? 'gray.100'
                          : undefined
                      }
                      key={`adminHeaderMenu:${i}`}
                      onClick={() =>
                        auth.me && auth.setOrganizationalUnit(auth.me, ou)
                      }
                    >
                      <HStack py={1} px={3} justify={'left'}>
                        <UserDisplay size={'xs'} name={ou.organization.name} />
                        <VStack justify="left" align="left" spacing="0px">
                          <Box maxW="100px">
                            <Text fontSize="sm" isTruncated>
                              {ou.organization.name}
                            </Text>
                          </Box>
                          <Text fontWeight="bold" fontSize="xs">
                            {ou.organization.parent_platform_tenant_name}
                          </Text>
                        </VStack>
                      </HStack>
                    </MenuItem>
                  ))}
                </Box>
              </MenuGroup>
              <MenuDivider />
              <MenuItem onClick={handleStartTour}>
                <FontAwesomeIcon
                  icon={faMapSigns}
                  style={{ marginRight: '8px' }}
                />
                Start Platform Tour
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  )
}
