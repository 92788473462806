import { Flex, FlexProps, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { IDailyFiveNotification } from '../../types'
import { BigDailyFive } from './BigDailyFive'
interface Props extends FlexProps {
  notification?: IDailyFiveNotification
}

export const DailyFiveDisplay: React.FC<Props> = ({
  notification,
  ...rest
}) => {
  return (
    <Flex
      flexDirection="column"
      // h="100%"
      w="100%"
      alignItems="space-between"
      {...rest}
    >
      <SimpleGrid
        // templateColumns={['1f', '1f', '1fr 1fr']}
        columns={[1, 2]}
        spacing={6}
        paddingBottom="32px"
      >
        <BigDailyFive
          imageHeight="280px"
          contentObject={notification && notification.content[0]}
        />
        <BigDailyFive
          imageHeight="280px"
          contentObject={notification && notification.content[1]}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={[1, 3]}
        // templateColumns={['1f', '1f', '1fr 1fr 1fr']}
        spacing={6}
        paddingBottom="32px"
      >
        <BigDailyFive
          imageHeight="220px"
          contentObject={notification && notification.content[2]}
        />
        <BigDailyFive
          imageHeight="220px"
          contentObject={notification && notification.content[3]}
        />
        <BigDailyFive
          imageHeight="220px"
          contentObject={notification && notification.content[4]}
        />
      </SimpleGrid>
    </Flex>
  )
}
