import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton
} from '@chakra-ui/react'
import React from 'react'
import { useFilters, usePagination, useTable } from 'react-table'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import { TablePaginator } from './TablePaginator'
import { SearchIcon } from '@chakra-ui/icons'
import { SKELETON_SIX_ROWS } from '../../utils/constants'
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  return (
    <InputGroup size="sm" width="80%">
      <InputLeftElement children={<SearchIcon color="gray.300" />} />
      <Input
        value={filterValue || ''}
        variant="outline"
        background="white"
        placeholder={`Search`}
        onChange={(e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
      />
    </InputGroup>
  )
}

const Tableizer = ({ columns, data, isLoading, defaultPageSize }) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  // @ts-ignore
  const instance = useTable(
    {
      columns,
      data,
      defaultColumn: defaultColumn as any,
      initialState: {
        pageSize: defaultPageSize,
        hiddenColumns: columns
          ?.filter((column) => !column.show)
          .map((column) => column.accessor)
      },
      autoResetPage: false
    },
    useFilters,
    usePagination
  ) as any

  // @ts-ignore
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage
  } = instance

  // Render the UI for your table
  return (
    <Box w="100%" h="100%" minWidth="750px" zIndex="999" {...getTableProps()}>
      <TableHeader headerGroups={headerGroups} />

      {isLoading ? (
        <Box alignContent="center" justifyItems="center" p={1} w="100%">
          {SKELETON_SIX_ROWS.map((i) => (
            <Skeleton key={i} h={'14'} w={'100%'} colorScheme={'cyan'} mb={2} />
          ))}
        </Box>
      ) : (
        <Box>
          <TableBody
            rows={page}
            isLoading={isLoading}
            prepareRow={prepareRow}
            {...getTableBodyProps()}
          />
          {pageOptions.length > 1 && (
            <TablePaginator
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageOptions={pageOptions}
              pageIndex={pageIndex}
              canNextPage={canNextPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

function DefaultFilter(rows, id, filterValue) {
  return rows.filter((row) => {
    try {
      const cellValue = row.values[id]
      let valueToCheck = ''
      if (Array.isArray(cellValue)) {
        valueToCheck = cellValue[0].filterValue as string
      } else {
        if (cellValue) valueToCheck = cellValue.filterValue
      }
      if (valueToCheck) {
        return (
          valueToCheck.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
        )
      }
      return false
    } catch (error) {
      console.error('Error filtering row:', error)
      return false
    }
  })
}

export function TableDataProp(value: any, filterValue?: string) {
  return { value, filterValue }
}

export type TableHeaders<T> = Array<{
  accessor: T
  header?: string
  show?: boolean
  width?: number
  minWidth?: string
  enableFilter?: boolean
}>

interface TableInput {
  data: any
  headers: TableHeaders<string>
  loading: boolean
  pageSize?: number
}

export const Table: React.FC<TableInput> = (input) => {
  const columnData = React.useMemo(
    () =>
      input.headers.map((header) => {
        const width = header.width ? header.width : 0.75
        const minWidth = header.minWidth ? header.minWidth : '0px'
        const enableFilter = header.enableFilter ? header.enableFilter : false

        let column = {
          key: header.accessor,
          accessor: header.accessor,
          Header: header.header,
          show: header.show,
          minWidth: minWidth,
          width: width,
          disableFilters: !!!enableFilter,
          filter: DefaultFilter
        }

        return column
      }),
    []
  )

  // Set the headers
  const columns = React.useMemo(() => columnData, [])

  const defPageSize =
    input.pageSize === undefined || input.pageSize <= 0 ? 10 : input.pageSize

  return (
    <Tableizer
      columns={columns}
      data={input.data}
      isLoading={input.loading}
      defaultPageSize={defPageSize}
    />
  )
}
