import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Text,
  useToast,
  Stack
} from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { createBond, updateBond } from '../../../api'
import { Button } from '../../../components/Button'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { InputWarning } from '../../../styled/InputWarning'

import { IBond, IOrganization } from '../../../types'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  bondToCancel?: IBond
  organization?: IOrganization
}
export const CreateBondModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  bondToCancel,
  organization
}) => {
  const toast = useToast()

  const { platformTenant } = usePlatformTenant()
  const [members, setMembers] = useState<{ id: number; name: string }[]>()

  const [memberId, setMemberId] = useState(0) // default is zero: used to differentiate menu item or additional action

  useEffect(() => {
    setMembers(
      organization && organization.members
        ? organization?.members.map((member) => ({
            name: member.user.name,
            id: member.user.id
          }))
        : []
    )
  }, [])

  function validateRate(value) {
    let error
    if (!value) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minW="450px" borderRadius="6px">
        <ModalHeader pb={5}>
          {bondToCancel ? 'Cancel' : 'New'} bond "{organization?.name}"
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            category: bondToCancel?.category,
            organization: bondToCancel?.organization ?? organization?.id,
            location: bondToCancel?.location,
            user: bondToCancel?.user,
            date_paid:
              bondToCancel?.date_paid &&
              typeof bondToCancel.date_paid === 'string'
                ? new Date(Date.parse(bondToCancel?.date_paid))
                : new Date(),
            date_returned:
              bondToCancel?.date_returned &&
              typeof bondToCancel.date_returned === 'string'
                ? new Date(Date.parse(bondToCancel?.date_returned))
                : undefined,
            amount_paid: bondToCancel?.amount_paid ?? 0,
            amount_returned: bondToCancel?.amount_returned ?? 0,
            notes_given: bondToCancel?.notes_given ?? ''
          }}
          validate={(values): IBond => {
            const errors: any = {}
            if (!bondToCancel) {
              if (!values.category) {
                errors.category = 'Category cannot be empty'
              }
              if (!values.location) {
                errors.location = 'Location cannot be empty'
              }
              if (validateRate(values.amount_paid)) {
                errors.amount_paid = validateRate(values.amount_paid)
              }
            } else {
              if (validateRate(values.amount_returned)) {
                errors.amount_returned = validateRate(values.amount_returned)
              }
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)

            const newBond: IBond = values

            if (bondToCancel?.id) {
              updateBond(bondToCancel.id, newBond)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Bond successfully cancelled'
                  })
                  onClose()
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    description: 'Failed to cancel bond'
                  })
                })
                .finally(() => {
                  setMemberId(0)
                  setSubmitting(false)
                })
            } else {
              createBond(newBond)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Bond Created'
                  })
                  onClose()
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    description: 'Failed to create Bond'
                  })
                })
                .finally(() => {
                  setSubmitting(false)
                })
            }
          }}
        >
          {({ values, setFieldValue, isSubmitting, touched, errors }) => (
            <Form>
              <ModalBody pb={5}>
                {!bondToCancel ? (
                  <Stack spacing={2}>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#4F4F4F"
                        fontSize="sm"
                        mb={1}
                      >
                        Category
                      </FormLabel>

                      <StyledSelect
                        placeholder="Select a Category"
                        value={values.category}
                        isRequired={true}
                        name="category"
                        onChange={(e) => {
                          setFieldValue('category', e.target.value)
                        }}
                      >
                        <option key={'LOCKER'} value={'LOCKER'}>
                          {'Locker'}
                        </option>
                        <option key={'SWIPE_CARD'} value={'SWIPE_CARD'}>
                          {'Swipe Card'}
                        </option>
                      </StyledSelect>
                      <ErrorMessage name="category" component={InputWarning} />
                    </FormControl>
                    <FormControl>
                      {' '}
                      <FormLabel
                        fontWeight="bold"
                        color="#4F4F4F"
                        fontSize="sm"
                        mb={1}
                      >
                        Location
                      </FormLabel>
                      <StyledSelect
                        placeholder="Select a Location"
                        value={values.location?.id}
                        isRequired={true}
                        name="location"
                        onChange={(e) => {
                          setFieldValue('location', parseInt(e.target.value))
                        }}
                      >
                        {platformTenant?.locations?.map((location, i) => (
                          <option key={i} value={location.id}>
                            {location.name}
                          </option>
                        ))}
                      </StyledSelect>
                      <ErrorMessage name="location" component={InputWarning} />
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#4F4F4F"
                        fontSize="sm"
                        mb={1}
                      >
                        Amount Paid
                      </FormLabel>
                      <StyledInput
                        placeholder="Amout Paid"
                        w="100%"
                        value={values.amount_paid || ''}
                        onChange={(e) => {
                          setFieldValue('amount_paid', e.target.value)
                        }}
                      ></StyledInput>
                      <ErrorMessage
                        name="amount_paid"
                        component={InputWarning}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        fontWeight="bold"
                        color="#4F4F4F"
                        fontSize="sm"
                        mb={1}
                      >
                        Assign to a member
                      </FormLabel>

                      <StyledSelect
                        placeholder="Select a Member"
                        value={values.user}
                        name="user"
                        onChange={(e) => {
                          setFieldValue('user', parseInt(e.target.value))
                        }}
                      >
                        {members?.map((member, i) => (
                          <option key={i} value={member.id}>
                            {member.name}
                          </option>
                        ))
                        /* .push(
                          <option key={0} value={0}>
                            None
                          </option>
                        ) */
                        }
                      </StyledSelect>
                    </FormControl>
                    <Field
                      name="notes_given"
                      render={({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.notes_given && form.touched.notes_given
                          }
                          pt={2}
                          pb={5}
                        >
                          <FormLabel htmlFor="notes_given">Notes</FormLabel>
                          <Textarea
                            {...field}
                            id="notes_given"
                            placeholder="Any additional notes on the bond?"
                            pl={4}
                            variant="flushed"
                            bg="#FAFAFA"
                            fontSize="md"
                            borderColor="eastbay07"
                            borderBottom="1px"
                            resize="vertical"
                            size="sm"
                            _invalid={{
                              border: '2px solid',
                              borderColor: '#E1202A'
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.notes_given}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Stack>
                ) : (
                  <Box>
                    <Text>{bondToCancel.amount_paid}</Text>
                    <Field
                      name="amount_returned"
                      validate={validateRate}
                      render={({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors.amount_returned &&
                            form.touched.amount_returned
                          }
                        >
                          <FormLabel htmlFor="value">Amount returned</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              children="$"
                              pr="1.5rem"
                              color="eastBay05"
                            />
                            <StyledInput
                              {...field}
                              id="amount_returned"
                              placeholder="Amount returned"
                              w="100%"
                            />
                          </InputGroup>

                          <ErrorMessage
                            name="amount_returned"
                            component={InputWarning}
                          />
                        </FormControl>
                      )}
                    />
                    <Field
                      name="date_returned"
                      render={({ field, form }) => (
                        <FormControl
                          mr={4}
                          isInvalid={
                            form.errors.date_returned &&
                            form.touched.date_returned
                          }
                          pt={2}
                          pb={5}
                        >
                          <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                            Date returned
                          </FormLabel>
                          <Calendar
                            selectRange={false}
                            onChange={(date) => {
                              setFieldValue('date_returned', date as Date)
                            }}
                            value={form.values.date_returned}
                            // minDate={new Date()}
                          />

                          <FormErrorMessage>
                            {form.errors.date_returned}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />

                    <Field
                      name="notes_given"
                      render={({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.notes_given && form.touched.notes_given
                          }
                          pt={2}
                          pb={5}
                        >
                          <FormLabel htmlFor="notes_given">Notes</FormLabel>
                          <Textarea
                            {...field}
                            id="notes_given"
                            placeholder="Was there something to note about the return?"
                            pl={4}
                            variant="flushed"
                            bg="#FAFAFA"
                            fontSize="md"
                            borderColor="eastbay07"
                            borderBottom="1px"
                            resize="vertical"
                            size="sm"
                            _invalid={{
                              border: '2px solid',
                              borderColor: '#E1202A'
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.notes_given}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Box>
                )}
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                  mr={3}
                >
                  Save
                </Button>
                <Button
                  disabled={isSubmitting}
                  variant="secondary"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
