import { Box, Center, Skeleton, Text } from '@chakra-ui/react'
import { formatDistance } from 'date-fns'
import React, { useState } from 'react'
import { Button } from '../../../components/Button'
import { StatusBox } from '../../../components/StatusBox'
import { Table, TableDataProp } from '../../../components/table/Table'
import { IAccountDeletionRequest } from '../../../types'

import { TeamLink } from './TeamLink'
import { ReviewUserDeletionRequestModal } from '../Modals/ReviewUserDeletionRequestModal'
import { revalidateUserDeletionRequestData } from '../../../api'

function RenderStatus(status: string) {
  return (
    <StatusBox
      status={status}
      mapping={{
        PENDING: { color: 'yellow' },
        ERROR: { color: 'negative' },
        COMPLETED: { color: 'positive' },
        APPROVED: { color: 'positive' },
        CHANGE: { color: 'positive' },
        CANCELLATION: { color: 'negative' }
      }}
    />
  )
}

interface IInputParams {
  pendingRequests: IAccountDeletionRequest[] | undefined
  status?: string
  revalidateRequests: () => void
  pagination?: {
    fetchMoreResults: () => void
    hasMoreResults: boolean
    isLoading: boolean
    count: number
  }
}

export const UserDeletionRequests: React.FC<IInputParams> = (input) => {
  const { pendingRequests, pagination, revalidateRequests } = input
  const titleStatus = input.status ? input.status.toLowerCase() : 'All'
  const [isReloading, setIsReloading] = useState(false)

  const [requestToReview, setRequestToReview] = useState<
    IAccountDeletionRequest | undefined
  >()

  const reviewRequestAction = {
    text: 'Review Request',
    testIdPrefix: 'review-request',
    action: (rowId) => {
      const requestObj = pendingRequests!.find((obj) => obj.id === rowId)
      if (requestObj) {
        setRequestToReview(requestObj)
      }
    }
  }

  const revalidateRequestAction = {
    text: 'Reload Request Data',
    testIdPrefix: 'reload-request-data',
    action: (rowId) => {
      if (isReloading) return
      const requestObj = pendingRequests!.find((obj) => obj.id === rowId)
      if (requestObj) {
        setIsReloading(true)
        revalidateUserDeletionRequestData({ requestId: requestObj.id }).then(
          (res) => {
            setIsReloading(false)
            revalidateRequests()
          }
        )
      }
    }
  }

  if (pendingRequests === undefined) return null

  const tableData = pendingRequests
    ? pendingRequests.map((request) => {
        const cleanedData = {
          id: TableDataProp(request.id),
          test_prefix: TableDataProp(request.requested_by?.name),
          status: TableDataProp(RenderStatus(request.status)),
          requested: TableDataProp(
            formatDistance(Date.parse(request.created_at), new Date(), {
              addSuffix: true
            })
          ),
          requested_for: request.requested_for
            ? [
                TableDataProp(request.requested_for?.name),
                TableDataProp(request.requested_for?.email)
              ]
            : TableDataProp(request.requested_for_email),
          teams: request.teams?.map((team) => {
            return TableDataProp(
              TeamLink({
                organizationId: team.id,
                organizationName: team.name
              })
            )
          }),

          notes: !request.requested_for
            ? TableDataProp('User Removed')
            : request.notes_list?.map((note) => {
                return TableDataProp(note)
              })
        }

        const actions: any = []
        if (request.status === 'PENDING') {
          actions.push(reviewRequestAction)
          actions.push(revalidateRequestAction)
        }

        cleanedData['actions'] = TableDataProp(actions)

        return cleanedData
      })
    : []

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box display="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {titleStatus} user deletion requests
          </Text>
          <Text fontSize="xs" color="grey">
            {pagination
              ? pagination.count
              : pendingRequests
              ? pendingRequests.length
              : ''}{' '}
            Total
          </Text>
        </Box>

        <Table
          pageSize={pagination ? 100 : 10}
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'Status', accessor: 'status', show: true },
            { header: 'Requested', accessor: 'requested', show: true },
            { header: 'User', accessor: 'requested_for', show: true },
            { header: 'Teams', accessor: 'teams', show: true },
            { header: 'Notes', accessor: 'notes', show: true },
            { header: 'Actions', accessor: 'actions', show: true }
          ]}
          data={tableData}
          loading={false}
        />
      </Box>
      {pagination && (
        <Center display={'block'}>
          {pagination.isLoading && <Skeleton my={3} w={'100%'} h="60px" />}

          {pagination.hasMoreResults &&
            pendingRequests &&
            pendingRequests?.length > 0 && (
              <Center>
                <Button
                  disabled={pagination.isLoading || !pagination.hasMoreResults}
                  onClick={pagination.fetchMoreResults}
                  mt={3}
                  p={3}
                >
                  Fetch more requests
                </Button>
              </Center>
            )}
        </Center>
      )}
      <ReviewUserDeletionRequestModal
        request={requestToReview}
        closeModalCallback={() => {
          setRequestToReview(undefined)
          revalidateRequests()
        }}
      />
    </div>
  )
}
