import { FERN_ALLENDALE_PLAN_UUID, ILUMA_PLAN_UUID } from "./utils/constants"

export const routeStrings = {
  home: '/',
  login: '/login',
  logout: '/logout',
  location: '/start/location',
  plan: '/start/plan',
  notFound: '/not-found',
  locationPlans: (locationId:number) =>  `/start/plan/?loc=${locationId}`,
  fullExternalMeetingRoomURL: (room_slug:string) =>  `${window.location.origin}/external/meeting-rooms/${room_slug}`,
  relativeExternalMeetingRoomURL: (room_slug:string) =>  `/external/meeting-rooms/${room_slug}`,
  startAllendaleFernPlan: `/start/plan/${FERN_ALLENDALE_PLAN_UUID}`,
  startIlumaPlan: `/start/plan/${ILUMA_PLAN_UUID}`,
  getStartExternalSignup: (tenantID:number, name?: string) =>  `/external/start/${name??"signup"}-${tenantID}`,
    
  singlePageSignup: '/start/single-page',
  details: '/start/details',
  termsAndConditions: '/start/view-terms-and-conditions',
  acceptTermsAndConditions: '/start/terms-and-conditions',
  thankyou: '/start/thank-you',
  externalThankyou: '/external/thank-you',
  externalSignupMain: '/external/start/:tenant',
  completeLanding: '/complete',
  completeProfile1: '/complete/profile/1',
  completeProfile2: '/complete/profile/2',
  completeProfile3: '/complete/profile/3',
  completeBookOnboarding: '/complete/book-onboarding',
  memberAwaitingApproval: '/awaiting-approval',
  memberDashboardHome: '/dashboard/home',
  memberDashboardHomeContentExplorer: '/dashboard/home?tab=explorer',
  memberDashboardBilling: '/management/billing',
  memberDashboardMembership: '/dashboard/membership',
  memberDashboardChangePlan: '/management/change-plan',
  memberDashboardChangePlanSuccess: '/management/change-plan/success',
  memberDashboardCancelPlan: '/management/cancel-plan',
  memberDashboardCancelPlanSuccess: '/management/cancel-plan/success',
  memberDashboardProfile: '/dashboard/profile',
  memberDashboardMembersDirectory: '/dashboard/directory',
  memberDashboardMeetingRooms: '/dashboard/meeting-rooms',
  memberDashboardBookings: '/dashboard/bookings/',
  memberDashboardWorkspaceBookings: '/dashboard/workspace-bookings/',
  memberDashboardWorkspaces: '/dashboard/workspaces/',
  memberDashboardCreateNewBooking: '/dashboard/bookings/new',
  memberDashboardCalendar: '/dashboard/calendar',
  memberDashboardBooking: '/dashboard/booking/:bookingId',
  getMemberDashboardBooking: (locationId:number) =>  `/dashboard/booking/${locationId}`,
  memberDashboardMeetingRoomView: '/dashboard/meeting-rooms/:memberId/view',
  memberDashboardMeetingRoomHistory: '/dashboard/meeting-rooms-history',
  memberDashboardBillingInfo: '/management/billing/info',
  memberDashboardBillingUpdate: '/management/billing/update',
  memberDashboardBillingUpdateSuccess: '/management/billing/update/success',
  memberDashboardBillingInvoice: '/management/billing/invoice/:invoiceId',
  memberDashboardBillingInvoiceView: '/management/billing/invoice/:invoiceId/view',
  memberDashboardCreatePost: '/dashboard/create-post',
  memberDashboardViewPost: (contentId: number) => `/dashboard/content/${contentId}`,
  memberDashboardEditPost: (contentId: number) => `/dashboard/content/edit/${contentId}`,
  adminDashboard: '/admin/dashboard',
  adminDashboardCommunity: '/admin/dashboard/Community',
  adminDashboardAwaitingMembers: '/admin/dashboard/awaiting-members',
  adminDashboardMeetingRooms: '/admin/dashboard/meeting-rooms',
  adminDashboardMeetingRoomsAdmin: '/admin/dashboard/meeting-rooms-admin',
  adminDashboardMeetingRoomsBookings: '/admin/dashboard/meeting-rooms-bookings',
  adminDashboardWorkspaces: '/admin/dashboard/workspaces',
  adminDashboardWorkspacesAdmin: '/admin/dashboard/workspaces-admin',
  adminDashboardWorkspacesBookings: '/admin/dashboard/workspaces-admin-bookings',
  adminDashboardMembers: '/admin/dashboard/members',
  adminDashboardTeams: '/admin/dashboard/teams',

  /** DEPRECATED */
  adminDashboardUnpaidInvoices: '/admin/dashboard/unpaid-invoices',
  adminDashboardInvoiceView: '/admin/dashboard/invoice/:invoiceId/view',
  adminDashboardPendingRequests: '/admin/dashboard/pending-requests',
  adminDashboardLocations: '/admin/dashboard/locations',
  adminDashboardPlatformTenantSettings: "/admin/dashboard/coworking-business",
  adminDashboardReports: "/admin/dashboard/reports",
  adminDashboardBilling: (orgId: number) => `/admin/management/billing/${orgId}`,
  AdminDashboardPlans: '/admin/dashboard/plans',
  onboarding0Invite: '/invite/new/:token',
  onboarding1Email: '/invite/email',
  onboarding2Name: '/invite/name',
  onboarding3Details: '/invite/details',
  onboarding4Title: '/invite/job-titile',
  onboarding5SkillsAndBio: '/invite/skills-and-bio',
  onboarding6Password: '/invite/password',
  onboarding7Completed: '/invite/complete',
  teamAdminDashboardBilling: '/management/billing',
  existingMemberTeamInvite: "/dashboard/accept-invite/:token",

  teamAdminDashboardHome: '/management/home',
  teamDashboardHome: '/dashboard/team',
  teamAdminMemberProfileView: '/dashboard/team/member/:ouId',
  adminTeamDetailView: (orgId: number) => `/admin/dashboard/teams/${orgId}`,
  adminDashboardTeamSettings: (orgId: number) => `/admin/dashboard/teams/${orgId}/settings`,
  onboardingTenant0Invite: '/invite/tenant/new/:token',
  onboardingTenant6Password: '/invite/tenant/password',
  onboardingTenant1BusinessSettings: '/admin/onboarding/business',
  onboardingTenantIntegrations: '/admin/onboarding/integrations',
  onboardingTenantComplete: '/admin/onboarding/complete',
}

export const getTeamMemberProfileUrl = (orgId: number, ouId: number, isStaffUser: boolean) =>
  isStaffUser ? `/admin/dashboard/teams/${orgId}/member/${ouId}` :
    `/dashboard/team/member/${ouId}`