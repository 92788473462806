import { createIcon } from "@chakra-ui/react"
import React from "react"

export const CustomForwardChevronIcon = createIcon({
    displayName: "ForwardChevronIcon",
    path: <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.998154 19.0152C1.48815 19.5052 2.27815 19.5052 2.76815 19.0152L11.0782 10.7052C11.4682 10.3152 11.4682 9.68518 11.0782 9.29518L2.76815 0.985176C2.27815 0.495176 1.48815 0.495176 0.998154 0.985176C0.508154 1.47518 0.508154 2.26518 0.998154 2.75518L8.23815 10.0052L0.988154 17.2552C0.508154 17.7352 0.508154 18.5352 0.998154 19.0152Z" fill="black" fill-opacity="0.54" />
    </svg>
})
export const BellIcon = createIcon({
    displayName: "BellIcon",
    viewBox: '0 0 16 20',
    // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
    path: (
        <path
            d="M15.5 16.2071V16.5H0.5V16.2071L2.35355 14.3536L2.5 14.2071V14V9C2.5 6.09437 4.02219 3.78092 6.61528 3.16653L7 3.07538V2.68V2C7 1.44614 7.44614 1 8 1C8.55386 1 9 1.44614 9 2V2.68V3.07506L9.38434 3.16644C11.9681 3.78076 13.5 6.10482 13.5 9V14V14.2071L13.6464 14.3536L15.5 16.2071ZM9.41352 18.5C9.20605 19.0806 8.64884 19.5 8 19.5C7.34433 19.5 6.79074 19.0813 6.58536 18.5H9.41352Z"
            stroke="currentColor"
            strokeOpacity="0.7"
            fill="none"
        />
    ),
})

export const DotsIcon = createIcon({
    displayName: "DotsIcon",
    path: (
        <path
            d="M11 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM20 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
            fill="currentColor"
        />
    ),
    viewBox: '0 0 22 4'

})
export const TeamMembersIcon = createIcon({
    displayName: "TeamMembersIcon",
    path: <path
        d="M9 8.836c0-.604-.265-1.179-.738-1.554C7.539 6.708 6.285 6 4.5 6S1.461 6.708.738 7.282C.265 7.657 0 8.232 0 8.836V10h9V8.836zM4.5 5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zM11.5 5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zM15.262 7.282C14.539 6.708 13.285 6 11.5 6c-.561 0-1.063.075-1.519.189A3.953 3.953 0 0 1 11 8.836V10h5V8.836c0-.604-.265-1.179-.738-1.554z"
        fill="currentColor"
    />,
    viewBox: '0 0 16 10'

})
export const MeetingRoomsIcon = createIcon({
    displayName: "MeetingRoomsIcon",
    path: <svg
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM9 7a1 1 0 0 0 0-2H2a2 2 0 0 0-2 2v8a1 1 0 1 0 2 0v-4h1v4a1 1 0 1 0 2 0V7h4z"
            fill="currentColor"
        />
        <path
            d="M15 1H7v2h7v7H7v2h3.382l1.723 3.447a1 1 0 1 0 1.79-.894L12.618 12H15a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"
            fill="currentColor"
        />
    </svg>,
    viewBox: '0 0 16 16'

})
export const BillingIcon = createIcon({
    displayName: "BillingIcon",
    path: (
        <svg
            width="16"
            height="12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 3V1c0-.6-.4-1-1-1H1C.4 0 0 .4 0 1v2h16zM0 5v6c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5H0zm6 4H2V8h4v1zm8 0h-2V8h2v1z"
                fill="currentColor"
            />
        </svg>
    ),
    viewBox: '0 0 16 12'

})
export const CheckCustomIcon = createIcon({
    displayName: "CheckCustomIcon",
    path: (
        <svg
            width="33"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <ellipse cx="16.729" cy="16" rx="16.271" ry="16" fill="#fff" />
                <path
                    d="M16.73 0c-3.219 0-6.365.938-9.04 2.696a16.061 16.061 0 0 0-5.993 7.181 15.752 15.752 0 0 0-.926 9.244 15.92 15.92 0 0 0 4.453 8.193 16.359 16.359 0 0 0 8.33 4.378c3.157.618 6.429.3 9.402-.91a16.215 16.215 0 0 0 7.302-5.893A15.81 15.81 0 0 0 33 15.999 15.913 15.913 0 0 0 28.22 4.7 16.46 16.46 0 0 0 16.73 0zm-3.052 22.414L8.173 17l1.438-1.414 4.067 4 10.17-10L25.286 11 13.678 22.414z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        transform="translate(.458)"
                        d="M0 0h32.542v32H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    ),
    viewBox: '0 0 32 32'

})
export const SupportIcon = createIcon({
    displayName: "SupportIcon",
    path: (
        <svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-8c.9 0 1.8.2 2.6.6L9.044 4.156c-.761-.207-1.327-.207-2.089 0L5.4 2.6C6.2 2.2 7.1 2 8 2zM2 8c0-.9.2-1.8.6-2.6l1.556 1.556c-.207.761-.207 1.327 0 2.089L2.6 10.6C2.2 9.8 2 8.9 2 8zm6 6c-.9 0-1.8-.2-2.6-.6l1.556-1.556c.761.207 1.327.207 2.089 0L10.6 13.4c-.8.4-1.7.6-2.6.6zm5.4-3.4l-1.556-1.556c.207-.761.207-1.327 0-2.089L13.4 5.4c.4.8.6 1.7.6 2.6 0 .9-.2 1.8-.6 2.6z"
                fill="currentColor"
            />
        </svg>
    ),
    viewBox: '0 0 16 16'

})
export const CrossCustomIcon = createIcon({
    displayName: "CrossCustomIcon",
    path: (
        <svg
            width="34"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <ellipse cx="16.729" cy="16" rx="16.271" ry="16" fill="#fff" />
                <path
                    d="M16.729 0c-3.218 0-6.364.938-9.04 2.696a16.061 16.061 0 0 0-5.993 7.181 15.752 15.752 0 0 0-.926 9.244 15.92 15.92 0 0 0 4.453 8.193 16.36 16.36 0 0 0 8.331 4.379c3.157.617 6.428.3 9.401-.91a16.215 16.215 0 0 0 7.303-5.894A15.81 15.81 0 0 0 33 16a15.913 15.913 0 0 0-4.78-11.3A16.46 16.46 0 0 0 16.73 0zm7.54 22l-1.439 1.414-6.101-6-6.102 6L9.189 22l6.102-6-6.102-6 1.438-1.414 6.102 6 6.101-6L24.268 10l-6.101 6 6.101 6z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path
                        fill="#fff"
                        transform="translate(.458)"
                        d="M0 0h32.542v32H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    ),
    viewBox: '0 0 34 32'

})
export const ArrowBackIcon = createIcon({
    displayName: "ArrowBackIcon",
    path: (
        <path
            fill="currentColor"
            d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
        />
    )

})
export const LogoutIcon = createIcon({
    displayName: "LogoutIcon",
    path: (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <g fill="currentColor">
                <path d="M3.4 2H8v2h2V1c0-.6-.4-1-1-1H1C.4 0 0 .4 0 1v9c0 .3.1.5.3.7l5 5c.2.2.4.3.7.3.1 0 .3 0 .4-.1.4-.1.6-.5.6-.9V6c0-.3-.1-.5-.3-.7L3.4 2zM5 12.6l-3-3V3.4l3 3v6.2z" />
                <path
                    data-color="currentColor"
                    d="M15.7 7.3L12 3.6 10.6 5l2 2H8v2h4.6l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4z"
                />
            </g>
        </svg>
    ),
    viewBox: '0 0 16 16'

})
export const CollapseIcon = createIcon({
    displayName: "CollapseIcon",
    path: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <g fill="currentColor">
                <path
                    d="M22 9.5a1.5 1.5 0 0 0-1.061.439L12 18.879l-8.939-8.94a1.5 1.5 0 0 0-2.122 2.122l10 10a1.5 1.5 0 0 0 2.122 0l10-10A1.5 1.5 0 0 0 22 9.5z"
                    data-color="currentColor"
                />
                <path d="M22 1.5a1.5 1.5 0 0 0-1.061.439L12 10.879l-8.939-8.94A1.5 1.5 0 0 0 .939 4.061l10 10a1.5 1.5 0 0 0 2.122 0l10-10A1.5 1.5 0 0 0 22 1.5z" />
            </g>
        </svg>
    ),
    viewBox: '0 0 24 24'

})
export const UserIcon = createIcon({
    displayName: "UserIcon",
    path: (
        <svg
            data-icon="user"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
        >
            <path
                d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"
                fill="currentColor"
            />
        </svg>
    ),
    viewBox: '64 64 896 896'

})
export const DashboardIcon = createIcon({
    displayName: "DashboardIcon",

    path: (
        <svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 9H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zM6 16H1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1zM15 6h-5a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM15 16h-5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z"
                fill="currentColor"
            />
        </svg>
    ),
    viewBox: '0 0 16 16'
})
export const GroupSizeIcon = createIcon({
    displayName: "GroupSizeIcon",
    path: (
        <svg width="12" height="12" fill="none">
            <path
                d="M6 4.5c-1.275 0-2.25-.975-2.25-2.25S4.725 0 6 0s2.25.975 2.25 2.25S7.275 4.5 6 4.5zM7.5 12h-3V9L3 7.5V5.25h6V7.5L7.5 9v3zM1.5 3.75c-.825 0-1.5-.675-1.5-1.5S.675.75 1.5.75 3 1.425 3 2.25s-.675 1.5-1.5 1.5zM2.25 7.8V4.5H0v2.25l.75.75v3H3V8.55l-.75-.75zM10.5 3.75c.825 0 1.5-.675 1.5-1.5s-.675-1.5-1.5-1.5S9 1.425 9 2.25s.675 1.5 1.5 1.5zM9.75 7.8V4.5H12v2.25l-.75.75v3H9V8.55l.75-.75z"
                fill="currentColor"
            />
        </svg>
    ),
    viewBox: '0 0 12 12 '

})
export const CalendarCustomIcon = createIcon({
    displayName: "CalendarCustomIcon",
    path: (
        <svg width="16" height="16" fill="none">
            <path
                d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1c-.553 0-1 .447-1 1v12c0 .553.447 1 1 1h14c.553 0 1-.447 1-1V3c0-.553-.447-1-1-1zm-1 12H2V5h12v9z"
                fill="currentColor"
            />
            <path
                d="M6 7H4v2h2V7zM9 7H7v2h2V7zM6 10H4v2h2v-2zM9 10H7v2h2v-2zM12 7h-2v2h2V7zM12 10h-2v2h2v-2z"
                fill="currentColor"
            />
        </svg>
    ),
    viewBox: '0 0 16 16'

})
export const TelIcon = createIcon({
    displayName: "TelIcon",
    path: (
        <svg width="32" height="32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#24A148" />
            <path
                d="M23.285 20.3043l-2.578-2.594c-.39-.393-1.025-.393-1.416-.002L17 19.9993l-5-5 2.294-2.294c.39-.39.391-1.023.001-1.414l-2.58-2.58399c-.391-.391-1.024-.391-1.415 0L8.004 11.0023 8 10.9993c0 7.18 5.82 13 13 13l2.283-2.283c.39-.39.391-1.021.002-1.412z"
                fill="#fff"
            />
        </svg>
    ),
    viewBox: '0 0 32 32'

})
export const MailIcon = createIcon({
    displayName: "MailIcon",
    path: (
        <svg width="32" height="32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#247FFF" />
            <path
                d="M23 9H9c-.6 0-1 .4-1 1v1.4l8 4.5 8-4.4V10c0-.6-.4-1-1-1z"
                fill="#fff"
            />
            <path
                d="M15.5 17.9002l-7.5-4.2v8.3c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-8.3l-7.5 4.2c-.28.14-.72.14-1 0z"
                fill="#fff"
            />
        </svg>
    ),
    viewBox: '0 0 32 32'

})
export const SlackIcon = createIcon({
    displayName: "SlackIcon",

    path: (
        <svg width="32" height="32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#EB5757" />
            <path
                d="M13.8668 8a1.5998 1.5998 0 0 0-1.6 1.6 1.6001 1.6001 0 0 0 1.6 1.6h1.6V9.6a1.5998 1.5998 0 0 0-1.6-1.6M13.867 12.2666H9.6a1.6002 1.6002 0 0 0-1.6 1.6 1.6002 1.6002 0 0 0 1.6 1.6h4.267c.4243 0 .8313-.1686 1.1314-.4686a1.6004 1.6004 0 0 0 0-2.2628 1.6004 1.6004 0 0 0-1.1314-.4686zM24 13.8666a1.6 1.6 0 1 0-3.2 0v1.6h1.6a1.6 1.6 0 0 0 1.6-1.6zM19.733 13.867V9.6a1.5999 1.5999 0 0 0-2.7314-1.1314c-.3001.3-.4686.707-.4686 1.1314v4.266a1.6 1.6 0 1 0 3.2 0M18.133 23.9998a1.6 1.6 0 0 0 0-3.2h-1.6v1.6a1.6 1.6 0 0 0 1.6 1.6zM18.133 19.7332H22.4a1.6 1.6 0 0 0 0-3.2h-4.267a1.6 1.6 0 1 0 0 3.2zM8 18.1332a1.6 1.6 0 1 0 3.2 0v-1.6H9.6a1.6002 1.6002 0 0 0-1.6 1.6M12.2668 18.1332v4.267a1.6001 1.6001 0 0 0 3.2 0v-4.267a1.6 1.6 0 1 0-3.2 0z"
                fill="#fff"
            />
        </svg>
    ),
    viewBox: '0 0 32 32'

})
export const Icon = createIcon({
    displayName: "MeetingRoomIcon",
    path: (
        <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#a)" fill="#F08409">
                <path d="m4 6c1.6568 0 3-1.3432 3-3s-1.3432-3-3-3-3 1.3432-3 3 1.3432 3 3 3z" />
                <path d="M16 9V7H3C2.46957 7 1.96086 7.21071 1.58579 7.58579C1.21071 7.96086 1 8.46957 1 9V24H3V17H5V24H7V9H16Z" />
                <path d="m23 2h-14v2h13v11h-13v2h7.341l2.74 6.394c0.0771 0.1798 0.2053 0.3331 0.3686 0.4408 0.1634 0.1078 0.3547 0.1652 0.5504 0.1652 0.1355 6e-4 0.2697-0.027 0.394-0.081 0.2437-0.1045 0.4359-0.3016 0.5344-0.5478 0.0984-0.2462 0.0951-0.5215-0.0094-0.7652l-2.403-5.606h4.484c0.2652 0 0.5196-0.1054 0.7071-0.2929s0.2929-0.4419 0.2929-0.7071v-13c0-0.26522-0.1054-0.51957-0.2929-0.70711-0.1875-0.18753-0.4419-0.29289-0.7071-0.29289z" />
            </g>
            <defs>
                <clipPath id="a">
                    <rect width="24" height="24" fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    )

})
export const CapacityGroupIcon = createIcon({
    displayName: "CapacityGroupIcon",
    path: (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 4.5C4.725 4.5 3.75 3.525 3.75 2.25C3.75 0.975 4.725 0 6 0C7.275 0 8.25 0.975 8.25 2.25C8.25 3.525 7.275 4.5 6 4.5Z"
                fill="#757575"
            />
            <path d="M7.5 12H4.5V9L3 7.5V5.25H9V7.5L7.5 9V12Z" fill="#757575" />
            <path
                d="M1.5 3.75C0.675 3.75 0 3.075 0 2.25C0 1.425 0.675 0.75 1.5 0.75C2.325 0.75 3 1.425 3 2.25C3 3.075 2.325 3.75 1.5 3.75Z"
                fill="#757575"
            />
            <path
                d="M2.25 7.8V4.5H0V6.75L0.75 7.5V10.5H3V8.55L2.25 7.8Z"
                fill="#757575"
            />
            <path
                d="M10.5 3.75C11.325 3.75 12 3.075 12 2.25C12 1.425 11.325 0.75 10.5 0.75C9.675 0.75 9 1.425 9 2.25C9 3.075 9.675 3.75 10.5 3.75Z"
                fill="#757575"
            />
            <path
                d="M9.75 7.8V4.5H12V6.75L11.25 7.5V10.5H9V8.55L9.75 7.8Z"
                fill="#757575"
            />
        </svg>
    )

})

