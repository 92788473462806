import { Box, IconButton, Button } from '@chakra-ui/react'
import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { DotsIcon } from '../icons/icons'
interface IInput {
  pageSize: number
  setPageSize: () => {}
  pageOptions: any
  pageIndex: number
  canNextPage: boolean
  nextPage: () => {}
  canPreviousPage: boolean
  previousPage: () => {}
  gotoPage: (page: number) => {}
}

export const TablePaginator: React.FC<IInput> = input => {
  const {
    pageOptions,
    pageIndex,
    canNextPage,
    nextPage,
    canPreviousPage,
    previousPage,
    gotoPage
  } = input

  const currentPage = pageIndex + 1
  const pagesShown: number[] = []
  const firstShown = currentPage - 1 > 0 ? currentPage - 1 : 1
  const lastShown =
    currentPage + 1 <= pageOptions.length ? currentPage + 1 : pageOptions.length

  for (let i = firstShown; i <= lastShown; i++) {
    pagesShown.push(i)
  }

  return (
    <Box
      textAlign="center"
      margin="auto"
      w="100%"
      position="relative"
      minHeight={16}
      padding="8px"
    >
      <IconButton
        aria-label="back"
        icon={<ChevronLeftIcon />}
        fontSize={30}
        onClick={() => previousPage()}
        isDisabled={!canPreviousPage}
      />

      {firstShown > 1 && (
        <>
          <Button onClick={() => gotoPage(0)}>{1}</Button>

          {firstShown > 2 && (
            <IconButton
              aria-label=""
              icon={<DotsIcon />}
              isDisabled={true}
            />
          )}
        </>
      )}

      {pagesShown.map(i => (
        <Button
          key={i}
          onClick={() => gotoPage(i - 1)}
          borderWidth={i === currentPage ? '2px' : ''}
          borderColor={i === currentPage ? 'brandPrimary' : ''}
          bg={i === currentPage ? '#F4F5F5' : ''}
        >
          {i}
        </Button>
      ))}

      {lastShown < pageOptions.length && (
        <>
          {pageOptions.length - lastShown > 1 && (
            <IconButton
              aria-label=""
              icon={<DotsIcon />}
              isDisabled={true}
            />
          )}
          <Button onClick={() => gotoPage(pageOptions.length - 1)}>
            {pageOptions.length}
          </Button>
        </>
      )}

      <IconButton
        aria-label="forward"
        icon={<ChevronRightIcon />}
        fontSize={30}
        onClick={() => nextPage()}
        isDisabled={!canNextPage}
      />
    </Box>
  )
}
