import { Flex, Text } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import React from 'react'

interface IModalPrivileges {
  onClose: () => void
}

export const SuperAdminPrivilegeRequiredModal: React.FC<IModalPrivileges> = ({
  onClose
}) => {
  return (
    <Flex direction="column" justify="center" align="center" p={6}>
      <Text mb={4}>
        We are sorry, you are not authorised to perform this action: minimum super admin
        privilege required.
      </Text>
      <Button
        key="submit"
        data-testid="submit"
        variant="secondary"
        onClick={onClose}
      >
        OK
      </Button>
    </Flex>
  )
}
