import {
  Box,
  Flex,
  FormControl,
  Image,
  Spacer,
  useTheme,
  Heading,
  Stack
} from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { OnboardingUser } from '../../interfaces'
import { routeStrings } from '../../routeStrings'
import { useUserDetails } from '../../context/user-onboard-context'
import { EMAIL_REGEX } from '../../utils/constants'
import { useAuth } from '../../context/auth-context'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { useRole } from '../../utils/rbac'

export const CompleteProfile1: React.FC = () => {
  const { setUserDetails, userDetails } = useUserDetails()
  const { me } = useAuth()
  const navigate = useNavigate()
  const { isExternalMember } = useRole()

  const theme: any = useTheme()
  return (
    <OnboardingWrapper>
      <Box my={6}>
        <Image
          src={theme.logos.logo_long}
          width="128px"
          className="location-logo"
        />
      </Box>
      <Formik
        enableReinitialize
        initialValues={{
          personalEmail: userDetails!.personalEmail,
          jobTitle: userDetails!.jobTitle,
          company: userDetails!.company
            ? userDetails.company
            : isExternalMember
            ? `External ${me?.user.name}`
            : ''
        }}
        validate={(values): OnboardingUser => {
          const errors: any = {}

          if (!values.personalEmail) {
            errors.personalEmail = 'Required'
          } else if (!EMAIL_REGEX.test(values.personalEmail)) {
            errors.personalEmail = 'Invalid email address'
          }

          if (!values.jobTitle) {
            errors.jobTitle = 'Required'
          }
          if (!values.company) {
            errors.company = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          values.personalEmail = values.personalEmail?.toLowerCase()
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            ...values
          }))
          navigate(routeStrings.completeProfile3)
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Box bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
              <Heading size="xs" color="gray.400" my={6}>
                Step 1 of 2
              </Heading>
              <Box mt={3}>
                <Heading size="md" my={6}>
                  Complete your Profile
                </Heading>
                <Heading size="sm" mb={2}>
                  Secure your account
                </Heading>

                <FormControl
                  isRequired
                  isInvalid={
                    touched.personalEmail && errors.personalEmail !== undefined
                  }
                >
                  <Field
                    data-testid="complete-personal-email-field"
                    type="email"
                    name="personalEmail"
                    // id="email"
                    // autoFocus={true}
                    placeholder="Backup email for account recovery"
                    component={FormInput}
                  />
                  <ErrorMessage
                    name="personalEmail"
                    component={FormErrorMessage}
                  />
                </FormControl>
              </Box>

              <Stack spacing={3} my={6}>
                <Heading size="sm">Tell us about yourself</Heading>

                <FormControl
                  isRequired
                  isInvalid={touched.jobTitle && errors.jobTitle !== undefined}
                >
                  <Field
                    type="text"
                    name="jobTitle"
                    data-testid="complete-jobTitle-field"
                    placeholder="What's your job title"
                    component={FormInput}
                  />
                  <ErrorMessage name="jobTitle" component={FormErrorMessage} />
                </FormControl>
                {!isExternalMember && (
                  <FormControl
                    isRequired
                    isInvalid={touched.company && errors.company !== undefined}
                  >
                    <Field
                      type="text"
                      name="company"
                      data-testid="complete-company-field"
                      placeholder="Name your company"
                      component={FormInput}
                    />
                    <ErrorMessage name="company" component={FormErrorMessage} />
                  </FormControl>
                )}
              </Stack>

              <Flex>
                <Spacer />
                <Button
                  my={2}
                  ml="auto"
                  type="submit"
                  data-testid="complete-next"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>
    </OnboardingWrapper>
  )
}
