import { Flex, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { ChakraModal } from '../../../components/ChakraModal'
import { Button } from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { routeStrings } from '../../../routeStrings'
import { useAuth } from '../../../context/auth-context'
import { Actions, useRBAC } from '../../../utils/rbac'

interface IModalInput {
  isOpen: boolean
  closeModal: () => void
}

export const UpdateBillingDetailsRequiredModal: React.FC<IModalInput> = (
  input
) => {
  const navigate = useNavigate()
  const { me, currentOrganization } = useAuth()
  const canViewBilling = useRBAC(Actions.ViewBillingForOwnTeam)

  const setUp = () => {
    navigate(routeStrings.memberDashboardBilling)
  }
  return (
    <ChakraModal
      title="Update payment info"
      isOpen={input.isOpen}
      onClose={input.closeModal}
    >
      <Box p={2}>
        {canViewBilling && !me?.user.is_space_admin && (
          <Text>
            Before you can book workspaces you must update your payment.
            information.
          </Text>
        )}
        {!canViewBilling && !me?.user.is_space_admin && (
          <Text>
            Before you can book workspaces a team admin from{' '}
            <b>{currentOrganization?.name}</b> must update the payment details.
          </Text>
        )}
      </Box>

      <Flex flexDirection="row" mt={10}>
        {canViewBilling && !me?.user.is_space_admin && (
          <Button key="submit" data-testid="assign" onClick={setUp} mr="8px">
            Go to Billing
          </Button>
        )}
        <Button
          data-testid="cancel"
          variant="secondary"
          onClick={input.closeModal}
        >
          Dismiss
        </Button>
      </Flex>
    </ChakraModal>
  )
}
