import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  Select,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { WorkspaceTimeLineCard } from '../../../components/WorkspaceTimelineCard'
import { useBookingContext } from '../../../context/BookingContext'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { useCustomToast } from '../../../context/toast-context'
import { routeStrings } from '../../../routeStrings'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { ISimpleBooking, IWorkspace, Location } from '../../../types'

interface IWorkspaceGroup {
  id: number //location ID
  workspaces: IWorkspace[]
}

export const MemberWorkspaces: React.FC = () => {
  const {
    loading,
    workspaces,
    chosenDate,
    locations,
    chosenLocation,
    revalOrgUnitBookings,
    handleChangeDate,
    setChosenLocation
  } = useBookingContext()

  const { platformTenant } = usePlatformTenant()
  const { newToast: toast } = useCustomToast()
  const navigate = useNavigate()

  const [mainWorkspace, setMainWorkspace] = useState<IWorkspace | undefined>()

  useEffect(() => {
    if (workspaces) {
      setMainWorkspace(workspaces.find((w) => w.is_main_space))
    } else {
      setMainWorkspace(undefined)
    }
  }, [workspaces])
  useEffect(() => {
    handleChangeDate(new Date())
  }, [])

  const bookingConfirmed = (status: boolean, data: any) => {
    if (status) {
      toast({ description: 'Booking created', status: 'success' })
      revalOrgUnitBookings()
    }
  }
  useEffect(() => {
    if (locations && locations.length > 0) {
      setChosenLocation(locations[0])
    }
  }, [locations])

  const workspacesBookings =
    workspaces && workspaces.length > 1
      ? workspaces
          .filter((w) => !w.is_main_space)
          .map((w) => w.bookings || [])
          .reduce(
            (b1: ISimpleBooking[], b2: ISimpleBooking[]) => b1.concat(b2),
            []
          )
      : []
  const mainSpaceBookings =
    workspaces && workspaces.length > 1
      ? workspaces
          .filter((w) => w.is_main_space)
          .map((w) => w.bookings || [])
          .reduce(
            (b1: ISimpleBooking[], b2: ISimpleBooking[]) => b1.concat(b2),
            []
          )
      : []

  return (
    <>
      <Box pb={6} px={8} bg="#FFFFFF" width="100%">
        <Text py={8} fontWeight="bold" fontSize="18px" color="black">
          Search for Workspaces
        </Text>
        <Grid
          templateColumns={['1fr', 'repeat(4, minmax(150px, 45%))']}
          gap={[5, 10]}
        >
          <Box>
            <CalendarFormInput
              dateProps={{
                chosenDate,
                handleChangeDate
              }}
            />
          </Box>
          <Box>
            <Text fontWeight="bold" mb="4px">
              Location
            </Text>
            <Select
              bg="#e5e5e5"
              variant="filled"
              value={chosenLocation?.id}
              onChange={(e) => {
                const loc = locations?.find((l) => l.id + '' === e.target.value)
                setChosenLocation(loc)
              }}
            >
              {locations &&
                locations.map((location, i) => (
                  <option key={i} value={location.id}>
                    {location.name} {' - '} {location.address}
                  </option>
                ))}
            </Select>
          </Box>
          <Spacer />
          <Box display="flex" alignItems="center">
            <HStack>
              <Button
                key={'action'}
                variant="secondary"
                onClick={() => {
                  navigate(routeStrings.memberDashboardWorkspaceBookings)
                }}
                data-testid=""
              >
                My Bookings
              </Button>
            </HStack>
          </Box>
        </Grid>
      </Box>

      <Flex
        bg="#F4F5F5"
        width="100%"
        height="100%"
        pos="relative"
        overflow="scroll"
      >
        {loading && (
          <Flex
            pos="absolute"
            align="center"
            justify="center"
            h="100%"
            w="100%"
            overflow="hidden"
            opacity={0.5}
            bg="#f4f5f5"
            cursor=""
          >
            <Spinner size="xl" />
          </Flex>
        )}
        <Container maxW="container.lg">
          {/* {loading && <Spinner />} */}
          {chosenLocation && chosenLocation.opening_hours ? (
            <Box mt="10px">
              <Box>
                {mainWorkspace && mainWorkspace.is_bookable && (
                  <Box w={'50%'}>
                    <Text color="gray" fontSize={'lg'} my={2}>
                      Book the whole space
                    </Text>
                    <Divider />
                    <WorkspaceTimeLineCard
                      openingHours={chosenLocation.opening_hours}
                      workspace={mainWorkspace}
                      chosenDate={chosenDate}
                      bookingConfirmed={bookingConfirmed}
                      otherBookings={workspacesBookings}
                    />
                  </Box>
                )}
                <Text color="gray" fontSize={'lg'} my={2}>
                  Book individual workspaces
                </Text>
                <Divider />
                <SimpleGrid columns={[1, 2]} spacing="10px">
                  {workspaces
                    ?.filter((w) => !w.is_main_space && w.is_bookable)
                    .map((workspace, i) => (
                      <WorkspaceTimeLineCard
                        key={i}
                        openingHours={chosenLocation.opening_hours}
                        workspace={workspace}
                        chosenDate={chosenDate}
                        bookingConfirmed={bookingConfirmed}
                        otherBookings={mainSpaceBookings}
                      />
                    ))}
                </SimpleGrid>
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Container>
      </Flex>
    </>
  )
}
