import {
  Box,
  Flex,
  HStack,
  Select,
  Skeleton,
  Spinner,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { filterOrganizationalUnits, onboardMember } from '../../api'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { AlertBox } from '../../components/AlertBox'
import { FilterableSelect } from '../../components/FilterableSelect'
import {
  GroupingHeaderLayout,
  IActionButton
} from '../../components/GroupingHeaderLayout'
import { AdminDashboardInviteMemberModal } from '../../components/modals/AdminDashboardInviteMemberModal'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useAuth } from '../../context/auth-context'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { IOrganizationalUnit } from '../../types'
import { usePlans } from '../../utils/apiHooks'
import { SKELETON_SIX_ROWS } from '../../utils/constants'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { StripeConnectRequiredModal } from './Modals/StripeConnectRequiredModal'
import { Members } from './Tables/Members'
import { DebouncedInputText } from '../../components/DebouncedInputText'

export interface TableDropdownOption {
  text: string
  testIdPrefix?: string
  variant: string
  onClick: (row: any) => void
}

export const AdminDashboardMembers: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const { currentOrgUnit } = useAuth()
  const { tenantIntegration, platformTenant } = usePlatformTenant()
  const { stripeConnectOnboardingEnabled } = useFeatureFlags()
  const [data, setData] = useState<IOrganizationalUnit[]>([])

  const [membersFilter, setMembersFilter] = useState('')
  const [total, setTotal] = useState(0)
  const page_size = 10
  const [page, setPage] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFeching] = useState(false)
  const [filterLocations, setFilterLocations] = useState<string | undefined>()
  const [planFilter, setPlanFilter] = useState<number>()

  const { plans } = usePlans(platformTenant?.id, true)

  const fetchOrgs = (
    input_page: number,
    org_unit_name: string | undefined,
    location_id?: string | undefined,
    plan_id?: number | undefined
  ) => {
    if (isFetching) return
    if (platformTenant) {
      setIsFeching(true)
      return filterOrganizationalUnits(
        platformTenant.id,
        org_unit_name,
        location_id,
        plan_id,
        undefined,
        input_page,
        page_size
      )
        .then((res) => {
          if (res && res.data) {
            let results = res.data.results ?? []
            setData(input_page > 1 ? data.concat(results) : results)
            setTotal(res.data.count ?? 0)
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }

  useEffect(() => {
    if (platformTenant) {
      setData([])
      setIsFeching(true)
      setMembersFilter('')
      setPlanFilter(undefined)
      setFilterLocations(undefined)
      setIsFeching(false)
      fetchOrgs(1, '')
    }
  }, [platformTenant])
  useEffect(() => {
    if (platformTenant && membersFilter !== undefined && !isFetching) {
      fetchOrgs(1, membersFilter, filterLocations, planFilter)
    }
  }, [membersFilter, filterLocations, planFilter])

  // Toast info module from chakra
  const toastPopup = useToast()

  const headerAction: IActionButton = {
    text: 'New Member',
    testIdPrefix: 'request-seat',
    variant: '',
    onClick: () => {
      completeTenantSetUp
        ? setSetUpModalIsVisible(true)
        : setInviteModalIsVisible(true)
    }
  }

  // State to hide/show invite modal
  const [inviteModalIsVisible, setInviteModalIsVisible] = useState(false)

  // State to hold focus on a particular member for modal popups

  const [setUpModalIsVisible, setSetUpModalIsVisible] = useState(false)

  function handleOnboarding(id: number) {
    const token = window.localStorage.getItem('Token')
    if (currentOrgUnit?.organization.parent_platform_tenant) {
      onboardMember(currentOrgUnit?.organization.parent_platform_tenant, id, {
        token
      }).then(() => {
        // revalidateOrgUnits && revalidateOrgUnits()
        toastPopup({
          position: 'top',
          duration: 5000,
          render: (onClose) => (
            <AlertBox onClose={onClose}>Member onboarded</AlertBox>
          ),
          isClosable: true
        })
      })
    }
  }

  let completeTenantSetUp =
    stripeConnectOnboardingEnabled &&
    tenantIntegration &&
    !tenantIntegration.is_stripe_connected

  const MemoizedMembers = React.memo<{
    handleOnboarding: (id: number) => void

    orgUnits: IOrganizationalUnit[]
  }>((props) => {
    return (
      <Members
        handleOnboarding={props.handleOnboarding}
        orgUnits={props.orgUnits}
      />
    )
  })

  return (
    <LayoutAdminDashboard>
      {/* Header */}
      <GroupingHeaderLayout
        basicTitle="Members"
        action={headerAction}
        totalValue={total ? total : ''}
      />
      {/* Tables */}
      <AdminBodyWrapper
        onBottomReached={() => {
          if (!hasMoreResults || isFetching || membersFilter === undefined)
            return
          fetchOrgs(page, membersFilter, filterLocations, planFilter)
        }}
      >
        <Box bg="white" w={'100%'} shadow={4} rounded="md" px={8} py={5}>
          <VStack w="100%" alignItems="left">
            <Flex>
              <Text fontWeight="medium" fontSize={22}>
                Filter search
              </Text>
              {isFetching && <Spinner ml={3} mt={3} size="sm" />}
            </Flex>
            <HStack w="100%" spacing={3}>
              <Box w={['100%', '30%']}>
                <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                  Name or email
                </Text>
                <HStack spacing={3}>
                  <DebouncedInputText
                    bg={'white'}
                    placeholder={'All members'}
                    setValue={setMembersFilter}
                    value={membersFilter}
                    data-testid="input-member-search"
                  />
                </HStack>
              </Box>
              <Box w={['100%', '30%']}>
                <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                  Location
                </Text>

                <Select
                  background="white"
                  id="memberLocation"
                  placeholder="All locations"
                  color="#8b96a3"
                  value={filterLocations}
                  onChange={(ev) => setFilterLocations(ev.target.value)}
                >
                  {platformTenant?.locations.map((loc) => {
                    return (
                      <option key={loc.name} value={loc.id}>
                        {loc.name}
                      </option>
                    )
                  })}
                </Select>
              </Box>
              <Box w={['100%', '30%', '25%']}>
                <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                  Plan
                </Text>

                <FilterableSelect
                  dataTestId="booking-select-room-id"
                  isClearable={true}
                  items={plans.map((p) => {
                    return { label: p.name, value: p.id }
                  })}
                  isOptionDisabled={(value) => value.disabled}
                  onSelect={(n) => {
                    setPlanFilter(n >= 0 ? n : undefined)
                  }}
                />
              </Box>
            </HStack>
          </VStack>
        </Box>

        <MemoizedMembers handleOnboarding={handleOnboarding} orgUnits={data} />
        {isFetching && (
          <VStack my={2} spacing={1}>
            {SKELETON_SIX_ROWS.map((i) => (
              <Skeleton key={i} w={'100%'} h={14} />
            ))}
          </VStack>
        )}
      </AdminBodyWrapper>
      {/* Modals */}
      <AdminDashboardInviteMemberModal
        revalidate={() => {}}
        visibility={{
          modalIsVisible: inviteModalIsVisible,
          setModalIsVisible: setInviteModalIsVisible
        }}
      />
      <StripeConnectRequiredModal
        isOpen={setUpModalIsVisible}
        closeModal={() => setSetUpModalIsVisible(false)}
      />
      {/* these modals require a selected user */}
    </LayoutAdminDashboard>
  )
}
