import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import { History } from 'history'

import { isProduction } from './isProduction'

export function initErrorTracking(history: History) {
  if (isProduction) {
    Sentry.init({
      integrations: [
        // new Integrations.BrowserTracing({
        //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        //   tracingOrigins: ['api.spacecubed.com']
        // })
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        }),
        Sentry.replayIntegration()
      ],
      tracesSampleRate: 0.25,
      dsn: 'https://3e4fa46249984e179b090dc5c620e218@sentry.io/1809253'
    })
  }
}
