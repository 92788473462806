import {
  Box,
  Button as ChakraButton,
  Flex,
  Spinner,
  Container,
  Spacer,
  Heading,
  Text,
  Center,
  useTheme,
  Divider,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { isFuture } from 'date-fns'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from 'use-query-params'
import {
  cancelBooking,
  getMeetingRoomTimelineByBooking,
  getWorkspaceTimelineByBooking
} from '../../../api'
import { useBookingContext } from '../../../context/BookingContext'
import { routeStrings } from '../../../routeStrings'
import {
  IBookingDisplay,
  IInterval,
  IMeetingRoom,
  IWorkspace
} from '../../../types'
import { useBooking } from '../../../utils/apiHooks'
import { viewBookingQueryParams } from '../../../utils/queryParams'
import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { BookingDisplay } from './BookingDisplay'
import { BookingDisplayMobile } from './mobile/BookingDisplayMobile'
import { CheckCircleIcon } from '@chakra-ui/icons'
import { CancellationPolicyComponent } from '../../../components/CancellationPolicyComponent'
import { BookingInfoTimeline } from '../../../components/BookingInfoTimeline'
import { Button } from '../../../components/Button'
import { GenericAlertDialog } from '../../AdminDashboard/Components/GenericAlertDialog'
import { useNavigate } from 'react-router-dom'

export const ViewBooking: React.FC = () => {
  const { showMobileVersion, revalOrgUnitBookings } = useBookingContext()
  const {
    isOpen: isOpenCancelBookingModal,
    onOpen: onOpenCancelBookingModal,
    onClose: onCloseCancelBookingModal
  } = useDisclosure()
  const navigate = useNavigate()
  const params = useParams<{ bookingId: string }>()
  const [isCancelling, setIsCancelling] = useState(false)
  const toast = useToast()

  const bookingId = params.bookingId
  const [{ newBooking }] = useQueryParams(viewBookingQueryParams)
  const { data: bookingInfo, mutate } = useBooking(bookingId)
  const [meetingRoom, setMeetingRoom] = useState<IMeetingRoom>()
  const [workspace, setWorkspace] = useState<IWorkspace>()
  const [isEditionVisible, setIsEditionVisible] = useState<boolean>(false)

  const theme = useTheme()

  if (bookingInfo === undefined) {
    return (
      <LayoutMemberDashboard
        showBack={true}
        backButtonAction={() => navigate(routeStrings.memberDashboardBookings)}
      >
        <Flex
          pos="absolute"
          align="center"
          justify="center"
          h="100%"
          w="100%"
          overflow="hidden"
          opacity={0.5}
          bg="#f4f5f5"
          cursor=""
        >
          <Spinner size="xl" />
        </Flex>
      </LayoutMemberDashboard>
    )
  }

  const booking: IBookingDisplay = {
    location: bookingInfo.location,
    meetingRoomName: bookingInfo.meeting_room_name,
    workspaceName: bookingInfo.workspace_name,
    startTime: new Date(Date.parse(bookingInfo.start_time)),
    endTime: new Date(Date.parse(bookingInfo.end_time)),
    meetingRoomImage: bookingInfo.meeting_room_display_url,
    has_videoconference: bookingInfo?.has_videoconference ?? false,
    has_screen_mirroring: bookingInfo?.has_screen_mirroring ?? false,
    capacity: bookingInfo?.capacity ?? 0,
    is_external: bookingInfo?.is_external,
    location_address: bookingInfo.location_address,
    location_name: bookingInfo.location_name,
    timezone: bookingInfo.timezone
  }

  const isCancelled = bookingInfo.cancelled_at != null
  const isFutureBooking = isFuture(booking.startTime)

  // can cancel the booking if it is in the future and not already cancelled
  const isCancellableBooking = isFutureBooking && !isCancelled
  const isEditablebleBooking =
    isFutureBooking && !isCancelled && !booking.is_external
  const isMeetingRoomBooking = booking.meetingRoomName !== undefined

  const handleEditBooking = () => {
    if (!meetingRoom) {
      getMeetingRoomTimelineByBooking(bookingInfo.id).then(({ data: res }) => {
        setMeetingRoom(res)
      })
    }
    setIsEditionVisible(!isEditionVisible)
  }
  const handleEditWorkspaceBooking = () => {
    if (!workspace) {
      getWorkspaceTimelineByBooking(bookingInfo.id).then(({ data: res }) => {
        setWorkspace(res)
      })
    }
    setIsEditionVisible(!isEditionVisible)
  }
  const handleCancelBooking = () => {
    if (bookingId) {
      setIsCancelling(true)
      cancelBooking(parseInt(bookingId))
        .then(() => {
          toast({
            description: 'Booking cancelled',
            status: 'success'
          })
          if (isMeetingRoomBooking) {
            navigate(routeStrings.memberDashboardBookings)
          } else {
            navigate(routeStrings.memberDashboardWorkspaceBookings)
          }
        })
        .catch(() => {
          toast({
            description: 'Failed to cancel booking',
            status: 'error'
          })
          setIsCancelling(false)
        })
        .finally(() => revalOrgUnitBookings())
    }
  }

  const bookingConfirmed = (status: boolean, data: any) => {
    setIsEditionVisible(false)
    mutate()
    revalOrgUnitBookings()
  }

  const setNewBookingTimes = (times: IInterval) => {
    bookingConfirmed(true, {})
  }

  const BookingConfirmation = () => {
    return (
      <Center mb={2} py={4} textAlign="center" flexDir="column">
        <Heading fontSize={['sm', 'lg']}>
          <CheckCircleIcon
            boxSize={['20px', '30px', '30px']}
            color={theme.colors.brandPrimary}
          />{' '}
          Booking confirmed
        </Heading>
        <Divider mt={4} />
      </Center>
    )
  }
  const BookingButtons = () => {
    return (
      <Box rounded="md" p={2}>
        <Flex>
          {isCancellableBooking && !isEditionVisible && (
            <ChakraButton
              cursor="pointer"
              color="brandPrimary"
              borderRadius="100px"
              variant="outline"
              size="sm"
              onClick={onOpenCancelBookingModal}
              isLoading={isCancelling}
            >
              Cancel Booking
            </ChakraButton>
          )}
          <Spacer />
          {isEditablebleBooking && (
            <Button
              variant="secondary"
              size={'xs'}
              onClick={() => {
                if (isMeetingRoomBooking) {
                  handleEditBooking()
                } else {
                  handleEditWorkspaceBooking()
                }
              }}
            >
              {isEditionVisible ? 'Cancel' : 'Reschedule Booking'}
            </Button>
          )}
        </Flex>
      </Box>
    )
  }

  const MobileUI = () => {
    return (
      <>
        {newBooking && <BookingConfirmation />}
        <BookingDisplayMobile booking={booking}>
          {/* {getTimeLine()} */}
          <BookingButtons />
        </BookingDisplayMobile>
      </>
    )
  }

  return (
    <LayoutMemberDashboard
      showBack={showMobileVersion}
      backButtonAction={() => navigate(routeStrings.memberDashboardBookings)}
    >
      {bookingInfo &&
        (showMobileVersion ? (
          <MobileUI />
        ) : (
          <Container
            maxW="container.lg"
            overflowY="scroll"
            className="scrollable-list"
          >
            <Box pt={8}>
              {newBooking && <BookingConfirmation />}
              <BookingDisplay booking={booking}>
                {booking.is_external && (
                  <Box mb={2}>
                    <CancellationPolicyComponent />
                  </Box>
                )}
                {!isEditionVisible && <Divider />}
                {isEditionVisible && (
                  <Box
                    w={'100%'}
                    maxW={'container.sm'}
                    borderWidth={1}
                    borderRadius={'lg'}
                    p={2}
                    px={4}
                  >
                    <Text
                      fontSize={'md'}
                      fontWeight={'bold'}
                      color={'gray.600'}
                    >
                      {isMeetingRoomBooking ? 'Meeting Room' : 'Workspace'}{' '}
                      booking reschedule
                    </Text>
                    <Divider mb={2} />
                    <BookingInfoTimeline
                      booking={bookingInfo}
                      handleBookingRescheduled={setNewBookingTimes}
                    />
                  </Box>
                )}
                <BookingButtons />
              </BookingDisplay>
            </Box>
          </Container>
        ))}
      <GenericAlertDialog
        onClose={onCloseCancelBookingModal}
        isConfirming={isCancelling}
        isOpen={isOpenCancelBookingModal}
        title={'Cancel Booking'}
        description={'Are you sure you want to cancel this booking?'}
        confirmButtonText={'Confirm'}
        onConfirm={handleCancelBooking}
      >
        {booking.is_external && (
          <Box mt={5}>
            <CancellationPolicyComponent />
          </Box>
        )}
      </GenericAlertDialog>
    </LayoutMemberDashboard>
  )
}
