import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { startOfMonth, addMonths } from 'date-fns'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { createBillingCredit, updateBillingCredit } from '../../../api'
import { Button } from '../../../components/Button'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { useAuth } from '../../../context/auth-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IBillingCredit, IOrganization } from '../../../types'
import { useAccountCodes } from '../../../utils/apiHooks'
import { moneyFormatter } from '../../../utils/formUtils'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  billingCreditToEdit?: IBillingCredit
  organization?: IOrganization
}
export const CreateBillingCreditModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  billingCreditToEdit,
  organization
}) => {
  const toast = useToast()
  const { currentOrganization } = useAuth()

  const { platformTenant } = usePlatformTenant()
  const [isExpiryDate, setIsExpiryDate] = useState(false)

  const firstOfNextMonth = startOfMonth(addMonths(new Date(), 1))
  const { data: accountCodes } = useAccountCodes(platformTenant?.id)

  useEffect(() => {
    if (!isOpen) {
      setIsExpiryDate(false)
    } else {
      setIsExpiryDate(
        billingCreditToEdit
          ? billingCreditToEdit.expiry !== undefined
            ? true
            : false
          : true
      )
    }
  }, [isOpen, billingCreditToEdit])

  function validateRate(value) {
    let error = ''
    if (!value) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minW="450px" borderRadius="6px">
        <ModalHeader pb={5}>
          {billingCreditToEdit ? 'Edit' : 'New'} billing credit{' '}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            credits_given: billingCreditToEdit?.credits_given ?? 0,
            credits_remaining: billingCreditToEdit?.credits_remaining ?? 0,
            expiry:
              billingCreditToEdit?.expiry &&
              typeof billingCreditToEdit.expiry === 'string'
                ? new Date(Date.parse(billingCreditToEdit.expiry))
                : undefined,

            reason: billingCreditToEdit?.reason ?? '',
            xero_account_code: billingCreditToEdit?.xero_account_code ?? '',
            organization: billingCreditToEdit?.organization || organization?.id
          }}
          validate={(values): any => {
            const errors: any = {}
            if (!values.xero_account_code) {
              errors.xero_account_code = 'Select a valid Accounting Code'
            }
            return errors
          }}
          onSubmit={(values, actions) => {
            if (billingCreditToEdit?.id) {
              const newBillingCredit: IBillingCredit = values

              if (!isExpiryDate) {
                newBillingCredit.expiry = undefined
              } else {
              }

              updateBillingCredit(billingCreditToEdit.id, newBillingCredit)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Billing credit updated'
                  })
                  onClose()
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    description: 'Failed to update Billing credit'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                })
            } else {
              // create
              const newBillingCredit: IBillingCredit = {
                ...values,
                credits_remaining: values.credits_given
              }
              if (isExpiryDate && !values.expiry) {
                newBillingCredit.expiry = firstOfNextMonth
              } else if (!isExpiryDate) {
                newBillingCredit.expiry = undefined
              }

              createBillingCredit(newBillingCredit)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Billing Credit created'
                  })
                  onClose()
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    description: 'Failed to create Billing Credit'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                })
            }
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody pb={5}>
                <Field
                  name="organization"
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={!!billingCreditToEdit}
                      isRequired
                      isInvalid={
                        form.errors.organization && form.touched.organization
                      }
                      pt={2}
                      pb={5}
                    >
                      <FormLabel color="#4F4F4F" mb={1}>
                        Organization
                      </FormLabel>
                      <Text fontSize={'md'} fontWeight="bold">
                        {currentOrganization?.name}
                      </Text>
                    </FormControl>
                  )}
                />

                <Field
                  name="xero_account_code"
                  render={({ field, form }) => (
                    <FormControl
                      isDisabled={!!billingCreditToEdit}
                      isInvalid={
                        form.errors.xero_account_code &&
                        form.touched.xero_account_code
                      }
                      isRequired
                      pt={2}
                      pb={5}
                    >
                      <FormLabel color="#4F4F4F" mb={1}>
                        XERO account code
                      </FormLabel>
                      <StyledSelect
                        placeholder="Select the XERO account code"
                        value={form.values.xero_account_code}
                        name="xero_account_code"
                        onChange={(e) => {
                          props.setFieldValue(
                            'xero_account_code',
                            e.target.value
                          )
                        }}
                      >
                        {accountCodes?.map((accountCode) => (
                          <option
                            key={accountCode.code}
                            value={accountCode.code}
                          >
                            {accountCode.title}
                          </option>
                        ))}
                      </StyledSelect>

                      <FormErrorMessage>
                        {form.errors.xero_account_code}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="reason"
                  render={({ field, form }) => (
                    <FormControl
                      isReadOnly={!!billingCreditToEdit}
                      isInvalid={form.errors.reason && form.touched.reason}
                      pt={2}
                      pb={5}
                    >
                      <FormLabel htmlFor="numberDesks">Reason</FormLabel>
                      <Textarea
                        {...field}
                        id="reason"
                        placeholder="Reason for credit"
                        pl={4}
                        variant="flushed"
                        bg="#FAFAFA"
                        fontSize="md"
                        borderColor="eastbay07"
                        borderBottom="1px"
                        resize="vertical"
                        size="sm"
                        _invalid={{
                          border: '2px solid',
                          borderColor: '#E1202A'
                        }}
                      />

                      <FormErrorMessage>{form.errors.reason}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <SimpleGrid pb={5} columns={[1, 2]} spacing={8}>
                  <Field
                    name="credits_given"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isReadOnly={!!billingCreditToEdit}
                        isInvalid={
                          form.errors.credits_given &&
                          form.touched.credits_given
                        }
                      >
                        <FormLabel htmlFor="credits_given">
                          Credits given:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            {...field}
                            id="credits_given"
                            placeholder="Credits given"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.credits_given}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  {billingCreditToEdit && (
                    <Box>
                      <Text fontSize={'md'}>Credits Remaining</Text>
                      <Text fontSize={'lg'} pt={3}>
                        {!!billingCreditToEdit
                          ? moneyFormatter.format(
                              billingCreditToEdit.credits_remaining ?? 0
                            )
                          : moneyFormatter.format(props.values.credits_given)}
                      </Text>
                    </Box>
                  )}
                </SimpleGrid>
                <Box>
                  <Box>
                    <Checkbox
                      isChecked={isExpiryDate}
                      onChange={(value) => {
                        setIsExpiryDate(value.target.checked)
                      }}
                      size="sm"
                      fontWeight={'bold'}
                    >
                      Set an expiry date
                    </Checkbox>
                    {!isExpiryDate && (
                      <Text fontSize={'xs'}>
                        Billing Credits will be valid until they are used up
                      </Text>
                    )}
                  </Box>
                  {isExpiryDate && (
                    <Field
                      name="expiry"
                      render={({ field, form }) => (
                        <FormControl
                          isReadOnly={!!billingCreditToEdit}
                          isRequired
                          mr={4}
                          isInvalid={form.errors.expiry && form.touched.expiry}
                          pt={2}
                          pb={5}
                        >
                          <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                            Credits Remaining will be valid until
                          </FormLabel>

                          <CalendarFormInput
                            dateProps={{
                              chosenDate:
                                form.values.expiry ?? firstOfNextMonth,
                              handleChangeDate: (newDate: Date) => {
                                newDate.setHours(23)
                                newDate.setMinutes(59)
                                newDate.setSeconds(59)
                                props.setFieldValue('expiry', newDate)
                              }
                            }}
                            closeOnSelect={true}
                          />
                          <FormErrorMessage>
                            {form.errors.expiry}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  )}
                </Box>
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button disabled={props.isSubmitting} type="submit" mr={3}>
                  Save
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  )
}
