import React, { useEffect, useState } from 'react'
import { AdminBodyWrapper } from '../../../components/AdminBodyWrapper'
import { useAuth } from '../../../context/auth-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { updateDirectoryPreferences } from '../../../api'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { useCommunityProfile } from '../../../utils/apiHooks'
import { useMembersDirectoryContext } from '../../../context/members-directory-context'
import { Button } from '../../../components/Button'

export const DirectorySettings: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const { currentOrgUnit } = useAuth()
  const { preferences, revalidatePreferences } = useMembersDirectoryContext()
  const { data: communityProfile } = useCommunityProfile()
  const [touched, setTouched] = useState(false)

  const [settings, setSettings] = useState(preferences)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (!preferences) {
      revalidatePreferences()
    }
  }, [])
  useEffect(() => {
    if (preferences) {
      setSettings(preferences)
    }
  }, [preferences])

  if (!communityProfile) {
    return <PrimarySpinner />
  }

  if (!platformTenant || !currentOrgUnit || !preferences) {
    return <CenteredSpinner />
  }

  const updateSettings = () => {
    if (!settings) return null
    setIsSubmitting(true)
    updateDirectoryPreferences(currentOrgUnit?.id, settings)
      .then((res) => {
        setTouched(false)
        revalidatePreferences()
        setIsSubmitting(false)
        toast({
          description: 'Preferences udpdated',
          status: 'success'
        })
      })
      .catch((err) => {
        setIsSubmitting(false)
        toast({
          description: 'An error ocurred',
          status: 'error'
        })
      })
  }

  return (
    <AdminBodyWrapper>
      <Box mt={1} maxWidth="650px">
        <Flex flexDir="column">
          <Heading mr={3} fontSize="xl" mb={2}>
            Directory Settings
          </Heading>
          <Flex
            bg="white"
            w="100%"
            boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
            rounded="md"
            px={8}
            py={5}
            position="relative"
            color="eastBay09"
            align="center"
            justifyContent="space-between"
          >
            <Stack spacing={8}>
              <Stack>
                <Text color="#4F4F4F" fontWeight={'bold'} fontSize="sm" mb={1}>
                  General preferences
                </Text>

                <Flex>
                  <Checkbox
                    mr={2}
                    isChecked={settings?.appear_in_directory === true}
                    isDisabled={isSubmitting}
                    onChange={(value) => {
                      setTouched(true)

                      let checked = value.target.checked
                      setSettings({
                        ...settings,
                        appear_in_directory: checked
                      })
                    }}
                    color="#666"
                  />
                  <Text>
                    Allow my profile to be discoverable by other members
                  </Text>
                </Flex>
                <Text color="#4F4F4F" fontWeight={'bold'} fontSize="sm" mb={1}>
                  Notifications
                </Text>

                <Flex>
                  <Checkbox
                    mr={2}
                    isChecked={
                      settings?.new_connection_email_notifications === true
                    }
                    isDisabled={isSubmitting}
                    onChange={(value) => {
                      setTouched(true)
                      let checked = value.target.checked

                      setSettings({
                        ...settings,
                        new_connection_email_notifications: checked
                      })
                    }}
                    color="#666"
                  ></Checkbox>
                  <Text>Notify me via email when someone connects with me</Text>
                </Flex>
              </Stack>

              {touched && (
                <Box>
                  <Button disabled={isSubmitting} onClick={updateSettings}>
                    Update settings
                  </Button>
                </Box>
              )}
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </AdminBodyWrapper>
  )
}
