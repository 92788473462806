import React from 'react'
import { Button as BaseButton } from '../components/Button'

export const CalendarButton: React.FC<any> = props => {
  const { variant, ...rest } = props

  let color = '#ffff'

  if (variant === 'secondary') color = 'brandPrimary'

  return (
    <BaseButton {...rest} variant={variant}>
      <span>
        <svg
          aria-hidden="true"
          width="16"
          height="14"
          viewBox="0 0 448 512"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            marginRight: '4px',
            verticalAlign: 'sub',
            display: 'inline'
          }}
        >
          <path
            fill={color}
            d="M336 292v24c0 6.6-5.4 12-12 12h-76v76c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-76h-76c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h76v-76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v76h76c6.6 0 12 5.4 12 12zm112-180v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
          />
        </svg>
        {props.children}
      </span>
    </BaseButton>
  )
}
