import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import React from 'react'
import { postContent } from '../../../api'
import { useAuth } from '../../../context/auth-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import {
  ApprovalStatusChoices,
  IDailyFiveContent,
  INewContent
} from '../../../types'
import { analyticsTrack } from '../../../utils/analytics'
import { MemberDashboardCreatePostForm } from '../../DailyFive/MemberDashboardCreatePostForm'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  onEventCreated: (content: IDailyFiveContent) => void
}
export const CreatePostModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  onEventCreated
}) => {
  const toast = useToast()
  const { platformTenant } = usePlatformTenant()

  const { me } = useAuth()

  const handleCreatePost = (data: INewContent) => {
    return new Promise<{
      message: string
      success: boolean
      content: IDailyFiveContent
    }>((resolve, reject) => {
      return postContent(data).then((res) => {
        let message = 'Post submitted'
        let success = false
        if (res.status === 201) {
          success = true
          const respData = res.data as IDailyFiveContent
          message =
            respData.approval_status === ApprovalStatusChoices.APPROVED
              ? message
              : 'Post submitted for admin approval'
          const trackingData = {
            userId: me?.user.id,
            contentType: data.content_type,
            tenant: platformTenant?.id
          }
          analyticsTrack('Submit New Daily Five Content', trackingData)
          resolve({ message, success, content: respData })
          return
        } else {
          message = 'An error occurred'
        }
        reject({ reason: message })
      })
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minW="450px" borderRadius="md">
        <ModalHeader pb={2} pl={[2, 10]}>
          Create an event on the Calendar
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MemberDashboardCreatePostForm
            handleCancel={() => {
              onClose()
            }}
            handleSave={(data: INewContent) => {
              return new Promise((resolve, reject) => {
                handleCreatePost(data)
                  .then((res) => {
                    if (res && res.content) {
                      toast({
                        description:
                          res.content.content_type.toLowerCase() === 'event'
                            ? 'Event Created'
                            : 'Post created',
                        status: 'info'
                      })
                      onEventCreated(res.content)
                    }
                  })
                  .catch((error) => {
                    toast({ description: 'An error ocurred ' })
                    console.log(error)
                  })
              })
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
