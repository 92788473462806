import {
  Box,
  Button,
  FlexProps,
  Tag,
  TagCloseButton,
  TagLabel,
  TagProps,
  TagRightIcon
} from '@chakra-ui/react'
import React from 'react'
import { Interest } from '../../../types'

interface Props extends TagProps {
  size?: 'sm' | 'md' | string
  tag: Interest
  isSelected?: boolean
  selectTag?: () => void
  unSelectTag?: () => void
}

export const TagItem: React.FC<Props> = ({
  size,
  tag,
  isSelected,
  selectTag,
  unSelectTag,
  ...rest
}) => {
  return (
    <Box ml={1} mb={2}>
      <Tag
        size={size ? size : 'md'}
        key={tag.id}
        variant={isSelected ? 'solid' : 'outline'}
        colorScheme="blue"
        onClick={() => {
          if (selectTag === undefined) return
          if (!isSelected) selectTag()
        }}
        cursor={'pointer'}
        {...rest}
      >
        <TagLabel>{tag.name}</TagLabel>
        {isSelected ? <TagCloseButton onClick={unSelectTag} /> : null}
      </Tag>
    </Box>
  )
}
