import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import React from 'react'
import { FormLabel, useTheme } from '@chakra-ui/react'
import { PhoneWrapper } from '../components/PhoneWrapper'

interface Props {
  field: any
  form: any
  autoFocus?: boolean
  label?: string
  name?: any
  isInvalid?: boolean
}

export const Phone: React.FC<Props> = ({
  field,
  form,
  autoFocus,
  isInvalid,
  ...props
}) => {
  const theme: any = useTheme()

  const inputStyles = {
    transition: 'all ease 0.2s',
    borderRadius: '0',
    border: isInvalid ? '2px solid #E1202A !important' : '0px none',
    backgroundColor: '#FAFAFA',
    fontFamily: 'Open Sans',
    color: 'rgba(0, 0, 0, 0.65)',
    padding: '8px',
    borderBottom: isInvalid
      ? '2px solid #E1202A !important'
      : '2px solid #757575'
  }

  return (
    <PhoneWrapper>
      {props.label && (
        <FormLabel fontWeight="bold" fontSize="sm" mb={1}>
          {props.label}
        </FormLabel>
      )}
      <PhoneInput
        defaultCountry="AU"
        countryOptionsOrder={['AU', '|']}
        type="tel"
        data-testid="onboarding-mobileNumber-field"
        value={field && field.value}
        onChange={(value) => {
          if (form && field) {
            if (!form.touched[field.name]) form.setFieldTouched(field.name)
            form.setFieldValue(field.name, value)
          }
        }}
        autoFocus={autoFocus}
        style={inputStyles}
      />
    </PhoneWrapper>
  )
}
