import { useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getContent, updateContent } from '../../api'
import { IDailyFiveContent, INewContent } from '../../types'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { MemberDashboardCreatePost } from './MemberDashboardCreatePost'
import { useAuth } from '../../context/auth-context'
import { routeStrings } from '../../routeStrings'
import { useNavigate } from 'react-router-dom'
export const ContentEdition: React.FC = () => {
  //const [saveIcon, setSaveIcon] = useState<string>()

  const { me } = useAuth()
  const { contentId: contentId } = useParams<{ contentId?: string }>()
  const [content, setContent] = useState<IDailyFiveContent>()
  const navigate = useNavigate()
  const toast = useToast()
  useEffect(() => {
    //get the content

    contentId &&
      getContent(parseInt(contentId))
        .then(({ data }) => {
          let content = data as IDailyFiveContent
          if (content.user === me?.user.id) {
            setContent(content)
          } else {
            navigate(routeStrings.memberDashboardHome)
          }
        })
        .catch((err) => {
          console.log(err)
          navigate(routeStrings.memberDashboardHome)
        })
  }, [])
  return (
    <>
      {contentId && content ? (
        <MemberDashboardCreatePost
          content={content}
          handleSave={(data: INewContent) => {
            return new Promise((resolve, reject) => {
              updateContent(data, parseInt(contentId)).then((res) => {
                let message = 'Post updated'
                let success = false
                if (res.data) {
                  if (content) {
                    navigate(routeStrings.memberDashboardViewPost(content?.id))
                  }
                  toast({
                    position: 'top',
                    description: message,
                    status: 'success'
                  })
                  resolve({
                    message: 'Post Updated',
                    success: true,
                    content: content
                  })
                  return
                } else {
                  message = 'An error occurred'
                }
                reject({ reason: message })
              })
            })
          }}
        />
      ) : (
        <CenteredSpinner />
      )}
    </>
  )
}
