import styled from '@emotion/styled'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../components/chakra-v2/PrimaryButton'
import { routeStrings } from '../routeStrings'
import { Actions, useRBAC } from '../utils/rbac'
import { useFeatureFlags } from '../context/feature-flag-context'
import { NavLink } from 'react-router-dom'

const MenuLink = styled(NavLink)`
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  margin-bottom: 32px;
  color: #303539;
  &.current {
    color: #40a9ff;
  }
  &.d-none {
    display: none;
  }
`

export const BurgerMenu: React.FC = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>()
  const canViewTeam = useRBAC(Actions.ViewTeam)
  const canBookMeetingRooms = useRBAC(Actions.BookMeetingRooms)
  const canViewBilling = useRBAC(Actions.ViewBillingForOwnTeam)
  const canCreateNewPost = useRBAC(Actions.CreateNewPost)
  const { dailyFiveEnabled } = useFeatureFlags()
  const closeMenu = () => {
    setIsOpen(false)
  }

  const isWhite = window.location.href.indexOf('meeting-rooms') !== -1
  return (
    <Menu
      right
      width={'100%'}
      isOpen={isOpen}
      // @ts-ignore
      styles={{
        bmBurgerBars: {
          background: isWhite ? '#FFFFFF' : '#707070'
        }
      }}
      className="burger-menu"
      id={'burger-menu'}
      data-testid="burger-menu"
    >
      <MenuLink
        id="home"
        className={({ isActive }) =>
          ['menu-item', isActive ? 'current d-none' : ''].join(' ')
        }
        to={routeStrings.memberDashboardHome}
        onClick={() => closeMenu()}
      >
        Home
      </MenuLink>
      {canViewBilling && (
        <MenuLink
          id="billing"
          className="menu-item"
          to={routeStrings.memberDashboardBilling}
          onClick={() => closeMenu()}
        >
          Billing
        </MenuLink>
      )}
      <MenuLink
        className="menu-item--small"
        to={routeStrings.memberDashboardProfile}
        onClick={() => closeMenu()}
      >
        Profile
      </MenuLink>
      {canViewTeam && (
        <MenuLink
          className={({ isActive }) =>
            ['menu-item--small', isActive ? 'current' : ''].join(' ')
          }
          data-testid="team-admin"
          id="team-admin"
          to={routeStrings.teamDashboardHome}
          onClick={() => closeMenu()}
        >
          Team
        </MenuLink>
      )}
      {canCreateNewPost && dailyFiveEnabled && (
        <MenuLink
          className={({ isActive }) =>
            ['menu-item--small', isActive ? 'current' : ''].join(' ')
          }
          data-testid="team-admin"
          id="team-admin"
          to={routeStrings.memberDashboardCreatePost}
          onClick={() => closeMenu()}
        >
          Create Post
        </MenuLink>
      )}
      <MenuLink className="menu-item--small" to={routeStrings.logout}>
        Logout
      </MenuLink>

      {canBookMeetingRooms && (
        <PrimaryButton
          data-testid="complete-next"
          onClick={() => {
            navigate(routeStrings.memberDashboardMeetingRooms)
          }}
        >
          Find a Meeting Room
        </PrimaryButton>
      )}
    </Menu>
  )
}
