import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useTheme
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { addDays, endOfMonth, format } from 'date-fns'
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { requestAddonPlanCancellation } from '../../../../api'
import { KeyValueText } from '../../../../components/KeyValueText'
import { BaseModal } from '../../../../components/modals/BaseModal'
import { useCustomToast } from '../../../../context/toast-context'
import { IAddonSubscription } from '../../../../types'
import { Roles, useRole } from '../../../../utils/rbac'

interface IModalInput {
  subscription_manager: IAddonSubscription | undefined
  closeModalCallback: () => void
}

interface ITabMapping {
  tabName: string
  buttonText: string
  action: () => void
}

export const ManageAddonPlanModal: React.FC<IModalInput> = ({
  subscription_manager,
  closeModalCallback
}) => {
  const { allowedRoles } = useRole()

  const [tabIndex, setTabIndex] = useState<number>(0)

  const [cancelPlan, setCancelPlan] = useState(false)
  const [cancellationDate, setCancellationDate] = useState<Date | undefined>()
  const theme: any = useTheme()

  function resetModal() {
    setCancelPlan(false)
    setCancellationDate(undefined)
  }

  function closeModal() {
    resetModal()
    closeModalCallback()
  }

  const tabMapping: ITabMapping[] = [
    {
      tabName: 'Cancel Plan',
      buttonText: 'Request Plan Cancellation',
      action: () => {
        if (cancellationDate) {
          setCancelPlan(true)
        }
      }
    }
  ]
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `

  if (cancelPlan && cancellationDate) {
    return (
      <ConfirmCancellationModal
        subscription_manager={subscription_manager}
        cancellationDate={cancellationDate}
        closeModalCallback={closeModal}
      />
    )
  }

  // allow space admins to cancel plans at the end of the current month
  // all other users must follow the 30 day rule
  const minimumCancellationDate = () => {
    if (allowedRoles.includes(Roles.SpaceAdmin)) {
      return endOfMonth(new Date())
    }
    return addDays(new Date(), 30)
  }
  if (!!!subscription_manager || !!!subscription_manager.plan) {
    return null
  }
  return (
    <BaseModal
      title="Manage Membership"
      isOpen={subscription_manager !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={tabMapping[tabIndex].buttonText}
      primaryAction={tabMapping[tabIndex].action}
      secondaryAction={closeModal}
    >
      <KeyValueText
        title="Current Plan"
        value={subscription_manager.plan?.name || ''}
      />

      <Tabs mt={6} mb={4} onChange={(index) => setTabIndex(index)}>
        <TabList>
          <StyledTab>Cancel Plan</StyledTab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Stack isInline mb={2}>
              <Text color=" #2f4858" fontWeight="bold" fontSize="14px">
                Select the cancellation date for this addon plan:{' '}
              </Text>
              <Text fontSize="14px">
                {cancellationDate ? format(cancellationDate, 'dd/MM/yyyy') : ''}
              </Text>
            </Stack>
            <Calendar
              selectRange={false}
              onChange={(date) => {
                setCancellationDate(
                  Array.isArray(date) ? (date[0] as Date) : (date as Date)
                )
              }}
              value={cancellationDate}
              minDate={minimumCancellationDate()}
            />
            <Text as={'i'}>
              Note: 30 Days notice must be given when cancelling addon plans
            </Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </BaseModal>
  )
}

interface ICancelModalInput {
  subscription_manager?: IAddonSubscription
  cancellationDate: Date
  closeModalCallback: () => void
}

const ConfirmCancellationModal: React.FC<ICancelModalInput> = (input) => {
  const { subscription_manager, cancellationDate, closeModalCallback } = input

  const { newToast: toast } = useCustomToast()

  function closeModal() {
    closeModalCallback()
  }

  function requestCancellation() {
    // do cancellaiton call
    if (subscription_manager && cancellationDate && subscription_manager.id) {
      requestAddonPlanCancellation(subscription_manager.id, cancellationDate)
        .then(() => {
          toast({
            status: 'success',
            description: `Add-on Plan cancellation request sent`
          })
        })
        .catch(() => {
          toast({
            status: 'error',
            description: `Failed to send cancellation request`
          })
        })
    }
    closeModal()
  }

  if (!!!subscription_manager) {
    return null
  }
  return (
    <BaseModal
      title="Confirm Cancellation"
      isOpen={subscription_manager !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={'Confirm'}
      primaryAction={requestCancellation}
      secondaryAction={closeModal}
    >
      <Text>
        Are you sure you want to cancel your membership on{' '}
        {format(cancellationDate, 'dd/MM/yyyy')}?
      </Text>
    </BaseModal>
  )
}
