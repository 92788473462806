import {
  Box,
  Center,
  Flex,
  Input,
  Select,
  Skeleton,
  Text
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/Button'
import { RouterLink } from '../../../components/Link'
import { Table, TableDataProp } from '../../../components/table/Table'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { getTeamMemberProfileUrl } from '../../../routeStrings'
import { IOrganizationSeat, IPlanChangeRequest } from '../../../types'
import { Roles, useRole } from '../../../utils/rbac'
import { TeamLink } from './TeamLink'

interface Props {
  requests: IPlanChangeRequest[]
  pagination: {
    fetchMoreResults: () => void
    hasMoreResults: boolean
    isLoading: boolean
    count: number
  }
}

export const PlanChangeReportTable: React.FC<Props> = ({
  requests,
  pagination
}) => {
  const { isInAdminRole } = useRole()
  const { platformTenant } = usePlatformTenant()

  const [names, setNames] = useState<Set<string>>(new Set<string>())
  const [orgNames, setOrgNames] = useState<Set<string>>(new Set<string>())
  const [planNames, setPlanNames] = useState<Set<string>>(new Set<string>())

  const [filterLocation, setFilterLocation] = useState<number | undefined>()
  const [filterPlan, setFilterPlan] = useState<string>('')
  const [filterTeam, setFilterTeam] = useState<string>('')
  const [filterMemberName, setFilterMemberName] = useState<string>('')
  const [filterRequests, setFilterRequests] = useState<IPlanChangeRequest[]>([])

  useEffect(() => {
    if (requests) {
      // Filter the incoming member cancellations requests or plan changes requests
      let filteringRequests: IPlanChangeRequest[] = requests

      filteringRequests = filteringRequests.filter((request) =>
        filterMemberName
          ? request.organization_seat.organizational_unit?.user.name
              .toLowerCase()
              .startsWith(filterMemberName.toLowerCase())
          : true
      )

      filteringRequests = filteringRequests.filter((request) =>
        filterTeam
          ? request.organization_seat.organization.name
              .toLowerCase()
              .startsWith(filterTeam.toLowerCase())
          : true
      )

      filteringRequests = filteringRequests.filter((request) =>
        filterPlan
          ? request.old_plan?.name === filterPlan || !request.new_plan?.location
          : true
      )

      filteringRequests = filteringRequests.filter((request) => {
        if (filterLocation === undefined) {
          return true
        } else if (filterLocation === -1) {
          //for community plans etc with no location
          return !request.old_plan?.location
        } else {
          return request.old_plan?.location?.id === filterLocation
        }
      })

      filteringRequests.sort((a, b) => a.id - b.id)

      setFilterRequests(filteringRequests)
    }
  }, [requests, filterMemberName, filterTeam, filterPlan, filterLocation])

  useEffect(() => {
    if (requests) {
      const reqName = new Set<string>()
      const reqTeamName = new Set<string>()
      const reqPlanName = new Set<string>()

      requests.forEach((req) => {
        if (req.organization_seat.organizational_unit?.user.name) {
          reqName.add(req.organization_seat.organizational_unit?.user.name)
        }
        if (req.old_plan?.name) {
          reqPlanName.add(req.old_plan?.name)
        }
        reqTeamName.add(req.organization_seat.organization.name)
      })
      setNames(reqName)
      setOrgNames(reqTeamName)
      setPlanNames(reqPlanName)
    }
  }, [requests])

  function MemberLink(seat: IOrganizationSeat) {
    if (!seat.organizational_unit || !seat.organizational_unit.user) return '-'
    return (
      <RouterLink
        color="#255CE1"
        mb={0}
        to={getTeamMemberProfileUrl(
          seat.organization.id,
          seat.organizational_unit.id,
          isInAdminRole
        )}
      >
        {seat.organizational_unit.user.name}
      </RouterLink>
    )
  }

  const tableData = filterRequests.map((request) => ({
    id: TableDataProp(request.id),
    member: TableDataProp(MemberLink(request.organization_seat)),
    test_prefix: TableDataProp(request.requested_by?.name),
    change_date: TableDataProp(
      format(Date.parse(request.change_date), 'dd/MM/yyyy')
    ),
    previous_plan: [
      TableDataProp(request.old_plan ? request.old_plan.name : ''),
      TableDataProp(request.old_plan ? request.old_plan.rate_string : '')
    ],
    new_plan: [
      TableDataProp(request.new_plan ? request.new_plan.name : ''),
      TableDataProp(request.new_plan ? request.new_plan.rate_string : '')
    ],
    organization: TableDataProp(
      TeamLink({
        organizationId: request.organization_seat.organization.id,
        organizationName: request.organization_seat.organization.name
      })
    )
  }))

  return (
    <Box>
      <Text m={3}>Search by:</Text>
      <Flex flexDirection={['column', 'row']} justify="flex-start" mb={5}>
        <Input
          background="white"
          m={3}
          maxWidth="250px"
          id="member_name"
          placeholder="Name"
          value={filterMemberName}
          onChange={(ev) => setFilterMemberName(ev.target.value)}
        />
        <Input
          background="white"
          m={3}
          maxWidth="250px"
          id="team"
          placeholder="Team name"
          value={filterTeam}
          onChange={(ev) => setFilterTeam(ev.target.value)}
        />
      </Flex>
      <Text m={3}>Filter by:</Text>
      <Flex flexDirection={['column', 'row']} justify="flex-start" mb={5}>
        <Select
          background="white"
          m={3}
          maxWidth="250px"
          id="memberName"
          placeholder="Member name"
          color="#8b96a3"
          value={filterMemberName}
          onChange={(ev) => setFilterMemberName(ev.target.value)}
        >
          {Array.from(names).map((name) => {
            return (
              <option key={name} value={name}>
                {name}
              </option>
            )
          })}
        </Select>
        <Select
          background="white"
          m={3}
          maxWidth="250px"
          id="teamName"
          placeholder="Team name"
          color="#8b96a3"
          value={filterTeam}
          onChange={(ev) => setFilterTeam(ev.target.value)}
        >
          {Array.from(orgNames).map((name) => {
            return (
              <option key={name} value={name}>
                {name}
              </option>
            )
          })}
        </Select>
        <Select
          background="white"
          m={3}
          maxWidth="250px"
          id="plan"
          placeholder="Plan name"
          color="#8b96a3"
          value={filterPlan}
          onChange={(ev) => setFilterPlan(ev.target.value)}
        >
          {Array.from(planNames).map((plan) => {
            return (
              <option key={plan} value={plan}>
                {plan}
              </option>
            )
          })}
        </Select>

        <Select
          background="white"
          m={3}
          maxWidth="250px"
          id="location"
          placeholder="Location"
          color="#8b96a3"
          value={filterLocation}
          onChange={(ev) => {
            setFilterLocation(
              ev.target.value === '' ? undefined : parseInt(ev.target.value)
            )
          }}
        >
          {platformTenant?.locations.map((location, i) =>
            location ? (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ) : null
          )}
        </Select>
      </Flex>

      <Table
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },
          { header: 'Member', accessor: 'member', show: true },
          { header: 'Team', accessor: 'organization', show: true },
          { header: 'Previous Plan', accessor: 'previous_plan', show: true },
          { header: 'New Plan', accessor: 'new_plan', show: true },
          { header: 'Change Date', accessor: 'change_date', show: true }
        ]}
        data={tableData}
        loading={false}
      />
      <Center display={'block'}>
        {pagination.isLoading && <Skeleton my={3} w={'100%'} h="60px" />}

        {pagination.hasMoreResults && requests.length > 0 && (
          <Center>
            <Button
              disabled={pagination.isLoading || !pagination.hasMoreResults}
              onClick={pagination.fetchMoreResults}
              mt={3}
              p={3}
            >
              Fetch more requests
            </Button>
          </Center>
        )}
      </Center>
    </Box>
  )
}
