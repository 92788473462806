import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'
import { Button } from '../Button'

interface IModalInput {
  title: string
  isOpen: boolean
  primaryButtonText?: string
  secondaryButtonText?: string
  closeModalCallback: () => void
  primaryAction?: () => void
  secondaryAction?: () => void
  size?: string
  isSubmitting?: boolean
  isLoading?: boolean
  children?: React.ReactNode
}

export const BaseModal: React.FC<IModalInput> = (input) => {
  const {
    title,
    isOpen,
    primaryButtonText,
    secondaryButtonText,
    size,
    isSubmitting,
    isLoading,
    closeModalCallback,
    primaryAction,
    secondaryAction,
    children
  } = input

  const { onClose } = useDisclosure()

  function closeModal() {
    closeModalCallback()
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal} size={size ?? 'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={2}>{children}</ModalBody>

          <ModalFooter>
            {primaryAction && (
              <Button
                disabled={isSubmitting}
                isLoading={isLoading}
                variant="primary"
                onClick={primaryAction}
                mr={3}
              >
                {primaryButtonText ? primaryButtonText : 'Confirm'}
              </Button>
            )}
            {secondaryAction && (
              <Button
                disabled={isSubmitting || isLoading}
                variant="secondary"
                onClick={secondaryAction}
              >
                {secondaryButtonText ? secondaryButtonText : 'Cancel'}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
