import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends FlexProps {
  dataTestid?: string
}

export const TableRow: React.FC<Props> = props => {
  const { children, dataTestid, ...rest } = props

  return (
    <Flex
      bg="white"
      w="100%"
      boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
      rounded="md"
      position="relative"
      color="eastBay09"
      align="center"
      justifyContent="space-between"
      data-testid={dataTestid}
      minHeight={16}
      padding="8px"
      {...rest}
    >
      {children}
    </Flex>
  )
}
