import { ChatIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDistance } from 'date-fns'
import React from 'react'
import { UserDisplay } from '../../../../components/UserDisplay'
import { useMembersDirectoryContext } from '../../../../context/members-directory-context'
import { DirectoryMemberResult } from '../../../../types'

interface Props {
  contact: DirectoryMemberResult
  isLoading: boolean
  displayMessage?: () => void
  displayOrganizationProfile?: () => void
  handleDisconnect: () => void
}

export const DirectoryContactProfile: React.FC<Props> = (props) => {
  const {
    contact,
    isLoading,
    handleDisconnect,
    displayMessage,
    displayOrganizationProfile
  } = props

  const {
    logRemoveContact,
    logViewConnectionMessage,
    logViewCompanyExtraDetail,
    logViewMemberExtraDetail
  } = useMembersDirectoryContext()

  const { member, organization, created_at, message } = contact
  const { profile } = member
  const theme: any = useTheme()

  const linkStyle = {
    fontSize: '11px',
    color: theme.colors.primary ?? '#777',
    marginTop: '0px',
    fontWeight: 'bold' as 'bold'
  }

  return (
    <Box background={'white'} borderRadius="lg" p={4} my={3} boxShadow="sm">
      <Flex position={'sticky'} height="0" background={'red'}>
        <Spacer />
        <Menu size={'xs'}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <Portal>
            <MenuList zIndex={'popover'}>
              {contact.first_contact && (
                <MenuItem isFocusable={false} isDisabled={true}>
                  <Text fontSize={'xx-small'} color="#00028d">
                    Connection started by you
                  </Text>
                </MenuItem>
              )}

              {contact.created_at && (
                <MenuItem isFocusable={false} isDisabled={true}>
                  <Text fontSize={'xx-small'} color="#00028d">
                    Connected since{' '}
                    {formatDistance(new Date(contact.created_at), new Date(), {
                      includeSeconds: false,
                      addSuffix: true
                    })}
                  </Text>
                </MenuItem>
              )}
              {contact.message && (
                <MenuItem
                  icon={<ChatIcon />}
                  onClick={() => {
                    logViewConnectionMessage()
                    displayMessage && displayMessage()
                  }}
                >
                  <Text>View Message</Text>
                </MenuItem>
              )}
              <MenuItem
                disabled={isLoading}
                icon={<CloseIcon />}
                onClick={() => {
                  logRemoveContact(
                    created_at ? new Date(created_at) : new Date()
                  )
                  handleDisconnect()
                }}
              >
                Remove contact
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
      <Flex>
        <Box w={['100%', '70%']}>
          <Flex>
            <Box mr={1}>
              <UserDisplay
                my="5px"
                size="sm"
                name={member.user?.name}
                src={profile?.profile_picture_url}
              />
            </Box>
            <Box>
              {/* COntact Data */}
              <Stack px={2} spacing={0.5}>
                <Text color={'#444'} fontSize="xs" fontStyle={'italic'}>
                  {profile?.job_title}
                </Text>
                <Heading my={0} py={0} lineHeight={'1'} size={'sm'}>
                  {member.user?.name}
                </Heading>
                <Text
                  pb={2}
                  fontWeight="medium"
                  fontSize="xs"
                  color={theme.colors.primary}
                  _hover={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                  lineHeight={'1'}
                  cursor={'pointer'}
                  onClick={() => {
                    logViewCompanyExtraDetail(contact.organization.id, 'Modal')
                    displayOrganizationProfile && displayOrganizationProfile()
                  }}
                >
                  {organization.name}
                </Text>
                <Stack spacing={0}>
                  {profile?.linkedin_url && (
                    <Link
                      href={profile.linkedin_url}
                      style={linkStyle}
                      isExternal
                      onClick={() => {
                        logViewMemberExtraDetail(member.id, 'LinkedIn')
                      }}
                    >
                      LinkedIn profile
                    </Link>
                  )}
                </Stack>

                {organization?.home_location_address != null && (
                  <Box>
                    <Text
                      fontWeight={'medium'}
                      fontSize="xx-small"
                      color="#666"
                      lineHeight={'1'}
                    >
                      <FontAwesomeIcon icon={faHome} />{' '}
                      {organization.home_location_name}
                      <Text as="span" color={'#777'}>
                        {', ' + organization.home_location_address}
                      </Text>
                    </Text>
                  </Box>
                )}
              </Stack>
            </Box>
          </Flex>
          <Box mt={1}>
            {/* ABOUT */}

            {/* {profile.about_you && profile.about_you != '' && (
              <>
                <Heading size={'xs'} color="#444">
                  About
                </Heading>
                <Text fontSize={'xs'} color={'#777'}>
                  {profile.about_you}
                </Text>
              </>
            )} */}
          </Box>
        </Box>
        <Box w={['100%', '50%']}>
          {profile?.skills && profile.skills.length > 0 && (
            <Text mt={3} fontWeight={'bold'} fontSize="xs">
              Skills
            </Text>
          )}
          <Box mt={0} justifyContent="right" maxH={'100px'} overflowY="auto">
            {profile?.skills &&
              profile.skills.map((tag, i) => (
                <Text key={i} fontSize="xx-small" color={'#999'} as="span">
                  <>
                    {tag.name}
                    {profile.skills && i === profile.skills.length - 1
                      ? '.'
                      : ', '}
                  </>
                </Text>
              ))}
          </Box>
        </Box>
      </Flex>
      <Box px={2} mt={2}>
        {profile?.about_you && (
          <Text fontWeight={'bold'} fontSize="xs">
            About
          </Text>
        )}
        <Text>{profile?.about_you}</Text>
      </Box>
    </Box>
  )
}
