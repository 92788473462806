import React from 'react'
import { routeStrings } from '../../../routeStrings'
import { Link } from 'react-router-dom'

interface Props {
  organizationId: number
  organizationName: string
}

export const TeamLink: React.FC<Props> = ({
  organizationId,
  organizationName
}) => {
  return (
    <Link
      style={{ cursor: 'pointer', marginBottom: 0 }}
      to={routeStrings.adminTeamDetailView(organizationId)}
      className="table-link"
    >
      {organizationName}
    </Link>
  )
}
