import { matchPath } from 'react-router'
import { AuthContextProps } from '../context/auth-context'
import { routeStrings } from '../routeStrings'
import {
  IOrganization,
  IOrganizationalUnit,
  IOrganizationDetail,
  IPlatformTenant,
  Me,
  MembershipStates,
  MeResponse
} from '../types'
import { Roles, useRBAC, useRole } from './rbac'

function RedirectGuard(redirectPath: string) {
  // Use to prevent circular redirects
  let currentPath = window.location.pathname
  if (currentPath.endsWith('/')) {
    currentPath = currentPath.substring(0, currentPath.length - 1)
  }
  if (redirectPath !== currentPath) {
    window.location.replace(redirectPath)
  }
}

/**
 * Redirects the user to `redirectPath` if they are not in a whitelisted path.
 * @param whitelistPaths the allowed paths
 * @param redirectPath the path to redirect to if the user is not on an allowed path
 */
function WhitelistedPath(whitelistPaths: string[], redirectPath: string) {
  const currentPath = window.location.pathname

  if (!matchPath(currentPath, whitelistPaths)) {
    window.location.replace(redirectPath)
  }
}

const allowedToVisitIfNoProfile = [
  routeStrings.memberDashboardHome,
  routeStrings.completeLanding,
  routeStrings.onboarding0Invite,
  routeStrings.existingMemberTeamInvite,
  routeStrings.memberDashboardBooking
]
const tenantOnboardingRoutes = [
  routeStrings.onboardingTenantComplete,
  routeStrings.onboardingTenant1BusinessSettings,
  routeStrings.onboardingTenantIntegrations
]

export function BaseRedirects(auth: AuthContextProps) {
  // retrieving Me
  if (auth.loading) {
    return
  }
  // undefined Me
  if (auth.me === undefined) {
    return
  }
  // Not logged in
  if (!auth.isAuthenticated) {
    RedirectGuard(routeStrings.login)
  }
}

export function AdminRedirects(me: Me) {
  // not a staff
  if (!me.user.is_space_admin) {
    RedirectGuard(routeStrings.memberDashboardHome)
  }
}

export const getMembershipState = (
  data: MeResponse,
  current_org_unit?: IOrganizationalUnit
): MembershipStates => {
  let has_skipped_complete_profile =
    window.localStorage.getItem(`${data.user.id}_USER_SKIP_COMPLETE_PROFILE`) !=
    null
  if (data.organizational_units.length === 0)
    return MembershipStates.PendingAdminApproval

  if (!data.user.user_profile.profile_complete && !has_skipped_complete_profile)
    return MembershipStates.NeedsToCompleteInitialProfile

  if (current_org_unit) {
    let currentAdminOf = data.admin_of.find(
      (org) => org.id === current_org_unit.organization.id
    )
    if (
      currentAdminOf &&
      !hasActiveMemberships(current_org_unit.organization)
    ) {
      return MembershipStates.ActiveWithoutMembership
    }
  }

  // @TODO: the start date here should probably be pulled from the OU/seat instead of the member profile
  if (new Date(Date.parse(data.user.user_profile.start_date)) > new Date())
    return MembershipStates.PendingStartingMembership

  return MembershipStates.FullyActive
}

export function MemberRedirects(me: Me, auth: AuthContextProps, role: Roles) {
  // @TODO: look into whether htis route is necessary
  // staff user
  const membershipState = getMembershipState(me, auth.currentOrgUnit)
  if (!me.user.is_space_admin) {
    // has not yet been approved or has been declined or not linked to anything
    if (me.organizational_units.length === 0) {
      WhitelistedPath(
        allowedToVisitIfNoProfile,
        routeStrings.memberDashboardHome
      )
    }
    // profile is not complete, redirect to complete it
    if (membershipState === MembershipStates.NeedsToCompleteInitialProfile) {
      WhitelistedPath(
        [routeStrings.memberDashboardBooking],
        routeStrings.completeLanding
      )
    }
    // is an admin and payment method not yet set, (see comment on allendale plan)
    if (
      [
        MembershipStates.FullyActive,
        MembershipStates.PendingStartingMembership
      ].indexOf(membershipState) >= 0 &&
      auth.isTeamAdmin &&
      auth.currentOrganization?.is_space_admin === false &&
      !auth.currentOrganization.is_active_default_payment_method &&
      //This line prevent redirect for user on allendale tenant plan so that they don't get prompted for billing details until the try booking a paid room
      role !== Roles.FernAllendaleTenantTeamAdmin &&
      role !== Roles.IlumaTenantTeamAdmin
    ) {
      console.log('yes')
      RedirectGuard(routeStrings.memberDashboardBillingUpdate)
    }
  }
}

export const hasActiveMemberships = (organization: IOrganization) => {
  if (organization.is_active_organization != undefined) {
    return organization.is_active_organization
  }
  return false
}

export function TeamAdminRedirects(
  me: Me,
  auth: AuthContextProps,
  role: Roles
) {
  // do base member checks
  MemberRedirects(me, auth, role)

  // not a team admin
  if (!auth.isTeamAdmin && !me.user.is_space_admin) {
    RedirectGuard(routeStrings.memberDashboardHome)
  }
}

export function CompleteProfileRedirects(me: Me) {
  if (!me.user.is_space_admin) {
    // Awaiting approval
    if (me.organizational_units.length === 0) {
      WhitelistedPath(
        allowedToVisitIfNoProfile,
        routeStrings.memberDashboardHome
      )
    }

    // Profile is already complete
    if (me.user.user_profile.profile_complete) {
      RedirectGuard(routeStrings.memberDashboardHome)
    }
  }

  return me
}

export function CompleteTenantOnboardingRedirects(
  me: Me,
  platform_tenant: IPlatformTenant | undefined
) {
  if (
    me.user.is_space_admin &&
    platform_tenant != null &&
    !platform_tenant.is_onboard_complete
  ) {
    if (!tenantOnboardingRoutes.includes(window.location.pathname)) {
      RedirectGuard(routeStrings.onboardingTenant1BusinessSettings)
    }
  }

  return me
}
