import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { Location } from '../../types'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { BookingLocationSettings } from './BookingSettings/BookingLocationSettings'

export const AdminDashboardMeetingRoomsSettings: React.FC = () => {
  const { platformTenant } = usePlatformTenant()

  const [chosenLocation, setChosenLocation] = useState<Location>()

  useEffect(() => {}, [])
  useEffect(() => {}, [chosenLocation])

  return (
    <AdminBodyWrapper>
      <Flex mb={3}>
        <Box
          bg="white"
          w="100%"
          boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
          rounded="md"
          px={8}
          py={5}
          color="eastBay09"
        >
          <Text fontWeight="bold" fontSize="26px">
            Booking settings
          </Text>
          <Box>
            {platformTenant?.locations && (
              <BookingLocationSettings locations={platformTenant?.locations} />
            )}
          </Box>
        </Box>
      </Flex>
    </AdminBodyWrapper>
  )
}
