import React from 'react'
import { Box, Stack, Heading } from '@chakra-ui/react'
import { IBookingDisplay } from './types'
import { format } from 'date-fns'

// const availableTimes = [
//   {
//     id: 1,
//     time: '0800'
//   },
//   {
//     id: 2,
//     time: '0900'
//   },
//   {
//     id: 3,
//     time: '1000'
//   },
//   {
//     id: 4,
//     time: '1100'
//   },
//   {
//     id: 5,
//     time: '1200'
//   },
//   {
//     id: 6,
//     time: '1300'
//   },
//   {
//     id: 7,
//     time: '1400'
//   },
//   {
//     id: 8,
//     time: '1500'
//   },
//   {
//     id: 9,
//     time: '1600'
//   },
//   {
//     id: 10,
//     time: '1700'
//   },
//   {
//     id: 11,
//     time: '1800'
//   },
//   {
//     id: 12,
//     time: '1900'
//   },
//   {
//     id: 13,
//     time: '2000'
//   },
//   {
//     id: 14,
//     time: '2100'
//   }
// ]

const formatTime = (time: Date): string => format(time, 'hh:mm aa')

interface Props {
  bookings: IBookingDisplay[]
  currentTime: Date
}

/**
 * shows a timeline of the upcoming bookings.
 * Handles filtering of the `bookings` prop to display only
 * the upcoming ones based on `currentTime`
 */
export const KioskTimeline: React.FC<Props> = ({ bookings, currentTime }) => {
  const upcomingBookings = bookings
    .filter((booking) => booking.endTime > currentTime)
    .sort((a: IBookingDisplay, b: IBookingDisplay) =>
      a.endTime > b.endTime ? 1 : b.endTime > a.endTime ? -1 : 0
    )

  return (
    <Box color="white.100">
      <Box mb={5}>
        <Heading color="white" as="h4" size="md">
          Upcoming Bookings
        </Heading>
      </Box>
      <Stack spacing={3} shouldWrapChildren>
        {upcomingBookings.map((booking, index) => (
          <Box key={index} bg="white" color="black" p={5} borderRadius="8px">
            {formatTime(booking.startTime)} - {formatTime(booking.endTime)}
          </Box>
        ))}
      </Stack>

      {/* alternate available times timeline UI */}
      {/* <Box m="30px" p="">
        {availableTimes.map(at => (
          <h2 className="time"> {at.time} </h2>
        ))}
      </Box> */}
    </Box>
  )
}
