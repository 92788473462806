import React, { useState } from 'react'
import {
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Stack
} from '@chakra-ui/react'

interface Props {
  password: string
  showPasswordByDefault?: boolean
}

export const WifiPasswordDisplay: React.FC<Props> = ({
  password,
  showPasswordByDefault
}) => {
  const [show, setShow] = useState<boolean>(showPasswordByDefault || false)

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        value={password ?? ''}
        isReadOnly
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={() => setShow((show) => !show)}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}
