import { Box, Center, Skeleton, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Button } from '../../../components/Button'
import { StatusBox } from '../../../components/StatusBox'
import { Table, TableDataProp } from '../../../components/table/Table'
import { IAddonPlanChangeRequest } from '../../../types'
import { ReviewAddonPlanRequestModal } from '../Modals/ReviewAddonPlanRequestModal'
import { TeamLink } from './TeamLink'

function RenderStatus(status: string) {
  return (
    <StatusBox
      status={status}
      mapping={{
        PENDING: { color: 'yellow' },
        ERROR: { color: 'negative' },
        COMPLETED: { color: 'positive' },
        APPROVED: { color: 'positive' },
        CHANGE: { color: 'positive' },
        CANCELLATION: { color: 'negative' }
      }}
    />
  )
}

interface IInputParams {
  pendingRequests: IAddonPlanChangeRequest[] | undefined
  status?: string
  revalidateRequests: () => void
  pagination?: {
    fetchMoreResults: () => void
    hasMoreResults: boolean
    isLoading: boolean
    count: number
  }
}

export const AddonPlanChangeRequests: React.FC<IInputParams> = (input) => {
  const { pendingRequests, pagination, revalidateRequests } = input
  const titleStatus = input.status ? input.status.toLowerCase() : 'All'

  const [requestToReview, setRequestToReview] = useState<
    IAddonPlanChangeRequest | undefined
  >()

  const loading = pendingRequests === undefined

  const reviewRequestAction = {
    text: 'Review Request',
    testIdPrefix: 'review-request',
    action: (rowId) => {
      const requestObj = pendingRequests!.find((obj) => obj.id === rowId)
      if (requestObj) {
        setRequestToReview(requestObj)
      }
    }
  }

  // const approveRequestAction = {
  //   text: 'Approve Request',
  //   testIdPrefix: 'approve-request',
  //   action: rowId => {
  //     const requestObj = pendingRequests!.find(obj => obj.id === rowId)
  //     if (requestObj) {
  //       input.approveRequest(requestObj)
  //     }
  //   }
  // }

  // const declineRequestAction = {
  //   text: 'Decline Request',
  //   testIdPrefix: 'decline-request',
  //   action: rowId => {
  //     const requestObj = pendingRequests!.find(obj => obj.id === rowId)
  //     if (requestObj) {
  //       input.declineRequest(requestObj)
  //     }
  //   }
  // }

  const tableData = pendingRequests
    ? pendingRequests.map((request) => {
        const cleanedData = {
          id: TableDataProp(request.id),
          test_prefix: TableDataProp(request.requested_by?.name),
          type: TableDataProp(RenderStatus(request.plan_request_type)),
          status: TableDataProp(RenderStatus(request.status)),
          change_date: TableDataProp(
            format(Date.parse(request.change_date), 'dd/MM/yyyy')
          ),
          requested_by: [
            TableDataProp(request.requested_by?.name),
            TableDataProp(request.requested_by?.email),
            TableDataProp(request.requested_by?.user_profile.mobile_number)
          ],
          previous_plan: [
            TableDataProp(request.old_plan ? request.old_plan.name : ''),
            TableDataProp(request.old_plan ? request.old_plan.rate_string : '')
          ],
          new_plan: [
            TableDataProp(request.new_plan ? request.new_plan.name : ''),
            TableDataProp(request.new_plan ? request.new_plan.rate_string : '')
          ],
          override_value: [
            TableDataProp(
              request.override_value ? `$${request.override_value}` : ''
            )
          ],
          organization: TableDataProp(
            TeamLink({
              organizationId: request.organization.id,
              organizationName: request.organization.name
            })
          )
        }

        const actions: any = []
        if (request.status === 'PENDING') {
          actions.push(reviewRequestAction)
          // // actions for pending requests
          // if (request.plan_request_type === "Cancellation") {
          //   actions.push(approveRequestAction)
          // }
          // else {
          //   actions.push(reviewRequestAction)
          // }
          // actions.push(declineRequestAction)
        }

        cleanedData['actions'] = TableDataProp(actions)

        return cleanedData
      })
    : []

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box display="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {titleStatus} add-on plan requests
          </Text>
          <Text fontSize="xs" color="grey">
            {pagination
              ? pagination.count
              : pendingRequests
                ? pendingRequests.length
                : ''}{' '}
            Total
          </Text>
        </Box>

        <Table
          pageSize={pagination ? 100 : 10}
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'test_prefix', accessor: 'test_prefix', show: false },
            {
              header: 'Request Type',
              accessor: 'type',
              show: true,
              width: 1.25 / 12
            },
            {
              header: 'Status',
              accessor: 'status',
              show: true,
              width: 1.25 / 12
            },
            {
              header: 'Requested By',
              accessor: 'requested_by',
              show: true,
              width: 2.2 / 12
            },
            {
              header: 'Team',
              accessor: 'organization',
              show: true,
              width: 1.5 / 12
            },
            {
              header: 'Previous Plan',
              accessor: 'previous_plan',
              show: true,
              width: 1.5 / 12
            },
            {
              header: 'New Plan',
              accessor: 'new_plan',
              show: true,
              width: 1.5 / 12
            },
            {
              header: 'Overridden Value',
              accessor: 'override_value',
              show: true,
              width: 1.25 / 12
            },
            {
              header: 'Change Date',
              accessor: 'change_date',
              show: true,
              width: 1 / 12
            },
            { header: '', accessor: 'actions', show: true, width: 0.5 / 12 }
          ]}
          data={tableData}
          loading={loading}
        />
      </Box>
      {pagination && (
        <Center display={'block'}>
          {pagination.isLoading && <Skeleton my={3} w={'100%'} h="60px" />}

          {pagination.hasMoreResults &&
            pendingRequests &&
            pendingRequests?.length > 0 && (
              <Center>
                <Button
                  disabled={pagination.isLoading || !pagination.hasMoreResults}
                  onClick={pagination.fetchMoreResults}
                  mt={3}
                  p={3}
                >
                  Fetch more requests
                </Button>
              </Center>
            )}
        </Center>
      )}

      <ReviewAddonPlanRequestModal
        request={requestToReview}
        closeModalCallback={() => {
          setRequestToReview(undefined)
          revalidateRequests()
        }}
      />
    </div>
  )
}
