import { Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BaseModal } from '../../../components/modals/BaseModal'
import { IAccountDeletionRequest } from '../../../types'
import { KeyValueText } from '../../../components/KeyValueText'
import { format } from 'date-fns'
import { declineChangeRequest, approveChangeRequest } from '../../../api'
import { GenericAlertDialog } from '../Components/GenericAlertDialog'

interface IModalInput {
  request?: IAccountDeletionRequest
  closeModalCallback: () => void
}

export const ReviewUserDeletionRequestModal: React.FC<IModalInput> = (
  input
) => {
  const { request, closeModalCallback } = input
  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const isPending = request?.status === 'PENDING'
  function closeModal() {
    onClose()
    closeModalCallback()
  }

  function approveRequest() {
    if (request) {
      setIsSubmitting(true)
      approveChangeRequest({
        requestId: request.id
      })
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: 'Request Approved'
          })
          closeModal()
        })
        .catch(() => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to Approve Request'
          })
          closeModal()
        })
    }
  }

  function declineRequest() {
    if (request) {
      setIsSubmitting(true)
      declineChangeRequest(request.id)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: 'Request Declined'
          })
          closeModal()
        })
        .catch(() => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to Decline Request'
          })
          closeModal()
        })
    }
  }

  if (request === undefined) {
    return null
  }

  return (
    <BaseModal
      title="Review Request"
      isSubmitting={isSubmitting}
      isOpen={request !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={isPending ? 'Approve Request' : 'OK'}
      primaryAction={isPending ? onOpen : closeModal}
      secondaryButtonText={isPending ? 'Decline Request' : 'Cancel'}
      secondaryAction={isPending ? declineRequest : closeModal}
    >
      <KeyValueText title="Request Type" value={`User Deletion Request`} />
      <KeyValueText
        title={`Requested Date`}
        value={`${format(Date.parse(request.created_at), 'dd/MM/yyyy')}`}
      />
      <KeyValueText title="Notes" value={''} />
      <ul>
        {request.notes_list?.map((note, i) => (
          <Text key={i}>{note}</Text>
        ))}
      </ul>
      <GenericAlertDialog
        onClose={onClose}
        isConfirming={isSubmitting}
        isOpen={isOpen}
        title={'Confirm Account Deletion'}
        description={
          'Please, confirm you want to approve this request. This action cannot be undone.'
        }
        confirmButtonText={'Confirm'}
        onConfirm={approveRequest}
      />
    </BaseModal>
  )
}
