export const validateTextIsOnlyAscii = (value) => {
  //This validation returns an error message containing the invalid characters if value is not a string or if value has non ascii characters
  //regex returns true for non ascii characters
  const NON_ASCII_REGEX = new RegExp('[^\x00-\x7F]', 'g')
  let error
  if (typeof value === 'string' && NON_ASCII_REGEX.test(value)) {
    error = `Value cannot contain the following non-ASCII characters: ${value.match(
      NON_ASCII_REGEX
    )}`
  }
  return error
}

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

/* Assuming this is a typical error response from Django. 
This method processes contolled error responses or Django Exception responses */
export const processErrorMessage = (
  error: any,
  defaultErrorMessage?: string
) => {
  try {
    if (typeof error.response.data === 'string') {
      return error.response.data
    } else if (typeof error.response.data === 'object') {
      if (error.response.data.detail != undefined) {
        if (typeof error.response.data.detail === 'string') {
          return error.response.data.detail
        } else {
          return JSON.stringify(error.response.data.detail)
        }
      }
    }
  } catch (error) {
    return defaultErrorMessage ?? 'An unexpected error ocurred.'
  }
  return defaultErrorMessage ?? 'An error ocurred. Try again later.'
}

export const IS_VALID_HTTP_URL = (input) => {
  let url
  try {
    url = new URL(input)
  } catch (_) {
    return false
  }
  return url.protocol === 'https:'
}

export const moneyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD'
})
