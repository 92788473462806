import { CheckIcon, MinusIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, Tag, Text, VStack } from '@chakra-ui/react'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { IAction } from '../../../components/table/ActionsDropdown'
import { Table, TableDataProp } from '../../../components/table/Table'
import { usePlatformTenant } from '../../../context/platform-tenant-context'

import { IWorkspaceSuperAdmin, Plan } from '../../../types'
import { useAccountCodes } from '../../../utils/apiHooks'

interface IInputParams {
  workspaces: IWorkspaceSuperAdmin[] | undefined
  loading: boolean
  editAction: IAction | IAction[] | undefined
}

export const WorkspacesTable: React.FC<IInputParams> = ({
  workspaces,
  loading,
  editAction
}) => {
  const { platformTenant } = usePlatformTenant()

  const { data: accountCodes } = useAccountCodes(platformTenant?.id)

  function costsComponent(workspace: IWorkspaceSuperAdmin, isMember: boolean) {
    return (
      <VStack justify="center" align="start" spacing={1}>
        <Text fontSize={'md'} fontWeight="bold">
          $
          {isMember
            ? workspace.member_price_half_day_price
            : workspace.non_member_price_half_day_price}
          <Text fontSize={'sm'} fontWeight="normal" pt={3} as={'span'}>
            {' '}
            Half Day
          </Text>
        </Text>
        <Divider />
        <Text fontSize={'md'} fontWeight="bold">
          $
          {isMember
            ? workspace.member_price_full_day_price
            : workspace.non_member_price_full_day_price}
          <Text fontSize={'sm'} fontWeight="normal" pt={3} as={'span'}>
            {' '}
            Full Day
          </Text>
        </Text>
      </VStack>
    )
  }
  function checkComponent(value: boolean) {
    return (
      <Box justifyContent="center" marginLeft={3}>
        <Tag
          color={value ? 'green.600' : undefined}
          background={value ? 'green.100' : undefined}
        >
          {value ? <CheckIcon /> : <MinusIcon />}
        </Tag>
      </Box>
    )
  }

  function accountCodeComponent(title?: string, code?: string) {
    return (
      <VStack spacing={0} px={2} justify="center" align="start">
        <Flex alignItems={'left'}>
          <FontAwesomeIcon
            size={'1x'}
            icon={faCalculator}
            style={{ marginTop: 5, marginRight: 4 }}
          />
          <Text fontSize={'md'}>{code}</Text>
        </Flex>
        <Text fontSize={'sm'}>{title}</Text>
      </VStack>
    )
  }

  const getTableData = (workspaces: IWorkspaceSuperAdmin[]) => {
    return workspaces
      ? workspaces.map((workspace) => {
          let actions: IAction[] = []

          /**
           * Match the `xero_account_code` number to the list of codes from useAccountCodes
           * This keeps our BE query for meetings lightweight, and will cache the repsonse in SWR
           */
          let matchedXeroAcc = accountCodes?.find(
            (ac) => ac.id === workspace.xero_account_code
          )

          const cleanedData = {
            id: TableDataProp(workspace.id),
            test_prefix: TableDataProp(`workspace-row-${workspace.id}`),
            name: TableDataProp(
              <Box>
                {workspace.name}
                {workspace.is_main_space ? (
                  <Tag
                    size="sm"
                    borderRadius="full"
                    variant="solid"
                    colorScheme="green"
                    p="1"
                    ml="2"
                  >
                    Main
                  </Tag>
                ) : null}
              </Box>
            ),
            is_bookable: TableDataProp(checkComponent(workspace.is_bookable)),
            member_costs: TableDataProp(costsComponent(workspace, true)),
            non_member_costs: TableDataProp(costsComponent(workspace, false)),

            xero_account_code: TableDataProp(
              accountCodeComponent(matchedXeroAcc?.title, matchedXeroAcc?.code)
            ),
            actions: TableDataProp([])
          }

          // Define actions for the row
          editAction !== undefined &&
            (Array.isArray(editAction)
              ? (actions = editAction)
              : actions.push(editAction))

          cleanedData.actions = TableDataProp(actions)

          return cleanedData
        })
      : []
  }
  return (
    <Box mt={2}>
      <Box my="3" w="100%">
        <Table
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'test_prefix', accessor: 'test_prefix', show: false },
            {
              header: 'Name',
              accessor: 'name',
              show: true,
              width: 3 / 12
            },
            {
              header: 'Bookable',
              accessor: 'is_bookable',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Member Costs',
              accessor: 'member_costs',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Non-Member Costs',
              accessor: 'non_member_costs',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Xero Account Code',
              accessor: 'xero_account_code',
              show: true,
              width: 2 / 12
            },
            { header: '', accessor: 'actions', show: true, width: 1 / 12 }
          ]}
          data={getTableData(workspaces ?? [])}
          loading={loading}
        />
      </Box>
    </Box>
  )
}
