import React from 'react'
import { useBookingContext } from '../../../context/BookingContext'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { MemberWorkspaces } from './MemberWorkspaces'

export const WorkspaceWrapper: React.FC = () => {
  const { loading } = useBookingContext()

  if (loading) {
    return (
      <LayoutMemberDashboard showBack={true}>
        <CenteredSpinner />
      </LayoutMemberDashboard>
    )
  }
  return (
    <LayoutMemberDashboard showBack={true}>
      <MemberWorkspaces />
    </LayoutMemberDashboard>
  )
}
