import { Box, Button, Image, ImageProps } from '@chakra-ui/react'
import React from 'react'

interface IImageUploadWithPreview extends ImageProps {
  uploadedImage: File | undefined
  setUploadedImage: (image: File | undefined) => void
  initialUrl?: string | undefined
  setRemoveImage: (state: boolean) => void
  removeImage: boolean
}

export const ImageUploadWithPreview: React.FC<IImageUploadWithPreview> = ({
  setUploadedImage,
  uploadedImage,
  initialUrl,
  setRemoveImage,
  removeImage,
  ...rest
}) => {
  const cachedInitial = initialUrl

  return (
    <>
      {removeImage && (uploadedImage || initialUrl) && (
        <Button
          size={'xs'}
          variant="ghost"
          onClick={() => {
            setUploadedImage(undefined)
            setRemoveImage(false)
          }}
        >
          Undo
        </Button>
      )}
      {uploadedImage || initialUrl ? (
        <Box>
          {!removeImage && (
            <Box>
              <Image
                h="120px"
                w="210px"
                src={
                  uploadedImage
                    ? `${URL.createObjectURL(uploadedImage)}`
                    : initialUrl
                }
                {...rest}
              />
              <Button
                size={'xs'}
                variant="ghost"
                onClick={() => {
                  setUploadedImage(undefined)
                  setRemoveImage(true)
                }}
              >
                remove
              </Button>
            </Box>
          )}
        </Box>
      ) : null}
      {removeImage || (!initialUrl && !uploadedImage) ? (
        <input
          style={{
            borderRadius: '0px'
          }}
          type="file"
          id="image"
          accept=""
          onChange={(e) => {
            if (e.target.files) {
              setUploadedImage(e.target.files['0'])
            }
            setRemoveImage(false)
          }}
        />
      ) : null}
    </>
  )
}
