import React from 'react'
import { StatusBox } from '../../../components/StatusBox'
import { Table, TableDataProp } from '../../../components/table/Table'
import { IOrganizationalUnitRequest } from '../../../types'
import { Box, Center, Skeleton, Text } from '@chakra-ui/react'
// import 'antd/dist/antd.css'
import { TeamLink } from './TeamLink'
import { Button } from '../../../components/Button'
function RenderStatus(status: string) {
  return (
    <StatusBox
      status={status}
      mapping={{
        PENDING: { color: 'yellow' },
        ERROR: { color: 'negative' },
        COMPLETED: { color: 'positive' },
        APPROVED: { color: 'positive' }
      }}
    />
  )
}

interface IInputParams {
  pendingRequestData: IOrganizationalUnitRequest[] | undefined
  status?: string
  approveRequest: (request: IOrganizationalUnitRequest) => void
  declineRequest: (request: IOrganizationalUnitRequest) => void
  pagination?: {
    fetchMoreResults: () => void
    hasMoreResults: boolean
    isLoading: boolean
    count: number
  }
}

export const OrganizationalUnitRequests: React.FC<IInputParams> = (input) => {
  const { pendingRequestData, pagination } = input

  const titleStatus = input.status ? input.status.toLowerCase() : 'All'

  const loading = pendingRequestData === undefined

  const pendingRequestActions = (rowData?: any) => [
    {
      text: 'Approve/Edit',

      testIdPrefix:
        rowData && rowData.requested_by && rowData.requested_by?.email
          ? 'approve-' + rowData.requested_by?.email
          : 'approve',
      action: (rowId) => {
        const requestObj = pendingRequestData?.find((obj) => obj.id === rowId)
        if (requestObj) {
          input.approveRequest(requestObj)
        }
      }
    },
    {
      text: 'Decline',
      variant: 'secondary',
      testIdPrefix: 'decline',
      action: (rowId) => {
        const requestObj = pendingRequestData?.find((obj) => obj.id === rowId)
        if (requestObj) {
          input.declineRequest(requestObj)
        }
      }
    }
  ]

  const tableData = pendingRequestData
    ? pendingRequestData.map((request) => {
        const cleanedData = {
          id: TableDataProp(request.id),
          test_prefix: TableDataProp(request.requested_by?.email),
          status: TableDataProp(RenderStatus(request.status)),
          start_date: TableDataProp(request.start_date),
          requested_by: [
            TableDataProp(
              request.requested_by?.name,
              `/admin/dashboard/members/${request.requested_by?.id}`
            ),
            TableDataProp(request.requested_by?.email),
            TableDataProp(request.requested_by?.user_profile.mobile_number)
          ],
          plan: [
            TableDataProp(request.plan.name),
            TableDataProp(request.plan.rate_string)
          ]
        }
        if (request.status === 'PENDING') {
          // actions for pending requests
          cleanedData['actions'] = TableDataProp(pendingRequestActions(request))
        } else {
          cleanedData['actions'] = TableDataProp('')
        }

        if (request.requested_for) {
          cleanedData['requested_for'] = [
            TableDataProp(
              request.requested_for.name,
              `/admin/dashboard/members/${request.requested_for.id}`
            ),
            TableDataProp(request.requested_for.email),
            TableDataProp(request.requested_for.user_profile.mobile_number)
          ]
        } else {
          cleanedData['requested_for'] = TableDataProp('Unallocated')
        }
        if (request.organization) {
          cleanedData['organization'] = TableDataProp(
            TeamLink({
              organizationId: request.organization.id,
              organizationName: request.organization.name
            })
          )
        } else {
          cleanedData['organization'] = TableDataProp('')
        }

        return cleanedData
      })
    : []

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box display="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {titleStatus} member requests
          </Text>
          <Text fontSize="xs" color="grey">
            {pagination ? pagination.count : pendingRequestData?.length} Total
          </Text>
        </Box>
        <Table
          pageSize={pagination ? 100 : 10}
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'test_prefix', accessor: 'test_prefix', show: false },
            { header: 'Status', accessor: 'status', show: true },
            { header: 'Requested For', accessor: 'requested_for', show: true },
            { header: 'Requested By', accessor: 'requested_by', show: true },
            { header: 'Team', accessor: 'organization', show: true },
            { header: 'Plan', accessor: 'plan', show: true },
            { header: 'Start Date', accessor: 'start_date', show: true },
            { header: '', accessor: 'actions', show: true }
          ]}
          data={tableData}
          loading={loading}
        />
        {pagination && (
          <Center display={'block'}>
            {pagination.isLoading && <Skeleton my={3} w={'100%'} h="60px" />}

            {pagination.hasMoreResults &&
              pendingRequestData &&
              pendingRequestData?.length > 0 && (
                <Center>
                  <Button
                    disabled={
                      pagination.isLoading || !pagination.hasMoreResults
                    }
                    onClick={pagination.fetchMoreResults}
                    mt={3}
                    p={3}
                  >
                    Fetch more requests
                  </Button>
                </Center>
              )}
          </Center>
        )}
      </Box>
    </div>
  )
}
