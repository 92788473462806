import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  HStack,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Stack,
  Text
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { Dispatch, SetStateAction, useState } from 'react'
import Calendar from 'react-calendar'
import { approveChangeRequest } from '../../api'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useCustomToast } from '../../context/toast-context'
import { InputWarning } from '../../styled/InputWarning'
import { IOrganizationalUnitRequest, Location, Plan } from '../../types'
import { usePlanChangeRequests, usePlans } from '../../utils/apiHooks'
import { Button } from '../Button'
import { ChakraModal } from '../ChakraModal'
import { FormInput } from '../FormInput'
enum PlanType {
  NEW_PLAN = 'new_plan',
  FIRST_SUB_NON_CHARGEABLE = 'first_sub_non_chargeable',
  NON_CHARGABLE_PLAN = 'non_chargable_plan',
  ERROR = 'error'
}

interface AdminDashboardChangeRequestModalProps {
  visibility: {
    modalIsVisible: boolean
    setModalIsVisible: Dispatch<SetStateAction<boolean>>
  }
  selectedPendingRequest: IOrganizationalUnitRequest | null
}
export const AdminDashboardChangeRequestModal: React.FC<
  AdminDashboardChangeRequestModalProps
> = ({ visibility, selectedPendingRequest }) => {
  const { mutate } = usePlanChangeRequests()
  const { newToast: toast } = useCustomToast()

  // State to determing membership type, unique keys are
  const [planType, setPlanType] = useState<PlanType | null>(null)

  //  State to hold new values.
  const [showEditForm, setShowEditForm] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [isNonTaxable, setIsNonTaxable] = useState<boolean>(false)

  const [rateAdjustment, setRateAdjustment] = useState<number>()
  const [rateAdjustmentReason, setRateAdjustmentReason] = useState<string>('')
  const [dateAdjustment, setDateAdjustment] = useState<Date>()
  const [planAdjustment, setPlanAdjustment] = useState<Plan>()
  const [locationAdjustment, setLocationAdjustment] = useState<Location>()
  const [filteredPlans, setFilteredPlans] = useState<Plan[]>([])
  const modalTitles: Map<PlanType, string> = new Map([
    [
      PlanType.NEW_PLAN,
      'Are you sure you want to approve this plan as a new, chargeable plan?'
    ],
    [
      PlanType.FIRST_SUB_NON_CHARGEABLE,
      'Are you sure you want to approve this plan as a with first subscription non-chargeable?'
    ],
    [
      PlanType.NON_CHARGABLE_PLAN,
      'Are you sure you want to approve this plan as a non-chargeable plan?'
    ],
    [PlanType.ERROR, 'Error processing request']
  ])

  const modalContent: Map<PlanType, string> = new Map([
    [PlanType.NEW_PLAN, 'They will be charged a prorated fee for this month'],
    [
      PlanType.FIRST_SUB_NON_CHARGEABLE,
      'They will be charged at the end of their first subscription billing cycle'
    ],
    [PlanType.NON_CHARGABLE_PLAN, 'They will not be billed at all'],
    [
      PlanType.ERROR,
      'There has been an issue whilst trying to process this request. please contact the administrators'
    ]
  ])

  const { platformTenant } = usePlatformTenant()

  const { plans } = usePlans(platformTenant?.id)

  const handleLocationMenuClick = (locationId: number) => {
    const plansToShow = plans.filter(
      (p) => p.location?.id === locationId || p.location === null
    )
    setFilteredPlans(plansToShow)
  }

  const handleOk = () => {
    if (isSubmitting) return
    if (!!selectedPendingRequest && !!selectedPendingRequest.plan) {
      const id = selectedPendingRequest.id
      const token = window.localStorage.getItem('Token')
      const isFirstSubscriptionNonChargeable =
        planType === PlanType.FIRST_SUB_NON_CHARGEABLE
      const nonChargeable = planType === PlanType.NON_CHARGABLE_PLAN
      const overrideIsDifferent =
        rateAdjustment === 0 ||
        rateAdjustment !== selectedPendingRequest.plan.rate.value

      if (planType !== PlanType.ERROR && token) {
        const overrideValue = overrideIsDifferent ? rateAdjustment : undefined
        const overrideReason = overrideIsDifferent
          ? rateAdjustmentReason
          : undefined
        setIsSubmitting(true)
        approveChangeRequest({
          requestId: id,
          overrideValue,
          isFirstSubscriptionNonChargeable,
          nonChargeable,
          IsNonTaxable: isNonTaxable,
          overrideReason,
          location: locationAdjustment?.id,
          plan: planAdjustment?.id,
          startDate: dateAdjustment && format(dateAdjustment, 'yyyy-MM-dd')
        })
          .then((res) => {
            setIsSubmitting(false)
            toast({ description: 'Request approved', status: 'success' })
            mutate()
            clearState()
            visibility.setModalIsVisible(false)
          })
          .catch(() => {
            setIsSubmitting(false)
            toast({ description: 'An Error occurred', status: 'error' })
            setPlanType(PlanType.ERROR)
            visibility.setModalIsVisible(true)
          })
      }
    }
  }

  // clear all state associated with modal
  const clearState = () => {
    visibility.setModalIsVisible(false)
    setPlanType(null)
    setRateAdjustment(undefined)
    setDateAdjustment(undefined)
    setLocationAdjustment(undefined)
    setPlanAdjustment(undefined)
    setShowEditForm(false)
  }

  return (
    <ChakraModal
      padding={1}
      isOpen={visibility.modalIsVisible}
      onClose={() => {
        if (!isSubmitting) clearState()
      }}
    >
      <ModalBody>
        <Stack>
          <Text fontWeight="bold">Approve request</Text>
          <Text fontWeight="bold">Select a membership type</Text>
          <HStack>
            <Button
              disabled={isSubmitting}
              data-testid="new_member"
              variant={planType === PlanType.NEW_PLAN ? 'primary' : 'secondary'}
              onClick={() => {
                setPlanType(PlanType.NEW_PLAN)
              }}
            >
              New member
            </Button>
            <Button
              disabled={isSubmitting}
              data-testid="is_first_subscription_non_chargeable"
              variant={
                planType === PlanType.FIRST_SUB_NON_CHARGEABLE
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() => {
                setPlanType(PlanType.FIRST_SUB_NON_CHARGEABLE)
              }}
            >
              First Subscription Non-Chargeable
            </Button>
          </HStack>
          <HStack>
            <Button
              disabled={isSubmitting}
              data-testid="non_paying"
              variant={
                planType === PlanType.NON_CHARGABLE_PLAN
                  ? 'primary'
                  : 'secondary'
              }
              onClick={() => {
                setPlanType(PlanType.NON_CHARGABLE_PLAN)
              }}
            >
              Non-paying
            </Button>
            <Button
              mt="8px"
              data-testid="non_gst"
              //   onClick={() => {
              //       setPlanType(PlanType.NEW_PLAN)
              //   }}
              // color
              disabled={true}
            >
              GST non-chargeable
            </Button>
          </HStack>

          <Stack>
            <Flex>
              <Box width="50%">
                <Text fontWeight="bold">MEMBER CHOICES</Text>
                <Box mt={3}>
                  <Text fontWeight="bold">Chosen location:</Text>
                  <Text>{selectedPendingRequest?.location?.name}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Chosen plan:</Text>
                  <Text>{selectedPendingRequest?.plan.name}</Text>
                  <Text fontWeight="bold">Plan rate:</Text>
                  <Text>{selectedPendingRequest?.plan.rate_string}</Text>
                </Box>
                {selectedPendingRequest &&
                  selectedPendingRequest.start_date && (
                    <Box>
                      <Text fontWeight="bold">Chosen start date:</Text>
                      <Text>
                        {selectedPendingRequest.start_date &&
                          format(
                            new Date(selectedPendingRequest.start_date),
                            'do MMMM yyyy'
                          )}
                      </Text>
                    </Box>
                  )}
              </Box>
              <Box width="50%">
                <Text fontWeight="bold">ADMIN CHOICES</Text>

                <Box mt={3}>
                  <Text fontWeight="bold">Chosen location:</Text>
                  <Text>
                    {locationAdjustment?.name ||
                      selectedPendingRequest?.location?.name}
                  </Text>
                </Box>

                <Box>
                  <Text fontWeight="bold">Chosen plan:</Text>
                  <Text>
                    {planAdjustment?.name || selectedPendingRequest?.plan.name}
                  </Text>
                  <Text fontWeight="bold">Plan rate:</Text>

                  <Text>
                    {rateAdjustment != undefined
                      ? `$${rateAdjustment}, ${
                          planAdjustment?.rate.frequency ||
                          selectedPendingRequest?.plan.rate.frequency
                        }`
                      : selectedPendingRequest?.plan.rate_string}
                  </Text>
                </Box>

                {selectedPendingRequest &&
                  selectedPendingRequest.start_date && (
                    <Box>
                      <Text fontWeight="bold">Chosen start date:</Text>
                      <Text>
                        {format(
                          dateAdjustment ||
                            new Date(selectedPendingRequest.start_date),
                          'do MMMM yyyy'
                        )}
                      </Text>
                    </Box>
                  )}
              </Box>
            </Flex>

            {planType && (
              <Button
                onClick={() => setShowEditForm(!showEditForm)}
                disabled={isSubmitting}
              >
                Edit request
              </Button>
            )}
          </Stack>

          {showEditForm && (
            <Formik
              enableReinitialize
              initialValues={{
                rate: selectedPendingRequest?.plan.rate.value.toString(),
                rateReason: '',
                location: selectedPendingRequest?.location?.id,
                plan: selectedPendingRequest?.plan?.id,
                startDate: selectedPendingRequest?.start_date
                  ? new Date(Date.parse(selectedPendingRequest?.start_date))
                  : undefined
              }}
              validate={(values): any => {
                const errors: any = {}
                if (values.plan === undefined) {
                  errors.plan = 'You must select a plan'
                }

                if (
                  values.rateReason === '' &&
                  parseFloat(values.rate ?? '').toFixed(2) !==
                    selectedPendingRequest?.plan.rate.value.toString()
                ) {
                  errors.rateReason = 'Reason for rate change required'
                } else if (values.rateReason.length > 100) {
                  errors.rateReason =
                    'Reason for rate change must be less than 100 characters'
                }
                if (values.rate && parseFloat(values.rate) < 0) {
                  errors.rate = 'Altered rate cant be below zero'
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                const overrideIsDifferent =
                  rateAdjustment === 0 ||
                  rateAdjustment !== selectedPendingRequest?.plan.rate.value
                setPlanAdjustment(plans.find((plan) => plan.id === values.plan))
                setDateAdjustment(values.startDate)

                if (overrideIsDifferent) {
                  setRateAdjustment(parseFloat(values.rate ?? ''))
                  setRateAdjustmentReason(values.rateReason)
                }

                setLocationAdjustment(
                  platformTenant?.locations.find(
                    (location) => location.id === values.location
                  )
                )

                setSubmitting(false)
              }}
            >
              {({ isSubmitting, resetForm, setFieldValue, values }) => (
                <Form>
                  <Stack background="#F4F5F5" borderRadius="9px" padding="5px">
                    <Box>
                      <Select
                        value={values.location}
                        onChange={(e) => {
                          setFieldValue('location', parseInt(e.target.value))
                          handleLocationMenuClick(parseInt(e.target.value))
                          setFieldValue('plan', undefined)
                        }}
                      >
                        {platformTenant?.locations.map((location, i) => (
                          <option key={i} value={location.id}>
                            {location.name}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box>
                      {/* Plan */}
                      <Select
                        placeholder={
                          plans.find((plan) => plan.id === values.plan)?.name ||
                          'Select a plan'
                        }
                        onChange={(e) => {
                          setFieldValue('plan', parseInt(e.target.value))
                          setFieldValue(
                            'rate',
                            plans.find(
                              (plan) => plan.id === parseInt(e.target.value)
                            )?.rate.value
                          )
                        }}
                      >
                        {filteredPlans.map((plan, i) => (
                          <option key={i} value={plan.id}>
                            {plan.name}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage name="plan" component={InputWarning} />
                    </Box>
                    <Box>
                      <Text fontWeight="bold">Start date</Text>
                      <Popover>
                        <PopoverTrigger>
                          <Button>
                            {values.startDate &&
                              format(values.startDate!, 'dd/MM/yyyy')}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent zIndex={4}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Choose a date</PopoverHeader>
                          <PopoverBody>
                            <Calendar
                              view="month"
                              minDetail="month"
                              onChange={(date) =>
                                setFieldValue('startDate', date as Date)
                              }
                              value={values.startDate}
                              minDate={new Date()}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Box>
                    <Box>
                      <Field
                        type="number"
                        name="rate"
                        id="rate"
                        label={`Alter rate value ($AUD per ${
                          plans.find((plan) => plan.id === values.plan)?.rate
                            .frequency
                        })`}
                        data-testid="admin-rate-field"
                        placeholder="Enter a rate value ($AUD per month)"
                        component={FormInput}
                      />
                      <ErrorMessage name="rate" component={InputWarning} />
                    </Box>

                    <Box>
                      <Field
                        type="text"
                        name="rateReason"
                        id="rateReason"
                        label="Enter a reason for the rate change"
                        data-testid="admin-rate-reason-field"
                        placeholder="Reason for rate change"
                        component={FormInput}
                      />
                      <ErrorMessage
                        name="rateReason"
                        component={InputWarning}
                      />
                    </Box>

                    <Flex>
                      <Button type="submit" disabled={isSubmitting} mr="8px">
                        Update
                      </Button>
                      <Button
                        variant="secondary"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm()
                          setShowEditForm(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Stack>
                </Form>
              )}
            </Formik>
          )}
          {planType && (
            <Alert status="warning">
              <AlertIcon />
              <Stack>
                <Text>{modalTitles.get(planType)}</Text>
                <Text>{modalContent.get(planType)}</Text>
              </Stack>
            </Alert>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Stack isInline>
          <Button
            variant="secondary"
            key="back"
            onClick={clearState}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            key="submit"
            onClick={handleOk}
            ml="auto"
            data-testid={`confirm_change_request`}
            disabled={planType === null || isSubmitting}
            isLoading={isSubmitting}
            loadingText={'Processing'}
          >
            Continue
          </Button>
        </Stack>
      </ModalFooter>
    </ChakraModal>
  )
}
