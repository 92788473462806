import { useAuth } from '../context/auth-context'
import { MembershipStates } from '../types'
import React, { useEffect, useState, useContext } from 'react'
import { FERN_ALLENDALE_PLAN_UUID, ILUMA_PLAN_UUID } from './constants'
import { hasActiveMemberships } from './redirects'
import { ReactNodeWithProps } from '../component.types'

// inspired by https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

// this could be a good starting point for more complex
// frontend RBAC policies moving forward

export enum Roles {
  Default = 'Default',
  UnapprovedMember = 'UnapprovedMember',
  /** a member who has been approved but currently doesn't have an active membership */
  InactiveMember = 'InactiveMember',
  /** a member who has a current subscription */
  ActiveMember = 'ActiveMember',
  /** a member who does not has a current subscription and is able to book resources */
  ExternalTeamAdminMember = 'ExternalTeamAdminMember',
  ExternalMember = 'ExternalMember',
  TeamAdmin = 'TeamAdmin',
  SpaceAdmin = 'SpaceAdmin',
  SpaceSuperAdmin = 'SpaceSuperAdmin',
  //These roles have been added to be used for a specific use case of the Firn Allendale tenants accessing the business lounge
  FernAllendaleTenantTeamAdmin = 'FernAllendaleTenantTeamAdmin',
  //adding this just for piece of mind, I think in practice all will be team admins on the firn allendale plan
  FernAllendaleTenantActiveMember = 'FernAllendaleTenantActiveMember',
  //These roles have been added to be used for a specific use case of the Iluma tenants accessing the hub
  IlumaTenantTeamAdmin = 'IlumaTenantTeamAdmin',
  IlumaTenantActiveMember = 'IlumaTenantActiveMember'
}

export enum Actions {
  BookMeetingRooms = 'meeting-rooms:book',
  BookWorkspaces = 'workspaces:book',
  ViewPersonalWifiDetails = 'wifi:view',
  ViewTeam = 'team:view',
  ViewBillingForOwnTeam = 'own-billing:view',
  AdministerBillingForSpace = 'all-billing:admin',
  ViewMemberDashboard = 'member-dashboard:view',
  VisitSupportLink = 'support-link:visit',
  ViewPersonalProfile = 'personal-profile:view',
  EditAllWifiPasswords = 'all-wifi-passwords:edit',
  CreateNewPost = 'new-post:create',
  ViewCalendar = 'calendar:view',
  InviteTeamMember = 'new-member-invite:create',
  RequestNewSeat = 'new-seat-request:create',
  ManageTeamMemberships = 'team-membership:edit',
  ManageBillingCredits = 'billing-credits:admin',
  AdministerInvoicesActions = 'billing-invoice:admin',
  AdministerLocation = 'location:admin',
  AdministerOffice = 'office:admin',
  AdministerMeetingRooms = 'meeting-room:admin',
  AdministerWorkspaces = 'workspaces:admin',
  AdministerPlans = 'plans:admin',
  CancelFullTeam = 'team:cancel',
  CreditTeamCharges = 'team:credit-charges',
  ViewMembersDirectory = 'directory:view',
  ViewTeamSettings = 'team:settings'
}

// in future this could be modified to allow users to assume different roles.
// for example, a space admin could 'assume' the role of a member in order to book
// meeting rooms for themselves

export interface RoleProps {
  allowedRoles: Roles[]
  role: Roles
  assumeRole: (role: Roles) => void
  isInAdminRole: boolean
  isSpaceSuperAdminRole: boolean
  isExternalMember: boolean
}

const defaultProps: RoleProps = {
  allowedRoles: [],
  role: Roles.Default,
  assumeRole: () => {},
  isInAdminRole: false,
  isSpaceSuperAdminRole: false,
  isExternalMember: false
}

export const RoleContext = React.createContext<RoleProps>(defaultProps)

type RoleProviderProps = {} & ReactNodeWithProps

export const RoleProvider: React.FC<RoleProviderProps> = ({ children }) => {
  const auth = useAuth()
  const { me, currentOrgUnit, isTeamAdmin, currentOrganization } = auth
  const [allowedRoles, setAllowedRoles] = useState<Roles[]>([])
  const [role, setRole] = useState<Roles>(Roles.Default)

  // This is a convenience method give that spaceadmin and super admin are seperate roles
  const isInAdminRole = [Roles.SpaceAdmin, Roles.SpaceSuperAdmin].includes(role)
  const isSpaceSuperAdminRole = [Roles.SpaceSuperAdmin].includes(role)
  const isExternalMember = [
    Roles.ExternalMember,
    Roles.ExternalTeamAdminMember
  ].includes(role)
  useEffect(() => {
    const newAllowedRoles: Roles[] = []
    if (me && currentOrgUnit) {
      if (currentOrgUnit?.is_space_super_admin) {
        newAllowedRoles.push(Roles.SpaceSuperAdmin)
      }
      if (currentOrgUnit?.organization.is_space_admin) {
        newAllowedRoles.push(Roles.SpaceAdmin)
        newAllowedRoles.push(Roles.ActiveMember)
      }
      //IF ON THE FERN ALLENDALE BUSINESS LOUNGE PLAN, ACT DIFFERENTLY
      if (
        currentOrgUnit &&
        currentOrgUnit.seats &&
        currentOrgUnit.seats?.filter(
          (seat) => seat.active_plan?.uuid === FERN_ALLENDALE_PLAN_UUID
        ).length > 0
      ) {
        if (isTeamAdmin) {
          newAllowedRoles.push(Roles.FernAllendaleTenantTeamAdmin)
        }

        if (
          me?.membershipState === MembershipStates.FullyActive ||
          currentOrgUnit.organization.is_team
        ) {
          // users on a team organization assume the ActiveMember role even if they don't have an active subscription
          newAllowedRoles.push(Roles.FernAllendaleTenantActiveMember)
        }
      } else if (
        currentOrgUnit.seats &&
        currentOrgUnit.seats.filter(
          (seat) => seat.active_plan?.uuid === ILUMA_PLAN_UUID
        ).length > 0
      ) {
        if (isTeamAdmin) {
          newAllowedRoles.push(Roles.IlumaTenantTeamAdmin)
        }

        if (
          me?.membershipState === MembershipStates.FullyActive ||
          currentOrgUnit.organization.is_team
        ) {
          // users on a team organization assume the ActiveMember role even if they don't have an active subscription
          newAllowedRoles.push(Roles.IlumaTenantActiveMember)
        }
      } else if (
        me?.membershipState === MembershipStates.ActiveWithoutMembership ||
        (me &&
          currentOrganization &&
          !hasActiveMemberships(currentOrganization))
      ) {
        if (me.admin_of.find((org) => org.id === currentOrganization?.id)) {
          newAllowedRoles.push(Roles.ExternalTeamAdminMember)
        } else {
          newAllowedRoles.push(Roles.ExternalMember)
        }
      } else {
        if (isTeamAdmin) {
          newAllowedRoles.push(Roles.TeamAdmin)
        }

        if (
          me?.membershipState === MembershipStates.FullyActive ||
          currentOrgUnit?.organization.is_team
        ) {
          // users on a team organization assume the ActiveMember role even if they don't have an active subscription
          newAllowedRoles.push(Roles.ActiveMember)
        }
      }

      if (me?.membershipState === MembershipStates.PendingStartingMembership) {
        newAllowedRoles.push(Roles.InactiveMember)
      }
      newAllowedRoles.push(Roles.UnapprovedMember)
      setAllowedRoles(newAllowedRoles)

      // set the current role to the highest access role if we have one
      // unless the user was already in a role that is allowed
      if (newAllowedRoles.length > 0) {
        //Arguably a bit messy here
        //the goal is if the member is not currently on an admin route but they swotch to the admin role, rediredct them to the admin page.
        //if they are already on an admin route when they change roles, dont redirect them, for example the default role then me loads and sets them to the admin role
        //for all other use cases, we set teh current role to the highest role and let the other route guards/redirects handle it
        if (
          (newAllowedRoles.includes(Roles.SpaceAdmin) ||
            newAllowedRoles.includes(Roles.SpaceSuperAdmin)) &&
          !window.location.pathname.includes('/admin/')
        ) {
          if (!newAllowedRoles.includes(role) || role === Roles.Default) {
            setRole(newAllowedRoles[0])
          }
        } else {
          if (newAllowedRoles.includes(Roles.InactiveMember)) {
            setRole(Roles.InactiveMember)
          } else {
            setRole(newAllowedRoles[0])
          }
        }
      }
    } else {
      setAllowedRoles([])
      setRole(Roles.Default)
    }
  }, [me, isTeamAdmin, currentOrgUnit])

  const assumeRole = (role: Roles) => {
    if (allowedRoles.includes(role)) {
      setRole(role)
    }
  }
  return (
    <RoleContext.Provider
      value={{
        allowedRoles,
        assumeRole,
        role,
        isInAdminRole,
        isSpaceSuperAdminRole,
        isExternalMember
      }}
    >
      {children}
    </RoleContext.Provider>
  )
  // default role - @TODO: if the contexts are set up better such that `me` isn't nullable, this won't be necessary
  // return Roles.Default
}

export const useRole = () => {
  const context = useContext(RoleContext)
  if (context === undefined) {
    throw new Error('useRole must be used within a RoleProvider')
  }
  return context
}

const staticPermissions: { [role: string]: Actions[] } = {
  [Roles.Default]: [],
  [Roles.UnapprovedMember]: [
    Actions.ViewMemberDashboard,
    Actions.VisitSupportLink
  ],
  [Roles.InactiveMember]: [
    Actions.ViewMemberDashboard,
    Actions.ViewTeam,
    Actions.VisitSupportLink,
    Actions.ViewPersonalProfile
  ],
  [Roles.ActiveMember]: [
    Actions.ViewMemberDashboard,
    Actions.ViewPersonalWifiDetails,
    Actions.ViewTeam,
    Actions.BookMeetingRooms,
    Actions.BookWorkspaces,
    Actions.VisitSupportLink,
    Actions.ViewPersonalProfile,
    Actions.CreateNewPost,
    Actions.ViewMembersDirectory,
    Actions.ViewCalendar
  ],
  [Roles.ExternalTeamAdminMember]: [
    Actions.ViewMemberDashboard,
    Actions.ViewTeam,
    Actions.BookMeetingRooms,
    Actions.BookWorkspaces,
    Actions.ViewBillingForOwnTeam,
    Actions.VisitSupportLink,
    Actions.ViewPersonalProfile
  ],
  [Roles.ExternalMember]: [
    Actions.ViewMemberDashboard,
    Actions.ViewTeam,
    Actions.BookMeetingRooms,
    Actions.BookWorkspaces,
    Actions.VisitSupportLink,
    Actions.ViewPersonalProfile
  ],
  [Roles.TeamAdmin]: [
    Actions.ViewMemberDashboard,
    Actions.ViewPersonalWifiDetails,
    Actions.ViewTeam,
    Actions.BookMeetingRooms,
    Actions.BookWorkspaces,
    Actions.ViewBillingForOwnTeam,
    Actions.VisitSupportLink,
    Actions.ViewPersonalProfile,
    Actions.CreateNewPost,
    Actions.ViewCalendar,
    Actions.InviteTeamMember,
    Actions.RequestNewSeat,
    Actions.ManageTeamMemberships,
    Actions.ViewMembersDirectory
  ],
  [Roles.SpaceAdmin]: [
    Actions.ViewTeam,
    Actions.AdministerMeetingRooms,
    Actions.AdministerWorkspaces,
    Actions.AdministerBillingForSpace,
    Actions.ViewBillingForOwnTeam,
    Actions.ViewTeam,
    Actions.ViewTeamSettings,
    Actions.EditAllWifiPasswords,
    Actions.InviteTeamMember,
    Actions.RequestNewSeat,
    Actions.ManageTeamMemberships
  ],

  // Should have all SpaceAdmin permissions and more
  [Roles.SpaceSuperAdmin]: [
    Actions.ViewTeam,
    Actions.AdministerBillingForSpace,
    Actions.ViewBillingForOwnTeam,
    Actions.ViewTeam,
    Actions.ViewTeamSettings,
    Actions.EditAllWifiPasswords,
    Actions.InviteTeamMember,
    Actions.RequestNewSeat,
    Actions.ManageTeamMemberships,
    Actions.ManageBillingCredits,
    Actions.AdministerInvoicesActions,
    Actions.AdministerLocation,
    Actions.AdministerOffice,
    Actions.AdministerMeetingRooms,
    Actions.AdministerMeetingRooms,
    Actions.AdministerWorkspaces,
    Actions.AdministerPlans,
    Actions.CreditTeamCharges,
    Actions.CancelFullTeam
  ],
  [Roles.FernAllendaleTenantActiveMember]: [
    Actions.ViewMemberDashboard,
    Actions.BookMeetingRooms,
    Actions.VisitSupportLink,
    Actions.ViewMembersDirectory,
    Actions.ViewPersonalProfile,
    Actions.ViewCalendar
  ],
  [Roles.FernAllendaleTenantTeamAdmin]: [
    Actions.ViewMemberDashboard,
    Actions.BookMeetingRooms,
    Actions.ViewBillingForOwnTeam,
    Actions.VisitSupportLink,
    Actions.ViewMembersDirectory,
    Actions.ViewPersonalProfile,
    Actions.ViewCalendar
  ],
  [Roles.IlumaTenantActiveMember]: [
    Actions.ViewMemberDashboard,
    Actions.BookWorkspaces,
    Actions.VisitSupportLink,
    Actions.ViewPersonalProfile,
    Actions.ViewCalendar
  ],
  [Roles.IlumaTenantTeamAdmin]: [
    Actions.ViewMemberDashboard,
    Actions.BookWorkspaces,
    Actions.ViewBillingForOwnTeam,
    Actions.VisitSupportLink,
    Actions.ViewPersonalProfile,
    Actions.ViewCalendar
  ]
}

export const useRBAC = (action: Actions): boolean => {
  const auth = useAuth()
  const { role } = useRole()
  const { me } = auth
  // don't allow access if we don't have the user details `Me` object
  if (me === undefined) return false

  // insert business logic here to determine whether a user should have access to certain actions and resources

  const allowedActions = staticPermissions[role]
  if (allowedActions.includes(action)) {
    return true
  }

  return false
}
