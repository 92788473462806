// import 'antd/dist/antd.css'
import { ButtonGroup, Stack } from '@chakra-ui/react'

import {
  addMonths,
  endOfMonth,
  startOfMonth,
  format,
  subMonths
} from 'date-fns'
import React, { useEffect, useState } from 'react'
import { SecondaryButton } from '../../../components/chakra-v2/SecondaryButton'
import { useAuth } from '../../../context/auth-context'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'

import { PlanChangeReportTable } from '../Tables/PlanChangeReportTable'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IPlanChangeRequest } from '../../../types'
import { fetchPaginatedPlanChangesReport } from '../../../api'

interface Props {
  type: 'cancellation' | 'change'
}

export const PlanChangesReport: React.FC<Props> = ({ type }) => {
  const { currentOrgUnit } = useAuth()
  const { platformTenant } = usePlatformTenant()
  const today = new Date()
  const minDate = subMonths(today, 15)

  const [selectedRange, setSelectedRange] = useState<Date[]>([today, today])

  const [page, setPage] = useState(1)
  const page_size = 20
  const [total, setTotal] = useState(-1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isUsingRange, setIsUsingRange] = useState(false)

  const lastMonth = {
    start: startOfMonth(subMonths(today, 1)),
    end: endOfMonth(subMonths(today, 1))
  }

  const thisMonth = {
    start: startOfMonth(today),
    end: endOfMonth(today)
  }

  const nextMonth = {
    start: startOfMonth(addMonths(today, 1)),
    end: endOfMonth(addMonths(today, 1))
  }

  const [times, setTimes] = useState(thisMonth)
  const [allRequests, setAllRequests] = useState<IPlanChangeRequest[]>([])

  const fetchRequests = (
    start: Date,
    end: Date,
    input_type: 'cancellation' | 'change',
    input_page: number
  ) => {
    if (platformTenant) {
      setIsFetching(true)

      fetchPaginatedPlanChangesReport(
        start,
        end,
        input_type,
        input_page,
        page_size,
        platformTenant.id
      )
        .then((res) => {
          if (res && res.data) {
            let results = res.data.results ?? []
            setAllRequests(
              input_page > 1 ? allRequests.concat(results) : results
            )

            setTotal(res.data.count ?? 0)
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFetching(false)
          }
        })
        .catch((error) => {
          setIsFetching(false)
        })
    }
  }

  useEffect(() => {
    fetchRequests(times.start, times.end, type, 1)
  }, [times, type])

  useEffect(() => {
    if (platformTenant) {
      setTimes(thisMonth)
    }
  }, [platformTenant])

  const handleDatesChange = (start: Date, end: Date, isRange: boolean) => {
    if (times.start != start && times.end != end) {
      setIsUsingRange(isRange)
      setTimes({ start, end })
    }
  }

  if (!currentOrgUnit?.organization.parent_platform_tenant) {
    return <CenteredSpinner />
  }

  return (
    <Stack spacing={8}>
      <ButtonGroup>
        <SecondaryButton
          disabled={isFetching}
          variant={
            times.start.valueOf() === lastMonth.start.valueOf() && !isUsingRange
              ? 'outline'
              : 'ghost'
          }
          size="sm"
          onClick={() => {
            handleDatesChange(lastMonth.start, lastMonth.end, false)
          }}
        >
          {format(lastMonth.start, 'LLLL')}
        </SecondaryButton>
        <SecondaryButton
          disabled={isFetching}
          variant={
            times.start.valueOf() === thisMonth.start.valueOf() && !isUsingRange
              ? 'outline'
              : 'ghost'
          }
          size="sm"
          onClick={() => {
            handleDatesChange(thisMonth.start, thisMonth.end, false)
          }}
        >
          {format(thisMonth.start, 'LLLL')}
        </SecondaryButton>
        <SecondaryButton
          disabled={isFetching}
          variant={
            times.start.valueOf() === nextMonth.start.valueOf() && !isUsingRange
              ? 'outline'
              : 'ghost'
          }
          size="sm"
          onClick={() => {
            handleDatesChange(nextMonth.start, nextMonth.end, false)
          }}
        >
          {format(nextMonth.start, 'LLLL')}
        </SecondaryButton>

        <CalendarFormInput
          hideLabel={true}
          selectRange={true}
          rangeProps={{
            chosenDates: selectedRange,
            handleChangeDateRange: (dates) => {
              setSelectedRange(dates)
              handleDatesChange(dates[0], dates[1], true)
            }
          }}
          closeOnSelect={true}
          minDate={minDate}
          shouldConfirmChange={true}
        >
          <SecondaryButton
            variant={isUsingRange ? 'outline' : 'ghost'}
            size="sm"
            onClick={() => setTimes(nextMonth)}
          >
            {isUsingRange
              ? `${format(selectedRange[0], 'PP')} -
            ${format(selectedRange[1], 'PP')}`
              : 'Select a range'}
          </SecondaryButton>
        </CalendarFormInput>
      </ButtonGroup>

      <PlanChangeReportTable
        requests={allRequests}
        pagination={{
          hasMoreResults,
          isLoading: isFetching,
          fetchMoreResults: () => {
            fetchRequests(times.start, times.end, type, page)
          },
          count: total
        }}
      />
    </Stack>
  )
}
