import { Box, Flex, FlexProps, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import comment from '../../assets/comment.svg'
import { useCommunity } from '../../context/community-context'
import { IDailyFiveContent } from '../../types'
import { SaveButton } from './SaveButton'
interface Props extends FlexProps {
  contentObject?: IDailyFiveContent
}

export const SmallDailyFive: React.FC<Props> = ({ contentObject: content }) => {
  const { goToContent } = useCommunity()
  return (
    <Flex h="100%" w="100%" alignItems="space-between">
      <Box w="100%" h="100%">
        <Stack h="100%" cursor="pointer">
          <Text fontSize="xs" color="#255CE1" fontWeight="bold">
            {content ? content.content_type : <Skeleton width={30} />}
          </Text>
          <Text
            onClick={() => goToContent(content)}
            fontSize="s"
            color="#000000"
            fontWeight="bold"
          >
            {content ? content.title : <Skeleton width={150} />}
          </Text>
          <Flex>
            <img
              onClick={() => goToContent(content)}
              src={comment}
              alt="comment"
              style={{ marginRight: '17px' }}
            />
            <SaveButton contentObject={content} />
          </Flex>
        </Stack>
      </Box>

      <Box
        onClick={() => goToContent(content)}
        cursor="pointer"
        flexShrink={0}
        background={
          content && content.thumbnail
            ? `url("${content.thumbnail}")`
            : '#dadada'
        }
        w="80px"
        h="80px"
        backgroundSize="cover"
      />
    </Flex>
  )
}
