import { AxiosError } from 'axios'
import { DjangoErrorResponse } from '../types'

export const showToastAxiosError = (
  toast: any,
  e: AxiosError,
  title: string
) => {
  debugger
  const toastOptions = {
    title,
    status: 'error'
  }
  try {
    if (typeof e.response?.data == 'string') {
      toast(toastOptions)
    } else {
      const errorResponse = e as DjangoErrorResponse
      if (!errorResponse.response?.data?.non_field_errors) {
        throw new Error('No non_field_errors in response')
      }
      for (const error of errorResponse.response.data?.non_field_errors) {
        toast({ ...toastOptions, description: error })
      }
    }
  } catch (error) {
    toast(toastOptions)
  }
}
