import {
  Text,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  Stack
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { BaseModal } from '../../../components/modals/BaseModal'
import { IAddonPlanChangeRequest, IPlanChangeRequest } from '../../../types'
import { KeyValueText } from '../../../components/KeyValueText'
import { format } from 'date-fns'
import { declineChangeRequest, approveChangeRequest } from '../../../api'
import { useCustomToast } from '../../../context/toast-context'

interface IModalInput {
  request?: IAddonPlanChangeRequest
  closeModalCallback: () => void
}

export const ReviewAddonPlanRequestModal: React.FC<IModalInput> = (input) => {
  const { request, closeModalCallback } = input
  const { newToast: toast } = useCustomToast()

  const [overrideValue, setOverrideValue] = useState<number>()
  const [overrideValueValid, setOverrideValueValid] = useState(true)
  const handleSetOverrideValue = (event) => setOverrideValue(event.target.value)
  const [overrideReason, setOverrideReason] = useState<string>()
  const [overrideReasonValid, setOverrideReasonValid] = useState(true)
  const handleSetOverrideReason = (event) =>
    setOverrideReason(event.target.value)

  useEffect(() => {
    let isValid = true
    let isReasonValid = true
    if (overrideValue) {
      if (!overrideReason) {
        isReasonValid = false
      } else if (overrideReason.length > 100) {
        isReasonValid = false
      }
      if (isNaN(overrideValue)) {
        isValid = false
      }
      if (overrideValue < 0) {
        isValid = false
      }
    }
    setOverrideReasonValid(isReasonValid)
    setOverrideValueValid(isValid)
  }, [overrideValue, overrideReason])

  function closeModal() {
    setOverrideValue(undefined)
    setOverrideReason(undefined)
    closeModalCallback()
  }

  function approveRequest() {
    if (overrideValueValid && overrideReasonValid) {
      if (request) {
        approveChangeRequest({
          requestId: request.id,
          overrideValue,
          overrideReason
        })
          .then(() => {
            toast({
              status: 'success',
              description: 'Request Approved'
            })
            closeModal()
          })
          .catch(() => {
            toast({
              status: 'error',
              description: 'Failed to Approve Request'
            })
            closeModal()
          })
      }
    }
  }

  function declineRequest() {
    if (request) {
      declineChangeRequest(request.id)
        .then(() => {
          toast({
            status: 'success',
            description: 'Request Declined'
          })
          closeModal()
        })
        .catch(() => {
          toast({
            status: 'error',
            description: 'Failed to Decline Request'
          })
          closeModal()
        })
    }
  }

  if (request === undefined) {
    return null
  }

  return (
    <BaseModal
      title="Review Request"
      isOpen={request !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText="Approve Request"
      primaryAction={approveRequest}
      secondaryButtonText="Decline Request"
      secondaryAction={declineRequest}
    >
      <KeyValueText
        title="Request Type"
        value={`${request.plan_request_type} Addon Plan Request`}
      />
      <KeyValueText title="Team" value={`${request.organization.name}`} />
      <KeyValueText
        title={`Requested ${request.plan_request_type} Date`}
        value={`${format(Date.parse(request.change_date), 'dd/MM/yyyy')}`}
      />
      {request.old_plan && (
        <KeyValueText
          title="Current Plan"
          value={`${request.old_plan?.name}`}
        />
      )}
      {request.plan_request_type === 'Change' && (
        <>
          <KeyValueText
            title="Requested Plan"
            value={`${request.new_plan?.name} - ${request.new_plan?.rate_string}`}
          />
          <KeyValueText
            title="Plan Category"
            value={`${request.new_plan?.category}`}
          />
          <Stack isInline>
            <FormLabel htmlFor="overrideValue" fontSize={15}>
              Plan Override Value:
            </FormLabel>
            <InputGroup width={'35%'} size="sm">
              <InputLeftElement children="$" />
              <Input
                isInvalid={!!!overrideValueValid}
                value={overrideValue}
                onChange={handleSetOverrideValue}
                id="overrideValue"
                placeholder="Override Value"
                variant="flushed"
              />
            </InputGroup>
          </Stack>

          {overrideValue && (
            <Stack isInline>
              <FormLabel htmlFor="overrideValue" fontSize={15}>
                Plan Override Reason:
              </FormLabel>
              <InputGroup width={'35%'} size="sm">
                <Input
                  isInvalid={!!!overrideReasonValid}
                  value={overrideReason}
                  onChange={handleSetOverrideReason}
                  id="overrideReason"
                  placeholder="Override Reason"
                  variant="flushed"
                />
              </InputGroup>
            </Stack>
          )}
        </>
      )}
    </BaseModal>
  )
}
