import { StackProps, Text, Stack, Box, Flex, Spacer } from '@chakra-ui/react'
import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { routeStrings } from '../../../../routeStrings'

import { OverviewBox } from './OverviewBoxBase'
import { RouterLink } from '../../../../components/Link'
import { IBasicDirectoryMemberResult } from '../../../../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { formatDistance } from 'date-fns'
import { useMembersDirectoryContext } from '../../../../context/members-directory-context'
import { tourTargetStrings } from '../../../../tourStepsArrays'
interface Props extends StackProps {
  connections?: IBasicDirectoryMemberResult[]
  loading?: boolean
}

export const DirectoryConnectionsOverview: React.FC<Props> = ({
  connections,
  loading,
  ...rest
}) => {
  const { logOpenConnections, logOpenDirectory } = useMembersDirectoryContext()
  if (!connections) return null
  return (
    <SkeletonTheme baseColor="#c7ce94" highlightColor="#b3cdf5">
      <OverviewBox
        bg="#c6d4ef"
        header="Latest Member Connections"
        className={tourTargetStrings.dashboardDirectory}
        headerIcon={
          <FontAwesomeIcon icon={faAddressCard} size={'1x'} color="#2d4467" />
        }
        mb={5}
        {...rest}
      >
        <Stack spacing={1} shouldWrapChildren>
          {connections.map((connection, index) => {
            return (
              <Box my={0} key={index}>
                <Flex>
                  <Text fontSize={'xs'} fontWeight="bold">
                    {connection.member_name}
                  </Text>
                  <Spacer />
                  <Text fontSize={'xx-small'}>
                    {formatDistance(
                      new Date(connection.created_at),
                      new Date(),
                      {
                        includeSeconds: false,
                        addSuffix: true
                      }
                    )}
                  </Text>
                </Flex>
                <Text fontSize={'xs'}>{connection.organization}</Text>
              </Box>
            )
          })}
          {connections?.length > 0 ? (
            <RouterLink
              fontSize="sm"
              color="#255CE1"
              to={
                routeStrings.memberDashboardMembersDirectory +
                '/?tab=connections'
              }
              onClick={() => {
                logOpenConnections('HOME')
              }}
            >
              View all connections
            </RouterLink>
          ) : (
            <RouterLink
              fontSize="sm"
              color="#255CE1"
              to={routeStrings.memberDashboardMembersDirectory}
              onClick={() => {
                logOpenDirectory('HOME')
              }}
            >
              Browse the Directory
            </RouterLink>
          )}
        </Stack>
      </OverviewBox>
    </SkeletonTheme>
  )
}
