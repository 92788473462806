import { Box, Flex, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { endOfWeek, format } from 'date-fns'
import React from 'react'
import Placeholder from '../assets/placeholder.svg'
import { IBooking } from '../types'
import { useOrganizationalUnitBookings } from '../utils/apiHooks'
import { PrimarySpinner } from './chakra-v2/PrimarySpinner'

export const UpcomingMeetingRoomBookings: React.FC<any> = (props) => {
  const { data: allBookings } = useOrganizationalUnitBookings(
    'upcoming',
    props.ouId
  )

  if (allBookings) {
    const Records = allBookings ?? []
    const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 })

    const filteredThisWeek = Records.filter((meeting) => {
      let formattedStartDate = new Date(
        Date.parse(meeting.start_time.toString())
      )
      if (formattedStartDate > new Date() && formattedStartDate < endOfThisWeek)
        return true
    })

    const filteredNextWeek = Records.filter((meeting) => {
      let formattedStartDate = new Date(
        Date.parse(meeting.start_time.toString())
      )
      if (formattedStartDate > endOfThisWeek) return true
    })

    // console.log(data)
    return (
      <Flex
        // overflowX="hide"
        flexWrap="wrap"
        flexDirection="column"
        maxWidth="500px"
      >
        <Text
          mb={2}
          fontWeight="semibold"
          color="headingPrimary"
          fontSize="lg"
          my="24px"
        >
          Upcoming
        </Text>

        <MeetingRoomBookingGroup
          title="This week"
          meetingRoomBookings={filteredThisWeek}
        />
        <MeetingRoomBookingGroup
          title="Next week"
          meetingRoomBookings={filteredNextWeek}
        />
      </Flex>
    )
  } else
    return (
      <Flex p={5} justify="center">
        <PrimarySpinner />
      </Flex>
    )
}

interface MeetingRoomBookingGroupProps {
  meetingRoomBookings: IBooking[]
  title: string
}

const MeetingRoomBookingGroup = (props: MeetingRoomBookingGroupProps) => {
  return (
    <Box marginBottom="32px">
      <BookingTypeTitle>{props.title}</BookingTypeTitle>

      {props.meetingRoomBookings.map((meeting) => {
        let formattedStartDate = new Date(
          Date.parse(meeting.start_time.toString())
        )
        let formattedEndDate = new Date(Date.parse(meeting.end_time.toString()))
        let dayNum = format(formattedStartDate, 'd')
        let dayName = format(formattedStartDate, 'E')
        return (
          <Flex
            flexDirection="row"
            bg="white"
            width="389px"
            marginY="8px"
            padding="16px"
          >
            <DateBox>
              <h3>{dayName}</h3>
              <h2>{dayNum}</h2>
            </DateBox>
            <MeetingInfoBox>
              <h3 className="name">{meeting.meeting_room_name}</h3>
              <h3 className="time">
                {format(formattedStartDate, 'H:mm') +
                  '-' +
                  format(formattedEndDate, 'H:mm a')}
              </h3>
            </MeetingInfoBox>
            <Flex
              height="56px"
              width="100%"
              textAlign="center"
              verticalAlign="middle"
              backgroundImage={Placeholder}
              justifyContent="center"
            >
              <img src={Placeholder} alt="placeholder" />
            </Flex>
          </Flex>
        )
      })}
    </Box>
  )
}

// All styling shown below
const DateBox = styled(Box)`
  margin-right: 16px;
  /* date / day */
  h3 {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 14px;
    color: #2f4858; /* text / primary */
  }
  /* date / day (number) */
  h2 {
    font-family: Open Sans;
    font-size: 22px;
    color: #2f4858; /* text / primary */
  }
`

const MeetingInfoBox = styled(Flex)`
  flex-direction: column;
  width: 200px;

  h3.name {
    font-weight: 600;
    font-size: 14px;
    color: #2f4858; /* text / primary */
  }
  h3.time {
    font-size: 14px;
    color: #2f4858; /* text / primary */
  }
`

const BookingTypeTitle = styled(Flex)`
  font-size: 14px;
  line-height: 21px;
  color: #000000;
`

// const MeetingBox = styled(Flex)`
//   b
// `
