import React from 'react'
import { Text, VStack } from '@chakra-ui/react'

interface AccountCodeCellProps {
  value: string | undefined
}

export const ValueComponent: React.FC<AccountCodeCellProps> = (props) => {
  const { value } = props

  if (!value) {
    return null
  }
  return (
    <VStack justify="center" align="start" spacing={1}>
      <Text fontSize={'md'} fontWeight="bold">
        ${value}
      </Text>
    </VStack>
  )
}
