import React, { useEffect } from 'react'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import {
  BillingHistory,
  InvoiceStatusTag
} from '../../components/BillingHistory'
import { BillingInfo } from '../../components/BillingInfo'
import { Bonds } from '../../components/Bonds'
import {
  GroupingHeaderLayout,
  IActionButton
} from '../../components/GroupingHeaderLayout'
import { PaymentDetails } from '../../components/PaymentDetails'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'
import { MemberDashboardBillingUpdateModal } from '../MemberDashboard/Modals/MemberDashboardBillingUpdateModal'
import { useAuth } from '../../context/auth-context'
import { useParams } from 'react-router-dom'
import {
  useOrganization,
  useOrganizationInvoicePreview
} from '../../utils/apiHooks'
import { BillingCreditHistory } from '../../components/BillingCreditHistory'
import { BillingOneTimeCharge } from '../../components/BillingOneTimeCharge'

import { useFeatureFlags } from '../../context/feature-flag-context'
import { Roles, useRole } from '../../utils/rbac'
import {
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Box,
  Stack,
  Link,
  useToast
} from '@chakra-ui/react'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { TableRow } from '../../components/TableRow'
import { routeStrings } from '../../routeStrings'
import { RemovedOrganizationAlert } from '../../components/RemovedOrganizationAlert'
import { useNavigate } from 'react-router-dom'

export const TeamAdminDashboardBilling: React.FC = () => {
  // State to hide/show invite modal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const headerAction: IActionButton = {
    text: 'Update payment info',
    testIdPrefix: 'request-seat',
    variant: '',
    onClick: () => onOpen()
  }

  //used to enable/disable the billing update modal if user is an admin
  const auth = useAuth()
  const navigate = useNavigate()
  const { allowedRoles, isInAdminRole } = useRole()
  const toast = useToast()

  const { currentOrgUnit } = auth
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()
  const { bondsUIEnabled } = useFeatureFlags()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id

  const { data: organization, error } = useOrganization(orgId)
  const { data: preview } = useOrganizationInvoicePreview(organization?.id)

  useEffect(() => {
    if (organization && organization.id.toString() === routeOrgId) {
      auth.adminChangeOrganization(organization)
    }
  }, [organization])

  useEffect(() => {
    if (error) {
      toast({
        title: "You don't have enough permissions",
        status: 'error'
      })
      navigate(routeStrings.adminDashboard)
    }
  }, [error])

  const Preview: React.FC = (props) => {
    if (!preview) return <CenteredSpinner />
    return (
      <Box my="3" maxWidth="650px">
        <Box display="flex" mb={3} alignItems="center">
          <Text
            mb={0}
            fontWeight="semibold"
            color="headingPrimary"
            fontSize="lg"
          >
            Upcoming Invoice
          </Text>
        </Box>
        <TableRow justify="space-between" align="start" mb={3} p={3}>
          <Text
            color="textPrimary"
            fontWeight="bold"
            fontSize="sm"
            width={'33%'}
          >
            Upcoming
          </Text>
          <Box mr="auto">
            <Stack isInline align="center" mb={1}>
              {/* <Tag positive>Paid</Tag> */}
              <Text color="textPrimary" fontSize="sm" my={0} mr={2}>
                ${preview.invoice.amount_charged}
              </Text>
              <InvoiceStatusTag text={preview.invoice.status} />
              {/* <Tag negative>Outstanding</Tag> */}
            </Stack>
            {/* <Text color="eastBay07" fontSize="sm" my={0}>
            Due {format(getFirstOfNextMonth(), 'dd/MM/yyyy')}
          </Text> */}
          </Box>
          <Link
            fontSize="sm"
            color="link"
            onClick={() =>
              navigate(
                routeStrings.memberDashboardBillingInvoice.replace(
                  ':invoiceId',
                  preview.invoice.id + ''
                )
              )
            }
          >
            View PDF
          </Link>
        </TableRow>
      </Box>
    )
  }

  // @TOOD: this component needs to made more declarative. There are a bunch of "flags" eg
  // checking `currentOrganization` which determine what to render.
  // this should be refactored to remove the flags to be more scalable.
  return (
    <LayoutMemberDashboard>
      <RemovedOrganizationAlert removed={organization?.is_deleted} />
      <GroupingHeaderLayout
        basicTitle="Billing"
        action={
          !isInAdminRole && organization && !organization.is_deleted
            ? headerAction
            : undefined
        }
      />

      <AdminBodyWrapper pl={4}>
        <Tabs>
          <TabList>
            <Tab>Billing Details</Tab>

            {allowedRoles.includes(Roles.SpaceAdmin) && (
              <>
                <Tab>One-time charges</Tab>
                <Tab>Billing Credits</Tab>
                {bondsUIEnabled && <Tab>Bonds</Tab>}
              </>
            )}
          </TabList>

          <TabPanels>
            <TabPanel>
              <PaymentDetails />
              <BillingInfo />
              <Preview />
              <BillingHistory />
            </TabPanel>

            {/* Space admins only */}
            <TabPanel>
              {allowedRoles.includes(Roles.SpaceAdmin) && (
                <BillingOneTimeCharge />
              )}
            </TabPanel>
            {/* super admins only, the check is in the component, returns null if not permitted to view */}
            <TabPanel>
              {allowedRoles.includes(Roles.SpaceAdmin) && (
                <BillingCreditHistory />
              )}
            </TabPanel>
            {allowedRoles.includes(Roles.SpaceAdmin) && (
              <TabPanel>{bondsUIEnabled && <Bonds />}</TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </AdminBodyWrapper>

      {/*fix a bug in the admin dasboard when viewing member billing, will not render the modal */}
      {organization && currentOrgUnit && (
        <MemberDashboardBillingUpdateModal
          visibility={{
            isOpen: isOpen,
            onClose: onClose
          }}
        />
      )}
    </LayoutMemberDashboard>
  )
}
