import { BoxProps, Button, Icon, Stack, Text } from '@chakra-ui/react'
import React, { Dispatch, SetStateAction } from 'react'
import { Location } from '../../../../types'
import { DragHandleIcon } from "@chakra-ui/icons"
interface LocationPickerProps extends BoxProps {
  locationOptions?: Location[]
  selected?: Location
  setSelected: Dispatch<SetStateAction<Location | undefined>>
}

export const LocationPicker: React.FC<LocationPickerProps> = (props) => {
  return (
    <Stack overflowX="auto">
      <Stack isInline spacing="8px" align="center">
        <DragHandleIcon />
        <Text>Location</Text>
      </Stack>
      <Stack spacing="1px" isInline w="100%" overflow="scroll">
        {props.locationOptions?.map((location, index) => (
          <Button
            flexShrink={0}
            w="fit-content"
            h="44px"
            key={index}
            borderRadius="0px"
            backgroundColor={
              props.selected === location ? 'brandPrimary' : 'white'
            }
            color={props.selected === location ? 'white' : '#303B40'}
            onClick={() => {
              props.setSelected(location)
            }}
          >
            {location.name}
          </Button>
        ))}
      </Stack>
    </Stack>
  )
}
