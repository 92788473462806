// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'
/** @jsxImportSource @emotion/react */

import { Box, Checkbox } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'
import { fetchBookableMeetingRoomLocations } from '../api'
import { useAuth } from '../context/auth-context'
import { useBookingContext } from '../context/BookingContext'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { Location } from '../types'
import { useRole } from '../utils/rbac'
import { FilterableSelect } from './FilterableSelect'
export const FilterableBookableLocationsSelect = ({
  name,
  selectedIndex,
  onSelect,
  dataTestId,
  initialValue
}: {
  name?: string
  selectedIndex?: number
  initialValue?: number
  onSelect: (location: Location) => any
  additionalActions?:
    | Array<{ label: string | JSX.Element; itemAction: (arg: any) => void }>
    | undefined
  dataTestId?: string
}) => {
  const { currentOrganization } = useAuth()
  const { platformTenant } = usePlatformTenant()
  const { isExternalMember } = useRole()
  const { locations: tenantBookingLocations, setChosenLocation } =
    useBookingContext()

  const [value, setValue] = useState<string | undefined>()
  const [debouncedTerm, setDebouncedTerm] = useState('')
  const [includePublicRooms, setIncludePublicRooms] = useState(isExternalMember)

  const [locations, setLocations] = useState<Location[]>([])

  const [page, setPage] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFeching] = useState(false)
  const isSpaceAdmin = currentOrganization?.is_space_admin

  const fetchPublicLocations = (input_page: number, value: string) => {
    if (isFetching || !includePublicRooms) return
    if (platformTenant) {
      setIsFeching(true)
      return fetchBookableMeetingRoomLocations(
        platformTenant.id,
        includePublicRooms,
        value,
        input_page
      )
        .then((res) => {
          if (res && res.data) {
            if (res.data.results) {
              let fetchedLocations: Location[] = []
              res.data.results.forEach((location) => {
                if (!locations?.find((l) => l.id === location.id)) {
                  fetchedLocations.push(location)
                }
              })
              setLocations(locations.concat(fetchedLocations))
            }
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }

  useEffect(() => {
    if (initialValue) {
      if (!tenantBookingLocations.find((l) => l.id === initialValue)) {
        setChosenLocation(
          tenantBookingLocations.length > 0
            ? tenantBookingLocations[0]
            : undefined
        )
      }
    }
  }, [])
  useEffect(() => {
    if (tenantBookingLocations) {
      let has_bookable_meeting_rooms = false

      tenantBookingLocations.forEach((l) => {
        has_bookable_meeting_rooms =
          has_bookable_meeting_rooms || l.has_bookable_meeting_rooms === true
      })
      setIncludePublicRooms(!has_bookable_meeting_rooms || isExternalMember)
    }
    setLocations([])
  }, [tenantBookingLocations, isExternalMember])
  useEffect(() => {
    if (platformTenant && includePublicRooms) {
      fetchPublicLocations(1, '')
    }
    setLocations([])
  }, [includePublicRooms, platformTenant])

  useEffect(() => {
    if (platformTenant && value != undefined) {
      fetchPublicLocations(1, value)
    }
  }, [value, platformTenant])

  useEffect(() => {
    const timer = setTimeout(() => setValue(debouncedTerm), 600)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  const mapLocationOption = (loc: Location) => ({
    label: `${loc.name} - ${loc.address}, ${loc.city?.name}`,
    value: loc.id
  })

  const mapExternalLocationOption = (loc: Location) => ({
    label: `${loc.platform_tenant_name} - ${loc.name} - ${loc.address}, ${loc.city?.name}`,
    value: loc.id
  })

  return (
    <Box>
      <FilterableSelect
        onInputChange={(newValue: string) => {
          setDebouncedTerm(newValue)
        }}
        dataTestId={dataTestId}
        items={[
          {
            label: platformTenant?.business_name ?? '',
            options:
              tenantBookingLocations
                .filter((l) => l.has_bookable_meeting_rooms)
                .map(mapLocationOption) || []
          },
          {
            label: 'External Locations',
            options: locations.map(mapExternalLocationOption) || []
          }
        ]}
        name={name ?? 'Select a value'}
        initialValue={initialValue}
        onSelect={(n) => {
          const loc = tenantBookingLocations
            .concat(locations)
            .find((l) => l.id === n)
          if (loc) onSelect(loc)
        }}
        onMenuScrollToBottom={() => {
          if (hasMoreResults) fetchPublicLocations(page, value ?? '')
        }}
        isClearable={false}
      />
      {!isSpaceAdmin && !isExternalMember && (
        <Checkbox
          checked={includePublicRooms}
          onChange={(value) => {
            setIncludePublicRooms(value.target.checked)
            fetchPublicLocations(1, debouncedTerm)
          }}
          size="sm"
        >
          Find meeting rooms outside {platformTenant?.business_name}
        </Checkbox>
      )}
    </Box>
  )
}
