import { Box, Heading, Stack, Text } from '@chakra-ui/react'

import React from 'react'

interface IInput {
  title: string
  value?: string
  wrap?: boolean
}

export const VerticalKeyValueText: React.FC<IInput> = (input) => {
  const { title, value, wrap, ...rest } = input

  return (
    <Box>
      <Heading size="xs" textTransform="uppercase">
        {title}
      </Heading>
      <Text pt="2" fontSize="sm">
        {value ?? '--'}
      </Text>
    </Box>
  )
}
