import { Box, HStack, Select, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { IEventsFilter } from '../types'
import { FilterableInterestTags } from '../pages/DailyFive/FilterableInterestTags'
import { Button } from './Button'

interface Props {
  loading: boolean
  eventsFilter: IEventsFilter
  onFilterChange: (filterData: IEventsFilter) => void
}

export const EventsCalendarFilters: React.FC<Props> = (props) => {
  const { loading, onFilterChange } = props
  const [filterLocations, setFilterLocations] = useState<string | undefined>()
  const { platformTenant } = usePlatformTenant()
  const [interests, setInterests] = useState<number[] | undefined>()
  const debounceTime = 1000
  function handleClearFilter(event) {
    setFilterLocations(undefined)
    setInterests(undefined)
  }

  useEffect(() => {
    const timer = setTimeout(
      () =>
        onFilterChange({
          interests: interests,
          location: filterLocations ? parseInt(filterLocations) : undefined
        }),
      debounceTime
    )
    return () => clearTimeout(timer)
  }, [interests, filterLocations])

  return (
    <Box bg="white" w={'100%'} shadow={4} rounded="md" px={8} py={3}>
      <VStack w="100%" alignItems="left">
        <HStack w="100%" spacing={3}>
          <Box w={['100%', '30%']}>
            <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
              Location
            </Text>

            <Select
              disabled={loading}
              placeholder="All locations"
              value={filterLocations ? filterLocations : 'All locations'}
              onChange={(ev) => {
                setFilterLocations(ev.target.value)
              }}
            >
              {platformTenant?.locations.map((loc) => {
                return (
                  <option key={loc.name} value={loc.id}>
                    {loc.name}
                  </option>
                )
              })}
            </Select>
          </Box>
          <Box w={['100%', '30%', '50%']} style={{ zIndex: 5 }}>
            <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
              Interests
            </Text>
            <FilterableInterestTags
              initialValues={interests}
              value={interests}
              onSelectionChanges={(interests) => {
                setInterests(interests)
              }}
            />
          </Box>
          <Box pt={6}>
            {filterLocations || interests ? (
              <Button
                disabled={loading}
                variant="primary"
                size={'xs'}
                onClick={handleClearFilter}
              >
                Clear filters
              </Button>
            ) : null}
          </Box>
        </HStack>
      </VStack>
    </Box>
  )
}
