import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { superAdminRequestCancelFullTeam } from '../../../api'
import { Button } from '../../../components/Button'
import { ChakraModal } from '../../../components/ChakraModal'
import { useCustomToast } from '../../../context/toast-context'
import { IOrganization } from '../../../types'
import { Actions, useRBAC } from '../../../utils/rbac'
import { SuperAdminPrivilegeRequiredModal } from './SuperAdminPrivilegeRequiredModal'

interface IModalInput {
  isOpen: boolean
  org: IOrganization | undefined
  onClose: () => void
}

export const CancelFullTeamModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  org
}) => {
  const [cancellationDate, setCancellationDate] = useState<Date>(new Date())
  const canCancelFullTeam = useRBAC(Actions.CancelFullTeam)
  const { newToast: toast } = useCustomToast()

  if (!org) {
    return null
  }
  function submit() {
    if (org) {
      superAdminRequestCancelFullTeam(org.id, cancellationDate)
        .then(() => {
          toast({
            status: 'success',
            description: `All team subscriptions cancelled`
          })
          onClose()
        })
        .catch((e) => {
          toast({
            status: 'error',
            description: 'Failed to cancel team subscriptions'
          })
        })
    }
  }

  return (
    <ChakraModal title="Cancel full team" isOpen={isOpen} onClose={onClose}>
      {canCancelFullTeam ? (
        <>
          <Box mt={3}>
            <Stack fontWeight="normal" isInline mb={2}>
              <Text fontSize="md" color="textPrimary">
                Cancellation date:{' '}
              </Text>
              <Text fontSize="md" color="#ce2121">
                {cancellationDate.toLocaleDateString()}
              </Text>
            </Stack>
            <Text fontSize="sm" pb="26px">
              The cancellation must be end of current month or after.
            </Text>
            <Box>
              <Calendar
                selectRange={false}
                onChange={(date) => {
                  setCancellationDate(
                    Array.isArray(date) ? (date[0] as Date) : (date as Date)
                  )
                }}
                value={cancellationDate}
                minDate={new Date()}
              />
            </Box>
          </Box>

          <Flex flexDirection="row" mt={10}>
            <Button key="submit" data-testid="assign" onClick={submit} mr="8px">
              Confirm Team Cancellation
            </Button>
            <Button
              key="cancel"
              data-testid="cancel"
              variant="secondary"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Flex>
        </>
      ) : (
        <SuperAdminPrivilegeRequiredModal onClose={onClose} />
      )}
    </ChakraModal>
  )
}
