import { isProduction } from './isProduction'

export const ANALYTICS_MODULE_MEMBERS_DIRECTORY = 'members_directory'
export const ANALYTICS_MODULE_CHATBOT = 'ai_chat_bot'

//  The following is a triple slash directive to instruct TS to use segment-analytics types
/// <reference types="@types/segment-analytics" />
declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

export const analyticsLoad = () => {
  if (isProduction) {
    window.analytics.load('B593zSv9moqimvjyEM2vIrGbXYcR74Vm')
  }
}
export const analyticsPage = () => {
  if (isProduction) {
    window.analytics.page()
  }
}
export const analyticsTrack = (name: string, properties: any) => {
  if (isProduction) {
    window.analytics.track(name, { ...properties, medium: 'Platform Web App' })
  } else {
    console.log('Analytics', {
      name,
      properties
    })
  }
}

export const analyticsIdentify = (userId: string, traits: any) => {
  if (isProduction) {
    window.analytics.identify(userId, traits)
  }
}
