import { Flex, FlexProps, Grid } from '@chakra-ui/react'
import React from 'react'
import { IDailyFiveNotification } from '../../types'
import { SmallDailyFive } from './SmallDailyFive'
import { useCommunityProfile } from '../../utils/apiHooks'
interface Props extends FlexProps {
  notification?: IDailyFiveNotification
}

export const SavedContentList: React.FC<Props> = ({
  notification,
  ...rest
}) => {
  const { data: communityProfile } = useCommunityProfile()
  return (
    <Flex
      flexDirection="column"
      h="100%"
      w="100%"
      alignItems="space-between"
      {...rest}
    >
      <Grid templateColumns={['1f', '1f', '1fr 1fr']} gap={6}>
        {communityProfile &&
          communityProfile[0].saved_content &&
          communityProfile[0].saved_content.map((content, i) => {
            return <SmallDailyFive key={i} contentObject={content} />
          })}
      </Grid>
    </Flex>
  )
}
