import { Flex, FormErrorMessage, FormLabel, Stack } from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { ChakraModal } from '../ChakraModal'
import { UserDisplay } from '../UserDisplay'
import { Button } from '../Button'
import { FileUploadComponent } from '../../pages/AdminDashboard/Components/FileUploadComponent'

export const ProfilePictureUpdateModal = ({
  initialURL,
  revalidate,
  visibility,
  handleSave,
  handleDelete
}: {
  initialURL?: string
  revalidate?: () => void
  visibility: {
    modalIsVisible: boolean
    setModalIsVisible: Dispatch<SetStateAction<boolean>>
  }
  handleSave?: (file: File) => Promise<string>
  handleDelete?: () => void
}) => {
  const [file, setFile] = useState<File>()
  const clearState = () => {
    visibility.setModalIsVisible(false)
  }

  return (
    <ChakraModal
      title="Select an image"
      isOpen={visibility.modalIsVisible}
      onClose={clearState}
    >
      <Formik
        initialValues={{
          image: undefined
        }}
        validate={(values): { image: File } => {
          const errors: any = {}
          if (values.image === undefined) {
            errors.image = 'You must select an image'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (!file) return

          if (handleSave != undefined) {
            setSubmitting(true)
            handleSave(file)
              ?.then((success) => {
                setSubmitting(false)
              })
              .catch((err) => {
                setSubmitting(false)
              })
          }
        }}
      >
        {({ values, setFieldValue, isSubmitting, touched, errors }) => (
          <Form>
            <Stack>
              <Stack>
                <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                  Select a profile image
                </FormLabel>

                <FileUploadComponent
                  accept="image/png, image/jpeg"
                  documentType="Profile Image"
                  fileUrl={initialURL}
                  initialValue={initialURL}
                  uploadedFile={undefined}
                  isUploadingFile={false}
                  setUploadedFile={(file) => {
                    setFieldValue('image', file)
                    setFile(file)
                  }}
                />

                <ErrorMessage name="image" component={FormErrorMessage} />
                <UserDisplay
                  my="8px"
                  size="xl"
                  src={values.image && URL.createObjectURL(values.image)}
                />
              </Stack>

              <Flex>
                <Button type="submit" disabled={isSubmitting} mr="8px">
                  Save
                </Button>
                {handleDelete && (
                  <Button
                    disabled={isSubmitting}
                    mr="8px"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  disabled={isSubmitting}
                  variant="secondary"
                  onClick={() => clearState()}
                >
                  Cancel
                </Button>
              </Flex>
            </Stack>
          </Form>
        )}
      </Formik>
    </ChakraModal>
  )
}
