import {
  Box,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FilterableSelect } from '../../../components/FilterableSelect'
import { BaseModal } from '../../../components/modals/BaseModal'
import { IOrganizationalUnitBasic } from '../../../types'

interface IModalInput {
  allMembers?: IOrganizationalUnitBasic[]
  member?: IOrganizationalUnitBasic
  closeModalCallback: () => void
  handleRemoveMember: (action: string, assignee?: number) => void
}

const ACTION_ASSIGN = 'ASSIGN'
const ACTION_CANCEL = 'CANCEL'

export const RemoveMemberModal: React.FC<IModalInput> = (input) => {
  const { member, allMembers, closeModalCallback, handleRemoveMember } = input
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<number | undefined>()
  const [selectedAction, setSelectedAction] = useState<string | undefined>()
  const toast = useToast()

  const resetValues = () => {
    setSelectedAction(undefined)
    setSelectedOrgUnit(undefined)
  }

  return (
    <BaseModal
      title="Remove Member"
      isOpen={member !== undefined}
      closeModalCallback={closeModalCallback}
      primaryButtonText="Remove Member"
      primaryAction={() => {
        if (!selectedAction) return
        if (selectedAction === ACTION_ASSIGN) {
          if (!selectedOrgUnit) {
            toast({
              title: 'You must select a member to assign the bookings',
              status: 'error'
            })
            return
          }
        }
        handleRemoveMember(
          selectedAction,
          selectedAction === ACTION_ASSIGN ? selectedOrgUnit : undefined
        )
        resetValues()
      }}
      secondaryAction={() => {
        closeModalCallback()
        resetValues()
      }}
    >
      <Text>
        What would you like to do with the future bookings this member has done?
      </Text>
      <Divider my={3} />
      <RadioGroup
        onChange={(value) => {
          setSelectedAction(value)
        }}
      >
        <Stack spacing={2}>
          <Radio size="md" name="removalAction" value={ACTION_ASSIGN}>
            Assign bookings to another member
          </Radio>
          {selectedAction === ACTION_ASSIGN && (
            <Box>
              <FilterableSelect
                dataTestId="booking-select-room-id"
                isClearable={true}
                initialValue={selectedOrgUnit}
                items={allMembers
                  ?.filter((m) => m.id != member?.id)
                  .map((m) => {
                    return { label: m.user.name, value: m.id }
                  })}
                isOptionDisabled={(value) => value.disabled}
                onSelect={(n) => {
                  setSelectedOrgUnit(n >= 0 ? n : undefined)
                }}
              />
            </Box>
          )}

          <Divider />
          <Radio size="md" name="removalAction" value={ACTION_CANCEL}>
            Cancel the bookings
          </Radio>
        </Stack>
      </RadioGroup>
      <Divider my={3} />
      <Text>
        Are you sure you wish to remove {member?.user.name} from the team?
      </Text>
    </BaseModal>
  )
}
