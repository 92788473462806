import { Box, BoxProps, Divider, HStack, Stack, Text } from '@chakra-ui/react'
import { differenceInSeconds, format } from 'date-fns'
import React from 'react'
import { secondsToTimeString } from '../pages/MemberDashboard/MeetingRooms/meetingRoomUtils'
import { IBooking } from '../types'

interface Props {
  startTime: Date
  endTime: Date
  booking: IBooking
}
export const BookingTimeInfoCard: React.FC<Props> = (props) => {
  const { startTime, endTime, booking } = props
  return (
    <Stack spacing={2} justifyContent="space-between" color={'white'}>
      <Box>
        <Divider mt={1} />
      </Box>
      <Text fontSize="22px" fontWeight="bold" letterSpacing="0.25px">
        {format(startTime, 'PP')}
      </Text>

      <Text fontSize="14px" fontWeight="bold" letterSpacing="0.25px">
        {format(startTime, 'h:mm b')} - {format(endTime, 'h:mm b')}
      </Text>
      <Text fontSize="xs">{booking.timezone}</Text>
      <Text fontSize="12px" letterSpacing="0.25px">
        Booking for{' '}
        {secondsToTimeString(differenceInSeconds(endTime, startTime))}
      </Text>
      <Divider my={2} />
      <Stack spacing={0} justifyContent="space-between" color={'white'}>
        <Text fontSize="14px" fontWeight="bold" letterSpacing="0.25px">
          {booking.location_name}
        </Text>
        <Text>{booking.location_address}</Text>
      </Stack>
    </Stack>
  )
}
