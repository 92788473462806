import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'

import { uploadBookingConfirmationPDF } from '../../../api'
import { LocationListElement } from '../../../components/LocationListElement'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { Location } from '../../../types'
import { FileUploadComponent } from '../Components/FileUploadComponent'

interface Props {
  location: Location
}

export const IndvBookingLocationSettings: React.FC<Props> = (props) => {
  const { location } = props
  const toast = useToast()
  const { mutatePlatformTenant } = usePlatformTenant()

  const [isUploadingFile, setIsUploadingFile] = useState(false)

  const [file, setFile] = useState<any>()
  const handleChange = (file) => {
    setFile(file)
  }

  return (
    <Box>
      <LocationListElement location={location}>
        <Flex justifyContent={'space-around'} w="100%">
          <Box w={'75%'}>
            <Text fontWeight="bold" fontSize="sm" mb={1}>
              Booking Confirmation File
            </Text>
            <Text fontSize="xs" mb={1}>
              A PDF file with information about building access, Wifi
              connection, screen mirroring ,parking, etc.
            </Text>
            <Text mb="16px" fontSize="xs" color="eastBay07">
              Only .pdf files. 10 MB max file size.
            </Text>
            <FileUploadComponent
              accept="application/pdf"
              documentType="Booking Confirmation File"
              fileUrl={location.booking_confirmation_document_url}
              initialValue={location?.booking_confirmation_document_url}
              uploadedFile={undefined}
              isUploadingFile={isUploadingFile}
              onRemoveFile={() => {
                setIsUploadingFile(true)
                return uploadBookingConfirmationPDF(
                  location.id,
                  true,
                  undefined
                )
                  .then((res) => {
                    setFile(undefined)
                    setIsUploadingFile(false)
                    mutatePlatformTenant()
                    return res.data
                  })
                  .catch((err) => {
                    return 'An error ocurred'
                  })
              }}
              setUploadedFile={(file) => {
                setFile(file)
                setIsUploadingFile(true)
                const isRemove = file === undefined || file === null
                uploadBookingConfirmationPDF(location.id, isRemove, file)
                  .then((res) => {
                    setIsUploadingFile(false)
                    mutatePlatformTenant()
                    toast({
                      description: 'File updated successfully',
                      status: 'success'
                    })
                  })
                  .catch((err) => {
                    setIsUploadingFile(false)
                  })
              }}
            />
          </Box>
        </Flex>
      </LocationListElement>
    </Box>
  )
}
