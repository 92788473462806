import { Box, BoxProps, Image, Stack, Text, HStack } from '@chakra-ui/react'
import React from 'react'
import { IWorkspace, IWorkspaceSuperAdmin } from '../types'

interface Props extends BoxProps {
  workspace: IWorkspace | IWorkspaceSuperAdmin
  unavailable?: boolean
}

export const WorkspaceSmallCard: React.FC<Props> = ({
  workspace,
  unavailable,
  ...rest
}) => {
  const placeholder = require('../assets/placeholder.svg')

  return (
    <Box
      data-testid={`meeting-room-${workspace.id}`}
      opacity={unavailable ? 0.4 : 1}
      cursor={unavailable ? 'not-allowed' : 'pointer'}
      pt={1}
      mb={2}
      {...rest}
    >
      <Text fontWeight={600} fontSize={['sm', 'md', 'lg']} pl={2} mb={4}>
        {workspace.name}
      </Text>
    </Box>
  )
}
