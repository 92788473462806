import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { createCtx } from './createCtx'
import { analyticsTrack } from '../utils/analytics'
import { routeStrings } from '../routeStrings'
export interface AuthDrawerContextProps {
  isOpen: boolean
  isLogin: boolean
  onClose: () => void
  handleOpenSignUpDrawer: () => void
  handleOpenSignInDrawer: () => void
  analyticsRegisterSuccessfulSignUp: (
    tenantName: string,
    tenantId: string
  ) => void
}

const [useAuthDrawer, AuthDrawerContextProvider] =
  createCtx<AuthDrawerContextProps>()
const AuthDrawerProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(true)
  const { onClose, isOpen, onOpen, onToggle } = useDisclosure()

  const getRoomSlug = () => {
    let path = window.location.pathname
    let t = routeStrings.relativeExternalMeetingRoomURL('')
    return path.replace(t, '')
  }

  const handleOpenSignUpDrawer = () => {
    analyticsTrack('PublicBookings Sign up button pressed', {
      slug: getRoomSlug()
    })
    setIsLogin(false)
    setTimeout(onOpen, 100)
  }
  const handleOpenSignInDrawer = () => {
    analyticsTrack('PublicBookings Sign in button pressed', {
      slug: getRoomSlug()
    })
    setIsLogin(true)
    setTimeout(onOpen, 100)
  }

  const analyticsRegisterSuccessfulSignUp = (
    tenantName: string,
    tenantId: string
  ) => {
    analyticsTrack('PublicBookings sign up successful', {
      slug: getRoomSlug(),

      tenant_name: tenantName,
      tenant_id: tenantId
    })
  }

  return (
    <AuthDrawerContextProvider
      value={{
        isLogin,
        isOpen,
        onClose,
        handleOpenSignUpDrawer,
        handleOpenSignInDrawer,
        analyticsRegisterSuccessfulSignUp
      }}
    >
      {children}
    </AuthDrawerContextProvider>
  )
}

export { useAuthDrawer, AuthDrawerProvider }
