import React, { useEffect, useState } from 'react'
import { GroupingHeaderLayout } from '../../../components/GroupingHeaderLayout'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'

import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { DirectoryMyConnections } from './DirectoryMyConnections'
import { DirectoryMyTeam } from './DirectoryMyTeam'
import { DirectorySettings } from './DirectorySettings'
import { DirectorySearchDashboard } from './Search/DirectorySearchDashboard'
import { useLocation } from 'react-router-dom'

const TAB_CONNECTIONS = 'connections'
const TAB_DIRECTORY = 'directory'
const TAB_MY_TEAM = 'my-team'
const TAB_SETTINGS = 'settings'

export const MembersDirectoryWrapper: React.FC = () => {
  const { search } = useLocation()

  let params = new URLSearchParams(window.location.search)
  const tabs = [
    {
      text: 'Directory',
      id: TAB_DIRECTORY,
      active: true
    },
    {
      text: 'My Connections',
      id: TAB_CONNECTIONS,
      active: true
    },
    {
      text: 'My Team',
      id: TAB_MY_TEAM,
      active: true
    },
    {
      text: 'Settings',
      id: TAB_SETTINGS,
      active: true
    }
  ]

  const defaultTab =
    params.get('tab') === TAB_CONNECTIONS ? TAB_CONNECTIONS : TAB_DIRECTORY
  const [currentTab, setCurrentTab] = useState(defaultTab)
  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      let tab = params.get('tab')
      let tabIndex = tabs.findIndex((element) => element.id === tab)
      setCurrentTab(tabs[tabIndex < 0 ? 0 : tabIndex].id)
    } else {
      setCurrentTab(tabs[0].id)
    }
  }, [search])

  const getComponentToDisplay = () => {
    switch (currentTab) {
      case TAB_CONNECTIONS:
        return <DirectoryMyConnections />
      case TAB_DIRECTORY:
        return <DirectorySearchDashboard />
      case TAB_MY_TEAM:
        return <DirectoryMyTeam />
      case TAB_SETTINGS:
        return <DirectorySettings />
      default:
        return <CenteredSpinner />
    }
  }

  return (
    <LayoutMemberDashboard showBack={true}>
      <GroupingHeaderLayout
        basicTitle="Members Directory"
        tabs={tabs
          .filter((setting) => setting.active)
          .map((tab) => {
            return {
              text: tab.text,
              active: currentTab === tab.id,
              selectAction: () => {
                setCurrentTab(tab.id)
              }
            }
          })}
      />
      {getComponentToDisplay()}
    </LayoutMemberDashboard>
  )
}
