import { Alert, AlertIcon, Box, Flex, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { IBookingDisplay } from '../../../../types'
import { LayoutMemberDashboard } from '../../LayoutMemberDashboard'
import { ReactNodeWithProps } from '../../../../component.types'

export const BookingDisplayMobile: React.FC<
  {
    booking: IBookingDisplay
  } & ReactNodeWithProps
> = ({ booking, children }) => {
  //WIP requires backend work to store the meeting cost on the booking
  // const [creditsUsed, setCreditsUsed]=useState<number>(0)
  // useEffect(()=>{
  //   if(booking?.meeting_room){
  //     const {data}= useMeetingRoom( parseInt(booking.meeting_room_display_url))

  //   }
  // })

  /*
   startTime and endTime can use any datetime
   string that is acceptable by MomentJS
*/

  if (!booking) {
    return (
      <LayoutMemberDashboard showBack={true}>
        <Alert status="error">
          <AlertIcon />
          There was an error processing your request
        </Alert>
      </LayoutMemberDashboard>
    )
  }
  return (
    <Box overflow="scroll">
      <Flex direction="column" overflow="scroll">
        <Flex
          w="100%"
          h="250px"
          flexShrink={0}
          background={
            booking.meetingRoomImage
              ? `url("${booking.meetingRoomImage}")`
              : '#dadada'
          }
          backgroundPosition="center"
          backgroundSize="cover"
        ></Flex>
        <Stack spacing="4px" padding="24px">
          <Text
            fontSize="18px"
            fontWeight="600"
            color="#212121"
            marginBottom="64px"
          >
            {booking.meetingRoomName}
          </Text>
          <Text color="black" fontSize="14px" fontWeight="600">
            {format(booking.startTime, 'PPPP')}
          </Text>
          <Text color="black" fontSize="14px">
            {format(booking.startTime, 'p')} - {format(booking.endTime, 'p')}
          </Text>
          {/* WIP when the backend supports it, we will display the booking cost */}
          {/* <Text color="black" fontSize="14px" >
            Credits used
          </Text>
          <Text color="black" fontSize="14px" >
            {creditsUsed}
          </Text> */}
          <Box>{children}</Box>
        </Stack>
      </Flex>
    </Box>
  )
}
