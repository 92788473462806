import React, { useEffect } from 'react'
import { useAuth } from '../context/auth-context'
import { useFeatureFlags } from '../context/feature-flag-context'
import { ReactNodeWithProps } from '../component.types'

interface Props extends ReactNodeWithProps {
  leadForensicsCode: string
}

export const LeadForensicsEmbed: React.FC<Props> = ({
  children,
  leadForensicsCode
}) => {
  const { leadForensicsPluginEnabled } = useFeatureFlags()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (leadForensicsCode && leadForensicsPluginEnabled) {
      renderWidget(leadForensicsCode)
    }
    if (isAuthenticated) {
      cleanUpHelpWidget(leadForensicsCode)
    }
    return () => cleanUpHelpWidget(leadForensicsCode)
  }, [leadForensicsCode, leadForensicsPluginEnabled, isAuthenticated])

  const renderWidget = (widgetCode: string) => {
    var tagString = `<script id='leadforensics-plugin' type="text/javascript" src=https://secure.intelligentdatawisdom.com/js/783277.js ></script> <noscript><img id='leadforensics-image' alt="" src=https://secure.intelligentdatawisdom.com/783277.png style="display:none;" /></noscript>`
    var range = document.createRange()
    range.selectNode(document.getElementsByTagName('BODY')[0])
    var df = range.createContextualFragment(tagString)

    document.body.appendChild(df)
  }

  const cleanUpHelpWidget = (widgetCode: string) => {
    try {
      document.getElementById('leadforensics-plugin')?.remove()
      document.getElementById('leadforensics-image')?.remove()
    } catch (error) {}
  }
  return null
}
