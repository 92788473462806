// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'
import {
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  BoxProps
} from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'

interface Props extends BoxProps {
  onNumberChange: (value: number) => void
  value: number
  min?: number
  max?: number
}
export const NumberInputComponent: React.FC<Props> = (props) => {
  const { value, min, max, onNumberChange, ...rest } = props
  const handleInputChange = (valueAsString: string, valueAsNumber: number) => {
    onNumberChange(valueAsNumber)
  }

  return (
    <Flex {...rest}>
      <NumberInput
        value={value}
        onChange={handleInputChange}
        min={min}
        max={max}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Flex>
  )
}
