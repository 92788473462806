import React, { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import 'react-calendar/dist/Calendar.css'
import './App.css'
import { AppProviders } from './AppProviders'
import { UserDetailsProvider } from './context/user-onboard-context'
import { Login } from './pages/Login'
import { Logout } from './pages/Logout'
import { PasswordReset } from './pages/PasswordReset'
import { PasswordResetConfirm } from './pages/PasswordResetConfirm'
import { TermsAndConditions } from './pages/TermsAndConditions'
import {
  adminRoutes,
  completeProfileRoutes,
  inviteRoutes,
  memberRoutes,
  startRoutes,
  teamAdminRoutes,
  mobileEmbedRoutes,
  tenantInviteRoutes,
  externalStartRoutes,
  publicBookingRoutes
} from './routes'
import { routeStrings } from './routeStrings'
import { analyticsLoad, analyticsPage } from './utils/analytics'
import {
  CompleteProfileGuard,
  MemberGuard,
  TeamAdminGuard,
  UnauthenticatedGuard,
  ProtectedRoute,
  CompleteTenantOnboardingGuard
} from './utils/Guards'
import { Flex } from '@chakra-ui/react'
import { AdminHeader } from './components/AdminHeader'
import { OnboardingWrapper } from './components/OnboardingWrapper'
import { KioskApp } from './pages/Kiosk/KioskApp'
import { LayoutNewUserOnboarding } from './pages/Onboarding/components/LayoutNewUserOnboarding'
import { Roles } from './utils/rbac'
import { CommunityProvider } from './context/community-context'
import { HubspotEmbed } from './components/HubspotEmbed'
import { usePlatformTenant } from './context/platform-tenant-context'
import { SPACECUBED_HUBSPOT_CODE } from './utils/constants'
import { useMobileEmbed } from './context/mobile-embed-context'
import { BookingProvider } from './context/BookingContext'
import { ContentExplorerProvider } from './context/content-explorer-context'
import { PublicMeetingRoomWrapper } from './pages/MemberDashboard/MeetingRooms/ExternalProfile/PublicMeetingRoomWrapper'
import { NotFoundComponent } from './NotFoundComponent'
import { MainAIChatComponent } from './components/MainAIChatComponent'
import { LeadForensicsEmbed } from './components/LeadForensicsEmbed'
import { ReactNodeWithProps } from './component.types'

const trailingRoute = '/*'

const LoginRoute = () => {
  return (
    <UnauthenticatedGuard>
      <Login />
    </UnauthenticatedGuard>
  )
}

const StartRoutes = () => {
  const { platformTheme } = usePlatformTenant()
  return (
    <UnauthenticatedGuard>
      <UserDetailsProvider>
        <HubspotEmbed
          hubSpotHelpCode={
            platformTheme && platformTheme.id === '1'
              ? SPACECUBED_HUBSPOT_CODE
              : ''
          }
        />
        <LeadForensicsEmbed
          leadForensicsCode={
            platformTheme && platformTheme.id === '1' ? 'GO' : ''
          }
        />
        <LayoutNewUserOnboarding>
          <Routes>
            {Object.entries(startRoutes).map(([key, route]) => (
              <Route
                path={route.path + trailingRoute}
                key={key}
                element={<route.component />}
              />
            ))}
          </Routes>
        </LayoutNewUserOnboarding>
      </UserDetailsProvider>
    </UnauthenticatedGuard>
  )
}
const ExternalRoutes = () => {
  const { platformTheme } = usePlatformTenant()
  return (
    <UnauthenticatedGuard>
      <UserDetailsProvider>
        <HubspotEmbed
          hubSpotHelpCode={
            platformTheme && platformTheme.id === '1'
              ? SPACECUBED_HUBSPOT_CODE
              : ''
          }
        />
        <LeadForensicsEmbed
          leadForensicsCode={
            platformTheme && platformTheme.id == '1' ? 'GO' : ''
          }
        />
        <LayoutNewUserOnboarding isExternal>
          <Routes>
            {Object.entries(externalStartRoutes).map(([key, route]) => (
              <Route
                path={route.path + trailingRoute}
                key={key}
                element={<route.component />}
              />
            ))}
          </Routes>
        </LayoutNewUserOnboarding>
      </UserDetailsProvider>
    </UnauthenticatedGuard>
  )
}

const PublicMeetingRoomsRoutes = () => {
  return (
    <PublicMeetingRoomWrapper>
      <Routes>
        {Object.entries(publicBookingRoutes).map(([key, route]) => (
          <Route
            path={route.path + trailingRoute}
            key={key}
            element={<route.component />}
          />
        ))}
      </Routes>
    </PublicMeetingRoomWrapper>
  )
}

const CompleteProfileRoutes = () => {
  const { platformTenant } = usePlatformTenant()
  return (
    <CompleteProfileGuard>
      <UserDetailsProvider>
        {platformTenant?.hubspot_help_widget_code && (
          <HubspotEmbed
            hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
          />
        )}
        <Routes>
          {Object.entries(completeProfileRoutes).map(([key, route]) => (
            <Route
              path={route.path + trailingRoute}
              key={key}
              element={<route.component />}
            />
          ))}
        </Routes>
      </UserDetailsProvider>
    </CompleteProfileGuard>
  )
}

const AdminRoutes: React.FC = () => {
  return (
    <ProtectedRoute
      permittedRoles={[Roles.SpaceAdmin]}
      fallbackRoute={routeStrings.memberDashboardHome}
    >
      <CommunityProvider>
        <CompleteTenantOnboardingGuard>
          <Flex h={'100vh'}>
            <Routes>
              {Object.entries(adminRoutes).map(([key, route]) => {
                return (
                  <Route
                    key={key}
                    path={route.path + trailingRoute}
                    element={<route.component />}
                  />
                )
              })}
            </Routes>
          </Flex>
        </CompleteTenantOnboardingGuard>
      </CommunityProvider>
    </ProtectedRoute>
  )
}

const MemberRoutes = () => {
  const { platformTenant } = usePlatformTenant()
  return (
    <MemberGuard>
      <CommunityProvider>
        <ContentExplorerProvider>
          {/* do not display hubspot widget on mobile embed */}
          {platformTenant?.hubspot_help_widget_code && (
            <HubspotEmbed
              hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
            />
          )}
          <Flex h={'100vh'}>
            <Routes>
              {Object.entries(memberRoutes).map(([key, route]) => (
                <Route
                  path={route.path + trailingRoute}
                  key={key}
                  element={<route.component />}
                />
              ))}
            </Routes>
          </Flex>
        </ContentExplorerProvider>
      </CommunityProvider>
    </MemberGuard>
  )
}
const MobileEmbedRoutes = () => {
  return (
    <MemberGuard>
      <CommunityProvider>
        <BookingProvider>
          <Flex h={'100vh'}>
            <Routes>
              {Object.entries(mobileEmbedRoutes).map(([key, route]) => (
                <Route
                  path={route.path + trailingRoute}
                  key={key}
                  element={<route.component />}
                />
              ))}
            </Routes>
          </Flex>
        </BookingProvider>
      </CommunityProvider>
    </MemberGuard>
  )
}
const InviteRoutes = () => {
  const { platformTenant } = usePlatformTenant()
  return (
    <UserDetailsProvider>
      <OnboardingWrapper>
        {platformTenant?.hubspot_help_widget_code && (
          <HubspotEmbed
            hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
          />
        )}
        <UnauthenticatedGuard>
          <Flex h={'100vh'} display={'block'}>
            <Routes>
              {Object.entries(inviteRoutes).map(([key, route]) => (
                <Route
                  path={route.path + trailingRoute}
                  key={key}
                  element={<route.component />}
                />
              ))}
            </Routes>
            <Routes>
              {Object.entries(tenantInviteRoutes).map(([key, route]) => (
                <Route
                  path={route.path + trailingRoute}
                  key={key}
                  element={<route.component />}
                />
              ))}
            </Routes>
          </Flex>
        </UnauthenticatedGuard>
      </OnboardingWrapper>
    </UserDetailsProvider>
  )
}

const TeamAdminRoutes = () => {
  const { platformTenant } = usePlatformTenant()

  return (
    <TeamAdminGuard>
      {platformTenant?.hubspot_help_widget_code && (
        <HubspotEmbed
          hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
        />
      )}
      <Flex h={'100vh'}>
        <Routes>
          {Object.entries(teamAdminRoutes).map(([key, route]) => (
            <Route
              path={route.path + trailingRoute}
              key={key}
              element={<route.component />}
            />
          ))}
        </Routes>
      </Flex>
    </TeamAdminGuard>
  )
}

const App: React.FC = () => {
  useEffect(() => {
    analyticsLoad()
  }, [])

  // On window location change: tell analytics about page change
  useEffect(() => {
    analyticsPage()
  }, [window.location.href])

  return (
    <AppProviders>
      <AppWrapper>
        {/* Render admin header, if user has admin role in RBAC*/}
        <AdminHeader />
        <Routes>
          <Route
            path={routeStrings.login + trailingRoute}
            element={<LoginRoute />}
          />
          <Route
            path={'/not-found' + trailingRoute}
            element={<NotFoundComponent />}
          />
          <Route path={'/reset' + trailingRoute} element={<PasswordReset />} />
          <Route
            path="/accounts/reset/:uid/:token/:user"
            element={<PasswordResetConfirm />}
          />
          <Route
            path={routeStrings.logout + trailingRoute}
            element={<Logout />}
          />
          <Route
            path={routeStrings.termsAndConditions + trailingRoute}
            element={<TermsAndConditions />}
          />
          <Route
            path="/"
            element={<Navigate replace to={routeStrings.login} />}
          />
          <Route
            path={'/start'}
            element={<Navigate replace to={routeStrings.location} />}
          ></Route>
          <Route path={'start/*'} element={<StartRoutes />} />
          <Route
            path={'/external/meeting-rooms' + trailingRoute}
            element={<PublicMeetingRoomsRoutes />}
          />
          <Route
            path={'/external' + trailingRoute}
            element={<ExternalRoutes />}
          />

          <Route
            path={'/complete' + trailingRoute}
            element={<CompleteProfileRoutes />}
          />

          {/* @TODO: add token validation to routes? */}
          <Route path={'/invite' + trailingRoute} element={<InviteRoutes />} />

          <Route
            path={'/management' + trailingRoute}
            element={<TeamAdminRoutes />}
          />

          {/* meeting room kiosk app */}
          <Route path={'/kiosk' + trailingRoute} element={<KioskApp />} />

          <Route
            path={'/admin' + trailingRoute}
            element={
              <BookingProvider>
                <AdminRoutes />
              </BookingProvider>
            }
          />
          <Route
            path={'/dashboard' + trailingRoute}
            element={
              <BookingProvider>
                <MemberRoutes />
              </BookingProvider>
            }
          />
        </Routes>
        <MainAIChatComponent />
        <Outlet />
      </AppWrapper>
    </AppProviders>
  )
}

export default App

type AppWrapperProps = {} & ReactNodeWithProps

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
  //we user this app wrapper to dynamically add margin when we need it to prevent the hubspit widget from blocking content
  //previously this was being done with a media query in the root
  const { isMobileEmbed } = useMobileEmbed()
  return (
    <Flex
      direction="column"
      flexGrow={1}
      minH="100vh"
      overflow="hidden"
      mb={[isMobileEmbed ? 0 : '100px', '100px', 0]}
    >
      {/* here we are using the mobile embed routes to lock the user in to a controlled subset of routes when in teh mobile app webview. this is a safeguard against future work exposing other nav options to the user */}
      {isMobileEmbed ? <MobileEmbedRoutes /> : children}
    </Flex>
  )
}
