import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  HeadingProps,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tab,
  useTheme
} from '@chakra-ui/react'
import React, { useState } from 'react'
// import { StyledTab } from '../../../components/StyledTab'
import { useCommunity } from '../../../context/community-context'
import { useMedia } from '../../../hooks/useMedia'
import { IBasicDirectoryMemberResult, IBooking } from '../../../types'
import { DailyFiveDisplay } from '../../DailyFive/DailyFiveDisplay'
import { SavedContentList } from '../../DailyFive/SavedContentList'
import { BillingOverview } from './homePageDisplay/BillingOverview'
import { MeetingRoomOverview } from './homePageDisplay/MeetingRoomOverview'
import { IArticle, IInvoiceSummary } from './homePageDisplay/types'
import { WifiOverview } from './homePageDisplay/WifiOverview'
import styled from '@emotion/styled'
import { ExplorerWrapper } from '../Explorer/ExplorerWrapper'
import { useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useContentExplorer } from '../../../context/content-explorer-context'
import { MyPostsDisplay } from '../MyPosts/MyPostsDisplay'
import { DirectoryConnectionsOverview } from './homePageDisplay/DirectoryConnectionsOverview'
import { ReactNodeWithProps } from '../../../component.types'

const ContentSection: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Box as="section" {...rest}>
    {children}
  </Box>
)

const SectionHeading: React.FC<HeadingProps> = ({ children, ...rest }) => (
  <Heading size="sm" mb={2} {...rest}>
    {children}
  </Heading>
)

interface Props {
  notice?: React.ReactNode
  meetings: {
    bookedToday: IBooking[]
    creditsRemaining?: number
    daysUntilCreditsExpire?: number
    loading?: boolean
    hide?: boolean
  }
  articles: { content: IArticle[]; loading?: boolean }
  invoices: {
    overdue?: IInvoiceSummary[]
    nextInvoice?: IInvoiceSummary
    loading?: boolean
    hide?: boolean
  }
  wifi: {
    loading?: boolean
    wifiPassword?: string
    hide?: boolean
  }
  connection?: {
    otherUserName: string
    otherUserEmail: string
    message?: string
  }
  directoryConnections?: IBasicDirectoryMemberResult[]
}

export const NoticeWrapper: React.FC<ReactNodeWithProps> = ({ children }) => (
  <Box
    mb={6}
    gridColumn={['inherit', '1 / 3']}
    p={3}
    bg="#fffbc3"
    gridRow={-1}
    fontWeight="bold"
  >
    {children}
  </Box>
)

export const HomePageDailyFiveDisplay: React.FC<Props> = ({
  articles,
  meetings,
  invoices,
  notice,
  wifi,
  directoryConnections,
  connection
}) => {
  // hack to get the news article placeholders to be the right width on big screens while they are loading
  // if their width isn't fixed, the grid jumps in width as the stories are loaded
  const newsArticleWidth = useMedia(['(min-width: 1380px)'], ['724px'], 'auto')
  const { latest } = useCommunity()
  const theme: any = useTheme()
  const [tabIndex, setTabIndex] = React.useState(0)
  const { unSelectTag, selectedTags, setActiveTab, activeTab } =
    useContentExplorer()
  const [paramTab, setParamTab] = useQueryParam('tab', StringParam)
  const tabs = ['HOME', 'MYPOSTS', 'EXPLORER']

  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  useEffect(() => {
    try {
      if (paramTab === 'explorer') setTabIndex(2)
    } catch (error) {}
  }, [paramTab])
  useEffect(() => {
    try {
      if (selectedTags.length > 0) setTabIndex(2)
    } catch (error) {}
  }, [selectedTags])

  return (
    <Box maxW="1110px" w="100%" paddingY={10}>
      {notice}
      <Grid
        gridTemplateColumns={['1fr', '2fr 1fr']} //aust scales the page to single column
        gridRowGap={6}
        gridColumnGap={[0, 6]}
      >
        <SectionHeading gridColumn="1 / 3" mb={-4}>
          Your Daily Five
        </SectionHeading>
        <Flex flexDirection="column" w="100%">
          <Tabs
            index={tabIndex}
            onChange={(index) => {
              setTabIndex(index)
              setActiveTab(tabs[index])
            }}
          >
            <TabList border="0px">
              <StyledTab isDisabled={!latest || latest.content?.length === 0}>
                Today
              </StyledTab>
              <StyledTab>My Posts</StyledTab>
              <StyledTab
                onClick={() => {
                  if (tabIndex === 2) unSelectTag()
                }}
              >
                Content Explorer
              </StyledTab>
            </TabList>

            <TabPanels>
              <TabPanel paddingTop="8px" p={0}>
                <Stack spacing={3} w={newsArticleWidth}>
                  <ContentSection paddingBottom="30px">
                    <Grid templateColumns={['1fr']} gap={6}>
                      <DailyFiveDisplay notification={latest} />
                    </Grid>
                  </ContentSection>
                  <ContentSection>
                    <SectionHeading>Saved</SectionHeading>
                    <SavedContentList marginTop="22px" />
                  </ContentSection>
                </Stack>
              </TabPanel>
              <TabPanel paddingTop="8px" p={0}>
                <MyPostsDisplay />
              </TabPanel>
              <TabPanel>
                <ExplorerWrapper></ExplorerWrapper>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        <Stack spacing={6} gridRow={[1, 2]} gridColumn={[1, 2]}>
          {!meetings.hide && (
            <MeetingRoomOverview
              loading={meetings.loading}
              meetingsToday={meetings.bookedToday}
              creditsRemaining={meetings.creditsRemaining}
              daysUntilCreditsExpire={meetings.daysUntilCreditsExpire}
            />
          )}
          {!invoices.hide && (
            <BillingOverview
              overdue={invoices.overdue}
              nextInvoice={invoices.nextInvoice}
              loading={invoices.loading}
            />
          )}
          {!wifi.hide && wifi.wifiPassword && (
            <WifiOverview
              loading={wifi.loading}
              wifiPassword={wifi.wifiPassword}
            />
          )}
          <DirectoryConnectionsOverview connections={directoryConnections} />
        </Stack>
      </Grid>
    </Box>
  )
}
