import React, { useEffect, useState } from 'react'
import { createCtx } from './createCtx'
import { useRole, Roles } from '../utils/rbac'
import { useAuth } from './auth-context'

interface MobileEmbedContextProps {
  isMobileEmbed: boolean
}

const [useMobileEmbed, MobileEmbedContextProvider] =
  createCtx<MobileEmbedContextProps>()

const MobileEmbedProvider = ({ children }) => {
  const [isMobileEmbed, setIsMobileEmbed] = useState<boolean>(false)
  const [orgUnitId, setOrgUnitId] = useState<number>()

  const { role, assumeRole } = useRole()
  const { currentOrgUnit, setOrganizationalUnit, me } = useAuth()

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const mobileEmbed = params.get('mobileEmbed')
    const urlOrgUnitId = parseInt(params.get('orgUnitId') ?? '')

    // The mobile app needs a way to pass in the current OrgUnitId
    //this is used to set to app up to use the correct meeting rooms
    //Could be questionable to have this behaviour living in the mobile embed context however its the only use case for it right now
    //(setting orgunit via query param)
    if (!isNaN(urlOrgUnitId)) {
      // if its different to what we have saved, update the state
      // should only run once unless the org id changes
      if (urlOrgUnitId !== orgUnitId) {
        setOrgUnitId(urlOrgUnitId)
      }
    }

    // there is a race condition with the roles weher on first load, while me is undefined setting the role here is overwritten once me loads
    // the condition is mitigated by listening to the role and resetting if required
    if (mobileEmbed === 'true' || isMobileEmbed) {
      setIsMobileEmbed(true)
      assumeRole(Roles.ActiveMember)
    }
  }, [window.location.pathname, role, me])

  useEffect(() => {
    if (me && orgUnitId) {
      // attempt to set the org
      if (currentOrgUnit?.id !== orgUnitId) {
        // check if the orgId is in the available org units
        const ou = me.organizational_units.find((ou) => ou.id === orgUnitId)
        if (ou) {
          setOrganizationalUnit(me, ou)
        }
      }
    }
  }, [me, orgUnitId])

  return (
    <MobileEmbedContextProvider value={{ isMobileEmbed }}>
      {children}
    </MobileEmbedContextProvider>
  )
}

export { useMobileEmbed, MobileEmbedProvider }
