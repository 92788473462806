import { Box, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Location } from '../../../../types'
import { BusinessHoursDaySetting } from './BusinessHoursDaySetting'

interface Props {
  location: Location
}

export interface BusinessHoursProps {
  day: number
  open: Date | null
  close: Date | null
}

const castHoursToDate = (hours: string | undefined) => {
  if (!hours) return null
  try {
    let hoursArray = hours.split(':')
    let d = new Date()
    d.setHours(parseInt(hoursArray[0]))
    d.setMinutes(parseInt(hoursArray[1]))
    return d
  } catch (error) {
    console.error(error)
    return new Date()
  }
}

export const BusinessHoursConfig: React.FC<Props> = ({ location }) => {
  const toast = useToast()
  const [isMapped, setIsMapped] = useState(false)
  const [businessHours, setBusinessHours] = useState<BusinessHoursProps[]>([
    { day: 0, open: null, close: null },
    { day: 1, open: null, close: null },
    { day: 2, open: null, close: null },
    { day: 3, open: null, close: null },
    { day: 4, open: null, close: null },
    { day: 5, open: null, close: null },
    { day: 6, open: null, close: null }
  ])

  useEffect(() => {
    // Map Existing Business Hours

    if (location && location.business_hours) {
      const clonedHours = businessHours.slice(0)
      location.business_hours.forEach((h) => {
        if (h.open) {
          clonedHours[h.day].open = castHoursToDate(h.open)
          clonedHours[h.day].close = castHoursToDate(h.close)
        }
      })
      setIsMapped(true)
      setBusinessHours(clonedHours)
    }
  }, [])

  return (
    <Box>
      <Text fontWeight="bold" fontSize="sm" mb={1}>
        Business Hours
      </Text>
      <Text color="#4F4F4F" fontSize="xs">
        Configure the timetable for external bookings. These settings will be
        visible when an external member (someone without an active membership)
        tries to book.
      </Text>
      <Box>
        {isMapped &&
          businessHours.map((hour, index) => (
            <BusinessHoursDaySetting
              key={index}
              location={location}
              hours={hour}
            />
          ))}
      </Box>
    </Box>
  )
}
