import {
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  Stack,
  Card,
  CardBody,
  StackDivider,
  HStack
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { BaseModal } from '../../../components/modals/BaseModal'
import { IPlanChangeRequest } from '../../../types'
import { format } from 'date-fns'
import { declineChangeRequest, approveChangeRequest } from '../../../api'
import { useCustomToast } from '../../../context/toast-context'
import { VerticalKeyValueText } from '../../../components/VerticalKeyValueText'

interface IModalInput {
  request?: IPlanChangeRequest
  closeModalCallback: () => void
}

export const ReviewPlanRequestModal: React.FC<IModalInput> = (input) => {
  const { request, closeModalCallback } = input
  const { newToast: toast } = useCustomToast()

  const [overrideValue, setOverrideValue] = useState<number>()
  const [overrideValueValid, setOverrideValueValid] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSetOverrideValue = (event) => setOverrideValue(event.target.value)
  const [overrideReason, setOverrideReason] = useState<string>()
  const [overrideReasonValid, setOverrideReasonValid] = useState(true)
  const handleSetOverrideReason = (event) =>
    setOverrideReason(event.target.value)

  useEffect(() => {
    let isValid = true
    let isReasonValid = true
    if (overrideValue) {
      if (!overrideReason) {
        isReasonValid = false
      } else if (overrideReason.length > 100) {
        isReasonValid = false
      }
      if (isNaN(overrideValue)) {
        isValid = false
      }
      if (overrideValue < 0) {
        isValid = false
      }
    }
    setOverrideReasonValid(isReasonValid)
    setOverrideValueValid(isValid)
  }, [overrideValue, overrideReason])

  function closeModal() {
    setOverrideValue(undefined)
    setOverrideReason(undefined)
    closeModalCallback()
  }

  function approveRequest() {
    if (overrideValueValid && overrideReasonValid) {
      if (request) {
        setIsSubmitting(true)
        approveChangeRequest({
          requestId: request.id,
          overrideValue,
          overrideReason
        })
          .then(() => {
            setIsSubmitting(false)
            toast({
              status: 'success',
              description: 'Request Approved'
            })
            closeModal()
          })
          .catch(() => {
            setIsSubmitting(false)
            toast({
              status: 'error',
              description: 'Failed to Approve Request'
            })
            closeModal()
          })
      }
    }
  }

  function declineRequest() {
    if (request) {
      setIsSubmitting(true)
      declineChangeRequest(request.id)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: 'Request Declined'
          })
          closeModal()
        })
        .catch(() => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to Decline Request'
          })
          closeModal()
        })
    }
  }

  if (request === undefined) {
    return null
  }

  return (
    <BaseModal
      title="Review Request"
      isSubmitting={isSubmitting}
      isOpen={request !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText="Approve Request"
      primaryAction={approveRequest}
      secondaryButtonText="Decline Request"
      secondaryAction={declineRequest}
    >
      <Card>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="3">
            <VerticalKeyValueText
              title="Request Type"
              value={`${request.plan_request_type} Plan Request`}
            />
            <VerticalKeyValueText
              title="Team"
              value={request.organization_seat?.organization?.name || 'n/a'}
            />
            <VerticalKeyValueText
              title={`Requested ${request.plan_request_type} Date`}
              value={`${format(Date.parse(request.change_date), 'dd/MM/yyyy')}`}
            />
            {request.organization_seat?.active_plan?.name && (
              <VerticalKeyValueText
                title="Current Plan"
                value={request.organization_seat.active_plan.name}
              />
            )}
            {request.plan_request_type === 'Change' &&
              request.organization_seat?.active_plan?.name !== undefined && (
                <>
                  <VerticalKeyValueText
                    title="Requested Plan"
                    value={`${request?.new_plan?.name || 'n/a'} - ${
                      request.new_plan?.rate_string
                    }`}
                  />
                  <HStack>
                    <FormLabel htmlFor="overrideValue" fontSize={15}>
                      Plan Override Value:
                    </FormLabel>
                    <InputGroup width={'35%'} size="sm">
                      <InputLeftElement children="$" />
                      <Input
                        isInvalid={!!!overrideValueValid}
                        value={overrideValue}
                        onChange={handleSetOverrideValue}
                        id="overrideValue"
                        placeholder="Override Value"
                        variant="flushed"
                      />
                    </InputGroup>
                  </HStack>

                  {overrideValue && (
                    <HStack>
                      <FormLabel htmlFor="overrideValue" fontSize={15}>
                        Plan Override Reason:
                      </FormLabel>
                      <InputGroup width={'35%'} size="sm">
                        <Input
                          isInvalid={!!!overrideReasonValid}
                          value={overrideReason}
                          onChange={handleSetOverrideReason}
                          id="overrideReason"
                          placeholder="Override Reason"
                          variant="flushed"
                        />
                      </InputGroup>
                    </HStack>
                  )}
                </>
              )}
          </Stack>
        </CardBody>
      </Card>
    </BaseModal>
  )
}
