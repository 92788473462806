import { Stack, Text } from '@chakra-ui/react'

import React from 'react'

interface IInput {
  title: string
  value: string
  wrap?: boolean
}

export const KeyValueText: React.FC<IInput> = (input) => {
  const { title, value, wrap, ...rest } = input

  return (
    <Stack
      isInline
      whiteSpace={wrap ? 'normal' : 'nowrap'}
      pt={2}
      pb={2}
      {...rest}
    >
      <Text fontSize="16px" fontWeight="bolder">
        {title}:
      </Text>
      <Text
        fontSize="16px"
        overflowX="hidden"
        css={'text-overflow: ellipsis;'}
        maxWidth="70%"
      >
        {value}
      </Text>
    </Stack>
  )
}
