import { Box, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminBodyWrapper } from '../../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../../components/GroupingHeaderLayout'
import { MeetingRoomBookingGroup } from '../../../components/MeetingRoomBookingGroup'
import { useAuth } from '../../../context/auth-context'
import { useBookingContext } from '../../../context/BookingContext'
import { routeStrings } from '../../../routeStrings'
import { useOrganizationalUnitBookings } from '../../../utils/apiHooks'
import { UpdateBillingDetailsRequiredModal } from '../../AdminDashboard/Modals/UpdateBillingDetailsRequiredModal'
import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { FutureBookings } from '../MeetingRooms/FutureBookings'

export const WorkspaceBookings: React.FC = () => {
  const { orgUnitWorkspaceBookings: orgUnitBookings, revalOrgUnitBookings } =
    useBookingContext()
  const navigate = useNavigate()
  const { currentOrganization, me, currentOrgUnit } = useAuth()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [bookingFilter, setBookingFilter] = useState('upcoming')

  const futureBookings = orgUnitBookings
    ? orgUnitBookings.filter((booking) => {
        return (
          new Date(Date.parse(booking.end_time.toString())) > new Date() &&
          !booking.cancelled_at
        )
      })
    : []

  useEffect(() => {
    revalOrgUnitBookings()
  }, [])

  const { data: dataPastBookings } = useOrganizationalUnitBookings(
    'past',
    currentOrgUnit?.id
  )
  const pastBookings = dataPastBookings?.filter(
    (b) => b.workspace !== undefined
  )

  const headerGroups = [
    {
      text: 'Upcoming',
      active: bookingFilter === 'upcoming',
      selectAction: () => {
        setBookingFilter('upcoming')
      }
    },
    {
      text: 'Past',
      active: bookingFilter === 'past',
      selectAction: () => {
        setBookingFilter('past')
      }
    }
  ]

  const headerAction = {
    text: 'Book Workspace',
    variant: 'primary',
    onClick: () => {
      if (currentOrganization) {
        if (
          currentOrganization.is_active_default_payment_method ||
          me?.user?.is_space_admin
        ) {
          navigate({ pathname: routeStrings.memberDashboardWorkspaces })
        } else {
          onOpen()
        }
      }
    }
  }

  return (
    <LayoutMemberDashboard>
      <GroupingHeaderLayout
        basicTitle="Workspace Bookings"
        action={headerAction}
        tabs={headerGroups}
      />
      <AdminBodyWrapper>
        {bookingFilter === 'upcoming' && (
          <FutureBookings bookings={futureBookings} />
        )}
        {bookingFilter === 'past' && (
          <Box bg="#F4F5F5" width="100%" height="100%">
            <MeetingRoomBookingGroup title="" bookings={pastBookings ?? []} />
          </Box>
        )}
      </AdminBodyWrapper>
      <UpdateBillingDetailsRequiredModal isOpen={isOpen} closeModal={onClose} />
    </LayoutMemberDashboard>
  )
}
