import React, { useState, useEffect } from 'react'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { LayoutMemberDashboard } from './LayoutMemberDashboard'
import { EditProfile } from './memberDashboardProfile/EditProfile'
import { EditWifi } from './memberDashboardProfile/EditWifi'
import { EditSettings } from './memberDashboardProfile/EditSettings'
import { Actions, useRBAC } from '../../utils/rbac'
import { useAuth } from '../../context/auth-context'

enum Tabs {
  Profile,
  Wifi,
  Settings
}

export const MemberDashboardProfile: React.FC = () => {
  let params = new URLSearchParams(window.location.search)
  const { currentOrgUnit } = useAuth()
  const defaultTab = params.get('tab') === 'wifi' ? Tabs.Wifi : Tabs.Profile
  // HARDCODED: Only Spacecubed and CORE
  const canViewWifi =
    useRBAC(Actions.ViewPersonalWifiDetails) &&
    currentOrgUnit &&
    [1, 2].includes(currentOrgUnit.organization?.parent_platform_tenant)
  const [activeTab, setActiveTab] = useState<Tabs>(defaultTab)
  const [tabHeaders, setTabHeaders] = useState<any[]>()
  useEffect(() => {
    let t = [
      {
        text: 'Profile',
        active: activeTab === Tabs.Profile,
        selectAction: () => setActiveTab(Tabs.Profile)
      }
    ]
    if (canViewWifi) {
      t.push({
        text: 'WiFi',
        active: activeTab === Tabs.Wifi,
        selectAction: () => setActiveTab(Tabs.Wifi)
      })
    }

    t.push({
      text: 'Settings',
      active: activeTab === Tabs.Settings,
      selectAction: () => setActiveTab(Tabs.Settings)
    })
    setTabHeaders(t)
  }, [])
  return (
    <LayoutMemberDashboard>
      <GroupingHeaderLayout basicTitle="Your account" tabs={tabHeaders} />
      <AdminBodyWrapper>
        {activeTab === Tabs.Profile && <EditProfile />}
        {activeTab === Tabs.Wifi && <EditWifi />}
        {activeTab === Tabs.Settings && <EditSettings />}
      </AdminBodyWrapper>
    </LayoutMemberDashboard>
  )
}
