import React, { useEffect, useState } from 'react'
import { Select, SelectProps } from '@chakra-ui/react'

export const StyledSelect: React.FC<SelectProps> = ({ children, ...rest }) => {
  return (
    <Select
      background="#FAFAFA"
      fontSize="14px"
      variant="flushed"
      borderBottom="1px"
      css={{ paddingLeft: "11px" }}
      mb={0}
      borderColor="eastBay07"
      _hover={{
        borderColor: '#CBD5E0'
      }}
      {...rest}
    >
      {children}
    </Select>
  )
}