import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

export const PrimaryButton: React.FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<any>) => (
    <Button
      ref={ref}
      bg="brandPrimary"
      color="white"
      borderRadius="100px"
      {...props}
    />
  )
)
