import {
  Box,
  Center,
  Divider,
  Flex,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { Button } from '../../../components/Button'
import { ChakraModal } from '../../../components/ChakraModal'
import { FilterableSelect } from '../../../components/FilterableSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { Plan } from '../../../interfaces'
import { Location } from '../../../types'
import { usePlans } from '../../../utils/apiHooks'

interface IInputParams {
  isSubmitting: boolean
  visable: boolean
  handleCancel: () => void
  handleSendRequest: (locationId: number, planId: number, startDate) => void
}

export const RequestSeatModal: React.FC<IInputParams> = (input) => {
  const { isSubmitting, visable, handleCancel, handleSendRequest } = input
  const { platformTenant } = usePlatformTenant()
  const { plans } = usePlans(platformTenant?.id)

  const [filteredPlans, setFilteredPlans] = useState<Plan[]>([])

  const [chosenLocation, setChosenLocation] = useState<Location | null>(null)
  const [chosenPlan, setChosenPlan] = useState<Plan | null>(null)
  const [chosenStartDate, setChosenStartDate] = useState<any>(null)

  const toast = useToast()

  useEffect(() => {
    if (!visable) {
      setChosenLocation(null)
      setChosenPlan(null)
      setChosenStartDate(null)
    }
  }, [visable])

  const handleLocationMenuClick = (location: Location) => {
    if (location) {
      setChosenLocation(location)
      const plansToShow = plans.filter(
        (p) =>
          (p.location?.id === location.id || p.location === null) &&
          // This ensures the plan is a valid plan (not an addon plan),
          // prevents improper DB state
          !p.addon_plan
      )
      setFilteredPlans(plansToShow)
      location !== chosenLocation && setChosenPlan(null)
    }
  }

  const handlePlanMenuClick = (plan) => {
    plan && setChosenPlan(plan)
  }

  return (
    <ChakraModal
      isOpen={visable}
      // onOk={handleOk} // recordId from state
      onClose={handleCancel}
      title="Request a New Membership"
      size="3xl"
    >
      <ModalBody>
        <Flex>
          <Stack w="60%" spacing={3}>
            <FilterableSelect
              dataTestId="location-button"
              items={platformTenant?.locations.map((location) => ({
                label: location.name,
                value: location.id
              }))}
              name={'Choose a Location'}
              onSelect={(n) => {
                let location = platformTenant?.locations.find((l) => l.id === n)
                if (location) {
                  handleLocationMenuClick(location)
                }
              }}
            />
            <FilterableSelect
              dataTestId="plan-button"
              items={filteredPlans.map((plan) => ({
                label: plan.name,
                value: plan.id
              }))}
              name={'Choose a Plan'}
              onSelect={(n) => {
                let plan = filteredPlans.find((p) => p.id === n)
                if (plan) {
                  handlePlanMenuClick(plan)
                }
              }}
            />
            {chosenLocation && chosenPlan && (
              <Center>
                <Box mt={3} position="relative" ml="auto" mr="auto">
                  <Calendar
                    onChange={(date) => {
                      date && setChosenStartDate(date as Date)
                    }}
                    value={chosenStartDate}
                    minDate={new Date()}
                  />
                </Box>
              </Center>
            )}
          </Stack>
          <Box w="40%" p={4}>
            {' '}
            {chosenLocation && (
              <Box mt={3}>
                <Text fontWeight="bold">Location:</Text>
                <p>{chosenLocation.name}</p>
                <Divider />
              </Box>
            )}
            {chosenPlan && (
              <Box mt={3}>
                <Text fontWeight="bold">Selected plan:</Text>
                <p>{chosenPlan.name}</p>
                <p>{chosenPlan.rate_string}</p>
                <Divider />
              </Box>
            )}
            {chosenStartDate && (
              <Box mt={3}>
                <Text fontWeight="bold">Start date:</Text>
                <p>{format(chosenStartDate, 'do MMMM yyyy')}</p>
                <Divider />
              </Box>
            )}
          </Box>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Stack spacing={2} direction="row">
          <Button variant="secondary" key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            key="submit"
            onClick={() => {
              if (chosenPlan) {
                handleSendRequest(
                  chosenLocation!.id,
                  chosenPlan.id,
                  format(chosenStartDate, 'yyyy-MM-dd')
                )
              } else {
                toast({
                  title: 'Incomplete Information',
                  description: 'Please select a valid plan',
                  status: 'error'
                })
              }
            }}
            data-testid={`confirm`}
            disabled={
              chosenPlan === null || chosenStartDate === null || isSubmitting
            }
          >
            Confirm
          </Button>
        </Stack>
      </ModalFooter>
    </ChakraModal>
  )
}
