import { Badge, Box, Flex, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { stripeOnboardingLink } from '../../api'
import { Button } from '../../components/Button'
import { PrimarySpinner } from '../../components/chakra-v2/PrimarySpinner'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import 'react-quill/dist/quill.snow.css'
import { XeroConnectionSettings } from './AdminDashboardXeroSettings'

import {
  faCalendarCheck,
  faCreditCard,
  faMoneyBill,
  faRobot
} from '@fortawesome/free-solid-svg-icons'
import { AdminDashboardPlatformTenantEventsSettings } from './EventsSettings/AdminDashboardPlatformTenantEventsSettings'
import {
  ISideBarMenuItem,
  SideBarMenuComponent
} from '../../components/SideBarMenuComponent'
import { AdminDashboardPlatformTenantChatBotSettings } from './ChatBotSettings/AdminDashboardPlatformTenantChatBotSettings'

interface SideBarItem {
  label: string
  icon: any
  id: string
  component?: any
  active: boolean
  menu?: Array<SideBarItem>
}
export const AdminDashboardPlatformTenanIntegrationSettings: React.FC = () => {
  const { platformTenant, tenantIntegration } = usePlatformTenant()
  const { AIMembersChatbotEnabled } = useFeatureFlags()

  if (!platformTenant) {
    return <CenteredSpinner />
  }
  const StripeSettings = () => (
    <Flex>
      <Box p="4">
        <Text fontSize="16px" fontWeight={300}>
          Connect your Stripe account to receive payments
        </Text>
      </Box>
      <Box p="2">
        {tenantIntegration?.is_stripe_connected ? (
          <Badge rounded="md" colorScheme="green" p={2}>
            Status: Connected
          </Badge>
        ) : (
          <Button
            flexShrink={1}
            onClick={async () => {
              if (!platformTenant) return
              const link = await stripeOnboardingLink(platformTenant.id)
              window.location.replace(link.data.url)
            }}
          >
            Connect
          </Button>
        )}
      </Box>
    </Flex>
  )
  const sideBarMenu: Array<ISideBarMenuItem> = [
    {
      label: 'Stripe Connect',
      icon: faCreditCard,
      id: 'stripe',
      active: true,
      component: <StripeSettings />
    },
    {
      label: 'Xero',
      icon: faMoneyBill,
      id: 'xero',
      active: true,
      component: <XeroConnectionSettings />
    },
    {
      label: 'Chatbot',
      icon: faRobot,
      id: 'chatbot',
      active: AIMembersChatbotEnabled ?? false,
      component: <AdminDashboardPlatformTenantChatBotSettings />
    },
    {
      label: 'Events',
      icon: faCalendarCheck,
      id: 'events',
      active: true,
      component: <AdminDashboardPlatformTenantEventsSettings />
    }
  ]
  return (
    <Flex mb={3} flexDir="column">
      <VStack
        bg="white"
        w="100%"
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        rounded="md"
        px={8}
        py={5}
        position="relative"
        color="eastBay09"
        align="left"
      >
        {tenantIntegration ? (
          <SideBarMenuComponent
            sideBarMenu={sideBarMenu}
            title={'Integration Settings'}
          />
        ) : (
          <PrimarySpinner />
        )}
      </VStack>
    </Flex>
  )
}
