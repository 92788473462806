import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalProps
} from '@chakra-ui/react'
import styled from '@emotion/styled'

interface IInputParams extends ModalProps {
  title?: string
  padding?: number
}

/*
    ChakraModal
    - provide consistent styling 
    - strip out some of the unecessary boilerplate associated with modal
    - optional title 
    - basic props for visable adn handleCancel
*/

export const ChakraModal: React.FC<IInputParams> = ({
  padding,
  title,
  children,
  ...rest
}) => {
  return (
    <Modal size="lg" {...rest}>
      <ModalOverlay />
      <ModalContent p={padding ?? 6} bg="white" borderRadius="6px" minW="350px">
        {/* Add a title to modal if specified */}
        {title ? (
          <ModalHeader p={0} mb={4}>
            {title}
          </ModalHeader>
        ) : (
          ' '
        )}
        <ModalCloseButton />
        {/* Render modal body i.e. children */}
        {children}
      </ModalContent>
    </Modal>
  )
}

const StyledHeading = styled.h1`
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 8px;
`
