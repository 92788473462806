import {
  Flex,
  HStack,
  Input,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { encodeQueryParams } from 'serialize-query-params'
// import 'antd/dist/antd.css'
import { format, parse } from 'date-fns'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { exportInvoicesDeferred } from '../../../api'
import { AdminInvoiceTable } from '../../../components/AdminInvoiceTable'
import { Button } from '../../../components/Button'
import { DebouncedInputText } from '../../../components/DebouncedInputText'
import { useAuth } from '../../../context/auth-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IInvoice, _InvoiceSearchFilterProps } from '../../../types'
import { useInvoicesForLocation } from '../../../utils/apiHooks'

import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import stringify from 'query-string'
import { routeStrings } from '../../../routeStrings'

const invoiceReportQueryParams = {
  page: NumberParam,
  invoiceId: StringParam,
  teamName: StringParam,
  status: StringParam,
  date: StringParam,
  type: StringParam,
  tenant: NumberParam,
  locId: NumberParam
}

export const AdminDashboardInvoicesReports: React.FC = () => {
  const [queryParams, setQueryParams] = useQueryParams(invoiceReportQueryParams)

  const [invoiceStatuses, setInvoiceStatuses] = useState<Set<string>>(
    new Set<string>()
  )
  const [invoiceDates, setInvoiceDates] = useState<Set<string>>(
    new Set<string>()
  )
  const [filterInvoiceDate, setFilterInvoiceDate] = useState<Date>()
  const toast = useToast()
  const { currentOrgUnit } = useAuth()
  const { platformTenant } = usePlatformTenant()
  const [results, setResults] = useState<IInvoice[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [filterData, setFilterData] = useState<_InvoiceSearchFilterProps>({
    page: 1,
    invoiceId: queryParams.invoiceId ?? '',
    teamName: queryParams.teamName ?? '',
    locId: queryParams.locId ?? undefined,
    status: queryParams.status ?? '',
    tenant: currentOrgUnit?.organization.parent_platform_tenant,
    date: filterInvoiceDate
      ? format(filterInvoiceDate, 'MM-yyyy')
      : queryParams.date
      ? queryParams.date
      : ''
  })
  const { data, error, isLoading } = useInvoicesForLocation(filterData)
  const activeInputStyle = {
    borderColor: '#a8a8a8',
    borderWidth: '2px',
    background: '#f9f9f9'
  }
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (queryParams && currentOrgUnit) {
      try {
        setFilterData({
          page: 1,
          tenant: currentOrgUnit.organization.parent_platform_tenant,
          invoiceId: queryParams.invoiceId ?? undefined,
          locId: queryParams.locId ?? undefined,
          status: queryParams.status ?? '',
          teamName: queryParams.teamName ?? ''
        })
        if (queryParams.date) {
          setFilterInvoiceDate(parse(queryParams.date, 'MM-yyyy', new Date()))
        }
      } catch (e) {}
    }
  }, [])
  useEffect(() => {
    if (data && data.results) {
      const invoiceStatuses = new Set<string>()
      const invoiceDates = new Set<string>()
      data.results.forEach((invoice) => {
        invoiceStatuses.add(invoice.status)
        invoiceDates.add(format(new Date(invoice.issued_on), 'MMMM yyyy'))
      })
      setInvoiceStatuses(invoiceStatuses)
      setInvoiceDates(invoiceDates)
    }
  }, [data])
  useEffect(() => {
    if (data && data.results) {
      let filteringResults: IInvoice[] = data.results
      setResults(filteringResults)
    }
  }, [data])
  useEffect(() => {
    let params = encodeQueryParams(invoiceReportQueryParams, {
      type: 'invoices',
      ...filterData
    })
    window.history.replaceState(
      { data },
      'Reports',
      `${routeStrings.adminDashboardReports}/?${stringify.stringify(params)}`
    )
  }, [filterData])

  return (
    <VStack
      position="relative"
      width="100%"
      background="#ffffff"
      padding="16px"
      boxShadow="0px 16px 16px rgba(0, 0, 0, 0.05)"
      borderRadius="5px"
      align="left"
    >
      <HStack justify="space-between">
        <Text
          margin="16px"
          textAlign="left"
          fontSize="24px"
          lineHeight="24px"
          color="#373f49"
        >
          Review invoices
        </Text>
        <Button
          ml={3}
          loadingText={'Generating File'}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={async () => {
            if (isSubmitting) return
            setIsSubmitting(true)
            exportInvoicesDeferred(
              filterData.locId,
              filterData.status,
              currentOrgUnit?.organization.parent_platform_tenant
            )
              .then((res) => {
                setIsSubmitting(false)
                toast({
                  position: 'top',
                  status: 'info',
                  description:
                    'Your request is being processed, you will receieve an email when the export is ready.',
                  isClosable: true,
                  title: 'Request accepted'
                })
              })
              .catch((err) => {
                setIsSubmitting(false)
                toast({
                  position: 'top',
                  status: 'error',
                  description: 'Please, try again later.',
                  isClosable: true,
                  title: 'An error ocurred'
                })
              })
          }}
        >
          Export Invoices to PDF
        </Button>
      </HStack>

      <Flex flexDirection={['column', 'row']} justify="flex-start" mb={5}>
        <DebouncedInputText
          placeholder="Invoice Number"
          m={3}
          defaultDebounceTime={1000}
          maxWidth="250px"
          value={filterData.invoiceId}
          setValue={(value) => {
            setFilterData({ ...filterData, page: 1, invoiceId: value })
          }}
          style={
            filterData && filterData.invoiceId ? activeInputStyle : undefined
          }
        />

        <DebouncedInputText
          placeholder="Team Name"
          m={3}
          maxWidth="250px"
          value={filterData.teamName}
          setValue={(value) => {
            setFilterData({ ...filterData, page: 1, teamName: value })
          }}
          style={
            filterData && filterData.teamName != ''
              ? activeInputStyle
              : undefined
          }
        />

        <Select
          m={3}
          maxWidth="250px"
          placeholder="Invoice Location"
          color="#8b96a3"
          value={filterData.locId ?? ''}
          onChange={(e) => {
            setFilterData({
              ...filterData,
              page: 1,
              locId: e.target.value != '' ? parseInt(e.target.value) : undefined
            })
          }}
          style={filterData && filterData.locId ? activeInputStyle : undefined}
        >
          {platformTenant?.locations.map((location, i) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </Select>
        <Select
          m={3}
          maxWidth="250px"
          value={filterData.status}
          onChange={(e) => {
            setFilterData({ ...filterData, page: 1, status: e.target.value })
          }}
          // placeholder="Invoice Status"
          color="#8b96a3"
          style={filterData && filterData.status ? activeInputStyle : undefined}
        >
          {/* {Array.from(invoiceStatuses).map((status: string) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}  */}
          <option value="">Invoice Status</option>
          <option value="BUILDING"> Building</option>
          <option value="ISSUED"> Issued</option>
          <option value="PENDING"> Pending</option>
          <option value="DECLINED"> Declined</option>
          <option value="ERROR"> Error</option>
          <option value="PAID"> Paid</option>
          <option value="CREDITED"> Credited</option>
          <option value="CANCELLED"> Cancelled</option>
          <option value="PREVIEW"> Preview</option>
        </Select>
        <Popover isOpen={isOpen} matchWidth={true}>
          <PopoverTrigger>
            <Input
              placeholder="Invoice Date"
              m={3}
              value={
                filterInvoiceDate ? format(filterInvoiceDate, 'MMMM yyyy') : ''
              }
              onChange={(val) => {}}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              style={
                filterData && filterData.date ? activeInputStyle : undefined
              }
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverCloseButton
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            />
            <PopoverHeader
              onClick={() => {
                setFilterInvoiceDate(undefined)

                setFilterData({ ...filterData, page: 1, date: '' })
                setIsOpen(false)
              }}
            >
              <Link fontSize="lg">Reset Date</Link>
            </PopoverHeader>
            <PopoverBody>
              <DatePicker
                selected={filterInvoiceDate}
                onChange={(date) => {
                  setIsOpen(false)
                  setFilterInvoiceDate(date as Date)
                  setFilterData({
                    ...filterData,
                    page: 1,
                    date: format(date as Date, 'MM-yyyy')
                  })
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                inline
                maxDate={new Date()}
                todayButton="Go to Today"
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <AdminInvoiceTable
        loading={(!data && !error) || isLoading}
        invoices={results}
        pagination={{
          pageSize: 10,
          total: data?.count,
          onChange: (page) => {
            setFilterData({ ...filterData, page: page })
          },
          showTotal: (total, [a, b]) => <Flex mr={2}>Total {total} items</Flex>
        }}
      />
    </VStack>
  )
}
