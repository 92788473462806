import React from 'react'
import { IOrganizationalUnitBasic } from '../types'
import { RouterLink } from './Link'
import { getTeamMemberProfileUrl } from '../routeStrings'

interface Props {
  orgId: number
  member: IOrganizationalUnitBasic
  isInAdminRole: boolean
}
export const MemberTableLink: React.FC<Props> = (props) => {
  const { orgId, member, isInAdminRole } = props

  return (
    <RouterLink
      color="#255CE1"
      mb={0}
      to={getTeamMemberProfileUrl(orgId, member.id, isInAdminRole)}
    >
      {member.user.name}
    </RouterLink>
  )
}
