import React from 'react'
import { Button as BaseButton, ButtonProps } from '@chakra-ui/react'
import { usePlatformTenant } from '../context/platform-tenant-context'

interface customButtonProps extends ButtonProps {
  variant?: 'primary' | 'secondary'
  circular?: boolean
  disabled?: boolean
  small?: boolean
  greyedOut?: boolean
  activeStyles?: boolean
}

export const Button: React.FC<customButtonProps> = React.forwardRef(
  (props, ref: React.Ref<any>) => {
    const { disabled, small, greyedOut, variant, ...rest } = props
    const { platformTheme, platformTenant } = usePlatformTenant()
    const isDisabled = `
    opacity: 0.65;
    cursor: not-allowed !important;
  `

    const isSmall = `
    border-radius: 24px;
    line-height: 24px;
    height: 24px;
    padding: 0 12px;
    font-size: 12px;
    min-width: min-content;
  `
    const css = (disabled ? isDisabled : '') + (small ? isSmall : '')

    if (variant === 'secondary') {
      return (
        <BaseButton
          disabled={disabled}
          ref={ref}
          height="40px"
          color={
            platformTheme?.brand_primary_color ||
            platformTenant?.brand_primary_color ||
            '#EB4969'
          }
          bg="white"
          border="1px solid"
          borderColor={
            platformTheme?.brand_primary_color ||
            platformTenant?.brand_primary_color ||
            '#EB4969'
          }
          borderRadius="50px"
          transition="all ease .2s"
          fontWeight="bold"
          _hover={{
            cursor: 'pointer',
            transform: 'scale(1.008)',
            boxShadow: '0px 22px 22px rgba(0, 0, 0, 0.04)',
            bg: '#F4F5F5'
          }}
          {...rest}
          css={css}
        >
          {props.children}
        </BaseButton>
      )
    }

    return (
      <BaseButton
        ref={ref}
        height="40px"
        color="white"
        border="1px solid"
        disabled={disabled}
        borderColor={
          platformTheme?.brand_primary_color ||
          platformTenant?.brand_primary_color ||
          '#EB4969'
        }
        bg={
          platformTheme?.brand_primary_color ||
          platformTenant?.brand_primary_color ||
          '#EB4969'
        }
        fontWeight="500"
        borderRadius="50px"
        transition="all ease .2s"
        _hover={{
          cursor: 'pointer',
          transform: 'scale(1.008)',
          boxShadow: '0px 22px 22px rgba(0, 0, 0, 0.04)'
        }}
        {...rest}
        css={css}
      >
        {props.children}
      </BaseButton>
    )
  }
)
