import { Box, Flex, Link, Text, useToast } from '@chakra-ui/react'
import { formatDistance } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { assignMemberToSeat, cancelTeamInvite } from '../../../api'
import { Button } from '../../../components/Button'
import { ChakraModal } from '../../../components/ChakraModal'
import { DropdownFormInput } from '../../../components/DropdownFormInput'
import { FormInput } from '../../../components/FormInput'
import { useAuth } from '../../../context/auth-context'
import {
  IOrganizationalUnitBasic,
  IOrganizationSeatBasic
} from '../../../types'
import { useOrganization } from '../../../utils/apiHooks'
import { EMAIL_REGEX } from '../../../utils/constants'
import { SmallAddIcon } from '@chakra-ui/icons'
import { processErrorMessage } from '../../../utils/formUtils'
interface IInputParams {
  seat?: IOrganizationSeatBasic
  membersWithoutPlans: IOrganizationalUnitBasic[]
  visable: boolean
  handleCancel: () => void
  handleSendInvite: (email: string) => void
}

export const InviteMemberModal: React.FC<IInputParams> = (input) => {
  let { seat, visable, membersWithoutPlans, handleCancel } = input

  const toast = useToast()

  const invitation = seat ? seat.active_invitation : undefined

  const [memberId, setMemberId] = useState<number>(0) // default is zero: used to differentiate menu item or additional action

  const [showInviteState, setShowInviteState] = useState(false)

  const auth = useAuth()

  const { mutate: refreshOrganization } = useOrganization(
    auth.currentOrganization ? auth.currentOrganization.id : undefined
  )

  const formattedTeamMemberDropDownOptions = seat
    ? membersWithoutPlans &&
      membersWithoutPlans.map((member) => ({
        itemName: member.user.name,
        itemId: member.id
      }))
    : []

  // Reset state and close modal
  const resetStateAndClose = () => {
    setMemberId(0)
    setShowInviteState(false)
    handleCancel()
  }

  return (
    <ChakraModal
      title="Invite member"
      isOpen={visable}
      onClose={resetStateAndClose}
    >
      {invitation && (
        <Box mb={10}>
          {/* <Label>Existing invitation</Label> */}

          <Flex flexDirection="row" whiteSpace="nowrap">
            <Box width={'50%'}>
              <h4>Invitation email:</h4>
              <Text
                p={1}
                pl={0}
                mr={5}
                overflowX="hidden"
                css={'text-overflow: ellipsis;'}
              >
                {invitation.invitation_email}
              </Text>
            </Box>
            <Box width={'50%'}>
              <h4>Invite sent:</h4>
              <Text
                p={1}
                pl={0}
                overflowX="hidden"
                css={'text-overflow: ellipsis;'}
              >
                {formatDistance(Date.parse(invitation.created_at), new Date())}{' '}
                ago
              </Text>
            </Box>
          </Flex>
          <Box mt={2}>
            <Link
              color="#255CE1"
              onClick={() => {
                cancelTeamInvite(invitation.token)
                  .then(() => {
                    toast({ description: 'Invite Cancelled', status: 'info' })
                    refreshOrganization()
                    resetStateAndClose()
                  })
                  .catch((err) => {
                    toast({
                      description: processErrorMessage(err, 'An error ocurred'),
                      status: 'error'
                    })
                  })
              }}
            >
              Cancel Invitation
            </Link>
          </Box>
        </Box>
      )}

      <DropdownFormInput
        dataTestId="invite-member-menu"
        items={formattedTeamMemberDropDownOptions}
        additionalActions={[
          {
            itemName: (
              <Box data-testid="invite-a-member-option">
                <SmallAddIcon mr={2} /> Invite a member
              </Box>
            ),
            itemAction: (id) => {
              setShowInviteState(true)
              setMemberId(0)
            }
          }
        ]}
        name="Invite new or select an existing member"
        selectedIndex={memberId}
        onSelect={(n) => setMemberId(n)}
      />
      {/* Show a confirmation button to submit member invite if member is selected  */}
      {memberId !== 0 && (
        <Flex flexDirection="row">
          <Button
            key="submit"
            type="submit"
            data-testid="assign"
            onClick={() => {
              // If a current invite exists, cancel it
              invitation && cancelTeamInvite(invitation.token)

              // Then assign member to seat
              seat?.id &&
                memberId &&
                assignMemberToSeat(seat!.id, memberId)
                  .then((res) => {
                    toast({
                      description: 'Member assigned to seat',
                      status: 'success'
                    })
                    refreshOrganization()
                    resetStateAndClose()
                  })
                  .catch(() =>
                    toast({
                      description: 'Something went wrong',
                      status: 'error'
                    })
                  )
            }}
          >
            Assign
          </Button>
          <Button
            key="submit"
            data-testid="cancel"
            variant="secondary"
            onClick={() => resetStateAndClose()}
          >
            Cancel
          </Button>
        </Flex>
      )}

      {/* Show the form if the user has selected "invite a member" via email  */}
      {showInviteState && memberId === 0 && (
        <Formik
          enableReinitialize
          initialValues={{ email: '' }}
          validate={(values) => {
            const errors: any = {}

            if (!values.email) {
              errors.email = 'Required'
            } else if (!EMAIL_REGEX.test(values.email)) {
              errors.email = 'Invalid email address'
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (values.email) {
              input.handleSendInvite(values.email.toLowerCase())
              values.email = ''
              resetStateAndClose()
            }
          }}
        >
          {({ isSubmitting, errors }) => (
            <>
              <Form>
                {seat ? (
                  invitation ? (
                    <h4>Send new invitation:</h4>
                  ) : (
                    <h4>Send your team member an email invite:</h4>
                  )
                ) : (
                  <h4>Invite member to your team without a plan:</h4>
                )}

                <Field
                  type="email"
                  name="email"
                  autoFocus={true}
                  placeholder="johnsmith@mail.com"
                  data-testid="team-invite-email"
                  component={FormInput}
                />

                <Flex flexDirection="row-reverse" my={2}>
                  <Button
                    key="submit"
                    type="submit"
                    data-testid={`confirm`}
                    disabled={isSubmitting || !!errors.email}
                  >
                    Confirm
                  </Button>
                </Flex>
              </Form>
            </>
          )}
        </Formik>
      )}
    </ChakraModal>
  )
}
