import React, { useState } from 'react'
import {
  Flex,
  Heading,
  FlexProps,
  Box,
  Divider,
  Stack,
  Text,
  Link,
  HStack
} from '@chakra-ui/react'
import { SecondaryButton } from '../../../components/chakra-v2/SecondaryButton'

interface PlanDisplay {
  uuid: string
  name: string
  rate: string
  inclusions_url: string
}

interface Props extends FlexProps {
  title: string
  description: string
  planOptions: PlanDisplay[]
  onSelectPlan?: (uuid: string) => void
}

export const PlanCard: React.FC<Props> = ({
  title,
  description,
  planOptions,
  onSelectPlan,
  ...rest
}) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const multiplePlans = planOptions.length > 1

  /* disabled this UI to toggle hide/show of options */

  // const handleSelectOrExpand = () => {
  //   if (multiplePlans) {
  //     setExpanded(expanded => !expanded)
  //   } else {
  //     onSelectPlan && planOptions[0] && onSelectPlan(planOptions[0].id)
  //   }
  // }

  return (
    <Flex
      boxShadow="0px 4px 16px rgba(0, 0, 0, 0.1)"
      borderRadius="8px"
      color="#303B40"
      direction="column"
      justify="space-between"
      alignItems="center"
      data-testid={`PlanCard-${title}`}
      p={8}
      {...rest}
    >
      <Stack spacing={3} w="100%">
        <Heading as="h5" size="sm" textAlign="center" fontWeight="semibold">
          {title}
        </Heading>
        <Divider
          borderColor="brandPrimary"
          w="100%"
          borderWidth="2px"
          opacity={1}
        />
        {expanded ? (
          <Stack spacing={1} shouldWrapChildren>
            {planOptions.map((plan, i) => (
              <Box
                key={plan.uuid}
                _hover={{
                  boxShadow: 'sm'
                }}
                p={1}
              >
                <Flex justify="space-between">
                  <Stack>
                    <Text fontSize="sm" fontWeight="light">
                      {plan.name}
                    </Text>
                    <Text fontSize="sm" fontWeight="bold">
                      {plan.rate.split('.')[0] &&
                        plan.rate.split('.')[0] + ' + GST'}
                    </Text>
                  </Stack>
                  <SecondaryButton
                    variant="ghost"
                    size="sm"
                    ml={1}
                    mt={1}
                    flexGrow={0}
                    onClick={() => onSelectPlan && onSelectPlan(plan.uuid)}
                    data-testid={`select-${plan.name}`}
                  >
                    Select
                  </SecondaryButton>
                </Flex>

                {plan.inclusions_url && (
                  <Link
                    href={plan.inclusions_url}
                    isExternal
                    fontSize="small"
                    color="blue.500"
                    textDecorationLine="underline"
                  >
                    Membership Inclusions
                  </Link>
                )}
                {i !== planOptions.length - 1 && <Divider mt={3} />}
              </Box>
            ))}
          </Stack>
        ) : (
          <>
            <Stack>
              <Text fontSize="sm">{description}</Text>
              {!multiplePlans && (
                <Text fontWeight="bold" fontSize="sm">
                  {planOptions[0]?.rate}
                </Text>
              )}
            </Stack>
            {!multiplePlans && planOptions[0]?.inclusions_url && (
              <Link
                href={planOptions[0]?.inclusions_url}
                isExternal
                fontSize="small"
                color="teal.500"
              >
                Membership Inclusions
              </Link>
            )}
          </>
        )}
      </Stack>
      {/* disabled this UI to toggle hide/show of options */}
      {/* <SecondaryButton
        size="sm"
        flexGrow={0}
        onClick={handleSelectOrExpand}
        data-testid={
          multiplePlans
            ? expanded
              ? 'hide'
              : 'expand'
            : `select-${planOptions[0]?.name}`
        }
      >
        {multiplePlans
          ? expanded
            ? 'Hide Options'
            : 'View Options'
          : 'Sign Up'}
      </SecondaryButton> */}
    </Flex>
  )
}
