// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'
import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditableInputProps,
  EditablePreview,
  Flex,
  IconButton,
  Spinner,
  Text,
  useEditableControls
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'

interface Props extends EditableInputProps {
  defaultValue: string
  isLink?: boolean
  isUpdating?: boolean
  isDisabled?: boolean
  onSubmitValue?: (newValue: string) => void
}
export const EditableInputText: React.FC<Props> = ({
  defaultValue,
  isUpdating,
  isLink,
  isDisabled,
  onSubmitValue,

  ...rest
}) => {
  if (isDisabled)
    return (
      <Text
        style={{
          color: isLink ? 'blue' : 'black',
          textDecoration: isLink ? 'underline' : 'none',
          cursor: isLink ? 'pointer' : 'inherit'
        }}
        className="truncate-text"
      >
        {defaultValue}
      </Text>
    )

  const EditableButtons = (props) => {
    const { isUpdating } = props
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps
    } = useEditableControls()

    return isUpdating ? (
      <>
        &nbsp;&nbsp;
        <Spinner />{' '}
      </>
    ) : isEditing ? (
      <ButtonGroup
        display="flex"
        justifyContent="center"
        size="sm"
        alignContent="center"
      >
        <StyledIconButton
          icon={<CheckIcon />}
          size="sm"
          aria-label="confirm"
          {...getSubmitButtonProps()}
        />
        <StyledIconButton
          icon={<CloseIcon />}
          size="sm"
          aria-label="cancel"
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center" display="inline">
        <StyledIconButton
          size="sm"
          icon={<EditIcon />}
          aria-label="edit"
          {...getEditButtonProps()}
        />
      </Flex>
    )
  }

  return (
    <Editable
      textAlign="right"
      defaultValue={defaultValue}
      fontSize="sm"
      isPreviewFocusable={false}
      submitOnBlur={false}
      display="flex"
      flexDirection="row"
      onSubmit={onSubmitValue}
      overflowX="hidden"
    >
      <>
        <EditablePreview
          textAlign="right"
          display="inline"
          width="100%"
          style={{
            color: isLink ? 'blue' : 'black',
            textDecoration: isLink ? 'underline' : 'none',
            cursor: isLink ? 'pointer' : 'inherit'
          }}
          className="truncate-text"
          onClick={() => {
            if (isLink && defaultValue !== '') {
              window.open(defaultValue, '_blank')
            }
          }}
        />
        <EditableInput
          onBlur={() => null}
          textAlign="right"
          display="inline"
          background={'#f5f5f5'}
          _focus={{
            borderColor: 'none'
          }}
          minWidth="280px"
          {...rest}
        />
        <EditableButtons isUpdating={isUpdating} />
      </>
    </Editable>
  )
}

const StyledIconButton = styled(IconButton)`
  height: 23px;
  width: 23px;
  border-radius: 18px;
  border: none;
  color: grey;
  justify-content: center;
  margin-left: 0.75rem;
`
