import { Box, Text } from '@chakra-ui/react'

import React from 'react'
import { RouterLink } from '../../../components/Link'
import { StatusBox } from '../../../components/StatusBox'
import { Table, TableDataProp } from '../../../components/table/Table'
import { UserDisplay } from '../../../components/UserDisplay'
import { useAuth } from '../../../context/auth-context'
import { getTeamMemberProfileUrl, routeStrings } from '../../../routeStrings'
import { IOrganization, IOrganizationalUnit, User } from '../../../types'

import { useRole } from '../../../utils/rbac'

// import 'antd/dist/antd.css'

interface Props {
  status?: string
  handleOnboarding: (ouId: number) => void
  orgUnits: IOrganizationalUnit[] | undefined
}

export const Members: React.FC<Props> = (props) => {
  const { orgUnits } = props
  const auth = useAuth()
  const { isInAdminRole } = useRole()

  const getSignupStatus = (user: User) => {
    return (
      user.tenant_profiles.find(
        (tp) =>
          tp.platform_tenant ===
          auth.currentOrgUnit?.organization.parent_platform_tenant
      )?.signup_status ?? ''
    )
  }

  const PlanOfficeItem: React.FC<{ org_unit?: IOrganizationalUnit }> = ({
    org_unit
  }) => {
    if (!org_unit) return null
    if (!org_unit.seats || org_unit.seats.length === 0) {
      return null
    }
    const seat = org_unit.seats[0]
    const hasDesk = seat.office_desk !== undefined

    return (
      <Box
        overflowWrap={'break-word'}
        flexWrap="wrap"
        maxW="inherit"
        fontSize="xs"
      >
        {!hasDesk && seat.ongoing_plan && (
          <Text overflowWrap={'break-word'} flexWrap="wrap">
            {seat.ongoing_plan.name}
          </Text>
        )}
        {hasDesk && <Text>Office {seat.office_information?.name}</Text>}
      </Box>
    )
  }

  const memberActions = (rowData?: any) => [
    {
      text: 'Onboarding Done',

      testIdPrefix: rowData?.user?.email
        ? 'onboard-' + rowData.user.email
        : 'onboard',
      action: (rowId: number) => {
        const ouObj = orgUnits?.find((obj) => obj.id === rowId)
        if (ouObj) {
          props.handleOnboarding(ouObj.user.id)
        }
      }
    }
  ]

  function MemberLink(member: IOrganizationalUnit) {
    return (
      <RouterLink
        color="#255CE1"
        mb={0}
        to={getTeamMemberProfileUrl(
          member.organization.id,
          member.id,
          isInAdminRole
        )}
      >
        {member.user.name}
      </RouterLink>
    )
  }

  function OrgLink(org: IOrganization) {
    if (org) {
      return (
        <RouterLink
          className="table-link"
          mb={0}
          to={routeStrings.adminTeamDetailView(org.id)}
        >
          {org.name}
        </RouterLink>
      )
    }
  }

  const getMemberStatus = (ou: IOrganizationalUnit) => {
    if (
      ![
        'awaiting_onboarding',
        'awaiting_approval',
        'user_inactive',
        'cancelled_plan'
      ].includes(getSignupStatus(ou.user))
    ) {
      const activeDeskorPlan = ou.seats?.find(
        (seat) => seat.office_desk !== undefined || seat.active_plan
      )
      if (activeDeskorPlan === undefined) {
        return 'user_inactive'
      }
    }
    // return other status
    return getSignupStatus(ou.user)
  }
  function StatusRenderFilter(status: string) {
    return (
      <StatusBox
        status={status}
        mapping={{
          awaiting_approval: { color: 'yellow', label: 'Awaiting Approval' },
          awaiting_onboarding: {
            color: 'yellow',
            label: 'Awaiting Onboarding'
          },
          approved_and_onboarded: { color: 'positive', label: 'Approved' },
          user_inactive: { color: 'blue', label: 'User Inactive' }
        }}
      />
    )
  }

  const tableData =
    orgUnits &&
    orgUnits.map((ou) => {
      return {
        id: TableDataProp(ou.id),
        test_prefix: TableDataProp(ou.user.email),
        // rowData: TableDataProp(request),
        team: TableDataProp(OrgLink(ou.organization), ou.organization.name),
        profilePic: TableDataProp(
          <UserDisplay
            name={ou.user.name}
            src={ou.user.user_profile.profile_picture?.image}
          />
        ),
        name: [
          TableDataProp(MemberLink(ou), ou.user.name),
          TableDataProp(ou.user.email),
          TableDataProp(ou.user?.user_profile?.mobile_number)
        ],
        plan: TableDataProp(<PlanOfficeItem org_unit={ou} />),
        location: TableDataProp(
          ou?.organization?.home_location?.name,
          ou?.organization?.home_location?.name
        ),

        start_date: TableDataProp(ou.user.user_profile?.start_date),
        status: [
          TableDataProp(
            StatusRenderFilter(getMemberStatus(ou)),
            getMemberStatus(ou)
          )
        ],
        actions: TableDataProp(memberActions(ou))
      }
    })

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Table
          pageSize={900}
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'test_prefix', accessor: 'test_prefix', show: false },
            {
              header: '',
              accessor: 'profilePic',
              show: true,
              width: 1 / 24,
              minWidth: '45px'
            },
            {
              header: 'Name',
              accessor: 'name',
              show: true,
              width: 3.5 / 12
            },
            {
              header: 'Team',
              accessor: 'team',
              show: true,
              width: 2 / 12,
              enableFilter: false
            },
            {
              header: 'Status',
              accessor: 'status',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Plan / Office',
              accessor: 'plan',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Location',
              accessor: 'location',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Start Date',
              accessor: 'start_date',
              show: true,
              width: 1 / 12,
              minWidth: '74px'
            },
            { header: '', accessor: 'actions', show: true, width: 0.5 / 12 }
            // { header: '', accessor: 'spacer', show: true, width: 3 / 12 }
          ]}
          data={tableData}
          loading={orgUnits === undefined}
        />
      </Box>
    </div>
  )
}
