import {
  Box,
  Flex,
  Button as ChakraButton,
  Text,
  useToast,
  Center,
  Image,
  Spacer
} from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'

import { PrimaryButton } from '../../components/chakra-v2/PrimaryButton'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { FormInput } from '../../components/FormInput'
import { OnboardingUser } from '../../interfaces'
import {
  SinglePageSignupComponent,
  useUserDetails
} from '../../context/user-onboard-context'
import { OnboardingHeading } from '../Onboarding/OnboardingComponents'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import {
  sendEmailVerificationToken,
  validateVerificationEmailToken
} from '../../api'
import { Button } from '../../components/Button'

export const ExternalOnboardingEmailValidation: React.FC<
  SinglePageSignupComponent
> = (props) => {
  const { onCompleteCallback, setIsActive, goBack } = props
  const { platformTheme } = usePlatformTenant()
  const toast = useToast()
  const RESEND_SECONDS = 30
  const [timesSent, setTimesSent] = useState(0)
  const [seconds, setSeconds] = useState(RESEND_SECONDS)
  const [allowResend, setAllowResend] = useState(true)

  const { setUserDetails, userDetails } = useUserDetails()
  const onSendCode = () => {
    // Validate
    let t = timesSent + 1
    setTimesSent(t)
    setAllowResend(true)
    setSeconds(RESEND_SECONDS * t)
    sendEmailVerificationToken(userDetails.email)
  }
  useEffect(() => {
    if (!allowResend) return

    let t = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      } else {
      }
    }, 1000)
    return () => {
      clearTimeout(t)
    }
  }, [allowResend, seconds])

  if (!platformTheme) return <CenteredSpinner />
  return (
    <Box margin={'0 auto'} w={'100%'} maxW="450px" onClick={setIsActive}>
      <Center mb={1}>
        <Image
          src={platformTheme.logo_long}
          w={'10rem'}
          alt={`${platformTheme.business_name} Logo`}
        />
      </Center>
      <OnboardingHeading
        size={'xs'}
        color={'gray.400'}
        mb={4}
        textAlign="center"
      >
        Book a space at {platformTheme?.business_name}
      </OnboardingHeading>
      <OnboardingHeading
        size={'md'}
        color={'gray.800'}
        mb={4}
        textAlign="center"
      >
        Validate your email address
      </OnboardingHeading>

      <Formik
        initialValues={{
          validation_code: ''
        }}
        validate={(values): OnboardingUser => {
          const errors: any = {}

          // Hardcode: Code length set to 6
          if (!values.validation_code && values.validation_code.length < 6) {
            errors.validation_code = 'Required'
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (!userDetails) return
          validateVerificationEmailToken(
            userDetails.email,
            values.validation_code
          )
            .then((res) => {
              toast({
                title: userDetails.email + ' Verified',
                status: 'success',
                duration: 5000,
                isClosable: false
              })
              resetForm()
              onCompleteCallback()
              setSubmitting(false)
            })
            .catch((error) => {
              setSubmitting(false)
              toast({
                title: 'Invalid Code.',
                description: 'Please, try again.',
                status: 'error',
                duration: 4000,
                isClosable: false
              })
            })
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <Flex direction="column">
              <Box mb={8}>
                <Text margin={'0 auto'} mb={4}>
                  Please, use the code we have sent to {userDetails.email}
                </Text>
                <Field
                  type="text"
                  name="validation_code"
                  isInvalid={
                    touched.validation_code &&
                    errors.validation_code !== undefined
                  }
                  placeholder="XXXXXX"
                  textAlign="center"
                  component={FormInput}
                  data-testid="onboarding-validation_code-field"
                />
                <ErrorMessage
                  name="validation_code"
                  component={FormErrorMessage}
                />
              </Box>

              <Flex>
                <Button
                  style={{
                    background: 'transparent',
                    color: '#999',
                    border: 'none',
                    fontSize: '15px'
                  }}
                  mr={[1, 4]}
                  onClick={() => {
                    goBack && goBack()
                  }}
                >
                  Go back
                </Button>
                <Spacer />
                <PrimaryButton
                  type="submit"
                  px={6}
                  data-testid="onboarding-validation_code-next"
                  isDisabled={isSubmitting}
                >
                  Validate Email
                </PrimaryButton>
              </Flex>
              <Center>
                <ChakraButton
                  disabled={isSubmitting || !allowResend || seconds > 0}
                  isDisabled={isSubmitting || !allowResend || seconds > 0}
                  onClick={onSendCode}
                  loadingText="Verifying"
                  size="md"
                  colorScheme={'lightBlue'}
                  variant="ghost"
                  borderRadius={'3xl'}
                  my={4}
                  textAlign="center"
                >
                  {seconds === 0
                    ? 'Resend code'
                    : `Didn't get a code? Send again in ${seconds}s`}
                </ChakraButton>
              </Center>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
