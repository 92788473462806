import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  Flex,
  List,
  ListItem,
  Spacer,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import React, { useRef, useState } from 'react'
import { createOrUpdateLocationAdmin } from '../../../api'
import { Button } from '../../../components/Button'
import { FilterableOrganizationalUnitsMultiSelect } from '../../../components/FilterableOrganizationalUnitsMultiSelect'
import { useAuth } from '../../../context/auth-context'
import {
  IBasicLocation,
  ILocationAdmin,
  ILocationAdminDTO,
  IOrganizationalUnitFilterableSelect
} from '../../../types'
import { useLocationAdmins } from '../../../utils/apiHooks'

interface Props {
  location: IBasicLocation
}

export const BookingLocationAdmins: React.FC<Props> = (props) => {
  const { location } = props
  const {
    data: admins,
    mutate,
    isValidating
  } = useLocationAdmins(location.platform_tenant_id, location.id)
  const ref = useRef<any>()

  const [selectedOrgUnits, setSelectedOrgUnits] = useState<
    IOrganizationalUnitFilterableSelect[]
  >([])
  const [loading, setLoading] = useState(false)
  const { currentOrgUnit } = useAuth()

  const toggleReceivesNotifications = (
    admin: ILocationAdmin,
    value: boolean
  ) => {
    if (loading) return
    setLoading(true)
    const { id, location, organizational_unit } = admin

    const adminDTO: ILocationAdminDTO = {
      id,
      location: location.id,
      organizational_unit: organizational_unit.id,
      notify_new_external_booking: value
    }
    createOrUpdateLocationAdmin(adminDTO)
      .then((res) => {
        setLoading(false)
        mutate()
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        mutate()
      })
  }

  const handleAddAdmins = () => {
    if (loading || selectedOrgUnits.length === 0) return
    setLoading(true)

    let promises: Promise<AxiosResponse<ILocationAdmin>>[] = []
    selectedOrgUnits.forEach((selected) => {
      const adminDTO: ILocationAdminDTO = {
        location: location.id,
        organizational_unit: selected.id,
        notify_new_external_booking: true
      }
      promises.push(createOrUpdateLocationAdmin(adminDTO))
    })
    Promise.all(promises)
      .then((res) => {
        setLoading(false)
        setSelectedOrgUnits([])
        if (ref.current) ref.current.clear()
        mutate()
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        mutate()
      })
  }

  // Perform API service call when the checkbox is called
  // Replace '/api/service' with your API endpoint for the service call

  if (admins === undefined) {
    return null
  }

  return (
    <Box>
      <Box w={'100%'}>
        <Flex flexDir={['column', 'row', 'row']}>
          <Box w={['100%%', '50%', '50%']}>
            {currentOrgUnit && (
              <FilterableOrganizationalUnitsMultiSelect
                name=""
                hiddenIds={[]}
                dataTestId="orgunit-searchbox"
                organizationId={currentOrgUnit.organization.id}
                onSelect={(values) => setSelectedOrgUnits(values)}
                _ref={ref}
              />
            )}
          </Box>
          <Spacer />
          <Button
            w={['100%%', '20%', '20%']}
            mt={[2, 0, 0]}
            variant="secondary"
            size={'sm'}
            disabled={selectedOrgUnits.length === 0}
            isDisabled={selectedOrgUnits.length === 0}
            isLoading={loading}
            onClick={handleAddAdmins}
          >
            Add
          </Button>
        </Flex>
      </Box>

      <Divider mt={4} />
      <List mt={2}>
        <ListItem display="flex">
          <Text fontWeight={'bold'}>Admin name</Text>
          <Spacer />
          <Text fontWeight={'bold'}>Notify new external booking</Text>
        </ListItem>
        {admins && admins.length === 0 && (
          <ListItem
            alignItems="center"
            pb={1}
            my={2}
            borderBottomColor={'#dadada'}
            borderBottomWidth={1}
            color={'gray.700'}
          >
            <Text color={'gray.400'}>
              No space admins have been assign to this location
            </Text>
          </ListItem>
        )}
        {admins.map((admin) => (
          <ListItem
            key={admin.id}
            display="flex"
            alignItems="center"
            pb={1}
            my={2}
            borderBottomColor={'#dadada'}
            borderBottomWidth={1}
          >
            <Avatar
              size={'sm'}
              mr={2}
              src={
                admin.organizational_unit.user.user_profile.profile_picture
                  ?.image
              }
              name={admin.organizational_unit.user.name}
            />
            <Stack spacing={0}>
              <Text flex="1">{admin.organizational_unit.user.name}</Text>
              <Text flex="1" fontSize="xs" color={'gray.400'}>
                {admin.organizational_unit.user.email}
              </Text>
            </Stack>
            <Spacer />
            {!isValidating ? (
              <Checkbox
                isDisabled={loading}
                isChecked={admin.notify_new_external_booking}
                onChange={(e) =>
                  toggleReceivesNotifications(admin, e.target.checked)
                }
              ></Checkbox>
            ) : (
              <Spinner />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
