import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { addDays, addMinutes, isWithinInterval } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { MeetingRoomTimeLineMobileCard } from '../../../../components/MeetingRoomTimeLineMobileCard'

import { useBookingContext } from '../../../../context/BookingContext'
import { useMobileEmbed } from '../../../../context/mobile-embed-context'
import { CenteredSpinner } from '../../../../styled/CenteredSpinner'
import { useRole } from '../../../../utils/rbac'
import { DatePicker } from './DatePicker'
import { DurationPicker } from './DurationPicker'
import { LocationPicker } from './LocationPicker'

export const MeetingRoomsMobile: React.FC = () => {
  const {
    chosenStartDateTime,
    loading,
    chosenDate,
    handleChangeDate,
    setChosenLocation,
    chosenLocation,
    meetingRooms,
    locations
  } = useBookingContext()
  const { isExternalMember } = useRole()

  const durationOptions = [15, 30, 60, 90, 120, 150, 180]

  const { isMobileEmbed } = useMobileEmbed()

  // //generate the next 7 days
  const [dateOptions, setDateOptions] = useState<Date[]>([])
  useEffect(() => {
    //populate dates on first load, then only repopulate if the calendar is used to set a date outside the current range
    if (
      dateOptions.length === 0 ||
      !isWithinInterval(chosenDate, {
        start: dateOptions[0],
        end: addDays(dateOptions[6], 1)
      })
    )
      setDateOptions([
        chosenDate,
        addDays(chosenDate, 1),
        addDays(chosenDate, 2),
        addDays(chosenDate, 3),
        addDays(chosenDate, 4),
        addDays(chosenDate, 5),
        addDays(chosenDate, 6)
      ])
  }, [chosenDate, dateOptions])
  const bookingConfirmed = (status: boolean, data: any) => {}
  return (
    <Box mt={4} px="20px" overflow="scroll">
      <SimpleGrid columns={1} spacing="24px">
        {!isMobileEmbed && (
          <Text fontWeight="bold" fontSize="18px" color="#303B40">
            Meeting rooms
          </Text>
        )}
        <LocationPicker
          locationOptions={locations}
          selected={chosenLocation}
          setSelected={setChosenLocation}
        />
        <DatePicker
          dateOptions={dateOptions}
          selected={chosenDate}
          handleSelected={handleChangeDate}
        />

        {/* <DurationPicker
          durationOptions={durationOptions.filter(
            (duration) =>
              duration === 15 ||
              isSelectableTime(addMinutes(chosenStartDateTime, duration))
          )}
          selected={selectedDuration}
          setSelected={setSelectedDuration}
        /> */}
        {/* <CapacityPicker
          capacityOptions={capacityOptions}
          selected={selectedCapacity}
          setSelected={setSelectedCapacity}
        /> */}
      </SimpleGrid>

      <Box bg="#F4F5F5" width="100%" h="100%">
        {loading === undefined && (
          <Flex
            pos="absolute"
            align="center"
            justify="center"
            h="100%"
            w="100%"
            overflow="hidden"
            opacity={0.5}
            bg="#f4f5f5"
            cursor=""
          >
            <Spinner size="xl" />
          </Flex>
        )}
        <Flex py={2}>
          {/* {loading && <Spinner />} */}
          <Box width="100%" mb={2}>
            {meetingRooms ? (
              meetingRooms.map((meetingRoom, i) => (
                <MeetingRoomTimeLineMobileCard
                  key={i}
                  meetingRoom={meetingRoom}
                  chosenDate={chosenDate}
                  bookingConfirmed={bookingConfirmed}
                  isExternal={isExternalMember}
                  businessHours={chosenLocation?.business_hours}
                />
              ))
            ) : (
              <CenteredSpinner />
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}
