import {
  Box,
  BoxProps,
  Grid,
  Heading,
  HeadingProps,
  Stack
} from '@chakra-ui/react'
import React from 'react'
import { useMedia } from '../../../hooks/useMedia'
import { IBasicDirectoryMemberResult, IBooking } from '../../../types'
import { BigNewsArticle } from './homePageDisplay/BigNewsArticle'
import { BillingOverview } from './homePageDisplay/BillingOverview'
import { ConnectionOverview } from './homePageDisplay/ConnectionOverview'
import { DirectoryConnectionsOverview } from './homePageDisplay/DirectoryConnectionsOverview'
import { MeetingRoomOverview } from './homePageDisplay/MeetingRoomOverview'
import { SmallNewsArticle } from './homePageDisplay/SmallNewsArticle'
import { IArticle, IInvoiceSummary } from './homePageDisplay/types'
import { WifiOverview } from './homePageDisplay/WifiOverview'
import { ReactNodeWithProps } from '../../../component.types'

const ContentSection: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Box as="section" {...rest}>
    {children}
  </Box>
)

const SectionHeading: React.FC<HeadingProps> = ({ children, ...rest }) => (
  <Heading size="sm" mb={2} {...rest}>
    {children}
  </Heading>
)

interface Props {
  notice?: React.ReactNode
  meetings: {
    bookedToday: IBooking[]
    creditsRemaining?: number
    daysUntilCreditsExpire?: number
    loading?: boolean
    hide?: boolean
  }
  articles: { content: IArticle[]; loading?: boolean }
  invoices: {
    overdue?: IInvoiceSummary[]
    nextInvoice?: IInvoiceSummary
    loading?: boolean
    hide?: boolean
  }
  wifi: {
    loading?: boolean
    wifiPassword?: string
    hide?: boolean
  }
  connection?: {
    otherUserName: string
    otherUserEmail: string
    message?: string
  }
  showMembersDirectory?: boolean
  directoryConnections?: IBasicDirectoryMemberResult[]
}
const ForYouLoadingPlaceholder: React.FC = () => {
  return (
    <>
      {Array.from(new Array(6).keys()).map((i) => (
        <SmallNewsArticle key={i} />
      ))}
    </>
  )
}

export const NoticeWrapper: React.FC<ReactNodeWithProps> = ({ children }) => (
  <Box
    mb={6}
    gridColumn={['inherit', '1 / 3']}
    p={3}
    borderWidth="1px"
    borderRadius="lg"
    background="#fffbc3"
    gridRow={-1}
  >
    {children}
  </Box>
)

export const HomePageDisplay: React.FC<Props> = ({
  articles,
  meetings,
  invoices,
  notice,
  wifi,
  showMembersDirectory,
  directoryConnections,
  connection
}) => {
  // hack to get the news article placeholders to be the right width on big screens while they are loading
  // if their width isn't fixed, the grid jumps in width as the stories are loaded
  const newsArticleWidth = useMedia(['(min-width: 1380px)'], ['724px'], 'auto')
  const bottomArticles = articles.content.slice(
    3,
    Math.min(articles.content.length - 1, 11)
  )

  return (
    <Box maxW="1110px" w="100%">
      {notice}
      <Grid
        gridTemplateColumns={['1fr', '2fr 1fr']}
        gridRowGap={6}
        gridColumnGap={[0, 6]}
      >
        <SectionHeading gridColumn="1 / 3" mb={-4}>
          Top Stories
        </SectionHeading>
        <Stack spacing={3}>
          <ContentSection paddingBottom="30px">
            <Grid templateColumns={['1f', '1f', '1fr 1fr']} gap={6}>
              <BigNewsArticle
                w={newsArticleWidth}
                gridColumn={['1 / 2', '1 / 2', '1 / 3']}
                h={['278px', '418px']}
                article={articles.content[0]}
              />
              <BigNewsArticle
                article={articles.content[1]}
                h={['170px', '278px']}
              />
              <BigNewsArticle
                article={articles.content[2]}
                h={['170px', '278px']}
              />
            </Grid>
          </ContentSection>
          <ContentSection>
            <SectionHeading>For You</SectionHeading>

            <Grid
              templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
              templateRows="repeat(5, 84px)"
              gap={6}
            >
              {articles.loading ? (
                <ForYouLoadingPlaceholder />
              ) : (
                bottomArticles.map((article, i) => (
                  <SmallNewsArticle key={i} article={article} />
                ))
              )}
            </Grid>
          </ContentSection>
        </Stack>
        <Stack spacing={6} gridRow={[1, 2]} gridColumn={[1, 2]}>
          {!meetings.hide && (
            <MeetingRoomOverview
              loading={meetings.loading}
              meetingsToday={meetings.bookedToday}
              creditsRemaining={meetings.creditsRemaining}
              daysUntilCreditsExpire={meetings.daysUntilCreditsExpire}
            />
          )}
          {!invoices.hide && (
            <BillingOverview
              overdue={invoices.overdue}
              nextInvoice={invoices.nextInvoice}
              loading={invoices.loading}
            />
          )}
          {!wifi.hide && wifi.wifiPassword && (
            <WifiOverview
              loading={wifi.loading}
              wifiPassword={wifi.wifiPassword}
            />
          )}
          {connection && <ConnectionOverview connection={connection} />}
          {showMembersDirectory && (
            <DirectoryConnectionsOverview connections={directoryConnections} />
          )}
        </Stack>
      </Grid>
    </Box>
  )
}
