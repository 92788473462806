import { Flex, BoxProps, Text } from '@chakra-ui/react'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useCustomToast } from '../context/toast-context'

interface Props extends BoxProps {
  label: string
  url: string
}

export const CopyURLComponent: React.FC<Props> = ({ label, url, ...rest }) => {
  const toast = useCustomToast()

  const copyLink = () => {
    try {
      navigator.clipboard.writeText(url)
      toast.newToast({
        description: 'Link copied!',
        status: 'success',
        duration: 2000
      })
    } catch (error) {}
  }

  return (
    <Flex
      bg="white"
      {...rest}
      onClick={copyLink}
      style={{
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 2,
        cursor: 'pointer',
        alignItems: 'center'
      }}
      _hover={{
        background: 'gray.50'
      }}
    >
      <Text fontSize={'sm'} mr="10px" color={'gray.600'}>
        {label}
      </Text>
      <FontAwesomeIcon icon={faCopy} />
    </Flex>
  )
}
