import { Box, Container, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserDetails } from '../../context/user-onboard-context'
import {
  FERN_ALLENDALE_PLAN_UUID,
  ILUMA_PLAN_UUID
} from '../../utils/constants'
import { OnboardingEmail } from './OnboardingEmail'
import { OnboardingStartDate } from './OnboardingStartDate'
import { OnboardingAcceptTC } from './OnboardingAcceptTC'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { css } from '@emotion/react'
import { format } from 'date-fns'
import { Steps } from 'antd'

const STEP_EMAIL = 0
const STEP_DATE = 1
const STEP_PASSWORD = 2

export const OnboardingShortForm: React.FC = () => {
  const { userDetails } = useUserDetails()
  const { platformTheme } = usePlatformTenant()
  const { Step } = Steps
  const [, setHasCompletedDate] = useState(false)
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false)

  const [step, setStep] = useState(STEP_EMAIL)

  const navigate = useNavigate()
  const isAllendalePlan = FERN_ALLENDALE_PLAN_UUID === userDetails.plan
  const isIlumaPlan = ILUMA_PLAN_UUID === userDetails.plan
  const toast = useToast()

  const steps = [
    {
      key: 'personal',
      title: 'Personal Info'
    },
    {
      key: 'start',
      title: 'Start Date'
    },
    {
      key: 'finish',
      title: 'Confirmation'
    }
  ]

  useEffect(() => {
    if (!userDetails.plan) {
      toast({
        title: 'Error',
        description: 'Please, go back and select a valid plan / membership.',
        status: 'error',
        duration: 8000,
        isClosable: true
      })
      navigate(-1)
    }
  }, [])

  const buttonStyles = css`
    border: 1px solid ${platformTheme?.brand_primary_color};
  `

  return (
    <Container
      centerContent
      maxW="3xl"
      boxShadow="2xl"
      borderRadius="xl"
      className="onboarding-short-form"
      p={2}
      py={2}
    >
      <Container centerContent maxW="3xl" my={2}>
        {steps && (
          <Steps
            current={step}
            size="small"
            progressDot={true}
            style={{
              marginBottom: 18,
              paddingBottom: 18,
              boxShadow: '0px -1px 0 0 #e8e8e8 inset'
            }}
          >
            {steps
              .filter((s) => {
                return isIlumaPlan || isAllendalePlan ? s.key !== 'start' : true
              })
              .map((item, index) => (
                <Step
                  key={item.title}
                  title={item.title}
                  onClick={() => {
                    setStep(index)
                  }}
                  description={
                    item.key === 'start'
                      ? userDetails?.startDate
                        ? format(userDetails.startDate, 'PP')
                        : ''
                      : index === STEP_EMAIL
                      ? userDetails?.email
                      : ''
                  }
                ></Step>
              ))}
          </Steps>
        )}
      </Container>
      {step === STEP_EMAIL && (
        <Box>
          <OnboardingEmail
            onCompleteCallback={() => {
              setHasCompletedProfile(true)
              setStep(
                isIlumaPlan || isAllendalePlan ? STEP_PASSWORD : STEP_DATE
              )
            }}
            setIsActive={() => {}}
            isActive={step === STEP_EMAIL}
            isComplete={hasCompletedProfile}
            setComplete={(complete: boolean) => {
              setHasCompletedProfile(complete)
            }}
          />
        </Box>
      )}
      {step === STEP_DATE && !isIlumaPlan && !isAllendalePlan && (
        <Box>
          <OnboardingStartDate
            onCompleteCallback={() => {
              setHasCompletedDate(true)
              setStep(STEP_PASSWORD)
            }}
            setIsActive={() => {}}
            isActive={step === STEP_DATE}
            isComplete={hasCompletedProfile}
          />
        </Box>
      )}
      {step === STEP_PASSWORD && (
        <Box>
          <OnboardingAcceptTC
            onCompleteCallback={() => {}}
            setIsActive={() => {}}
            isActive={step === STEP_PASSWORD}
            isComplete={hasCompletedProfile}
          />
        </Box>
      )}
    </Container>
  )
}
