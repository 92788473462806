import { FormControl, FormLabel, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Box } from '@chakra-ui/react'
import { updatePlatformTenant } from '../../api'

import { IPlatformTenantUpdate } from '../../types'
import { routeStrings } from '../../routeStrings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { useNavigate } from 'react-router-dom'
import { StyledInput } from '../../components/StyledInputs'
import { ErrorMessage, Form, Formik } from 'formik'
import { InputWarning } from '../../styled/InputWarning'
import { Button } from '../../components/Button'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'

export const Onboarding1BusinessSettings: React.FC = () => {
  const navigate = useNavigate()
  const { platformTenant, mutatePlatformTenant } = usePlatformTenant()

  return (
    <OnboardingWrapper>
      {' '}
      {platformTenant ? (
        <Box p={5} pl={10} w="500px">
          <Box mt={6} maxW={'600px'}>
            <Text className="teams-complete" fontSize="20px">
              <strong>{platformTenant?.business_name}</strong>
              <br />
              Business details
            </Text>
            <br />
            <Stack isInline></Stack>
            <Formik
              initialValues={{
                id: platformTenant?.id,
                abn: platformTenant?.abn,
                address: platformTenant?.address
              }}
              validate={(values: IPlatformTenantUpdate) => {
                const errors: any = {}
                if (!values.abn) {
                  errors.abn = 'ABN / ACN is required'
                }
                if (!values.address) {
                  errors.address = 'Address is required'
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                updatePlatformTenant(values, platformTenant?.id || 0)
                  .then((data) => {
                    mutatePlatformTenant()
                    setSubmitting(false)
                    navigate(routeStrings.onboardingTenantIntegrations)
                  })
                  .catch((err) => {
                    setSubmitting(false)
                    console.log(err)
                  })
              }}
            >
              {({ values, setFieldValue, isSubmitting, touched, errors }) => (
                <Form>
                  <FormControl isRequired>
                    <FormLabel>ABN / ACN</FormLabel>
                    <StyledInput
                      placeholder="ABN / ACN"
                      maxLength={100}
                      value={values.abn || ''}
                      onChange={(e) => {
                        setFieldValue('abn', e.target.value)
                      }}
                      w="100%"
                      data-testid="tenant-setup-abn"
                    ></StyledInput>
                    <ErrorMessage name="abn" component={InputWarning} />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Main Address</FormLabel>
                    <StyledInput
                      placeholder="Business Address"
                      maxLength={100}
                      value={values.address || ''}
                      onChange={(e) => {
                        setFieldValue('address', e.target.value)
                      }}
                      w="100%"
                      data-testid="tenant-setup-address"
                    ></StyledInput>
                    <ErrorMessage name="address" component={InputWarning} />
                  </FormControl>
                  <br></br>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    data-testid="tenant-setup-button"
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      ) : (
        <CenteredSpinner />
      )}
    </OnboardingWrapper>
  )
}
