import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Heading, Image, useTheme } from '@chakra-ui/react'

import { BackButton } from '../../components/BackButton'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { useUserDetails } from '../../context/user-onboard-context'
import { OnboardingUser } from '../../interfaces'
import { routeStrings } from '../../routeStrings'

export const Onboarding4Title: React.FC = ({}: any) => {
  const { setUserDetails, userDetails } = useUserDetails()
  const navigate = useNavigate()
  const theme: any = useTheme()
  return (
    <Formik
      initialValues={{
        jobTitle: userDetails.jobTitle,
        company: userDetails.company
      }}
      validate={(values): OnboardingUser => {
        const errors: any = {}
        if (!values.jobTitle) {
          errors.jobTitle = 'Required'
        }
        if (userDetails.is_team_admin && !values.company) {
          errors.company = 'Required'
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setUserDetails((currentValues: any) => ({
          ...currentValues,
          ...values
        }))
        navigate(routeStrings.onboarding5SkillsAndBio)
      }}
    >
      {({ isSubmitting }) => (
        <Box p={3}>
          <Image
            src={theme.logos.logo_long}
            width="128px"
            className="location-logo"
          />
          <Box mt={6} bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
            <Heading size="xs" color="gray.400" my={6}>
              Step 4 of 6
            </Heading>
            <Box py={3}>
              <Heading size="sm" mb={2}>
                Job Title
              </Heading>
            </Box>

            <Form>
              <Field
                type="text"
                name="jobTitle"
                data-testid="complete-jobTitle-field"
                placeholder="Enter your job title"
                label="Job Title"
                component={FormInput}
              />
              <ErrorMessage name="jobTitle" component="div" />
              {userDetails.is_team_admin && (
                <>
                  <Field
                    type="text"
                    name="company"
                    data-testid="complete-company-field"
                    placeholder={
                      userDetails.is_new_tenant_invite
                        ? "Enter your team's name"
                        : 'Enter your company'
                    }
                    label={
                      userDetails.is_new_tenant_invite
                        ? "Team's Name"
                        : 'Company'
                    }
                    component={FormInput}
                    // required for users to be able to see members dashboard
                    required
                  />
                  <ErrorMessage name="company" component="div" />
                </>
              )}
              <br />
              <Button
                ml="auto"
                type="submit"
                data-testid="onboarding-next"
                style={{ float: 'right', display: 'inline-block' }}
                disabled={isSubmitting}
              >
                Next
              </Button>
              <BackButton>Back</BackButton>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
