import { Box, Button, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Table, TableDataProp } from '../../../components/table/Table'
import { TableRow } from '../../../components/table/TableRow'
import { useAuth } from '../../../context/auth-context'
import { IAddonSubscription } from '../../../types'
import { useOrganization } from '../../../utils/apiHooks'
import { AddIcon } from '@chakra-ui/icons'

function RenderMemberWithSubscriptionRow(handleAddSubscription: () => void) {
  return (
    <TableRow padding={0} key={-1} minHeight={0}>
      <Button
        width="100%"
        padding="8px"
        minHeight={25}
        colorScheme="blue"
        variant="outline"
        leftIcon={<AddIcon />}
        opacity={0.8}
        onClick={handleAddSubscription}
        data-testid="addons"
      >
        Add new subscription
      </Button>
    </TableRow>
  )
}

interface Props {
  title: string
  loading: boolean
  handleActionAddSubscription?: () => void
  handleActionCancelSubscription?: (
    subscription_manager: IAddonSubscription
  ) => void
  orgId?: number
}

export const TeamSubscriptions: React.FC<Props> = (props) => {
  const { loading, orgId, handleActionCancelSubscription } = props

  const [editSubscription, setEditSubscription] = useState<IAddonSubscription>()

  const auth = useAuth()
  const { mutate: revalidateOrg, data: organization } = useOrganization(orgId)
  const addons = organization?.addon_plans

  const subscriptionActions = [
    {
      text: 'Cancel subscription',
      testIdPrefix: 'cancel-subscription',
      action: (rowId) => {
        const addon = addons?.find((addon) => addon.id === rowId)
        if (addon) {
          setEditSubscription(addon)
          handleActionCancelSubscription &&
            handleActionCancelSubscription(addon)
        }
      }
    }
  ]

  const tableHeaders = [
    { header: 'id', accessor: 'id', show: false },
    { header: 'Plan Name', accessor: 'plan_name', show: true, width: 5 / 12 },
    {
      header: 'Meeting Credits',
      accessor: 'meeting_credits',
      show: true,
      width: 3 / 12
    },
    { header: 'Monthly Rate', accessor: 'rate', show: true, width: 4 / 12 },
    { header: '', accessor: 'actions', show: true, width: 2 / 12 }
  ]

  const tableData: any =
    addons
      ?.filter((addon) => addon.plan)
      .map((addon) => {
        const cleanedData = {
          id: TableDataProp(addon.id),
          plan_name: TableDataProp(addon.plan?.name),
          meeting_credits: TableDataProp(
            addon.plan?.meeting_room_credits_included ?? 0
          ),
          rate: TableDataProp(
            addon.override_value
              ? `$${addon.override_value}`
              : `$${addon.plan?.rate?.value}`
          ),
          actions: TableDataProp(subscriptionActions)
        }
        return cleanedData
      }) || []

  if (props.handleActionAddSubscription) {
    tableData.push(
      RenderMemberWithSubscriptionRow(props.handleActionAddSubscription)
    )
  }

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box display="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {props.title}
          </Text>
          <Text fontSize="xs" color="grey">
            {addons ? addons.length : 0} Total
          </Text>
        </Box>
        <Table headers={tableHeaders} data={tableData} loading={loading} />
      </Box>
    </div>
  )
}
