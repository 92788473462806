import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Text
} from '@chakra-ui/react'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

export interface ISideBarMenuItem {
  label: string
  icon: any
  id: string
  component?: any
  active: boolean

  menu?: Array<ISideBarMenuItem>
}

export interface Props {
  title?: string
  sideBarMenu: ISideBarMenuItem[]
  hideSectionTitle?: boolean
}

export const SideBarMenuComponent: React.FC<Props> = (props) => {
  const { sideBarMenu, title, hideSectionTitle } = props
  const [selectedTab, setSelectedTab] = useState<ISideBarMenuItem>()

  useEffect(() => {
    sideBarMenu.length > 0 && setSelectedTab(sideBarMenu[0])
  }, [])
  return (
    <Box>
      <Box display="flex" alignItems="baseline" mb={2}>
        {title && (
          <Text fontWeight="bold" fontSize="lg">
            {title}
          </Text>
        )}
      </Box>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem rowSpan={2} colSpan={1}>
          <Box>
            {sideBarMenu.map((mi, index) => {
              // return SideMenuItem(mi, selectedTab, setSelectedTab)
              return (
                <SideMenuItem
                  mi={mi}
                  key={index}
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              )
            })}
          </Box>
        </GridItem>
        <GridItem rowSpan={2} colSpan={4} rounded="md">
          {selectedTab && (
            <>
              {!hideSectionTitle && (
                <Box>
                  <Heading>{selectedTab.label}</Heading>
                </Box>
              )}
              <Box mt={6}>{selectedTab.component}</Box>
            </>
          )}
        </GridItem>
      </Grid>
    </Box>
  )
}

const SideMenuItem: React.FC<{
  mi: ISideBarMenuItem
  selectedTab?: ISideBarMenuItem
  setSelectedTab: (tab: ISideBarMenuItem) => void
}> = (props) => {
  const { mi, selectedTab, setSelectedTab } = props
  const isMenu = mi.menu != undefined
  return (
    <Box key={mi.id}>
      <Flex
        py={3}
        px={2}
        cursor="pointer"
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: 'gray.50'
        }}
        onClick={() => {
          setSelectedTab(mi)
        }}
        bg={selectedTab && selectedTab.id === mi.id ? 'gray.50' : 'white'}
      >
        <Box>
          <FontAwesomeIcon icon={mi.icon} />
        </Box>
        <Text mx={3}>{mi.label}</Text>
        <Spacer />
        {isMenu && <FontAwesomeIcon icon={faCaretDown} />}
      </Flex>
      {isMenu &&
        mi.menu?.map((i) => {
          return (
            <Flex
              py={3}
              pl={5}
              cursor="pointer"
              rounded={'md'}
              _hover={{
                textDecoration: 'none',
                bg: 'gray.50'
              }}
              onClick={() => {
                setSelectedTab(i)
              }}
              bg={selectedTab && selectedTab.id === i.id ? 'gray.50' : 'white'}
            >
              <Box>
                <FontAwesomeIcon size={'1x'} icon={i.icon} />
              </Box>
              <Text mx={2}>{i.label}</Text>
            </Flex>
          )
        })}
    </Box>
  )
}
