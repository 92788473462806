import {
  Box,
  BoxProps,
  Image,
  Stack,
  Text,
  HStack,
  Flex
} from '@chakra-ui/react'
import { faTv, faUsers, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { IMeetingRoom, IMeetingRoomSuperAdmin } from '../types'

interface Props extends BoxProps {
  meetingRoom: IMeetingRoom | IMeetingRoomSuperAdmin
  unavailable?: boolean
  isMobile?: boolean
}

export const MeetingRoomSmallCard: React.FC<Props> = ({
  meetingRoom,
  unavailable,
  isMobile,
  ...rest
}) => {
  const placeholder = require('../assets/placeholder.svg')

  return (
    <Box
      data-testid={`meeting-room-${meetingRoom.id}`}
      opacity={unavailable ? 0.4 : 1}
      cursor={unavailable ? 'not-allowed' : 'auto'}
      {...rest}
      display={{ md: 'flex' }}
    >
      <Box>
        <Image
          src={meetingRoom.display_url}
          w={isMobile ? '100%' : '200px'}
          h={isMobile ? '80px' : '80%'}
          objectFit="cover"
          fallbackSrc={placeholder.default}
          borderRadius={isMobile ? 'xs' : 'lg'}
          borderTopRadius={isMobile ? '3xl' : 'lg'}
        />
      </Box>
      <Stack pl={[1, 6]} mb={[8, 0]}>
        {meetingRoom.private_room && (
          <Text
            fontWeight={600}
            color={'#bbb'}
            fontSize={[8, 12]}
            lineHeight="10px"
            m={0}
            py={0}
          >
            Private Room
          </Text>
        )}
        <Text fontWeight={600} fontSize={['xl', 'md', 'lg', 'xl']} my={2}>
          {meetingRoom.name}
        </Text>
        {meetingRoom?.capacity && (
          <Text fontSize={'xs'}>
            <FontAwesomeIcon icon={faUsers} /> {meetingRoom.capacity} persons
          </Text>
        )}
        {meetingRoom?.has_videoconference && (
          <Text fontSize={'xs'}>
            <FontAwesomeIcon icon={faVideo} /> Video conference
          </Text>
        )}
        {meetingRoom?.has_screen_mirroring && (
          <Text fontSize={'xs'}>
            <FontAwesomeIcon icon={faTv} /> Screen Mirroring
          </Text>
        )}
      </Stack>
    </Box>
  )
}
