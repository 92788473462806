import { theme as baseTheme, Theme } from '@chakra-ui/react'

import { Breakpoints } from '@chakra-ui/theme-tools'
export interface ITheme {
  colors: {
    primary: string
    // brandPrimary: string
    brandBlue: string
    brandGreen: string
    buttonPrimary: string
    eastBay01: string
    eastBay02: string
    eastBay04: string
    eastBay05: string
    eastBay06: string
    eastBay07: string
    eastBay09: string
    eastBay10: string
    textPrimary: string
    headingPrimary: string
    contentBackgroundMain: string
    link: string
    alertSuccess: string
  }
  containerMaxWidths: string[]
  containerPercentageWidths: string[]
  // fontSizes: string[],
  breakpoints: Breakpoints<{
    sm: string
    md: string
    lg: string
    xl: string
  }>
  radii: {
    none: string
    sm: string
    md: string
    lg: string
    full: string
  }

  // buttons: {
  //   primary: {
  //     height: string
  //     color: string
  //     bg: string
  //     fontWeight: string
  //     borderRadius: string
  //     transition: string
  //     ':hover': {
  //       cursor: string
  //       transform: string
  //       boxShadow: string
  //     }
  //   }
  //   secondary: {
  //     height: string
  //     color: string
  //     bg: string
  //     fontWeight: string
  //     border: string
  //     borderColor: string
  //     borderRadius: string
  //     transition: string
  //     ':hover': {
  //       cursor: string
  //       transform: string
  //       boxShadow: string
  //       bg: string
  //     }
  //   }
  // }
}

export type MyTheme = ITheme & Theme

const theme: MyTheme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    // brandPrimary: '#F060B2',
    brandBlue: '#BBE5F5',
    brandGreen: '#D8E697',
    buttonPrimary: '#EB4969',
    eastBay01: '#E5E5E5',
    eastBay02: '#F5F5F5',
    eastBay04: '#A8A8A8',
    eastBay05: '#BDBDBD',
    eastBay06: '#9E9E9E',
    eastBay07: '#757575',
    eastBay09: '#424242;',
    eastBay10: '#212121',
    textPrimary: '#2F4858',
    headingPrimary: '#212121',
    contentBackgroundMain: '#F4F5F5',
    link: '#255CE1',
    alertSuccess: '#24A148',
    primary: '#EB4969'
  },
  breakpoints: {
    base: '0em',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1140px',
    '2xl': '1500px'
  },
  containerMaxWidths: ['570px', '760px', '960px', '1140px'], // First width value must be duplicatd for baseline
  containerPercentageWidths: ['955', '540px', '720px', '960px', '1140px'], // First width value must be duplicatd for baseline
  // fontSizes: ['12px', '14px', '16px', '20px', '24px', '32px', '48px', '64px'],
  radii: {
    ...baseTheme.radii,
    none: '0',

    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    full: '9999px'
  },
  fonts: {
    body: 'Open Sans, system-ui, sans-serif',
    heading: 'Open Sans, system-ui, sans-serif, inherit',
    mono: 'Open Sans, system-ui, sans-serif, Menlo, monospace'
  }
}

export default theme
