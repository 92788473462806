import { Box } from '@chakra-ui/react'
import { addDays, format, isThisWeek, isToday, isTomorrow } from 'date-fns'
import React from 'react'
import { MeetingRoomBookingGroup } from '../../../components/MeetingRoomBookingGroup'
import { IBooking } from '../../../types'

interface Props {
  bookings: IBooking[]
}

export const FutureBookings: React.FC<Props> = ({ bookings }) => {
  const todaysBookings = bookings.filter((booking) => {
    return isToday(new Date(Date.parse(booking.start_time.toString())))
  })

  const tomorrowsBookings = bookings.filter((booking) => {
    return isTomorrow(new Date(Date.parse(booking.start_time.toString())))
  })

  const thisWeeksBookings = bookings
    .filter((booking) => {
      return (
        todaysBookings.indexOf(booking) === -1 &&
        tomorrowsBookings.indexOf(booking) === -1
      )
    })
    .filter((booking) => {
      return isThisWeek(new Date(Date.parse(booking.start_time.toString())))
    })

  const allOthers = bookings.filter((booking) => {
    return (
      todaysBookings.indexOf(booking) === -1 &&
      tomorrowsBookings.indexOf(booking) === -1 &&
      thisWeeksBookings.indexOf(booking) === -1
    )
  })

  return (
    <Box bg="#F4F5F5" width="100%" height="100%">
      <MeetingRoomBookingGroup
        title={`Today ${format(new Date(), 'dd/MM/yyyy')}`}
        bookings={todaysBookings}
      />
      <MeetingRoomBookingGroup
        title={`Tomorrow ${format(addDays(new Date(), 1), 'dd/MM/yyyy')}`}
        bookings={tomorrowsBookings}
      />
      <MeetingRoomBookingGroup
        title={`This Week`}
        bookings={thisWeeksBookings}
      />
      <MeetingRoomBookingGroup title={`Future`} bookings={allOthers} />
    </Box>
  )
}
