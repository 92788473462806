import { Checkbox, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BaseModal } from '../../../../components/modals/BaseModal'
import { IOrganizationSeat } from '../../../../types'
import { format } from 'date-fns'
import { requestPlanCancellation } from '../../../../api'

interface IModalInput {
  seat?: IOrganizationSeat
  cancellationDate: Date
  closeModalCallback: () => void
}

export const ConfirmCancellationModal: React.FC<IModalInput> = (input) => {
  const { seat, cancellationDate, closeModalCallback } = input
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isNotifyAdmins, setIsNotifyAdmins] = useState(true)

  const toast = useToast()

  const isFutureMembershipChange =
    !seat?.active_plan && seat?.ongoing_plan !== undefined

  function closeModal() {
    closeModalCallback()
  }

  function requestCancellation() {
    if (isSubmitting) return
    setIsSubmitting(true)
    if (seat && cancellationDate) {
      requestPlanCancellation(seat.id, cancellationDate, isNotifyAdmins)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Plan cancellation request sent`
          })
          closeModal()
        })
        .catch(() => {
          setIsSubmitting(false)

          toast({
            status: 'error',
            description: `Failed to send cancellation request`
          })
        })
    }
  }
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNotifyAdmins(e.target.checked)
  }

  if (!!!seat) {
    return null
  }
  return (
    <BaseModal
      title="Confirm Cancellation"
      isOpen={seat !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={'Confirm'}
      primaryAction={requestCancellation}
      secondaryAction={closeModal}
      isSubmitting={isSubmitting}
      isLoading={isSubmitting}
    >
      {!isFutureMembershipChange && (
        <Text>
          Are you sure you want to cancel your membership on{' '}
          {format(cancellationDate, 'dd/MM/yyyy')}?
        </Text>
      )}
      {isFutureMembershipChange && (
        <Stack spacing={4} fontSize={'sm'}>
          <Text>
            Are you sure you want to completely cancel this membership?
          </Text>

          <Checkbox
            size={'sm'}
            isChecked={isNotifyAdmins}
            onChange={onCheckboxChange}
          >
            <Text fontSize={'sm'}>
              Notify the team's admins about this cancellation
            </Text>
          </Checkbox>
        </Stack>
      )}
    </BaseModal>
  )
}
