import { ExternalLinkIcon, TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  Stack,
  Flex,
  Wrap,
  Tag,
  Divider,
  TagLabel,
  Spacer,
  Heading,
  Link,
  useTheme
} from '@chakra-ui/react'
import { faMapPin } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import React from 'react'
import { UserDisplay } from '../../../components/UserDisplay'
import { useAuth } from '../../../context/auth-context'
import { useCommunity } from '../../../context/community-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IDailyFiveContent } from '../../../types'
import { analyticsTrack } from '../../../utils/analytics'
import { SaveButton } from '../../DailyFive/SaveButton'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  content?: IDailyFiveContent
  onAddToCalendar: () => void
}
export const ViewEventModal: React.FC<IModalInput> = ({
  isOpen,
  content,
  onClose,
  onAddToCalendar
}) => {
  const toast = useToast()
  const theme = useTheme()
  const { goToContent } = useCommunity()
  const { me, currentOrganization } = useAuth()
  const { platformTenant } = usePlatformTenant()

  const TimeDisplay = (
    <Flex alignContent="top" mr={[0, '50px']}>
      <Stack spacing="4px">
        <Text color="#333333" fontSize="s" fontWeight="bold">
          <TimeIcon marginRight="5px" />
          {content?.date
            ? format(Date.parse(content.date!), 'EEEE, dd/MM/yyyy')
            : 'Time'}
        </Text>
        <Text color="#333333" fontSize="s">
          {`${
            content?.start_time
              ? format(
                  Date.parse(`2020-04-23T${content?.start_time}`),
                  'h:mm aa'
                )
              : ''
          }${
            content?.finish_time
              ? ' - ' +
                format(
                  Date.parse(`2020-04-23T${content?.finish_time}`),
                  'h:mm	aa'
                )
              : ''
          }`}
        </Text>
      </Stack>
    </Flex>
  )
  const LocationDisplay = content?.location && (
    <Flex alignContent="top" mr={[0, '50px']}>
      <Stack spacing="4px">
        <Text color="#333333" fontWeight="bold">
          <FontAwesomeIcon icon={faMapPin} />
          {'    '}
          Location
        </Text>
        <Text color="#333333">{content?.location}</Text>
      </Stack>
    </Flex>
  )

  const ZoomLinkDisplay = (
    <Flex alignContent="top" mr={[0, '50px']}>
      <ExternalLinkIcon marginRight="20px" />
      <Stack spacing="4px">
        <Link
          href={content?.zoom_meeting_url}
          color="#2F80ED"
          fontSize="s"
          fontWeight="bold"
        >
          Join Zoom Meeting
        </Link>
        <Text color="#333333" fontSize="s">
          Password: {content?.zoom_meeting_password}
        </Text>
      </Stack>
    </Flex>
  )

  const URLDisplay = content && content.url && (
    <Flex alignContent="top" mr={[0, '50px']}>
      <Link
        isExternal
        href={
          content?.url.startsWith('http') || content?.url.startsWith('mailto:')
            ? content?.url
            : '//' + content?.url
        }
        color={theme.colors.brandPrimary}
        fontSize="s"
        fontWeight="bold"
        onClick={() => {
          const trackingData = {
            userId: me?.user.id,
            organization: currentOrganization?.id,
            tenant: platformTenant?.id,
            content: content.id
          }
          analyticsTrack('Calendar view open link', trackingData)
        }}
      >
        Open Event Link
      </Link>
    </Flex>
  )
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      {/* <ModalOverlay /> */}
      <ModalContent minW="450px" borderRadius="md">
        <ModalHeader
          pb={2}
          pl={[2, 10]}
          onClick={() => goToContent(content)}
          cursor={'pointer'}
          _hover={{ color: theme.colors.brandPrimary }}
        >
          {content?.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w="100%" h="100%" flexShrink={0} px={4}>
            <Flex w="60%" mr={2}>
              <Stack w="100%">
                <Box
                  cursor="pointer"
                  w="100%"
                  h="210px"
                  background={
                    content?.image ? `url("${content?.image}")` : '#dadada'
                  }
                  backgroundSize="cover"
                  backgroundPosition="center"
                ></Box>
                <Stack marginTop="32px" flexShrink={0}>
                  <Wrap cursor={'pointer'} maxWidth={'inherit'}>
                    {content &&
                      content.interests &&
                      content.interests.map((interest, i) => (
                        <Tag key={i} size={'sm'} variant="outline">
                          <TagLabel>{interest.name}</TagLabel>
                        </Tag>
                      ))}
                  </Wrap>
                  <Text fontSize="xs" color="#000000" fontWeight="bold">
                    Posted By
                  </Text>
                  <Flex align="center">
                    <UserDisplay
                      name={content?.author}
                      src={content?.user_profile_image}
                      size="xs"
                      marginRight="8px"
                    />
                    <Text fontSize="xs" color="#4F4F4F" fontWeight="bold">
                      {content?.author}
                    </Text>
                  </Flex>
                </Stack>
              </Stack>
            </Flex>
            <Stack p={2} spacing={4}>
              <Heading size="md" mb={2}>
                Event Details
              </Heading>
              {TimeDisplay}
              {LocationDisplay}
              {content?.zoom_meeting_url && ZoomLinkDisplay}
              {content?.url && URLDisplay}
            </Stack>
          </Flex>
          <Box w="100%" px={[2, 4]} mt={2}>
            <Text fontSize="xs" fontWeight="bold">
              {content?.summary && 'Description'}
            </Text>
            {content?.summary && (
              <Text
                whiteSpace="pre-line"
                dangerouslySetInnerHTML={{ __html: content.summary }}
              ></Text>
            )}
          </Box>
          <ModalFooter>
            <Flex
              flexShrink={0}
              h="40px"
              w="40px"
              borderRadius="20px"
              background="#FFFFFF"
              justify="center"
              align="center"
            >
              <SaveButton contentObject={content} />
            </Flex>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
