import {
  Box,
  ButtonGroup,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Button } from '../../../../components/Button'
import { useAuth } from '../../../../context/auth-context'
import { DirectoryOrganizationalUnitResult } from '../../../../types'

interface Props {
  isSubmitting: boolean
  isOpen: boolean
  selectedOu: DirectoryOrganizationalUnitResult | undefined
  handleCreateConnection: (message: string) => void
  closeModal: () => void
}

export const MemberConnectionModal: React.FC<Props> = (props) => {
  const {
    isSubmitting,
    isOpen,
    closeModal,
    selectedOu,
    handleCreateConnection
  } = props

  const { currentOrgUnit } = useAuth()

  const [connectionMessage, setConnectionMessage] = useState<string>()

  if (!currentOrgUnit || !selectedOu) return null

  async function submit() {
    // if the message box was edited but is now blank, just set it to be undefined
    const message = connectionMessage ?? ''
    handleCreateConnection(message)
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a connection</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={2}>
          <Stack spacing={3}>
            <Text>
              Connecting with <b>{selectedOu.user.name}</b>{' '}
            </Text>
            <Box>
              <Text fontWeight="bold" mb={1}>
                Enter a message to send to the member:
              </Text>
              <Textarea
                maxLength={300}
                placeholder="Connection message (optional)"
                onChange={(e: any) => setConnectionMessage(e.target.value)}
              />
              <Text fontSize={'xx-small'} color="#777" my={1}>
                Max 300 characters
              </Text>
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={submit} disabled={isSubmitting}>
              Connect
            </Button>
            <Button
              variant="secondary"
              disabled={isSubmitting}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
