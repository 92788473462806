import React from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'
import { useMedia } from '../hooks/useMedia'

export const useAdminBodyWidth = () =>
  useMedia(['(min-width: 1346px)'], ['1140px'], '100%')

interface Props extends FlexProps {
  disabled?: boolean
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void

  onBottomReached?: () => void
  children?: React.ReactNode
}

export const AdminBodyWrapper: React.FC<Props> = (props) => {
  const { disabled, onScroll, onBottomReached, ...rest } = props

  const width = useAdminBodyWidth()

  return (
    <Flex
      width="100%"
      bg="contentBackgroundMain"
      justify="center"
      align="start"
      overflowY="auto"
      overflowX="auto"
      data-testid="admin-body-wrapper"
      pb={16}
      flex={1}
      onScroll={(event) => {
        if (onBottomReached) {
          let element = event.target as HTMLElement
          if (
            element.scrollHeight - element.scrollTop <
            element.clientHeight * 1.2
          ) {
            onBottomReached()
          }
        }
        onScroll && onScroll(event)
      }}
    >
      <Flex
        width={width}
        justify="start"
        direction="column"
        alignSelf="bottom"
        alignItems="bottom"
        mt={10}
        mx={3}
        {...rest}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}
