import React, { useEffect, useState } from 'react'
import { Box, Flex, Text, Spacer } from '@chakra-ui/react'

import ReactQuill from 'react-quill'
import { Button } from './Button'
interface FormInputProps {
  initialValue: string | undefined
  onSaveText: (value: string) => void
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  isSubmitting: boolean
}

export const EditableHTMLTextArea: React.FC<FormInputProps> = ({
  initialValue,
  onSaveText,
  isOpen,
  onOpen,
  onClose,
  isSubmitting,
  ...rest
}) => {
  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const [valueText, setValueText] = useState<string>('')

  useEffect(() => {
    if (initialValue) {
      setValueText(initialValue)
    }
  }, [initialValue])

  const handleSave = () => {
    onSaveText(valueText)
  }

  if (!isOpen) {
    return (
      <Box my={1} p={2} borderWidth={1} borderColor={'gray.400'}>
        <Flex w="100%" flexShrink={0} p={2}>
          <Text
            maxW={'90%'}
            wordBreak="break-word"
            whiteSpace="pre-line"
            dangerouslySetInnerHTML={{ __html: initialValue ?? '' }}
          ></Text>
          <Spacer />
          <Button
            mr={3}
            disabled={isSubmitting}
            loadingText={'Updating'}
            isLoading={isSubmitting}
            onClick={onOpen}
            size={'sm'}
            variant="secondary"
          >
            Edit
          </Button>
        </Flex>
      </Box>
    )
  }

  return (
    <Box>
      <ReactQuill
        theme="snow"
        onChange={(e) => {
          setValueText(e)
        }}
        modules={HTMLEditorProps.modules}
        value={valueText}
        formats={HTMLEditorProps.formats}
        placeholder={'Add a message!'}
      />
      <Flex my={2}>
        <Spacer />
        <Button mr={3} variant="secondary" onClick={onClose} size={'xs'}>
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={handleSave}
        >
          Save changes
        </Button>
      </Flex>
    </Box>
  )
}

const HTMLEditorProps = {
  modules: {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  },

  formats: [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ]
}
