import {
  Box,
  Flex,
  SimpleGrid,
  Stack,
  StackProps,
  Wrap
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useContentExplorer } from '../../../context/content-explorer-context'
import { TagItem } from './TagItem'

interface Props extends StackProps {}

export const TagsFilterBar: React.FC<Props> = ({ ...rest }) => {
  const { loadingTags, tags, selectedTags, fetchTags, selectTag, unSelectTag } =
    useContentExplorer()
  useEffect(() => {
    fetchTags()
  }, [])

  return (
    <Wrap>
      {tags &&
        tags.map((tag, i) => (
          <TagItem
            key={i}
            tag={tag}
            selectTag={() => {
              selectTag(tag)
            }}
            unSelectTag={() => {
              unSelectTag(tag)
            }}
            isSelected={
              selectedTags.length > 0 && selectedTags[0].id === tag.id
            }
            mb={2}
          />
        ))}
    </Wrap>
  )
}
