import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import React from 'react'
import { BillingUpdateForm } from '../MemberDashboardBillingUpdate'

interface MemberDashboardBillingUpdateModalProps {
  visibility: {
    isOpen: boolean
    onClose: () => void
  }
  successCallback?: () => void
  callbackActionButtonMessage?: string
}

export const MemberDashboardBillingUpdateModal: React.FC<MemberDashboardBillingUpdateModalProps> =
  ({ visibility, successCallback, callbackActionButtonMessage }) => {
    const onSuccessCallback = () => {
      visibility.onClose()
      successCallback && successCallback()
    }

    return (
      <Modal isOpen={visibility.isOpen} onClose={visibility.onClose} size="xl">
        <ModalOverlay />
        <ModalContent p={6} bg="white" borderRadius="6px" minW="350px">
          <ModalHeader p={0} mb={4}>
            <h1>Payment Details</h1>
          </ModalHeader>

          <ModalCloseButton />
          {/* Render modal body i.e. children */}
          <ModalBody>
            <BillingUpdateForm
              showOutstandingCharges={true}
              showPlanPreview={true}
              postSubmitCallback={onSuccessCallback}
              actionButtonText={callbackActionButtonMessage}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
