import React, { useContext, useEffect, useState } from 'react'
import { IDailyFiveContent, Interest, IPaginatedDailyFive } from '../types'
import { analyticsTrack } from '../utils/analytics'
import { fetchSearchtags, fetchContentExplorerContent } from '../api'
import { usePlatformTenant } from './platform-tenant-context'

export interface ContentExplorerProps {
  isNextPage: boolean
  loadingPosts: boolean
  loadingTags: boolean
  tags: Interest[]
  selectedTags: Interest[]
  content: IDailyFiveContent[]
  activeTab: 'HOME' | 'MYPOSTS' | 'EXPLORER' | string
  postRemoved: number | undefined
  setActiveTab: (input: string) => void
  fetchContent: (page?: number) => Promise<IPaginatedDailyFive>
  fetchTags: () => void
  selectTag: (tagId: Interest) => void
  unSelectTag: (tagId?: Interest | undefined) => void
  removePost: (contentId: number) => void
}

const defaultProps: ContentExplorerProps = {
  isNextPage: true,
  loadingPosts: false,
  loadingTags: false,
  tags: [],
  selectedTags: [],
  content: [],
  activeTab: 'HOME',
  postRemoved: undefined,
  setActiveTab: (input: string) => console.log(''),
  fetchContent: (props?: any) =>
    new Promise<IPaginatedDailyFive>((resolve, reject) => {
      reject()
    }),
  fetchTags: () => console.log('not yet initialized'),
  selectTag: (tag?: Interest) => console.log('not yet initialized'),
  unSelectTag: (tag: Interest | undefined) =>
    console.log('not yet initialized'),
  removePost: (contentId: number) => console.log('')
}

export const ContentExplorerContext =
  React.createContext<ContentExplorerProps>(defaultProps)

export const ContentExplorerProvider = ({ children }) => {
  const { platformTenant } = usePlatformTenant()
  const [page, setPage] = useState<number>(1)
  const [loadingPosts, setLoadingPosts] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(true)
  const [loadingTags, setLoadingTags] = useState<boolean>(false)
  const [tags, setTags] = useState<Interest[]>([])
  const [activeTab, setActiveTab] = useState<string>('HOME')
  const [selectedTags, setSelectedTags] = useState<Interest[]>([])
  const [content, setContent] = useState<IDailyFiveContent[]>([])
  const [postRemoved, setPostRemoved] = useState<number>()

  const fetchContent = (pageNumber: number | undefined = page) => {
    return new Promise<IPaginatedDailyFive>((resolve, reject) => {
      if (loadingPosts) reject('Fetching page')
      setLoadingPosts(true)
      if (platformTenant && platformTenant?.id) {
        fetchContentExplorerContent(platformTenant.id, selectedTags, pageNumber)
          .then((result) => {
            if (result.data && result.data.results) {
              let newContent: IDailyFiveContent[] = result.data.results
                ? result.data.results
                : []
              setContent(
                pageNumber === 1
                  ? result.data.results
                  : content.concat(newContent)
              )
              setIsNextPage(result.data.next != null)
              setPage(isNextPage ? pageNumber + 1 : 1)
              resolve(result.data)
            } else {
              setIsNextPage(false)
            }
            setLoadingPosts(false)
          })
          .catch((error) => {
            setLoadingPosts(false)
          })
      } else {
        setLoadingPosts(false)
      }
    })
  }

  const selectTag = (tag: Interest) => {
    if (selectedTags.length > 0 && selectedTags[0].id === tag.id) return
    if (tags && tag && tags?.find((i) => i.id === tag.id) === undefined) {
      setTags((prevTags: Interest[] = []) => [...prevTags, tag])
    }
    setSelectedTags([tag])
  }
  const unSelectTag = (tag) => {
    setSelectedTags([])
  }
  const fetchTags = () => {
    setLoadingTags(true)
    fetchSearchtags()
      .then((tagsResponse) => {
        if (tagsResponse.data) {
          setLoadingTags(false)
          setTags(tagsResponse.data)
        }
      })
      .catch((err) => {
        setLoadingTags(false)
      })
  }

  const removePost = (contentId: number) => {
    const newContent = content.filter((post) => post.id != contentId)
    setContent(newContent)
    setPostRemoved(contentId)
  }

  useEffect(() => {
    fetchContent(1)
  }, [selectedTags])

  return (
    <ContentExplorerContext.Provider
      value={{
        isNextPage,
        loadingTags,
        loadingPosts,
        tags,
        selectedTags,
        content,
        activeTab,
        postRemoved,
        setActiveTab,
        fetchContent,
        selectTag,
        unSelectTag,
        fetchTags,
        removePost
      }}
    >
      {children}
    </ContentExplorerContext.Provider>
  )
}

export const useContentExplorer = () => {
  const context = useContext(ContentExplorerContext)
  if (context === undefined) {
    throw new Error(
      'useContentExplorer must be used within a ContentExplorerProvider'
    )
  }
  return context
}
