import { Box, Icon, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { CheckCustomIcon } from '../../../components/icons/icons'
import { MemberTableLink } from '../../../components/MemberTableLink'
import {
  ActionsDropdown,
  IAction
} from '../../../components/table/ActionsDropdown'
import { Cell } from '../../../components/table/Cell'
import { Table, TableDataProp } from '../../../components/table/Table'
import { TableRow } from '../../../components/table/TableRow'
import { UserDisplay } from '../../../components/UserDisplay'
import { IOrganizationSeat } from '../../../types'

import { Actions, useRBAC, useRole } from '../../../utils/rbac'

function RenderEmptySeatText(plan: string) {
  return (
    <>
      Empty seat:&nbsp;
      <strong>{plan}</strong>
    </>
  )
}

function RenderFutureSeatText(plan: string) {
  return (
    <>
      <strong>{plan}</strong>
    </>
  )
}

function RenderFutureSeatStartDateText(value: string) {
  return (
    <>
      Starts on:&nbsp;
      <strong>{value}</strong>
    </>
  )
}

function RenderInviteSent() {
  return (
    <Text mr={5}>
      <CheckCustomIcon color="alertSuccess" mr={2} />
      Invite sent
    </Text>
  )
}

function RenderEmptySeatRow(seat: IOrganizationSeat, actions: IAction[]) {
  return (
    <TableRow key={seat.id} minHeight="48px" opacity={0.6}>
      <Cell
        key="1"
        width={0.9}
        value={{
          value: RenderEmptySeatText(
            seat.active_plan ? seat.active_plan.name : ''
          )
        }}
      />
      {seat.active_invitation ? (
        <Cell key="2" value={{ value: RenderInviteSent() }} />
      ) : null}
      {
        <ActionsDropdown
          width="4%"
          actions={actions}
          rowId={seat.id}
          key={3}
          dataTestid={'expand-' + seat.id}
        />
      }
    </TableRow>
  )
}
function RenderFutureSeatRow(
  seat: IOrganizationSeat,
  organizationId: number,
  isInAdminRole: boolean,
  actions: IAction[]
) {
  const user = seat.organizational_unit?.user

  return {
    id: TableDataProp(seat.id),
    plan: TableDataProp(
      RenderFutureSeatText(seat.ongoing_plan ? seat.ongoing_plan.name : '')
    ),
    start_date: seat.ongoing_plan_start_date
      ? TableDataProp(
          RenderFutureSeatStartDateText(seat.ongoing_plan_start_date)
        )
      : null,
    assigned_to: !user
      ? null
      : TableDataProp(
          <Stack>
            {seat.organizational_unit && (
              <MemberTableLink
                orgId={organizationId}
                member={seat.organizational_unit}
                isInAdminRole={isInAdminRole}
              />
            )}
            <Text>
              {[
                user.email,
                seat.organizational_unit?.job_title ?? '',
                user.user_profile.mobile_number,
                seat.active_plan ? seat.active_plan.name : ''
              ]
                .filter((v) => v)
                .map((v) => (
                  <>
                    {v} <br />
                  </>
                ))}
            </Text>
          </Stack>
        ),

    actions: TableDataProp(actions)
  }
}

interface IInputParams {
  title: string
  seats: IOrganizationSeat[]
  loading: boolean
  isFutureSeat?: boolean
  organizationId: number
  handleActionInvite: (seatId: number) => void
  clearSeatAssignment: (seatId: number) => void
  manageSeatMembership: (seatId: number) => void
}

export const TeamSeats: React.FC<IInputParams> = (input) => {
  const { isInAdminRole, isSpaceSuperAdminRole } = useRole()

  const canManageMembership = useRBAC(Actions.ManageTeamMemberships)
  const seats = input.seats
  const loading = input.loading
  const isFutureSeat = input.isFutureSeat === true

  // Empty seat actions
  const inviteAction: IAction = {
    text: 'Assign Member',
    testIdPrefix: 'invite',
    action: input.handleActionInvite
  }
  const viewInviteAction: IAction = {
    text: 'View Invite',
    testIdPrefix: 'view-invite',
    action: input.handleActionInvite
  }
  const clearSeatAssignmentAction: IAction = {
    text: 'Clear Seat Assignment',
    testIdPrefix: 'clear-seat',
    action: input.clearSeatAssignment
  }
  const manageSeatPlanAction: IAction = {
    text: 'Manage Membership',
    testIdPrefix: 'manage-membership',
    action: input.manageSeatMembership
  }

  const tableHeaders = !isFutureSeat
    ? [
        { header: 'id', accessor: 'id', show: false },
        {
          header: '',
          accessor: 'displayImage',
          show: true,
          width: 0.125
        },
        { header: 'Name', accessor: 'name', show: true, width: 0.51 },
        { header: 'Title', accessor: 'title', show: true },
        { header: 'Phone', accessor: 'phone', show: true },
        { header: 'Plan', accessor: 'plan', show: true },
        { header: '', accessor: 'actions', show: true, width: 0.125 }
      ]
    : [
        { header: 'id', accessor: 'id', show: false },
        {
          header: 'Plan',
          accessor: 'plan',
          show: true
        },
        {
          header: 'Commence date',
          accessor: 'start_date',
          show: true
        },
        {
          header: '',
          accessor: 'assigned_to',
          show: true
        },
        { header: '', accessor: 'actions', show: true, width: 0.125 }
      ]

  const tableData = seats
    ? seats.map((seat) => {
        const user = seat.organizational_unit?.user
        let cleanedData

        if (user && !isFutureSeat) {
          // Has a user
          cleanedData = {
            id: TableDataProp(seat.id),
            displayImage: TableDataProp(
              <UserDisplay
                name={user.name}
                src={user.user_profile.profile_picture?.image}
              />
            ),
            actions: TableDataProp(
              canManageMembership
                ? [manageSeatPlanAction, clearSeatAssignmentAction]
                : []
            ),
            name: [
              TableDataProp(
                <>
                  {seat.organizational_unit && (
                    <MemberTableLink
                      orgId={input.organizationId}
                      member={seat.organizational_unit}
                      isInAdminRole={isInAdminRole}
                    />
                  )}
                </>
              ),
              TableDataProp(user.email)
            ],
            title: TableDataProp(seat.organizational_unit?.job_title ?? ''),
            phone: TableDataProp(user.user_profile.mobile_number),
            plan: TableDataProp(seat.active_plan ? seat.active_plan.name : '')
          }
        } else {
          // Empty seat
          const emptySeatActions: IAction[] = []
          emptySeatActions.push(manageSeatPlanAction)
          // has an active invite
          if (seat.active_invitation) {
            emptySeatActions.push(viewInviteAction)
          }
          //  no active invite
          else {
            emptySeatActions.push(inviteAction)
          }
          if (isFutureSeat) {
            const futureSeatActions: IAction[] = []
            if (isInAdminRole) {
              futureSeatActions.push(manageSeatPlanAction)
            }
            if (seat.organizational_unit) {
              futureSeatActions.push(clearSeatAssignmentAction)
            } else {
              futureSeatActions.push(inviteAction)
            }

            cleanedData = RenderFutureSeatRow(
              seat,
              input.organizationId,
              isInAdminRole,
              futureSeatActions
            )
          } else {
            cleanedData = RenderEmptySeatRow(seat, emptySeatActions)
          }
        }

        return cleanedData
      })
    : []

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box display="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {input.title}
          </Text>
          <Text fontSize="xs" color="grey">
            {seats ? seats.length : 0} Total
          </Text>
        </Box>
        <Table headers={tableHeaders} data={tableData} loading={loading} />
      </Box>
    </div>
  )
}
