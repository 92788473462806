import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  ModalBody,
  ModalFooter,
  useToast
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { createAddonSubscription } from '../../../api'
import { Button } from '../../../components/Button'
import { ChakraModal } from '../../../components/ChakraModal'
import { FilterableSelect } from '../../../components/FilterableSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IAddonSubscriptionRequest, Plan } from '../../../types'
import { usePlans } from '../../../utils/apiHooks'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  orgId: number
}
export const ExtraSubscriptionModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  orgId
}) => {
  const toast = useToast()

  const { platformTenant } = usePlatformTenant()
  const { plans, mutate: revalidatePlans } = usePlans(platformTenant?.id)
  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  const resetStateAndClose = () => {
    onClose()
  }

  return (
    <ChakraModal
      title="Add New Subscription"
      isOpen={isOpen}
      onClose={resetStateAndClose}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          start_date: new Date(),
          end_date: undefined
        }}
        onSubmit={(values, actions) => {
          // makes sure we have all the values we need
          if (selectedPlan) {
            const newAddonSubscriptionRequest: IAddonSubscriptionRequest = {
              organization: orgId,
              plan: selectedPlan.id,
              start_date: values.start_date
                ? format(values.start_date, 'yyyy-MM-dd')
                : undefined
            }

            createAddonSubscription(newAddonSubscriptionRequest)
              .then(() => {
                toast({
                  status: 'success',
                  description: 'Subscription Request created'
                })
                actions.setSubmitting(false)
                resetStateAndClose()
                actions.setSubmitting(false)
              })
              .catch(() => {
                toast({
                  status: 'error',
                  description: 'Failed to create the request'
                })
                actions.setSubmitting(false)

                revalidatePlans()
              })
          }
        }}
        render={(props) => (
          <form onSubmit={props.handleSubmit}>
            <ModalBody pb={4}>
              <Field
                header="Plans"
                render={({ field, form }) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.plan && form.touched.plan}
                    pb={3}
                  >
                    <FormLabel color="#4F4F4F" mb={1}>
                      Plans
                    </FormLabel>
                    <FilterableSelect
                      dataTestId="booking-select-room-id"
                      isClearable={true}
                      items={plans
                        .filter((p) => p.addon_plan)
                        .map((p) => ({
                          label: p.name,
                          value: p.id
                        }))}
                      isOptionDisabled={(value) => value.disabled}
                      onSelect={(n) => {
                        setSelectedPlan(
                          plans
                            .filter((p) => p.addon_plan)
                            .find((plan) => plan.id === n)
                        )
                      }}
                    />

                    <FormErrorMessage>{form.errors.category}</FormErrorMessage>
                  </FormControl>
                )}
              />
              <Field
                name="start_date"
                render={({ field, form }) => (
                  <FormControl
                    isRequired
                    pt={2}
                    pb={5}
                    mr={4}
                    isInvalid={
                      form.errors.start_date && form.touched.start_date
                    }
                  >
                    <FormLabel color="#4F4F4F" mb={1}>
                      Start Date
                    </FormLabel>
                    <Calendar
                      selectRange={false}
                      onChange={(date) => {
                        props.setFieldValue('start_date', date as Date)
                      }}
                      value={form.values.start_date}
                      minDate={new Date()}
                    />

                    <FormErrorMessage>
                      {form.errors.start_date}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </ModalBody>

            <ModalFooter justifyContent="flex-start">
              <Button disabled={props.isSubmitting} type="submit" mr={3}>
                Save
              </Button>
              <Button variant="secondary" onClick={resetStateAndClose}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        )}
      />
    </ChakraModal>
  )
}
