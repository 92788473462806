import { Divider, Flex, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { SecondaryButton } from '../../components/chakra-v2/SecondaryButton'
import { useAuth } from '../../context/auth-context'

import { useUserDetails } from '../../context/user-onboard-context'
import { OnboardingHeading } from './OnboardingComponents'
import {
  FERN_ALLENDALE_PLAN_UUID,
  ILUMA_PLAN_UUID
} from '../../utils/constants'
import { CheckCustomIcon } from '../../components/icons/icons'
import { usePlatformTenant } from '../../context/platform-tenant-context'

export const OnboardingThankyou: React.FC = () => {
  const { userDetails } = useUserDetails()
  const { platformTheme } = usePlatformTenant()
  const auth = useAuth()
  const isAllendalePlan = FERN_ALLENDALE_PLAN_UUID === userDetails.plan
  const isIlumaPlan = ILUMA_PLAN_UUID === userDetails.plan
  const tenantName = platformTheme ? platformTheme.business_name : 'Spacecubed'

  return (
    <Flex direction="column" flexGrow={1} align="center" justify="center">
      <Stack
        spacing={8}
        maxW="350px"
        shouldWrapChildren
        alignItems="center"
        justifyContent="center"
      >
        <CheckCustomIcon color="alertSuccess" height="2rem" width="2rem" />
        {isAllendalePlan ? (
          <Stack spacing={8} justify="center" align="center">
            <OnboardingHeading textAlign="center">Thank you</OnboardingHeading>

            <Text fontSize={18} textAlign="center">
              A {tenantName} administrator will be in touch via email to confirm
              your sign up.
            </Text>
          </Stack>
        ) : isIlumaPlan ? (
          <Stack spacing={8} justify="center" align="center">
            <OnboardingHeading textAlign="center">Thank you</OnboardingHeading>

            <Text fontSize={18} textAlign="center">
              A {tenantName} administrator will be in touch via email to confirm
              your sign up.
            </Text>
          </Stack>
        ) : (
          <Stack spacing={8} justify="center" align="center">
            <OnboardingHeading>Thank you</OnboardingHeading>
            <Divider />

            {userDetails.startDate !== undefined && (
              <Text fontSize={18} textAlign="center">
                You're starting a coworking membership on{' '}
                {format(userDetails.startDate, 'do MMMM yyyy')}
              </Text>
            )}
            <Text fontSize={18} textAlign="center">
              A {tenantName} administrator will be in touch via email to confirm
              your membership.
            </Text>
            <SecondaryButton
              w={'100%'}
              flexGrow={0}
              onClick={() => {
                const token = window.localStorage.getItem('Token')
                auth.getAndSetMe!(token)
              }}
            >
              Home
            </SecondaryButton>
          </Stack>
        )}
      </Stack>
    </Flex>
  )
}
