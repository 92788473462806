import { Box, Image, useTheme } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from '../../components/Button'
import { CheckCustomIcon } from '../../components/icons/icons'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { routeStrings } from '../../routeStrings'
const SomeParagraph = styled.p`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: 16px;
  color: #525961;
`

export const MemberDashboardBillingUpdateSuccess: React.FC = () => {
  const navigate = useNavigate()
  const theme: any = useTheme()
  return (
    <div className="App">
      <OnboardingWrapper>
        <Box p={3}>
          <Image
            src={theme.logos.logo_long}
            width="128px"
            className="location-logo"
          />
          <Box mt={6}></Box>
          <div style={{ padding: '16px' }}>
            <CheckCustomIcon color="alertSuccess" height="30px" width="30px" />

            <SomeParagraph>
              Successfully updated your billing information
            </SomeParagraph>
            <Button onClick={() => navigate(routeStrings.memberDashboardHome)}>
              Home
            </Button>
          </div>
        </Box>
      </OnboardingWrapper>
    </div>
  )
}
