import React, { useState, useEffect } from 'react'
import { BaseLayoutOnboarding } from './BaseLayoutOnboarding'
import { startRoutes } from '../../../routes'
import { useLocation } from 'react-router-dom'
import { useUserDetails } from '../../../context/user-onboard-context'
import { Container, Stack, Text } from '@chakra-ui/react'
import { Steps } from 'antd'
import { routeStrings } from '../../../routeStrings'

const onboardingRoutes = [
  startRoutes.location.path,
  startRoutes.plan.path,
  startRoutes.acceptTermsAndConditions.path,
  startRoutes.singlePageSignup.path
]

const steps = [
  {
    title: 'Choose your space',
    routes: ['/start' + startRoutes.location.path]
  },
  {
    title: 'Membership',
    routes: ['/start' + startRoutes.plan.path]
  },
  {
    title: 'Account Info',
    routes: ['/start' + startRoutes.singlePageSignup.path]
  }
]

interface IProps {
  isExternal?: boolean
  children: any
}
/**
 * A thin wrapper around `BaseLayoutOnboarding` which auto-advances the progress.
 * Because BaseLayoutOnboarding isn't re-rendered between screens,
 * we can nicely animate the progress bar with CSS.
 */
export const LayoutNewUserOnboarding: React.FC<IProps> = ({
  isExternal,
  children
}) => {
  const [, setProgress] = useState<number>(0)
  const [currentStep, setCurrentStep] = useState(0)
  const { userDetails } = useUserDetails()

  const urlLocation = useLocation()

  const stepStyle = {
    marginBottom: 60
  }

  const updateProgress = (location: string) => {
    let currentPath = location
    if (currentPath.endsWith('/')) {
      currentPath = currentPath.substring(0, currentPath.length - 1)
    }
    const currentRouteIndex = onboardingRoutes.findIndex(
      (route) => route === currentPath
    )
    const currentStepIndex = steps.findIndex((step) =>
      step.routes.includes(currentPath)
    )
    if (currentStepIndex !== -1) {
      setCurrentStep(currentStepIndex)
    }
    if (currentRouteIndex !== -1) {
      setProgress((currentRouteIndex + 1) / onboardingRoutes.length)
    }
  }

  useEffect(() => {
    try {
      updateProgress(urlLocation.pathname)
    } catch (error) {
      console.error('Error updating progress', error)
    }
  }, [urlLocation])

  useEffect(() => {
    const cancelToken = setTimeout(() => {
      updateProgress(window.location.pathname)
    }, 200)
    return () => clearTimeout(cancelToken)
  }, [])

  return (
    <BaseLayoutOnboarding>
      {!isExternal && (
        <Container maxW="5xl" className="user-onboarding-container">
          <Steps current={currentStep} style={stepStyle}>
            <Steps.Step
              title={'Choose your Space'}
              description={
                <Text fontWeight="bold" color="gray.600">
                  {userDetails?.locationName ?? undefined}
                </Text>
              }
              onClick={() => {
                if (userDetails.location_id) {
                }
              }}
            />
            <Steps.Step
              title={'Membership'}
              description={
                <Stack>
                  <Text fontWeight="bold" color="gray.600">
                    {userDetails?.plan_name ?? undefined}
                  </Text>
                  <Text fontSize={'sm'} fontWeight="light" color="gray.600">
                    {userDetails?.plan_rate}
                  </Text>
                </Stack>
              }
            />
            <Steps.Step
              title={'Account Info'}
              status={
                urlLocation.pathname === routeStrings.thankyou
                  ? 'finish'
                  : undefined
              }
            />
          </Steps>
        </Container>
      )}
      <Container maxW="7xl">{children}</Container>
    </BaseLayoutOnboarding>
  )
}
