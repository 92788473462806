import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  useToast
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React from 'react'
import { createOffice } from '../../../api'
import { Button } from '../../../components/Button'
import { INewOffice } from '../../../types'
import { StyledInput } from '../../../components/StyledInputs'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import XeroAccountDropdown from '../../../components/inputs/XeroAccountDropdown'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
}
export const CreateOfficeModal: React.FC<IModalInput> = ({
  isOpen,
  onClose
}) => {
  const toast = useToast()
  const { platformTenant } = usePlatformTenant()
  const validateRate = (value) => {
    let error
    if (!value) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  const validateDesks = (value) => {
    let error
    if (!value) {
      error = 'This rate is required'
      if (isNaN(value)) {
        error = 'Number of desks must be a number'
      } else if (value <= 0) {
        error = 'Office must have at least one desk'
      }
      return error
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>New Office </ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={{
            location: undefined as number | undefined,
            xero_account_code: 0,
            room: '',
            level: '',
            numberOfDesks: 0,
            creditRate: 0.0,
            officeRate: 0.0
          }}
          onSubmit={(values, actions) => {
            if (values.location) {
              const newOffice: INewOffice = {
                location: values.location,
                xero_account_code: values.xero_account_code,
                level: values.level,
                meeting_credit_rate: values.creditRate,
                number_of_desks: values.numberOfDesks,
                room: values.room,
                rate: values.officeRate
              }

              createOffice(newOffice)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Office Created'
                  })
                  onClose()
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    description: 'Failed to create office'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                })
            }
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody pb={4}>
                <SimpleGrid pt={3} columns={[1, 2]} spacing={8}>
                  <Field
                    name="location"
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.location && form.touched.location
                        }
                        pt={2}
                        pb={5}
                      >
                        <FormLabel color="#4F4F4F" mb={1}>
                          Location
                        </FormLabel>
                        <Select
                          placeholder="Select a location"
                          value={form.values.location}
                          variant="flushed"
                          borderBottom="1px"
                          borderColor="eastBay07"
                          _hover={{
                            borderColor: '#CBD5E0'
                          }}
                          name="location"
                          onChange={(e) => {
                            props.setFieldValue(
                              'location',
                              parseInt(e.target.value)
                            )
                          }}
                        >
                          {platformTenant?.locations.map((location) => (
                            <option key={location.id} value={location.id}>
                              {location.name}
                            </option>
                          ))}
                        </Select>

                        <FormErrorMessage>
                          {form.errors.category}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="xero_account_code"
                    as="select"
                    render={({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.xero_account_code &&
                          form.touched.xero_account_code
                        }
                        pb={5}
                        isRequired
                      >
                        <FormLabel htmlFor="xero_account_code">
                          Xero Account
                        </FormLabel>
                        <XeroAccountDropdown
                          {...field}
                          id="xero_account_code"
                          variant="filled"
                          onChange={(e) => {
                            props.setFieldValue(
                              'xero_account_code',
                              parseInt(e.target.value)
                            )
                          }}
                        />

                        <FormErrorMessage>
                          {form.errors.xero_account_code}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
                <SimpleGrid pt={3} columns={[1, 2]} spacing={8}>
                  <Field
                    name="room"
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.room && form.touched.room}
                      >
                        <FormLabel htmlFor="room">Room</FormLabel>

                        <StyledInput
                          {...field}
                          maxLength={10}
                          id="room"
                          placeholder="Room label"
                          w="100%"
                        />
                        <Text fontSize={'xs'} color={'#8c8c8c'}>
                          Max. 10 characters
                        </Text>
                        <FormErrorMessage>{form.errors.room}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="level"
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.level && form.touched.level}
                      >
                        <FormLabel htmlFor="level">Level</FormLabel>
                        <StyledInput
                          {...field}
                          id="level"
                          placeholder="Floor level"
                          maxLength={10}
                          w="100%"
                        />

                        <Text fontSize={'xs'} color={'#8c8c8c'}>
                          Max. 10 characters
                        </Text>
                        <FormErrorMessage>{form.errors.level}</FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="numberOfDesks"
                    validate={validateDesks}
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.numberOfDesks &&
                          form.touched.numberOfDesks
                        }
                      >
                        <FormLabel htmlFor="numberOfDesks">
                          Number of desks
                        </FormLabel>
                        <StyledInput
                          type="number"
                          {...field}
                          id="numberOfDesks"
                          placeholder="monthly rate"
                          w="100%"
                        />

                        <FormErrorMessage>
                          {form.errors.numberOfDesks}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="officeRate"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.officeRate && form.touched.officeRate
                        }
                      >
                        <FormLabel htmlFor="officeRate">
                          Rate (p/month):
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            {...field}
                            id="officeRate"
                            placeholder="monthly rate"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.officeRate}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="creditRate"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.creditRate && form.touched.creditRate
                        }
                      >
                        <FormLabel htmlFor="creditRate">
                          Meeting Credits per Desk:
                        </FormLabel>
                        <StyledInput
                          {...field}
                          id="creditRate"
                          placeholder="meeting room credits"
                          w="100%"
                        />
                        <FormErrorMessage>
                          {form.errors.creditRate}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button disabled={props.isSubmitting} type="submit" mr={3}>
                  Save
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  )
}
