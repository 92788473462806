import { useTheme } from '@chakra-ui/react'
import React, { useState } from 'react'

import { OnboardingWrapper } from '../components/OnboardingWrapper'
import { useAuth } from '../context/auth-context'
import { LoginComponent } from './LoginComponent'

export const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const auth = useAuth()
  const theme: any = useTheme()
  if (auth.loading) {
    return null
  }

  return (
    <OnboardingWrapper>
      <LoginComponent logo_url={theme.logos.logo_long} />
    </OnboardingWrapper>
  )
}
