import React from 'react'
import { Avatar, AvatarProps } from '@chakra-ui/react'

interface Props extends AvatarProps {
  name?: string
}

export const UserDisplay: React.FC<Props> = ({ name, ...rest }) => {
  function stringToHslColor(str, s, l) {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    var h = hash % 360
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
  }

  let initals = 'A A'

  if (name) {
    let splitName = name.split(' ')
    let twoWords = splitName.length === 2

    if (twoWords) {
      let firstChar = splitName[0][0]
      let lastChar = splitName[1][0]
      initals = firstChar + ' ' + lastChar
    }
  }

  return (
    <Avatar
      size="sm"
      backgroundColor={stringToHslColor(initals, 30, 70)}
      name={name}
      {...rest}
    />
  )
}
