import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { format, isBefore } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getOrgBillingCredits } from '../api'
import { useAuth } from '../context/auth-context'
import { CreateBillingCreditModal } from '../pages/AdminDashboard/Modals/CreateBillingCreditModal'
import { IBillingCredit } from '../types'
import { useOrganization } from '../utils/apiHooks'
import { Actions, useRBAC } from '../utils/rbac'
import { ValueComponent } from './billing/ValueComponent'
import { Button } from './Button'
import { CardPanel } from './CardPanel'
import { StatusBox } from './StatusBox'
import { Table, TableDataProp } from './table/Table'
import { Tag } from './Tag'

export const BillingCreditHistory: React.FC<any> = () => {
  const [editingBillingCredit, setEditingBillingCredit] =
    useState<IBillingCredit>()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const auth = useAuth()
  const canManageBillingCredits = useRBAC(Actions.ManageBillingCredits)
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id

  const { data: organization } = useOrganization(orgId)
  const [billingCredits, setBillingCredits] = useState<IBillingCredit[]>([])

  useEffect(() => {
    if (orgId && canManageBillingCredits) {
      getOrgBillingCredits(orgId)
        .then((res) => setBillingCredits(res.data))
        .catch((reason) => {
          console.error(reason)
        })
    }
  }, [orgId, organization, isOpen, canManageBillingCredits])

  if (!canManageBillingCredits) {
    return null
  }
  const billingCreditActions = [
    {
      text: 'Edit',

      testIdPrefix: 'edit',
      action: (rowId) => {
        const billingCredit = billingCredits.find(
          (credit) => credit.id === rowId
        )
        if (billingCredit) {
          setEditingBillingCredit(billingCredit)
          onOpen()
        }
      }
    }
  ]

  const StatusRenderer = (status: 'depleted' | 'available' | 'expired') => {
    return (
      <StatusBox
        status={status}
        mapping={{
          available: { color: 'positive', label: 'AVAILABLE' },
          depleted: { color: 'blue', label: 'USED' },
          expired: { color: 'gray', label: 'EXPIRED' }
        }}
      />
    )
  }

  const tableData =
    billingCredits.map((credit) => {
      const { credits_remaining, expiry } = credit
      // if (credits_remaining === undefined || credits_given === undefined)

      const expiryDate = expiry
        ? typeof expiry === 'string'
          ? new Date(Date.parse(expiry))
          : new Date(expiry)
        : undefined

      const status =
        (credits_remaining ?? 0) === 0
          ? 'depleted'
          : expiryDate
          ? isBefore(expiryDate, new Date())
            ? 'expired'
            : 'available'
          : 'available'

      const cleanedData = {
        id: TableDataProp(credit.id),
        reason: TableDataProp(credit.reason),
        // rowData: TableDataProp(org),
        created_at: TableDataProp(
          credit.created_at
            ? format(new Date(Date.parse(credit.created_at)), 'dd/MM/yyyy')
            : undefined
        ),
        status: TableDataProp(StatusRenderer(status)),
        // rowData: TableDataProp(org),

        expiry: TableDataProp(
          expiryDate ? format(expiryDate, 'dd/MM/yyyy') : ''
        ),
        credits_given: TableDataProp(
          <ValueComponent value={`${credit.credits_given}`} />
        ),
        credits_remaining: TableDataProp(
          <ValueComponent value={`${credit.credits_remaining}`} />
        ),

        actions: TableDataProp(billingCreditActions)
      }

      return cleanedData
    }) || []
  return (
    <Box my="3" minW="900px">
      <CardPanel align="initial" mb={2}>
        <Flex mb={1} alignItems="center" justify="space-between">
          <Box>
            <Text
              mb={4}
              fontWeight="semibold"
              color="headingPrimary"
              fontSize="lg"
            >
              Billing Credit History
            </Text>

            <Text mb={2} fontSize="sm">
              <Tag positive={true} style={{ textTransform: 'uppercase' }}>
                Available
              </Tag>{' '}
              billing credits will appear in the upcoming invoice but will only
              be used upon invoice issuance.{' '}
            </Text>
          </Box>
          {!organization?.is_deleted && (
            <Button
              mb={3}
              variant="secondary"
              my={4}
              mr={4}
              onClick={() => {
                setEditingBillingCredit(undefined)

                onOpen()
              }}
            >
              Create new credit
            </Button>
          )}
        </Flex>
      </CardPanel>
      {/* Billing history */}
      <Table
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },
          {
            header: 'Created',
            accessor: 'created_at',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Credits given',
            accessor: 'credits_given',
            show: true,
            width: 1 / 12,
            minWidth: '120px'
          },
          {
            header: 'Credits Remaining',
            accessor: 'credits_remaining',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Status',
            accessor: 'status',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Expires',
            accessor: 'expiry',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Reason',
            accessor: 'reason',
            show: true,
            width: 3 / 12
          },

          { header: '', accessor: 'actions', show: true, width: 1 / 12 }
          // { header: '', accessor: 'spacer', show: true, width: 3 / 12 }
        ]}
        data={tableData}
        loading={!billingCredits}
      />

      <CreateBillingCreditModal
        isOpen={isOpen}
        onClose={() => {
          setEditingBillingCredit(undefined)
          onClose()
        }}
        billingCreditToEdit={editingBillingCredit}
        organization={organization}
      />
    </Box>
  )
}
