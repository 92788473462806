import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { Button } from '../../components/Button'
import { PrimarySpinner } from '../../components/chakra-v2/PrimarySpinner'
import { StyledInput } from '../../components/StyledInputs'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { InputWarning } from '../../styled/InputWarning'
import { IPlatformTenant, IPlatformTenantUpdate, Location } from '../../types'
import 'react-quill/dist/quill.snow.css'

import { IS_VALID_HTTP_URL } from '../../utils/formUtils'
import { LocationURL } from '../../components/LocationURL'
import { useCustomToast } from '../../context/toast-context'
import { routeStrings } from '../../routeStrings'
import {
  FERN_ALLENDALE_PLAN_UUID,
  ILUMA_PLAN_UUID
} from '../../utils/constants'

export const AdminDashboardPlatformTenantSignupSettings: React.FC = () => {
  const { platformTenant, adminUpdatePlatformTenant } = usePlatformTenant()
  const toast = useCustomToast()

  if (!platformTenant) {
    return <CenteredSpinner />
  }
  // HARDCODED ILUMA
  const isIlumaTenant = platformTenant.business_name
    ?.toLocaleLowerCase()
    .replace(' ', '')
    .includes('workzone')

  return (
    <Box>
      <Flex mb={3} flexDir="column">
        <VStack
          bg="white"
          w="100%"
          boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
          rounded="md"
          px={8}
          py={5}
          position="relative"
          color="eastBay09"
          align="center"
          justifyContent="space-between"
        >
          {platformTenant ? (
            <Formik
              initialValues={{
                contact_form_url: platformTenant.contact_form_url?.toLowerCase()
              }}
              validate={(values): IPlatformTenant => {
                const errors: any = {}
                if (!IS_VALID_HTTP_URL(values.contact_form_url)) {
                  errors.contact_form_url = 'Support Link URL invalid'
                }
                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true)

                const data: IPlatformTenantUpdate = {
                  contact_form_url: values.contact_form_url
                }
                try {
                  const response = await adminUpdatePlatformTenant(data)
                  if (response.status === 200) {
                    setSubmitting(false)
                    toast.newToast({
                      status: 'success',
                      description: 'Sign-up Settings updated'
                    })
                  } else {
                    setSubmitting(false)
                    toast.newToast({
                      status: 'error',
                      description: 'An error occurred'
                    })
                  }
                } catch (e) {
                  setSubmitting(false)
                  toast.newToast({
                    status: 'error',
                    description: 'An error occurred'
                  })
                }
              }}
            >
              {({ values, setFieldValue, isSubmitting, touched, errors }) => (
                <Form>
                  <Stack spacing={8}>
                    <Stack w={['100%', '60%']}>
                      <Text fontWeight="bold" fontSize="26px">
                        Sign-up settings
                      </Text>
                      <FormLabel
                        fontWeight="bold"
                        color="#4F4F4F"
                        fontSize="sm"
                        mb={1}
                      >
                        Contact Form URL
                      </FormLabel>
                      <StyledInput
                        placeholder="www.contact-form.com"
                        ml="16px"
                        value={values.contact_form_url || ''}
                        onChange={(e) => {
                          setFieldValue('contact_form_url', e.target.value)
                        }}
                        w={'100%'}
                      ></StyledInput>
                      <ErrorMessage
                        name="contact_form_url"
                        component={InputWarning}
                      />
                    </Stack>

                    <Box>
                      <Button type="submit" disabled={isSubmitting}>
                        Update settings
                      </Button>
                    </Box>
                  </Stack>
                </Form>
              )}
            </Formik>
          ) : (
            <PrimarySpinner />
          )}
        </VStack>
      </Flex>

      <Flex mb={0} flexDir="column">
        <VStack
          w="100%"
          rounded="md"
          p={2}
          position="relative"
          color="eastBay09"
          align="left"
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h4" size="md">
              Sign-up Link for external members
            </Heading>
            <Text>
              Share this link with new users to join the platform without the
              need of a membership. <br /> Prices for meeting room or workspace
              bookings for these members will be calculated from the non-members
              hour rate.
            </Text>
          </Box>
          <Stack w={['100%', '100%', '70%']}>
            {platformTenant &&
              !isIlumaTenant &&
              getTenantSignUpLinkComponent(platformTenant)}
          </Stack>
        </VStack>
      </Flex>
      <Flex mb={3} flexDir="column">
        <VStack
          w="100%"
          rounded="md"
          px={2}
          py={5}
          position="relative"
          color="eastBay09"
          align="left"
          justifyContent="space-between"
        >
          <Box>
            <Heading as="h4" size="md">
              Location Sign-up Links
            </Heading>
          </Box>
          <Stack w={['100%', '100%', '70%']}>
            {platformTenant &&
              !isIlumaTenant &&
              platformTenant.locations.map((location) => {
                let allendaleComponent =
                  location.name.toLowerCase().includes('fern') &&
                  platformTenant.id === 1

                return (
                  <>
                    {''}
                    {allendaleComponent &&
                      getAllendaleLocationComponent(location)}
                    <LocationURL location={location} />
                  </>
                )
              })}
            {isIlumaTenant &&
              platformTenant.locations &&
              getIlumaHubLocationComponent(platformTenant.locations[0])}
          </Stack>
        </VStack>
      </Flex>
    </Box>
  )
}

const getTenantSignUpLinkComponent = (platform_tentant: IPlatformTenant) => {
  let tenant = platform_tentant.business_name ?? 'signup_today'
  tenant = tenant.replaceAll(' ', '_').replaceAll('-', '_')
  const URL = `${
    window.location.host +
    routeStrings.externalSignupMain.split(':')[0] +
    tenant +
    '-' +
    platform_tentant.id
  }`
  return (
    <LocationURL
      overrideURL={URL}
      description="Share this link with external members"
      location={{
        id: 9999,
        name: platform_tentant.business_name ?? '',
        city: {
          id: 1,
          name: 'Perth',
          timezone: 'Australia/Perth'
        },
        address: '',
        order_of_preference: 1,
        thumbnail_url: platform_tentant.logo_long ?? '',
        platform_tenant_name: platform_tentant.business_name ?? '',
        platform_tenant_id: platform_tentant.id
      }}
    />
  )
}

const getIlumaHubLocationComponent = (location: Location) => {
  return (
    <LocationURL
      overrideURL={
        window.location.host + routeStrings.plan + '/' + ILUMA_PLAN_UUID
      }
      location={{
        id: 9999,
        name: location.name,
        city: {
          id: 1,
          name: 'Perth',
          timezone: 'Australia/Perth'
        },
        address: '',
        order_of_preference: 1,
        thumbnail_url: location.thumbnail_url,
        platform_tenant_name: 'Work Zone',
        platform_tenant_id: 0
      }}
    />
  )
}
const getAllendaleLocationComponent = (location: Location) => {
  return (
    <LocationURL
      overrideURL={
        window.location.host +
        routeStrings.plan +
        '/' +
        FERN_ALLENDALE_PLAN_UUID
      }
      location={{
        id: 9999,
        name: 'Allendale Business Lounge',
        address: location.address,
        city: {
          id: 1,
          name: 'Perth',
          timezone: 'Australia/Perth'
        },
        order_of_preference: 1,
        thumbnail_url: location.thumbnail_url,
        platform_tenant_name: 'Allendale Business Lounge',
        platform_tenant_id: 0
      }}
    />
  )
}
