import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routeStrings } from '../routeStrings'
import { IDailyFiveContent, IDailyFiveNotification } from '../types'
import { analyticsTrack } from '../utils/analytics'
import {
  useLastSevenDailyFive,
  useLatestDailyFive,
  useCommunityProfile
} from '../utils/apiHooks'
import { useAuth } from './auth-context'
import { saveContentToProfile, removeContentFromProfile } from '../api'

export interface CommunityProps {
  loading: boolean
  goToContent: (content: IDailyFiveContent | undefined) => void
  previewContent: IDailyFiveContent | undefined
  lastSeven: IDailyFiveNotification[] | undefined
  latest: IDailyFiveNotification | undefined
  isSaved: (content: IDailyFiveContent | undefined) => boolean
  handleSave: (content: IDailyFiveContent | undefined) => void
  handleUnSave: (content: IDailyFiveContent | undefined) => void
  setPreviewContent: (content: IDailyFiveContent | undefined) => void
}

const defaultProps: CommunityProps = {
  goToContent: (props: any) => console.log('not yet initialized'),
  isSaved: (props: any) => false,
  handleSave: (props: any) => console.log('not yet initialized'),
  handleUnSave: (props: any) => console.log('not yet initialized'),
  setPreviewContent: () => console.log('not yet initialized'),
  loading: true,
  lastSeven: undefined,
  latest: undefined,
  previewContent: undefined
}

export const CommunityContext =
  React.createContext<CommunityProps>(defaultProps)

export const CommunityProvider = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const { me, currentOrgUnit } = useAuth()

  const { data: latest } = useLatestDailyFive(
    currentOrgUnit?.organization.parent_platform_tenant
  )
  const { data: lastSeven } = useLastSevenDailyFive(
    currentOrgUnit?.organization.parent_platform_tenant
  )
  const navigate = useNavigate()
  const { data: communityProfile, mutate: revalidateCommunityProfile } =
    useCommunityProfile()
  const goToContent = (content: IDailyFiveContent | undefined) => {
    if (content !== undefined) {
      const trackingData = {
        userId: me?.user.id,
        contentId: content.id,
        title: content.title,
        contentType: content.content_type,
        tenantId: currentOrgUnit?.organization.parent_platform_tenant
      }
      analyticsTrack('View Daily Five Post', trackingData)
      navigate(routeStrings.memberDashboardViewPost(content.id))
    }
  }

  const handleSave = (content: IDailyFiveContent | undefined) => {
    if (content?.id) {
      try {
        saveContentToProfile(content?.id).then((response) => {
          revalidateCommunityProfile()
          analyticsTrack('Save Daily Five Post', {
            contentId: content.id,
            title: content.title,
            userId: me?.user.id,
            tenantId: currentOrgUnit?.organization.parent_platform_tenant
          })
        })
      } catch (e: any) {
        if (e.response && e.response.data) {
          console.log(e.response.data)
        }
      }
    }
  }
  const handleUnSave = (content: IDailyFiveContent | undefined) => {
    if (content?.id) {
      try {
        // unsave content from profile
        removeContentFromProfile(content?.id).then((response) => {
          revalidateCommunityProfile()
          analyticsTrack('Unsave Daily Five Post', {
            contentId: content.id,
            title: content.title,
            userId: me?.user.id
          })
        })
      } catch (e: any) {
        if (e.response && e.response.data) {
          console.log(e.response.data)
        }
      }
    }
  }

  const [previewContent, setPreviewContent] = useState<IDailyFiveContent>()

  const isSaved = (content: IDailyFiveContent | undefined) => {
    if (communityProfile) {
      return (
        communityProfile[0].saved_content?.find((saved_content) => {
          return saved_content.id === content?.id
        }) !== undefined
      )
    }
    return false
  }

  return (
    <CommunityContext.Provider
      value={{
        loading,
        goToContent,
        lastSeven,
        latest,
        isSaved,
        handleSave,
        handleUnSave,
        previewContent,
        setPreviewContent
      }}
    >
      {children}
    </CommunityContext.Provider>
  )
}

export const useCommunity = () => {
  const context = useContext(CommunityContext)
  if (context === undefined) {
    throw new Error('useCommunity must be used within a CommunityProvider')
  }
  return context
}
