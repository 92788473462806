import { Flex, Box, Text, Stack, HStack, Spacer } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  IBookingAdmin,
  IOrganization,
  IOrganizationDetail
} from '../../../types'
import { ChakraModal } from '../../../components/ChakraModal'
import { Button } from '../../../components/Button'
import { format } from 'date-fns'

interface IModalInput {
  isOpen: boolean
  organization: IOrganizationDetail | undefined
  onConfirm: () => void
  onClose: () => void
}

export const CreditChargesModal: React.FC<IModalInput> = ({
  isOpen,
  organization,
  onConfirm,
  onClose
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    setIsSubmitting(false)
  }, [isOpen])

  return (
    <ChakraModal
      title={'Credit Current Charges'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box rounded="md" p={4}>
        <Text fontSize="14px" letterSpacing="0.25px">
          Confirm the application of the credits to the team{' '}
          {organization?.name}
        </Text>
        <Flex flexDirection="row" mt={10}>
          <Spacer />
          <Button
            data-testid="cancel"
            variant="secondary"
            onClick={onClose}
            mr="10px"
          >
            Cancel
          </Button>

          <Button
            disabled={isSubmitting}
            key="submit"
            data-testid="assign"
            onClick={() => {
              setIsSubmitting(true)
              onConfirm()
            }}
            mr="8px"
          >
            Apply credit
          </Button>
        </Flex>
      </Box>
    </ChakraModal>
  )
}
