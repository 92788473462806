import { Box, Alert, AlertIcon } from '@chakra-ui/react'
import React from 'react'

interface Props {
  removed?: boolean
}
export const RemovedOrganizationAlert: React.FC<Props> = ({ removed }) => {
  if (!removed) return null
  return (
    <Box my={2}>
      <Alert status="error">
        <AlertIcon />
        This company has been deactivated
      </Alert>
    </Box>
  )
}
