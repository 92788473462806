import {
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Tag,
  Text,
  Tooltip
} from '@chakra-ui/react'
import React from 'react'
import { faTv, faUsers, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, isWithinInterval } from 'date-fns'

import placeholder from '../assets/placeholder.svg'
import { IBooking } from '../types'
import { getFormatedBookingDates } from '../pages/MemberDashboard/Workspaces/meetingRoomUtils'
import { useNavigate } from 'react-router-dom'

interface IInput {
  bookings: IBooking[]
  title: string
}

export const MeetingRoomBookingGroup: React.FC<IInput> = (input) => {
  const navigate = useNavigate()

  const { bookings, title, ...rest } = input

  return (
    <Box mt={4} {...rest}>
      <Text fontSize="16px" fontWeight="bold">
        {title}
      </Text>

      {bookings.length === 0 && (
        <Text pl={5} mt={3} fontSize="16px">
          No Bookings
        </Text>
      )}

      {bookings.map((booking, index) => {
        const { startTime: formattedStartDate, endTime: formattedEndDate } =
          getFormatedBookingDates(booking)

        return (
          <Flex
            opacity={booking.cancelled_at ? 0.6 : 1}
            key={'bkg' + index}
            flexDirection="row"
            bg="white"
            width={['100%', '389px']}
            marginY="8px"
            padding="16px"
            cursor="pointer"
            _hover={{ background: '#faffff' }}
            rounded="lg"
            shadow={'md'}
            onClick={() => navigate(`/dashboard/booking/${booking.id}`)}
          >
            <Stack marginRight="18px" spacing={0}>
              <Text fontWeight={600} fontSize={'14px'}>
                {format(formattedStartDate, 'E')}
              </Text>
              <Text fontSize={'22px'}>{format(formattedStartDate, 'd')}</Text>
              <Text fontSize={'14px'}>{format(formattedStartDate, 'MMM')}</Text>
            </Stack>
            <HStack align="self-start" justify="space-between" flex={1}>
              <Stack maxW={['150px', '200px']}>
                {booking.is_external && (
                  <Text color={'#888'} m={0} p={0} fontSize="xs">
                    {booking.platform_tenant_name}
                  </Text>
                )}
                <Text fontWeight="bold" fontSize={'14px'} isTruncated>
                  {booking.meeting_room_name}
                  {booking.workspace_name}
                </Text>
                <Text color={'#888'} m={0} p={0}>
                  {booking.location_address}
                </Text>
                <HStack justifyContent={'left'}>
                  {booking?.capacity && (
                    <Tooltip label="Capacity">
                      <Text fontSize={'xs'}>
                        <FontAwesomeIcon icon={faUsers} /> {booking?.capacity}
                      </Text>
                    </Tooltip>
                  )}
                  {booking?.has_videoconference && (
                    <Tooltip label="Video Conference">
                      <Text fontSize={'xs'}>
                        <FontAwesomeIcon icon={faVideo} /> VC
                      </Text>
                    </Tooltip>
                  )}
                  {booking?.has_screen_mirroring && (
                    <Tooltip label="Screen Mirroring">
                      <Text fontSize={'xs'}>
                        <FontAwesomeIcon icon={faTv} /> SM
                      </Text>
                    </Tooltip>
                  )}
                </HStack>
                <Text fontSize={'14px'}>
                  {format(formattedStartDate, 'h:mm a') +
                    '-' +
                    format(formattedEndDate, 'h:mm a')}
                  <br />
                  <Text as="span" fontSize={'10px'} color="9b9b9b">
                    {booking.timezone}
                  </Text>
                </Text>
                {isWithinInterval(new Date(), {
                  start: formattedStartDate,
                  end: formattedEndDate
                }) && (
                  <Box>
                    <Tag size="sm" colorScheme="green" rounded="full">
                      In Progress
                    </Tag>
                  </Box>
                )}
                {booking.cancelled_at && (
                  <Box>
                    <Tag size="sm" colorScheme="red" rounded="full">
                      CANCELLED
                    </Tag>
                  </Box>
                )}
              </Stack>
              {booking.meeting_room_display_url && (
                <Box right={0}>
                  <Image
                    h="60px"
                    w="60px"
                    objectFit="cover"
                    src={booking.meeting_room_display_url}
                    fallbackSrc={placeholder}
                  />
                </Box>
              )}
            </HStack>
          </Flex>
        )
      })}
    </Box>
  )
}
