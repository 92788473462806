import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Link, Text, useToast } from '@chakra-ui/react'
import React from 'react'

interface IFileUpload {
  accept:
    | 'application/pdf'
    | 'image/png, image/jpeg'
    | 'application/pdf, image/png, image/jpeg'
  documentType: string
  uploadedFile: File | undefined
  setUploadedFile: (file: File | undefined) => void
  onRemoveFile?: () => Promise<string>
  initialValue?: string | undefined
  fileUrl?: string | undefined
  isUploadingFile?: boolean
}

export const FileUploadComponent: React.FC<IFileUpload> = ({
  setUploadedFile,
  onRemoveFile,
  documentType,
  uploadedFile,
  initialValue,
  isUploadingFile,
  fileUrl,
  accept,
  ...rest
}) => {
  const toast = useToast()
  return (
    <>
      <Box display="flex">
        {uploadedFile ? (
          <Box>
            <Text {...rest} pr={1}>
              {uploadedFile
                ? `${uploadedFile?.name}`
                : `${initialValue} ${documentType}`}
            </Text>
            <DeleteIcon
              cursor="pointer"
              color="brandPrimary"
              onClick={() => setUploadedFile(undefined)}
            />
          </Box>
        ) : (
          initialValue && (
            <Flex my={1}>
              <Link color="#255CE1" href={fileUrl} isExternal pr={1}>
                {`${documentType}`}
              </Link>
              <AttachmentIcon color="brandPrimary" />
              {fileUrl && (
                <Button
                  ml={3}
                  size={'xs'}
                  isLoading={isUploadingFile}
                  onClick={() => {
                    try {
                      navigator.clipboard.writeText(fileUrl)
                      toast({
                        description: 'Link copied!',
                        status: 'info',
                        duration: 1000
                      })
                    } catch (error) {}
                  }}
                >
                  Copy Link
                </Button>
              )}
              {onRemoveFile != undefined && fileUrl && (
                <Button
                  ml={3}
                  isLoading={isUploadingFile}
                  colorScheme="red"
                  size={'xs'}
                  variant="outline"
                  onClick={() => {
                    onRemoveFile()
                  }}
                >
                  Remove File
                </Button>
              )}
            </Flex>
          )
        )}
      </Box>

      <input
        disabled={isUploadingFile}
        style={{
          borderRadius: '0px',
          border: '1px dashed #ccc',
          padding: '5px',
          width: '300px'
        }}
        type="file"
        id="image"
        accept={accept}
        onChange={(e) => {
          setUploadedFile(e.target.files![0])
        }}
      />
    </>
  )
}
