import React, { useEffect, useState } from 'react'

import { SWRConfig } from 'swr'
import { request } from './api'
import { AuthProvider } from './context/auth-context'
import { BrowserRouter } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import { FeatureFlagProvider } from './context/feature-flag-context'
import { IndividualFeatureFlagProvider } from './context/individual-feature-flag-context'
import { ToastProvider } from './context/toast-context'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { RoleProvider } from './utils/rbac'
import { QueryParamProvider } from 'use-query-params'
import { EditableThemeProvider } from './context/editable-theme-context'
import { PlatformTenantProvider } from './context/platform-tenant-context'
import { MobileEmbedProvider } from './context/mobile-embed-context'
import { initErrorTracking } from './utils/errorTracking'
import { MembersDirectoryProvider } from './context/members-directory-context'
import { AppTourProvider } from './context/app-tour-context'
import { CenteredSpinner } from './styled/CenteredSpinner'
import { ReactNodeWithProps } from './component.types'

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env

const stripeKey =
  REACT_APP_STRIPE_PUBLIC_KEY !== undefined ? REACT_APP_STRIPE_PUBLIC_KEY : ''

const history = createBrowserHistory()

initErrorTracking(history)

// onboarding screens -> spacecubed
// member dashboard screens -> specified coworking space

type AppProvidersProps = {} & ReactNodeWithProps

export const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  const [stripe, setStripe] = useState<any>()

  useEffect(() => {
    if (!window.Stripe) {
      document.querySelector('#stripe-js')?.addEventListener('load', () => {
        setStripe(window.Stripe(stripeKey, {}))
      })
    } else {
      setStripe(window.Stripe(stripeKey, {}))
    }
    return () => {
      document
        .querySelector('#stripe-js')
        ?.removeEventListener('load', () => {})
    }
  }, [])

  // if (!stripe) return <CenteredSpinner />

  return (
    <SWRConfig
      value={{
        fetcher: (url: string) =>
          request<any>(url, 'GET').then((res) => res.data)
      }}
    >
      {/* <StripeProvider stripe={stripe}> */}
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <AuthProvider>
            <RoleProvider>
              <PlatformTenantProvider>
                <EditableThemeProvider>
                  <FeatureFlagProvider>
                    <IndividualFeatureFlagProvider>
                      <ToastProvider>
                        <MobileEmbedProvider>
                          <MembersDirectoryProvider>
                            <AppTourProvider>{children}</AppTourProvider>
                          </MembersDirectoryProvider>
                        </MobileEmbedProvider>
                      </ToastProvider>
                    </IndividualFeatureFlagProvider>
                  </FeatureFlagProvider>
                </EditableThemeProvider>
              </PlatformTenantProvider>
            </RoleProvider>
          </AuthProvider>
        </QueryParamProvider>
      </BrowserRouter>
      {/* </StripeProvider> */}
    </SWRConfig>
  )
}
