import { Box, BoxProps, Flex, Image, Text, useTheme } from '@chakra-ui/react'

import React from 'react'
import { Location } from '../types'

interface Props extends BoxProps {
  location: Location
  selected: boolean
}

export const LocationAdminSelector: React.FC<Props> = (props) => {
  const { location, selected, ...rest } = props
  const theme = useTheme()
  return (
    <Flex
      p={{ base: 1, md: 2 }}
      background="#fff"
      borderRadius="lg"
      borderWidth={selected ? 2 : undefined}
      borderColor={selected ? theme.colors.brandPrimary ?? '#888' : undefined}
      w={{ sm: '45%', md: '250px' }}
      m={1}
      cursor={'pointer'}
      {...rest}
    >
      <Image
        borderRadius="lg"
        maxHeight={['40px', '70px']}
        w={['40px', '30%']}
        src={location.thumbnail_url}
        alt={location.name}
      />

      <Box mt={{ base: 0, md: 0 }} ml={{ base: 2, lg: 4 }}>
        <Text
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          wordBreak={'break-all'}
        >
          {location.name}
        </Text>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize={'xx-small'}
          letterSpacing="wide"
          color="#999"
          textOverflow={'ellipsis'}
        >
          {location.address}
        </Text>
      </Box>
    </Flex>
  )
}
