import React, { useEffect } from 'react'
import { Spinner } from '@chakra-ui/react'
import { useAuth } from '../context/auth-context'

export const Logout: React.FC = () => {
  const auth = useAuth()

  useEffect(() => {
    if (auth.logoutUser) {
      auth.logoutUser()
    }
  })

  return <Spinner />
}
