import React, { useEffect, useState } from 'react'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'

import {
  GroupingHeaderLayout,
  IActionButton
} from '../../components/GroupingHeaderLayout'
import { Teams } from './Tables/Teams'
import { InitializeTeamModal } from './Modals/InitializeTeamModal'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { fetchPaginatedOrganizationsByName } from '../../api'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { StripeConnectRequiredModal } from './Modals/StripeConnectRequiredModal'
import { IOrganization } from '../../types'
import {
  Box,
  Flex,
  HStack,
  Input,
  Select,
  Skeleton,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { FilterableSelect } from '../../components/FilterableSelect'
import { usePlans } from '../../utils/apiHooks'
import { SKELETON_SIX_ROWS } from '../../utils/constants'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { tourTargetStrings } from '../../tourStepsArrays'
import { DebouncedInputText } from '../../components/DebouncedInputText'

export interface TableDropdownOption {
  text: string
  testIdPrefix?: string
  variant: string
  onClick: (row: any) => void
}

export const AdminDashboardTeams: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const { tenantIntegration, platformTenant } = usePlatformTenant()
  const { stripeConnectOnboardingEnabled } = useFeatureFlags()
  const [orgs, setOrgs] = useState<IOrganization[]>([])
  const [orgFilter, setOrgFilter] = useState<string>()
  const [total, setTotal] = useState(0)
  const page_size = 10
  const [page, setPage] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFeching] = useState(false)

  const [filterLocations, setFilterLocations] = useState<string | undefined>()
  const [planFilter, setPlanFilter] = useState<number>()

  const { plans } = usePlans(platformTenant?.id, true)

  const headerAction: IActionButton = {
    text: 'Initialise Team',
    testIdPrefix: 'initialise-team',
    className: tourTargetStrings.adminheaderTeamsInitialise,
    variant: '',
    onClick: () => {
      completeTenantSetUp
        ? setSetUpModalIsVisible(true)
        : setModalIsVisible(true)
    }
  }

  // State to hide/show invite modal
  const [modalIsVisible, setModalIsVisible] = useState(false)
  const [setUpModalIsVisible, setSetUpModalIsVisible] = useState(false)

  const fetchOrgs = (
    input_page: number,
    name: string | undefined,
    location_id?: string | undefined,
    plan_id?: number | undefined
  ) => {
    if (isFetching) return
    if (platformTenant) {
      setIsFeching(true)
      return fetchPaginatedOrganizationsByName(
        platformTenant.id,
        name,
        location_id,
        plan_id,
        input_page,
        page_size
      )
        .then((res) => {
          if (res && res.data) {
            let results = res.data.results ?? []
            setOrgs(input_page > 1 ? orgs.concat(results) : results)
            setTotal(res.data.count ?? 0)
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }

  useEffect(() => {
    if (platformTenant) {
      setOrgs([])
      setIsFeching(true)
      setOrgFilter('')
      setIsFeching(false)
      fetchOrgs(1, '')
    }
  }, [platformTenant])

  useEffect(() => {
    if (platformTenant && orgFilter != undefined && !isFetching) {
      fetchOrgs(1, orgFilter, filterLocations, planFilter)
    }
  }, [orgFilter, filterLocations, planFilter])

  let completeTenantSetUp =
    stripeConnectOnboardingEnabled &&
    tenantIntegration &&
    !tenantIntegration.is_stripe_connected

  const MemoizedTeams = React.memo<{
    orgs: IOrganization[] | undefined
  }>((props) => {
    return <Teams orgs={props.orgs} />
  })
  return (
    <LayoutAdminDashboard>
      {/* Header */}
      <GroupingHeaderLayout
        basicTitle="Teams"
        totalValue={total ? total : ''}
        action={headerAction}
      />
      <AdminBodyWrapper
        onBottomReached={() => {
          if (isFetching || orgFilter === undefined || !hasMoreResults) return
          fetchOrgs(page, orgFilter, filterLocations, planFilter)
        }}
      >
        {/* Tables */}
        <Box bg="white" w={'100%'} shadow={4} rounded="md" px={8} py={5}>
          <VStack w="100%" alignItems="left">
            <Flex>
              <Text fontWeight="medium" fontSize={22}>
                Filter search
              </Text>
              {isFetching && <Spinner size="sm" />}
            </Flex>
            <HStack w="100%" spacing={3}>
              <Box w={['100%', '30%']}>
                <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                  Name
                </Text>
                <HStack spacing={3}>
                  <DebouncedInputText
                    bg={'white'}
                    placeholder={'All teams'}
                    value={orgFilter}
                    setValue={setOrgFilter}
                  />
                </HStack>
              </Box>
              <Box w={['100%', '30%']}>
                <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                  Location
                </Text>

                <Select
                  background="white"
                  id="memberLocation"
                  placeholder="All locations"
                  color="#8b96a3"
                  value={filterLocations}
                  onChange={(ev) => setFilterLocations(ev.target.value)}
                >
                  {platformTenant?.locations.map((loc) => {
                    return (
                      <option key={loc.name} value={loc.id}>
                        {loc.name}
                      </option>
                    )
                  })}
                </Select>
              </Box>
              <Box w={['100%', '30%', '25%']}>
                <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                  Plan
                </Text>

                <FilterableSelect
                  dataTestId="booking-select-room-id"
                  isClearable={true}
                  items={plans.map((p) => {
                    return { label: p.name, value: p.id }
                  })}
                  isOptionDisabled={(value) => value.disabled}
                  onSelect={(n) => {
                    setPlanFilter(n >= 0 ? n : undefined)
                  }}
                />
              </Box>
            </HStack>
          </VStack>
        </Box>

        <MemoizedTeams orgs={orgs} />
        {isFetching && (
          <VStack my={2} spacing={1}>
            {SKELETON_SIX_ROWS.map((i) => (
              <Skeleton key={i} w={'100%'} h={14} />
            ))}
          </VStack>
        )}
      </AdminBodyWrapper>

      {/* Modals */}
      <InitializeTeamModal
        isOpen={modalIsVisible}
        closeModal={() => setModalIsVisible(false)}
      />
      <StripeConnectRequiredModal
        isOpen={setUpModalIsVisible}
        closeModal={() => setSetUpModalIsVisible(false)}
      />
    </LayoutAdminDashboard>
  )
}
