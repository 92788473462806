import React from 'react'
import { InputProps, Input } from '@chakra-ui/react'

export const StyledInput: React.FC<InputProps> = ({ children, ...rest }) => {
  return (
    <Input
      background="#FAFAFA"
      border="0px"
      borderRadius="0px"
      borderBottom="1px"
      borderColor="eastBay07"
      w="100px"
      {...rest}
    ></Input>
  )
}
