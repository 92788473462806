import {
  Box,
  Text,
  useTheme,
  CloseButton,
  Flex,
  Heading
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/auth-context'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { analyticsTrack, ANALYTICS_MODULE_CHATBOT } from '../utils/analytics'
import { useRole } from '../utils/rbac'

interface Props {
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  onToggle: () => void
}

export const MainAIChatBotDrawer: React.FC<Props> = (props) => {
  const { onClose, isOpen } = props
  const { me } = useAuth()
  const { isInAdminRole } = useRole()
  const [hidden, setHidden] = useState(false)
  const { platformTenant } = usePlatformTenant()
  const [position, setPosition] = useState<'left' | 'right'>('right')
  const theme = useTheme()

  useEffect(() => {
    if (isOpen) {
      analyticsTrack('Open AI Bot Drawer', {
        module: ANALYTICS_MODULE_CHATBOT,
        userId: me?.user.id,
        tenant: platformTenant?.id,
        isUser: !isInAdminRole,
        isAdmin: isInAdminRole
      })
    }
  }, [isOpen])
  return (
    <motion.div
      // {...getDisclosureProps()}
      hidden={hidden}
      initial={false}
      onAnimationStart={() => setHidden(false)}
      onAnimationComplete={() => setHidden(!isOpen)}
      animate={{ width: isOpen ? 500 : 0 }}
      style={{
        background: 'white',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'absolute',
        right: position == 'right' ? '0' : undefined,
        left: position == 'left' ? '0' : undefined,
        height: '100vh',
        top: '0',
        borderLeftWidth: '1px'
      }}
    >
      <Flex p={3} borderBottomWidth="1px" justifyContent="space-between">
        <Box>
          <Text
            style={{
              background: theme.colors?.brandPrimary,
              lineHeight: '11px',
              textAlign: 'right',
              borderRadius: '3px',
              width: '30px',
              color: 'white',
              padding: '2px',
              fontSize: '11px'
            }}
          >
            Beta
          </Text>
          <Heading size="md">
            AI Chatbot Assistant for{' '}
            {isInAdminRole ? 'Space Admins' : 'Members'}
          </Heading>
        </Box>
        <CloseButton onClick={onClose} />
      </Flex>
      <Box p={5}>
        {isInAdminRole &&
          platformTenant?.enable_admin_chatbot &&
          platformTenant?.admin_chatbot_iframe_html && (
            <Box>
              <Box
                dangerouslySetInnerHTML={{
                  __html: platformTenant.admin_chatbot_iframe_html
                }}
              ></Box>
            </Box>
          )}
        {!isInAdminRole &&
          platformTenant?.enable_user_chatbot &&
          platformTenant?.user_chatbot_iframe_html && (
            <Box>
              <Box
                dangerouslySetInnerHTML={{
                  __html: platformTenant.user_chatbot_iframe_html
                }}
              ></Box>
            </Box>
          )}
      </Box>
    </motion.div>
  )
}
