import {
  Box,
  Container,
  Flex,
  Button as ChakraButton,
  Heading,
  Text,
  Stack,
  ContainerProps,
  Avatar,
  Divider
} from '@chakra-ui/react'
import React from 'react'
import { useBookingContext } from '../../../../context/BookingContext'
import { IPublicMeetingRoom } from '../../../../types'
import { useRole } from '../../../../utils/rbac'
import { ReactNodeWithProps } from '../../../../component.types'

interface Props {
  meetingRoom: IPublicMeetingRoom
}

export const LocationDetailsContainer: React.FC<Props & ReactNodeWithProps> = (
  props
) => {
  const { meetingRoom, children } = props
  const { isExternalMember } = useRole()

  const { locations: bookableLocations } = useBookingContext()
  const isExternalBooking =
    isExternalMember ||
    !bookableLocations.find((l) => l.id === meetingRoom?.location)
  const boxStyles: ContainerProps = {
    py: 8,
    px: 10,
    bg: 'white',
    shadow: 'lg',
    rounded: 'lg'
  }

  return (
    <Container maxW={'6xl'} {...boxStyles}>
      <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
        <Stack w={['100%', '100%', '50%']} pr={[1, 2, 5]}>
          <Box>
            <Stack mt={6} direction={'row'} spacing={4}>
              <Avatar src={meetingRoom.platformTenant.logo_square} />
              <Stack
                direction={'column'}
                spacing={0}
                fontSize={'sm'}
                w={['100%', '100%']}
              >
                <Text fontWeight={600} fontSize="2xl">
                  {meetingRoom.platformTenant.business_name}
                </Text>
                {/* <Text color={'gray.500'}>Something something</Text> */}
              </Stack>
            </Stack>
            <Box display={'row'} mt={5}>
              {meetingRoom.platformTenant.contact_form_url && (
                <ChakraButton
                  colorScheme="gray"
                  variant={'solid'}
                  as={'a'}
                  href={meetingRoom.platformTenant.contact_form_url}
                  target={'_blank'}
                  background={meetingRoom.platformTenant.brand_primary_color}
                  textColor={'white'}
                  size={'sm'}
                >
                  Contact
                </ChakraButton>
              )}
            </Box>
            {meetingRoom.platformTenant.id === 1 && (
              <>
                <Divider my={[2, 5, 6]} />
                <Stack spacing={4}>
                  <Heading size={'md'}>About this space</Heading>
                  <Flex w="100%" flexShrink={0} p={2}>
                    <Text
                      wordBreak="break-word"
                      textAlign={'justify'}
                      whiteSpace="pre-line"
                      dangerouslySetInnerHTML={{
                        __html: meetingRoom.locationSettings.booking_about ?? ''
                      }}
                    ></Text>
                  </Flex>
                </Stack>
                <Divider my={[2, 5, 6]} />

                <Stack spacing={4}>
                  <Heading size={'md'}>Rules</Heading>
                  <Flex w="100%" flexShrink={0} p={2}>
                    <Text
                      wordBreak="break-word"
                      textAlign={'justify'}
                      whiteSpace="pre-line"
                      dangerouslySetInnerHTML={{
                        __html: meetingRoom.locationSettings.booking_rules ?? ''
                      }}
                    ></Text>
                  </Flex>
                </Stack>
              </>
            )}
          </Box>
          <Stack></Stack>
        </Stack>
        <Stack py={6} w={['100%', '100%', '50%']}>
          <Box
            maxW={'600px'}
            w={'full'}
            borderWidth={1}
            rounded={'md'}
            p={6}
            overflow={'hidden'}
          >
            <Stack spacing={0}>
              <Text
                color={meetingRoom.platformTenant.brand_primary_color}
                textTransform={'uppercase'}
                fontWeight={800}
                fontSize={'sm'}
                letterSpacing={1.1}
              >
                Member Price
              </Text>
              <Text
                color={'gray.800'}
                fontSize={'4xl'}
                fontFamily={'body'}
                textAlign="center"
                fontWeight={'bold'}
              >
                ${meetingRoom?.member_price_per_hour}{' '}
                <Text as={'span'} fontSize="xs">
                  {' '}
                  per hour
                </Text>
              </Text>
              {!isExternalBooking && (
                <>
                  <Divider />
                  <Text
                    color={'gray.600'}
                    fontSize={'md'}
                    fontFamily={'body'}
                    textAlign="center"
                  >
                    {meetingRoom?.credits_per_hour}
                    <Text as={'span'} fontSize="xs">
                      {' '}
                      Credits per hour
                    </Text>
                  </Text>
                </>
              )}
            </Stack>
          </Box>
          <Box
            maxW={'600px'}
            w={'full'}
            borderWidth={1}
            rounded={'md'}
            p={6}
            overflow={'hidden'}
          >
            <Stack>
              <Text
                color={meetingRoom.platformTenant.brand_primary_color}
                textTransform={'uppercase'}
                fontWeight={800}
                fontSize={'sm'}
                letterSpacing={1.1}
              >
                Non Member Price
              </Text>
              <Text
                color={'gray.800'}
                fontSize={'4xl'}
                fontFamily={'body'}
                textAlign="center"
                fontWeight={'bold'}
              >
                ${meetingRoom?.non_member_price_per_hour}{' '}
                <Text as={'span'} fontSize="xs">
                  {' '}
                  per hour
                </Text>
              </Text>
            </Stack>
          </Box>
          {children}
        </Stack>
      </Flex>
    </Container>
  )
}
