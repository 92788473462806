import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Box,
  Text,
  ModalFooter,
  Button
} from '@chakra-ui/react'
import { UserDisplay } from '../../../components/UserDisplay'
import { analyticsTrack } from '../../../utils/analytics'

interface Props {
  isOpen: boolean
  onClose: () => void
  otherUserName: string
  otherUserEmail: string
  message?: string
}

export const ConnectionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  otherUserName,
  otherUserEmail,
  message
}) => {
  const onConnectClick = () => {
    analyticsTrack('Accept connection', {
      otherUserEmail,
      otherUserName
    })
    window.open(`mailto:${otherUserEmail}`, '_new')
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Member Connection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5}>
            <Text>
              One of the benefits of being a Spacecubed member is networking
              with other coworking members, and meeting new people. We've
              connected you with
            </Text>

            <Stack isInline spacing={3} align="center" justify="center">
              <UserDisplay name={otherUserName} size="lg" />
              <Text fontSize="xl">{otherUserName}</Text>
            </Stack>
            {message && (
              <Box>
                <Text fontWeight="bold" mb={2}>
                  Connection message:
                </Text>
                <Box bg="gray.50" w="100%" p={5} borderRadius="sm">
                  <Text>{message}</Text>
                </Box>
              </Box>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConnectClick}>
            Connect via email
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
