import { Box, ModalProps, Stack, Text } from '@chakra-ui/react'

import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Location } from '../../../../types'
import { BookingLocationAdmins } from '../../BookingSettings/BookingLocationAdmins'

interface Props extends ModalProps {
  location: Location
}

export const PanelLocationAdmins: React.FC<Props> = ({
  location,
  isOpen,
  ...rest
}) => {
  return (
    <Stack>
      <Box>
        <Text fontWeight="bold" fontSize="sm" mb={1}>
          Location Admins
        </Text>
        <Text color="#4F4F4F" fontSize="xs">
          Add specific admins to this space and manage the notifications they
          receive about{' '}
          <Text as={'span'} fontWeight="bold">
            External Bookings.
          </Text>
        </Text>
      </Box>
      <BookingLocationAdmins location={location} />
    </Stack>
  )
}
