import { useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  GroupingHeaderLayout,
  IActionButton
} from '../../components/GroupingHeaderLayout'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useBookingContext } from '../../context/BookingContext'
import { IWorkspaceSuperAdmin } from '../../types'
import { Actions, useRBAC } from '../../utils/rbac'
import { AdminDashboardWorkspacesAdmin } from './AdminDashboardWorkspacesAdmin'
import { AdminDashboardWorkspacesBookings } from './AdminDashboardWorkspacesBookings'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { CreateWorkspaceModal } from './Modals/CreateWorkspaceModal'

const workspacePages = [
  {
    text: 'Workspace bookings',
    id: 'workspace-bookings'
  },
  {
    text: 'Workspaces',
    id: 'workspaces'
  }
]

export const AdminDashboardWorkspaces: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<IWorkspaceSuperAdmin>()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const canAdministerWorkspaces = useRBAC(Actions.AdministerWorkspaces)
  const [workspacePage, setWorkspacePage] = useQueryParam('type', StringParam)
  const { revalidateAdminWorkspaces } = useBookingContext()
  useEffect(() => {
    if (!workspacePage) {
      setWorkspacePage(workspacePages[0].id)
    }
  }, [workspacePage])

  const headerActions: IActionButton[] = []
  workspacePage == 'workspaces' &&
    canAdministerWorkspaces &&
    headerActions.push({
      text: 'New workspace',
      variant: 'primary',
      onClick: () => {
        setSelectedWorkspace(undefined)
        onOpen()
      }
    })

  const getMeetingRoomPage = () => {
    if (workspacePage == 'workspaces') {
      revalidateAdminWorkspaces()
      return <AdminDashboardWorkspacesAdmin />
    }
    if (workspacePage == 'workspace-bookings') {
      return <AdminDashboardWorkspacesBookings />
    }
  }

  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout
        basicTitle="Workspaces"
        tabs={workspacePages.map((page) => {
          return {
            text: page.text,
            active: workspacePage == page.id,
            selectAction: () => {
              setWorkspacePage(page.id)
            }
          }
        })}
        action={headerActions}
      />
      {getMeetingRoomPage()}
      <CreateWorkspaceModal
        onClose={() => {
          onClose()
          revalidateAdminWorkspaces()
        }}
        isOpen={isOpen}
        workspaceToEdit={selectedWorkspace}
      />
    </LayoutAdminDashboard>
  )
}
