import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { assignOffice } from '../../../api'
import { ChakraModal } from '../../../components/ChakraModal'
import { useCustomToast } from '../../../context/toast-context'
import { IOfficeListDetail } from '../../../types'
import { Button } from '../../../components/Button'
import { FilterableOrganizationsSelect } from '../../../components/FilterableOrganizationsSelect'

interface IModalInput {
  isOpen: boolean
  office: IOfficeListDetail | undefined
  closeModal: () => void
}

export const AssignOfficeModal: React.FC<IModalInput> = (input) => {
  const { isOpen, office, closeModal: closeCallback } = input

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<number>(0)
  const [chosenStartDate, setChosenStartDate] = useState<Date>(new Date())

  const { newToast: toast } = useCustomToast()

  function closeModal() {
    setSelectedOrganizationId(0)
    closeCallback()
  }

  function submit() {
    if (office && selectedOrganizationId) {
      setIsSubmitting(true)
      assignOffice(office.id, selectedOrganizationId, chosenStartDate)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Team Assigned to ${office.name}`
          })
          closeModal()
        })
        .catch((e) => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to assign office'
          })
        })
    }
  }

  if (office === undefined) {
    return null
  }

  return (
    <ChakraModal title="Assign a Team" isOpen={isOpen} onClose={closeModal}>
      <FilterableOrganizationsSelect
        name="Select a team"
        initialValue={selectedOrganizationId}
        onSelect={(n) => setSelectedOrganizationId(n)}
      />

      <Box mt={3}>
        <Stack isInline fontWeight="bold" mb={2}>
          <Text color="#2f4858">Start Date: </Text>
          <Text color="#ce2121">{chosenStartDate.toLocaleDateString()}</Text>
        </Stack>
        <Box>
          <Calendar
            selectRange={false}
            onChange={(date) => {
              setChosenStartDate(
                Array.isArray(date) ? (date[0] as Date) : (date as Date)
              )
            }}
            value={chosenStartDate}
            minDate={new Date()}
          />
        </Box>
      </Box>

      <Flex flexDirection="row" mt={10}>
        <Button
          key="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          data-testid="assign"
          onClick={submit}
          mr="8px"
        >
          Save changes
        </Button>
        <Button
          data-testid="cancel"
          disabled={isSubmitting}
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Flex>
    </ChakraModal>
  )
}
