import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { format } from 'date-fns'

interface Props {
  time: Date
}

export const KioskClock: React.FC<Props> = ({ time }) => {
  return <Heading color="white">{format(time, 'hh:mm aa')}</Heading>
}
