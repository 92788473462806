import React from 'react'
import { Step } from 'react-joyride'

interface ITourStepsArray {
  generalMemberSteps: Step[]
  spaceAdminSteps: Step[]
}

const generalStyles: React.CSSProperties = { textAlign: 'left', fontSize: 'sm' }
const bold: React.CSSProperties = { fontWeight: 'bold' }

export const tourTargetStrings = {
  sbTeam: 'tour-sb-team',
  sbDirectory: 'tour-sb-directory',
  sbCalendar: 'tour-sb-calendar',
  sbMeeting: 'tour-sb-meeting-rooms',
  sbWorkspaces: 'tour-sb-workspaces',
  sbBilling: 'tour-sb-billing',
  sbCreate: 'tour-sb-create-post',
  sbProfile: 'tour-sb-profile',
  sbTeams: 'tour-sb-teams',

  tourSidebar: 'tour-sidebar',
  adminheaderInit: 'tour-adminheader-init',
  adminheaderAdminUserMenu: 'tour-adminheader-adminuser-menu',

  dashboardBookings: 'tour-dashboard-bookings',
  dashboardBilling: 'tour-dashboard-billing',
  dashboardDirectory: 'tour-dashboard-directory',
  dashboardWifi: 'tour-dashboard-wifi',
  adminheaderRequests: 'tour-adminheader-requests',
  adminheaderMembers: 'tour-adminheader-members',
  adminheaderTeams: 'tour-adminheader-teams',
  adminheaderTeamsInitialise: 'tour-adminheader-teams-initialise',
  adminheaderLocations: 'tour-adminheader-locations',
  adminheaderLocationsCreate: 'tour-adminheader-locations-create',
  adminheaderLocationsOffice: 'tour-adminheader-locations-office',
  adminheaderPlans: 'tour-adminheader-plans',
  adminheaderPlansCreate: 'tour-adminheader-plans-create',
  adminheaderPlansCategory: 'tour-adminheader-plans-office',
  adminheaderReports: 'tour-adminheader-reports',
  adminheaderMeetingrooms: 'tour-adminheader-meetingrooms',
  adminheaderMeetingroomsBooking: 'tour-adminheader-meetingrooms-booking',
  adminheaderMeetingroomsMeetingRoom: 'tour-adminheader-meetingrooms-create',
  adminheaderWorkspaces: 'tour-adminheader-workspaces',
  adminheaderCommunity: 'tour-adminheader-community',
  adminheaderSettings: 'tour-adminheader-settings',
  adminheaderSettingsPlatform: 'tour-adminheader-settings-platform',
  adminheaderSettingsInvoices: 'tour-adminheader-settings-invoices',
  adminheaderSettingsEmail: 'tour-adminheader-settings-email',
  adminheaderSettingsSignup: 'tour-adminheader-settings-signup',
  adminheaderSettingsIntegration: 'tour-adminheader-settings-integration'
}
export const tourStepsArrays: ITourStepsArray = {
  generalMemberSteps: [
    {
      title: 'A Tour through the platform!',

      target: '.' + tourTargetStrings.tourSidebar,
      placement: 'center',
      disableOverlayClose: true,
      content: (
        <p style={generalStyles}>
          Let's have a look around the Platform where you will be able to manage
          your Team, Bookings, Billing Information and engage with your
          community!
        </p>
      )
    },
    {
      title: 'Your Team',
      target: '.' + tourTargetStrings.sbTeam,
      disableOverlayClose: true,
      content: 'Manage your team members and your memberships'
    },
    {
      title: 'Meeting Rooms',
      target: '.' + tourTargetStrings.sbMeeting,
      disableOverlayClose: true,
      content: 'Book Meeting Rooms available in your space or other locations.'
    },

    {
      title: 'Members Directory',
      target: '.' + tourTargetStrings.sbDirectory,
      disableOverlayClose: true,
      content: 'Find members inside your community and connect with them'
    },
    {
      title: 'Workspaces',
      target: '.' + tourTargetStrings.sbWorkspaces,
      disableOverlayClose: true,
      content: 'Hire a desk for the day for you or another colleague!'
    },
    {
      title: 'Daily Five',
      target: '.' + tourTargetStrings.sbCreate,
      content: 'Create Posts or Events to engage with your community!'
    },
    {
      title: 'Billing Info',
      target: '.' + tourTargetStrings.sbBilling,
      content: 'Manage your Payment Details and everything about your billing'
    },

    {
      title: 'Bookings Overview',
      target: '.' + tourTargetStrings.dashboardBookings,
      content:
        'You can view your upcoming reservations as well as the remaining balance of your Booking Credits.',
      showSkipButton: true
    },
    {
      title: 'Billing Overview',
      target: '.' + tourTargetStrings.dashboardBilling,
      content: 'Have a look at your upcoming billing.',
      showSkipButton: true
    },
    {
      title: 'Members Directory Overview',

      target: '.' + tourTargetStrings.dashboardDirectory,
      content: 'Check your latest connections within your community!',
      showSkipButton: true
    },
    {
      title: 'Wifi Overview',
      target: '.' + tourTargetStrings.dashboardWifi,
      content: 'Check your Wifi credentials',
      showSkipButton: true
    }
  ],

  spaceAdminSteps: [
    {
      title: 'A tour through the Platform',
      target: '#' + tourTargetStrings.adminheaderInit,
      disableOverlayClose: true,
      content: (
        <p>
          Learn and understand how to navigate the Platform from a{' '}
          <span style={bold}>Space Admin</span> point of view.
        </p>
      ),
      placement: 'center'
    },
    {
      title: 'Plan Requests',
      target: '.' + tourTargetStrings.adminheaderRequests,
      content: 'Approve or Decline Membership Requests'
    },
    {
      title: 'Members',
      target: '.' + tourTargetStrings.adminheaderMembers,
      content: 'Find Members in all your spaces.'
    },
    {
      title: 'Teams',
      target: '.' + tourTargetStrings.adminheaderTeams,
      content: 'All the teams can be found here.'
    },
    {
      title: 'Invite a Team',
      target: '.' + tourTargetStrings.adminheaderTeamsInitialise,
      content: 'Initialise a team with its admin Members Plans and Offices'
    },
    {
      title: 'Locations & Offices',
      target: '.' + tourTargetStrings.adminheaderLocations,
      content:
        'Manage your Spaces and Offices. Assign teams and configure External Bookings.'
    },
    {
      target: '.' + tourTargetStrings.adminheaderLocationsCreate,
      content: 'Add all the locations you manage'
    },
    {
      target: '.' + tourTargetStrings.adminheaderLocationsOffice,
      content: 'Add offices to each location and and assign them to your teams.'
    },
    {
      title: 'Plans',
      target: '.' + tourTargetStrings.adminheaderPlans,
      content: 'Manage your Memberships and Add-ons'
    },
    {
      target: '.' + tourTargetStrings.adminheaderPlansCategory,
      content: 'Differentiate revenue into accounts with these categories'
    },
    {
      target: '.' + tourTargetStrings.adminheaderPlansCreate,
      content:
        'Create flexible memberships and manage them to be visible by new members on the sign-up pages.'
    },
    {
      title: 'Platform Reports',
      target: '.' + tourTargetStrings.adminheaderReports,
      content: 'Get insights on Invoices and Changes on Memberships'
    },
    {
      title: 'Meeting Rooms & Bookings',
      target: '.' + tourTargetStrings.adminheaderMeetingrooms,
      content: 'Manage your Meeting Rooms: prices, availability, bookings.'
    },
    {
      target: '.' + tourTargetStrings.adminheaderMeetingroomsBooking,
      content: (
        <p style={generalStyles}>
          Create and manage Bookings for you and your members.
          <br />
          Check the cost and whether or not they are active members.
        </p>
      )
    },
    {
      title: 'Meeting Rooms',
      target: '.' + tourTargetStrings.adminheaderMeetingroomsMeetingRoom,
      content:
        'Create meeting rooms to be booked by members and external users. '
    },
    {
      title: 'Workspaces',
      target: '.' + tourTargetStrings.adminheaderWorkspaces,
      content: 'Manage your Workspaces: prices, availability, bookings.'
    },
    {
      title: 'Community',
      target: '.' + tourTargetStrings.adminheaderCommunity,
      content: 'Review Content to be shared in your Space.'
    },
    {
      title: 'Settings',
      target: '.' + tourTargetStrings.adminheaderSettings,
      content: 'Manage your Platform Settings. '
    },
    {
      target: '.' + tourTargetStrings.adminheaderSettingsPlatform,
      content:
        'Set up your brand and configure your Hubspot Help Widget to be displayed to your members.'
    },
    {
      target: '.' + tourTargetStrings.adminheaderSettingsSignup,
      content: "Get and share Links to your locations' memberships."
    },
    {
      target: '.' + tourTargetStrings.adminheaderSettingsInvoices,
      content: (
        <p style={{ ...generalStyles, fontSize: '14px' }}>
          Set up the information included in the invoices for members.
          <br />
          Configure the <span style={bold}>EFT Account</span> to be displayed on
          the Invoices that have this Payment Method enabled.
        </p>
      )
    },
    {
      target: '.' + tourTargetStrings.adminheaderSettingsEmail,
      content: (
        <p style={{ ...generalStyles, fontSize: '14px' }}>
          <li>Edit the branding of your emails</li>
          <li> Add Social Media links.</li>
          <li> Custom your "Welcome Message!" to new members.</li>
        </p>
      )
    },

    {
      target: '.' + tourTargetStrings.adminheaderSettingsIntegration,
      content: (
        <p style={generalStyles}>
          Connect your Account with different Integrations
          <li>Stripe: Payment Platform</li>
          <li>XERO: Accounting System</li>
          <li>Humanitix: Events Synchronization</li>
          <li>AI Chatbots</li>
        </p>
      )
    }
  ]
}
