import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Container,
  Flex,
  FormLabel,
  Heading,
  Stack,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { Button } from '../../components/Button'
import { PrimarySpinner } from '../../components/chakra-v2/PrimarySpinner'
import { StyledInput } from '../../components/StyledInputs'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useCustomToast } from '../../context/toast-context'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { InputWarning } from '../../styled/InputWarning'
import { IPlatformTenant, IPlatformTenantUpdate } from '../../types'
import { EMAIL_REGEX } from '../../utils/constants'
import { EditEFTAccountModal } from './Modals/EditEFTAccountModal'

export const AdminDashboardPlatformTenantInvoiceSettings: React.FC = () => {
  const { platformTenant, adminUpdatePlatformTenant } = usePlatformTenant()

  const toast = useCustomToast()
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (!platformTenant) {
    return <CenteredSpinner />
  }
  return (
    <Container maxW="full" centerContent>
      <VStack
        bg="white"
        w="100%"
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        rounded="md"
        px={8}
        py={5}
        position="relative"
        color="eastBay09"
        align="center"
        justifyContent="space-between"
      >
        <EditEFTAccountModal isOpen={isOpen} onClose={onClose} />
        {platformTenant ? (
          <Formik
            initialValues={{
              support_link: platformTenant.support_link,
              business_name: platformTenant.business_name,
              abn: platformTenant.abn,
              invoice_logo_long: undefined,
              address: platformTenant.address,
              email_from_address_billing:
                platformTenant.email_from_address_billing
            }}
            validate={(values): IPlatformTenant => {
              const errors: any = {}
              if (!values.abn) {
                errors.abn = 'ABN cannot be empty'
              }
              if (!values.business_name) {
                errors.business_name = 'Business name cannot be empty'
              }
              if (!values.address) {
                errors.address = 'Address cannot be empty'
              }
              if (
                values.email_from_address_billing &&
                !EMAIL_REGEX.test(values.email_from_address_billing)
              )
                errors.email_from_address_billing = 'Invalid email address'
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)

              const data: IPlatformTenantUpdate = {
                support_link: values.support_link,
                business_name: values.business_name,
                abn: values.abn,
                invoiceLogoLongImageFile: values.invoice_logo_long,
                email_from_address_billing: values.email_from_address_billing,
                address: values.address
              }
              try {
                const response = await adminUpdatePlatformTenant(data)
                if (response.status === 200) {
                  setSubmitting(false)
                  toast.newToast({
                    status: 'success',
                    description: 'Invoice Settings updated'
                  })
                } else {
                  setSubmitting(false)
                  toast.newToast({
                    status: 'error',
                    description: 'An error occurred'
                  })
                }
              } catch (e) {
                setSubmitting(false)
                toast.newToast({
                  status: 'error',
                  description: 'An error occurred'
                })
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, touched, errors }) => (
              <Form>
                <Stack spacing={8}>
                  <Stack w="80%">
                    <Text fontWeight="bold" fontSize="26px">
                      Invoice branding settings
                    </Text>
                    <Heading size="sm">EFT Account</Heading>
                    <Box
                      w="70%"
                      display="inline-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text fontSize="xs">
                        Teams that have this Payment Method enabled will
                        exclusively see the account information on their
                        invoices.
                      </Text>
                      <Button onClick={onOpen}>Edit Account</Button>
                    </Box>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Billing From email address
                    </FormLabel>
                    <Text fontSize="xs">
                      Billing emails will be dispatched from this specified
                      email address.
                    </Text>
                    <StyledInput
                      w={['100%', '70%']}
                      value={values.email_from_address_billing || ''}
                      placeholder={`accounts@${
                        platformTenant.business_name?.toLowerCase() ??
                        'spacecubed'
                      }.com`}
                      onChange={(e) => {
                        setFieldValue(
                          'email_from_address_billing',
                          e.target.value
                        )
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="email_from_address_billing"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Business Name
                    </FormLabel>
                    <StyledInput
                      placeholder="Spacecubed Ventures Pty Ltd"
                      w={['100%', '70%']}
                      value={values.business_name || ''}
                      onChange={(e) => {
                        setFieldValue('business_name', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="business_name"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      ABN
                    </FormLabel>
                    <StyledInput
                      placeholder="12 121 121 121"
                      w={['100%', '70%']}
                      value={values.abn || ''}
                      onChange={(e) => {
                        setFieldValue('abn', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage name="abn" component={InputWarning} />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Business Address
                    </FormLabel>
                    <StyledInput
                      placeholder="45 St Georges Tce, Perth, WA 6000"
                      w={['100%', '70%']}
                      value={values.address || ''}
                      onChange={(e) => {
                        setFieldValue('address', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage name="address" component={InputWarning} />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Invoice Logo Long (Should be dark text with transparent
                      background)
                    </FormLabel>
                    <Flex
                      position="relative"
                      background=" #EEEEEE"
                      backgroundImage={
                        values.invoice_logo_long
                          ? `url("${URL.createObjectURL(
                              values.invoice_logo_long
                            )}")`
                          : `url("${platformTenant.invoice_logo_long}")`
                      }
                      backgroundSize="cover"
                      h="67px"
                      w="329px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {!values.invoice_logo_long &&
                        !platformTenant.invoice_logo_long && (
                          <AddIcon color="#BDBDBD" />
                        )}

                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          height: '90px',
                          width: '329px',
                          borderRadius: '0px',
                          background: '#757575',
                          cursor: 'pointer'
                        }}
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setFieldValue('invoice_logo_long', e.target.files![0])
                        }}
                      />
                    </Flex>
                    <Flex>
                      <ErrorMessage
                        name="invoice_logo_long"
                        component={InputWarning}
                      />
                    </Flex>
                  </Stack>
                  <Box>
                    <Button type="submit" disabled={isSubmitting}>
                      Update settings
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <PrimarySpinner />
        )}
      </VStack>
    </Container>
  )
}
