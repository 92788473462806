import {
  Box,
  TagProps,
  Collapse,
  Flex,
  Spacer,
  CloseButton,
  useToast
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { addInterestUserProfile } from '../../../api'
import { AlertBox } from '../../../components/AlertBox'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { Interest } from '../../../types'

interface Props extends TagProps {
  isOpen: boolean
  interest?: Interest
  toggle: (interestAdded?: boolean) => void
}

export const PromptAddInterest: React.FC<Props> = ({
  isOpen,
  toggle,
  interest,
  ...rest
}) => {
  const { platformTenant } = usePlatformTenant()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const toastPopup = useToast()

  const handleAddInterest = () => {
    if (interest === undefined) return
    setIsSubmitting(true)
    addInterestUserProfile(interest)
      .then((res) => {
        toastPopup({
          position: 'top',
          duration: 5000,
          render: (onClose) => (
            <AlertBox onClose={onClose}>
              User preferences updated
              {/*  <Button onClick={() => handleUndoAddInterest(interest)}>
                Undo
              </Button> */}
            </AlertBox>
          ),
          isClosable: true
        })
        // fetchTags()
        toggle(true)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Collapse in={isOpen}>
      <Box
        p="10px"
        mt="0"
        rounded="md"
        w="inherit"
        // css={content && content.length > 3 ? fixed : ''}
      >
        <Flex width="100%">
          <Flex
            py={'1'}
            px={'2'}
            height="40px"
            color="white"
            border="1px solid"
            borderColor={platformTenant?.brand_primary_color || '#EB4969'}
            bg={platformTenant?.brand_primary_color || '#EB4969'}
            fontWeight="500"
            borderRadius="50px"
            transition="all ease .2s"
            _hover={{
              cursor: 'pointer',
              transform: 'scale(1.008)',
              boxShadow: '0px 22px 22px rgba(0, 0, 0, 0.04)'
            }}
          >
            <Box
              p={'1'}
              onClick={() => {
                !isSubmitting && handleAddInterest()
              }}
            >
              Add&nbsp;
              <strong>{interest?.name}</strong>&nbsp;to your Interests.
            </Box>
            <CloseButton onClick={() => toggle()} />
          </Flex>
          <Spacer />
        </Flex>
      </Box>
    </Collapse>
  )
}
