import { AddIcon } from '@chakra-ui/icons'
import { Box, Flex, FormLabel, Stack, Text, VStack } from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Button } from '../../components/Button'
import { PrimarySpinner } from '../../components/chakra-v2/PrimarySpinner'
import { StyledInput } from '../../components/StyledInputs'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { InputWarning } from '../../styled/InputWarning'
import { IPlatformTenant, IPlatformTenantUpdate, Location } from '../../types'
import 'react-quill/dist/quill.snow.css'

import { IS_VALID_HTTP_URL } from '../../utils/formUtils'
import { useCustomToast } from '../../context/toast-context'
export const AdminDashboardPlatformTenantPlatformSettings: React.FC = () => {
  const { platformTenant, adminUpdatePlatformTenant } = usePlatformTenant()

  const toast = useCustomToast()

  if (!platformTenant) {
    return <CenteredSpinner />
  }
  return (
    <Flex mb={3} flexDir="column">
      <VStack
        bg="white"
        w="100%"
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        rounded="md"
        px={8}
        py={5}
        position="relative"
        color="eastBay09"
        align="center"
        justifyContent="space-between"
      >
        {platformTenant ? (
          <Formik
            initialValues={{
              brand_primary_color: platformTenant.brand_primary_color,
              logo_long_white: undefined,
              logo_long: undefined,
              logo_square: undefined,
              hubspot_help_widget_code: platformTenant.hubspot_help_widget_code,

              support_link: platformTenant.support_link
            }}
            validate={(values): IPlatformTenant => {
              const errors: any = {}
              if (
                values.brand_primary_color &&
                values.brand_primary_color.length > 7
              ) {
                errors.brand_primary_color = 'Hex colour code invalid'
              }
              if (!IS_VALID_HTTP_URL(values.support_link)) {
                errors.support_link = 'Support Link URL invalid'
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)

              const data: IPlatformTenantUpdate = {
                brand_primary_color: values.brand_primary_color,

                logoLongWhiteImageFile: values.logo_long_white,
                logoLongImageFile: values.logo_long,
                logoSquareImageFile: values.logo_square,
                hubspot_help_widget_code: values.hubspot_help_widget_code,

                support_link: values.support_link
              }
              try {
                const response = await adminUpdatePlatformTenant(data)
                if (response.status === 200) {
                  setSubmitting(false)
                  toast.newToast({
                    status: 'success',
                    description: 'Sign-up Settings updated'
                  })
                } else {
                  setSubmitting(false)
                  toast.newToast({
                    status: 'error',
                    description: 'An error occurred'
                  })
                }
              } catch (e) {
                setSubmitting(false)
                toast.newToast({
                  status: 'error',
                  description: 'An error occurred'
                })
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, touched, errors }) => (
              <Form>
                <Stack spacing={8}>
                  <Stack>
                    <Text fontWeight="bold" fontSize="26px">
                      Platform branding settings
                    </Text>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Brand Primary Colour
                    </FormLabel>
                    <Flex>
                      <Flex
                        backgroundColor={
                          values.brand_primary_color || '#FAFAFA'
                        }
                        width="60px"
                        h="40px"
                        justify="center"
                        align="center"
                        color="white"
                      ></Flex>
                      <StyledInput
                        placeholder="#FFFFFF"
                        ml="16px"
                        value={values.brand_primary_color || ''}
                        onChange={(e) => {
                          setFieldValue('brand_primary_color', e.target.value)
                        }}
                        w="100px"
                      ></StyledInput>
                    </Flex>

                    <ErrorMessage
                      name="brand_primary_color"
                      component={InputWarning}
                    />

                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Logo Long White (Should be white text with transparent
                      background)
                    </FormLabel>
                    <Flex
                      position="relative"
                      background="#757575"
                      backgroundImage={
                        values.logo_long_white
                          ? `url("${URL.createObjectURL(
                              values.logo_long_white
                            )}")`
                          : `url("${platformTenant.logo_long_white}")`
                      }
                      backgroundSize="cover"
                      h="67px"
                      w="329px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {!values.logo_long_white &&
                        !platformTenant.logo_long_white && (
                          <AddIcon color="#BDBDBD" />
                        )}
                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          height: '67px',
                          width: '329px',
                          borderRadius: '0px',
                          background: '#757575',
                          cursor: 'pointer'
                        }}
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setFieldValue('logo_long_white', e.target.files![0])
                        }}
                      />
                    </Flex>
                    <ErrorMessage
                      name="logo_long_white"
                      component={InputWarning}
                    />
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Logo Long (Should be dark text with transparent
                      background)
                    </FormLabel>
                    <Flex
                      position="relative"
                      background=" #EEEEEE"
                      backgroundImage={
                        values.logo_long
                          ? `url("${URL.createObjectURL(values.logo_long)}")`
                          : `url("${platformTenant.logo_long}")`
                      }
                      backgroundSize="cover"
                      h="67px"
                      w="329px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {!values.logo_long && !platformTenant.logo_long && (
                        <AddIcon color="#BDBDBD" />
                      )}

                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          height: '67px',
                          width: '329px',
                          borderRadius: '0px',
                          background: '#757575',
                          cursor: 'pointer'
                        }}
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setFieldValue('logo_long', e.target.files![0])
                        }}
                      />
                    </Flex>
                    <ErrorMessage name="logo_long" component={InputWarning} />
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Logo Square
                    </FormLabel>
                    <Flex
                      position="relative"
                      background=" #EEEEEE"
                      backgroundImage={
                        values.logo_square
                          ? `url("${URL.createObjectURL(values.logo_square)}")`
                          : `url("${platformTenant.logo_square}")`
                      }
                      backgroundSize="cover"
                      h="90px"
                      w="90px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {!values.logo_square && !platformTenant.logo_square && (
                        <AddIcon color="#BDBDBD" />
                      )}

                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          height: '90px',
                          width: '90px',
                          borderRadius: '0px',
                          background: '#757575',
                          cursor: 'pointer'
                        }}
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setFieldValue('logo_square', e.target.files![0])
                        }}
                      />
                    </Flex>
                    <ErrorMessage name="logo_square" component={InputWarning} />
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Hubspot Help Widget Code
                    </FormLabel>

                    <StyledInput
                      placeholder="1234567"
                      value={values.hubspot_help_widget_code || ''}
                      onChange={(e) => {
                        setFieldValue(
                          'hubspot_help_widget_code',
                          e.target.value
                        )
                      }}
                      w="100px"
                    ></StyledInput>
                    <ErrorMessage
                      name="hubspot_help_widget_code"
                      component={InputWarning}
                    />
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Support Link URL
                    </FormLabel>
                    <StyledInput
                      placeholder="https://spacecubed.com/contact-us"
                      w="100%"
                      value={values.support_link || ''}
                      onChange={(e) => {
                        setFieldValue('support_link', e.target.value)
                      }}
                    ></StyledInput>
                    <ErrorMessage
                      name="support_link"
                      component={InputWarning}
                    />
                  </Stack>
                  <Box>
                    <Button type="submit" disabled={isSubmitting}>
                      Update settings
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <PrimarySpinner />
        )}
      </VStack>
    </Flex>
  )
}
