import { Flex, Box, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IOfficeDetail, IOfficeListDetail } from '../../../types'
import { unassignOffice } from '../../../api'
import { useCustomToast } from '../../../context/toast-context'
import { ChakraModal } from '../../../components/ChakraModal'
import { Button } from '../../../components/Button'

interface IModalInput {
  isOpen: boolean
  office: IOfficeListDetail | undefined
  closeModal: () => void
}

export const UnassignOfficeModal: React.FC<IModalInput> = (input) => {
  const { isOpen, office, closeModal: closeCallback } = input

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { newToast: toast } = useCustomToast()

  function closeModal() {
    closeCallback()
  }

  function submit() {
    if (office) {
      if (isSubmitting) return
      setIsSubmitting(true)
      unassignOffice(office.id)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Office ${office.name} unassociated`
          })
          closeCallback()
        })
        .catch((e) => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: 'Failed to unassign office'
          })
        })
    }
  }

  if (office === undefined) {
    return null
  }

  return (
    <ChakraModal title="Unassign a Team" isOpen={isOpen} onClose={closeModal}>
      <Box p={2}>
        <Text>
          {`Are you sure you want to unassign ${office.organization?.name} from office ${office.name}?`}
        </Text>
      </Box>

      <Flex flexDirection="row" mt={10}>
        <Button
          disabled={isSubmitting}
          key="submit"
          data-testid="assign"
          onClick={submit}
          mr="8px"
        >
          Unassign Team
        </Button>
        <Button
          key="submit"
          data-testid="cancel"
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Flex>
    </ChakraModal>
  )
}
