import {
  Box,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import { faGlobe, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { UserDisplay } from '../../../../components/UserDisplay'
import { DirectoryOrganizationResult } from '../../../../types'

interface Props {
  organization: DirectoryOrganizationResult
}

export const DirectoryResultOrganizationProfile: React.FC<Props> = (props) => {
  const { organization } = props
  const { organization_profile: profile } = organization
  const theme: any = useTheme()

  const linkStyle = {
    fontSize: '11px',
    color: theme.colors.primary ?? '#777',
    marginTop: '0px',
    fontWeight: 'bold' as 'bold'
  }

  const disabledLink = {
    fontSize: '11px',
    color: '#777',
    cursor: 'not-allowed',
    opacity: '0.5',
    textDecoration: 'none'
  }

  return (
    <Box background={'white'} borderRadius="lg" p={4} my={3} boxShadow="sm">
      <Flex>
        <Box w={['100%']}>
          <Flex>
            <Box mr={4}>
              <UserDisplay
                my="5px"
                size="lg"
                name={organization.name}
                src={profile.logo_url}
              />
            </Box>
            <Box>
              {/* COntact Data */}
              <Stack px={2}>
                <Heading my={0} lineHeight={'1'} size={'sm'}>
                  {profile.organization.name}
                </Heading>

                <Stack spacing={0}>
                  <Link
                    href={profile.website}
                    style={profile.website ? linkStyle : disabledLink}
                    color={theme.colors.primary}
                    isExternal
                  >
                    <FontAwesomeIcon icon={faGlobe} /> Website
                  </Link>

                  <Text color={'#444'} fontSize="xs">
                    {profile.mobile_number}
                  </Text>
                  <Link
                    href={profile.linkedin_url}
                    style={profile.linkedin_url ? linkStyle : disabledLink}
                    isExternal
                  >
                    LinkedIn profile
                  </Link>
                </Stack>
              </Stack>
            </Box>
          </Flex>
          <Box w={['100%']} py={1}>
            <Box>
              {/* Home */}
              {organization.home_location_name && (
                <Text
                  fontWeight={'bold'}
                  fontSize="xx-small"
                  color="#666"
                  lineHeight={'1'}
                >
                  <FontAwesomeIcon icon={faHome} />{' '}
                  {organization.home_location_name}
                  <Text
                    as="span"
                    color={'#777'}
                    fontSize="xx-small"
                    fontWeight={'normal'}
                  >
                    {', ' + organization.home_location_address}
                  </Text>
                </Text>
              )}
            </Box>
            <Flex my={2} flexWrap="wrap">
              {profile.industries?.map((tag, index) => (
                <Text
                  border={'1px'}
                  borderColor={'#757575'}
                  borderRadius="md"
                  color={'#777'}
                  px={1}
                  my={1}
                  mr={1}
                  fontSize="xx-small"
                  maxW="100%"
                  overflow={'hidden'}
                  textOverflow="ellipsis"
                  whiteSpace={'nowrap'}
                  key={index}
                >
                  {tag.name}
                </Text>
              ))}
            </Flex>
          </Box>
          <Box mt={3} maxH="400px" overflow={'hidden'}>
            {/* ABOUT */}

            {profile.overview && profile.overview != '' && (
              <>
                <Heading size={'xs'} color="#444">
                  Overview
                </Heading>
                <Text fontSize={'xs'} color={'#777'}>
                  {profile.overview}
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
