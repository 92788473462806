import {
  Box,
  Code,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useToast,
  VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/Button'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
var regex = /^<iframe\b[^>]*>[^<]*<\/iframe>$/i

interface Props {}
const validateIframe = (value) => {
  const errors: any = {}
  if (value && !regex.test(value)) {
    return false
  }

  return true
}
export const AdminDashboardPlatformTenantChatBotSettings: React.FC<Props> = (
  props
) => {
  const { platformTenant, adminUpdatePlatformTenant, revalidateSettings } =
    usePlatformTenant()
  const { AIMembersChatbotEnabled } = useFeatureFlags()

  const [membersChatbotHTML, setMembersChatbotHTML] = useState<string>(
    platformTenant?.user_chatbot_iframe_html ?? ''
  )
  const [isValid, setValid] = useState(validateIframe(membersChatbotHTML))
  const [submitting, setSubmitting] = useState(false)
  const toast = useToast()

  useEffect(() => {
    if (platformTenant) {
      revalidateSettings()
    }
  }, [platformTenant])

  if (!platformTenant) {
    return <CenteredSpinner />
  }

  const updateAPI = () => {
    if (!platformTenant?.enable_user_chatbot) {
      toast({ title: 'You cannot update the Chatbot Value', status: 'error' })
      return
    }
    if (!validateIframe(membersChatbotHTML)) {
      toast({ title: 'The HTML code is not valid', status: 'error' })
      return
    }
    if (!submitting && AIMembersChatbotEnabled) {
      setSubmitting(true)
      adminUpdatePlatformTenant({
        user_chatbot_iframe_html: membersChatbotHTML
      })
        .then((res) => {
          toast({ title: 'Members Chatbot updated', status: 'success' })
          setSubmitting(false)
        })
        .catch((error) => {
          setSubmitting(false)
          toast({ title: 'An error ocurred', status: 'error' })
        })
    }
  }

  return (
    <Stack spacing={3} textAlign="left">
      <Box>
        <Heading size="sm">Members Chatbot</Heading>
      </Box>
      <Text>
        Set up an AI Chatbot to assist your Community of members to serve as
        their round-the-clock companion, ready to assist them with various
        aspects of their coworking journey. From answering questions about
        membership benefits, amenities, and events. The chatbot is here to
        streamline their interactions and ensure a seamless experience within
        our vibrant coworking community.
      </Text>
      <Text>
        <Text as={'span'} fontWeight="bold">
          Note:{' '}
        </Text>
        You probably need to add <Code>{window.location.origin}</Code> to the
        list of domains allowed.
      </Text>
      <Divider my="3" />
      <Box w={['100%', '100%', '70%']}>
        <Box>
          <Box p={1}>
            <Text fontWeight="800" fontSize="md">
              HTML Code (iframe element)
            </Text>
          </Box>
          <Spacer />
          <Textarea
            isDisabled={!platformTenant.enable_user_chatbot}
            name="admin_chatbot_iframe_html"
            placeholder={"<iframe src='https://....'> </iframe>"}
            value={membersChatbotHTML}
            onChange={(val) => {
              setMembersChatbotHTML(val.target.value)
            }}
            minH={'200px'}
            variant={'filled'}
          />
        </Box>

        {platformTenant.enable_user_chatbot ? (
          <Flex alignContent="right" w={['100%']} mt={3}>
            <Button disabled={submitting} onClick={updateAPI}>
              Update
            </Button>
          </Flex>
        ) : (
          <Text>Feature disabled</Text>
        )}
        {AIMembersChatbotEnabled && membersChatbotHTML && isValid && (
          <Box>
            <Divider my={3} />
            <Text fontWeight="800" fontSize="md">
              Preview of the Chat bot
            </Text>
            <Text fontSize="md">HTML Code (iframe element)</Text>
            {platformTenant?.enable_user_chatbot &&
              platformTenant?.user_chatbot_iframe_html && (
                // Only supports Chatbase for now
                <Box>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: membersChatbotHTML
                    }}
                  ></Box>
                </Box>
              )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}
