import {
  Flex,
  Stack,
  StackProps,
  Icon,
  Heading,
  IconProps
} from '@chakra-ui/react'
import React from 'react'
import { RouterLink } from '../../../../components/Link'
interface Props extends StackProps {
  header: string
  headerIcon?: React.ReactElement

  manageLink?: string
}

export const OverviewBox: React.FC<Props> = ({
  header,
  headerIcon,
  children,
  manageLink,

  ...rest
}) => (
  <Stack flexDir="column" alignContent="stretch" p={4} spacing={6} {...rest}>
    <Flex alignContent="center" justifyContent="space-between">
      <Stack isInline alignItems="baseline">
        {headerIcon}
        <Heading fontSize={['lg', 'sm']}>{header}</Heading>
      </Stack>
      {manageLink && (
        <RouterLink fontSize={['md', 'xs']} color="#255CE1" to={manageLink}>
          Manage
        </RouterLink>
      )}
    </Flex>
    {children}
  </Stack>
)
