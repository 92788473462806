import { Box, Flex, Image, useTheme } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getTermsAndConditions } from '../api'
import { RouterLink } from '../components/Link'
import ReactMarkdown from 'react-markdown'

export const TermsAndConditions: React.FC = () => {
  const [tcs, setTcs] = useState('loading...')
  useEffect(() => {
    getTermsAndConditions().then(({ data }: any) =>
      setTcs(data[0].terms_conditions_text)
    )
  }, [])
  const theme: any = useTheme()
  return (
    <Flex direction="column" overflow="hidden" flexGrow={1}>
      <Flex
        as="nav"
        py={3}
        px={5}
        justify="space-between"
        align="center"
        h={'66px'}
      >
        <RouterLink to={'/'}>
          <Image src={theme.logos.logo_long} width={200} />
        </RouterLink>
      </Flex>
      <Box w="100%" bg="#EEEEEE" position="relative" h="4px" />
      <Flex
        flexDir="column"
        align="center"
        overflow="scroll"
        pt={16}
        flexGrow={1}
      >
        <Box maxW="900px" padding={['20px', '20px', '20px', 0]}>
          <ReactMarkdown children={tcs} />
        </Box>

        {/* <Text style={{ whiteSpace: 'pre-wrap' }}>{tcs}</Text> */}
      </Flex>
    </Flex>
  )
}
