import { Flex } from '@chakra-ui/react'
// import 'antd/dist/antd.css'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { BackButton } from '../../components/BackButton'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { useInvoiceHTML } from '../../utils/apiHooks'

const Invoice = styled.div`
  width: 100%;

  > .invoice-box {
    background: white;
    max-width: 80%;
  }
`

export const AdminDashboardInvoiceView: React.FC = () => {
  const params = useParams<{ invoiceId: string }>()

  let invoiceId

  if (params) {
    invoiceId = parseInt(params.invoiceId || '')
  }

  const { data: invoiceHTML } = useInvoiceHTML(invoiceId)

  return (
    <AdminBodyWrapper>
      <Flex data-testid="admin-body-wrapper" flex={1}>
        <BackButton>Back</BackButton>
      </Flex>
      <Flex data-testid="admin-body-wrapper" pb={16} flex={1}>
        {invoiceHTML == null ? (
          <CenteredSpinner />
        ) : (
          <Invoice dangerouslySetInnerHTML={{ __html: invoiceHTML }} />
        )}
      </Flex>
    </AdminBodyWrapper>
  )
}
