import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Center,
  Flex,
  Heading,
  Input,
  SkeletonCircle,
  SkeletonText,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import {
  fetchMyConnections,
  removeMemberDirectoryConnection
} from '../../../api'
import { AdminBodyWrapper } from '../../../components/AdminBodyWrapper'
import { useAuth } from '../../../context/auth-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import {
  DirectoryMemberResult,
  DirectoryOrganizationResult
} from '../../../types'
import { DirectoryContactProfile } from './components/DirectoryContactProfile'
import { OrganizationProfileModal } from './components/OrganizationProfileModal'

export const DirectoryMyConnections: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const { currentOrgUnit } = useAuth()
  const page_size = 20
  const [memberFilter, setMemberFilter] = useState<string>('')
  const [page, setPage] = useState(1)
  const [debouncedTerm, setDebouncedTerm] = useState('')
  const [total, setTotal] = useState(-1)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [members, setMembers] = useState<DirectoryMemberResult[]>([])
  const [isFetching, setIsFeching] = useState(false)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenOrganizationModal,
    onClose: onCloseOrganizationModal,
    onOpen: onOpenOrganizationModal
  } = useDisclosure()
  const [selectedContact, setSelectedContact] =
    useState<DirectoryMemberResult>()
  const [selectedOrganization, setSelectedOrganization] =
    useState<DirectoryOrganizationResult>()
  const toast = useToast()
  const skeletons = [1, 2, 3, 4, 5, 6, 7]

  const fetchContacts = (input_page: number, textFilter: string) => {
    if (isFetching) return
    if (currentOrgUnit) {
      setIsFeching(true)
      return fetchMyConnections(
        input_page,
        page_size,
        currentOrgUnit.id,
        textFilter
      )
        .then((res) => {
          if (res && res.data) {
            let results = res.data.results ?? []
            setMembers(input_page > 1 ? members.concat(results) : results)

            setTotal(res.data.count ?? 0)
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }
  useEffect(() => {
    fetchContacts(1, memberFilter)
  }, [memberFilter])
  useEffect(() => {
    setMemberFilter('')
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => setMemberFilter(debouncedTerm), 600)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  const handleRemoveContact = (member: DirectoryMemberResult) => {
    setIsLoading(true)
    removeMemberDirectoryConnection(member.id)
      .then((res) => {
        setIsLoading(false)
        setMembers(members.filter((m) => m.id != member.id))
        setTotal(total - 1)
        toast({
          description: 'Contact removed from the directory',
          status: 'info'
        })
      })
      .catch((err) => {
        toast({
          description: 'An error occurred',
          status: 'error'
        })

        setIsLoading(false)
      })
  }

  if (!platformTenant) {
    return <CenteredSpinner />
  }
  return (
    <AdminBodyWrapper
      onScroll={(event) => {
        let element = event.target as HTMLElement
        if (
          element.scrollHeight - element.scrollTop <
          element.clientHeight * 1.2
        ) {
          if (hasMoreResults && !isFetching) {
            fetchContacts(page, memberFilter)
          }
        }
      }}
    >
      <Box bg="white" w={'100%'} boxShadow="sm" rounded="md" px={8} py={5}>
        <VStack w="100%" alignItems="left">
          <Flex flexDir={['column', 'column', 'column', 'row']} w="100%">
            <Box w={['100%', '100%', '40%', '30%']} mr={2}>
              <Text fontWeight={'bold'} fontSize={'sm'} mb={2}>
                {'Find a connection'}
              </Text>
              <Input
                bg={'contentBackgroundMain  '}
                placeholder={'Search'}
                onChange={(e) => setDebouncedTerm(e.target.value)}
              />
            </Box>
            <Spacer />
            {total >= 0 && (
              <Flex p={3}>
                <Text ml={3} fontSize={'xs'}>
                  {`${total} contact${total === 1 ? '' : 's'}`}
                </Text>
              </Flex>
            )}
          </Flex>
        </VStack>
      </Box>
      <Center>
        <Box w={['100%', '100%', '80%', '70%']} pr={3}>
          {members.length === 0 &&
            isFetching &&
            skeletons.map((s) => {
              return (
                <Box key={s} padding="6" my={1} boxShadow="lg" bg="white">
                  <SkeletonCircle size="10" />
                  <SkeletonText
                    mt="4"
                    noOfLines={4}
                    spacing="4"
                    skeletonHeight="2"
                  />
                </Box>
              )
            })}
          {members.length > 0 ? (
            members.map((member) => {
              return (
                <DirectoryContactProfile
                  key={member.id}
                  contact={member}
                  isLoading={isLoading}
                  displayOrganizationProfile={() => {
                    setSelectedOrganization(member.organization)
                    setTimeout(onOpenOrganizationModal, 0)
                  }}
                  displayMessage={() => {
                    setSelectedContact(member)
                    setTimeout(onOpen, 0)
                  }}
                  handleDisconnect={() => {
                    handleRemoveContact(member)
                  }}
                />
              )
            })
          ) : (
            <Center>
              {' '}
              <Heading size={'md'} color="#ccc" my={5}>
                <FontAwesomeIcon icon={faUserAlt} /> No connections found
              </Heading>
            </Center>
          )}
        </Box>
      </Center>
      <OrganizationProfileModal
        isOpen={isOpenOrganizationModal}
        onClose={onCloseOrganizationModal}
        orgID={selectedOrganization?.id}
      />
      <MessageDialog
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        contact={selectedContact}
      />
      {/* DirectoryContactProfile */}
    </AdminBodyWrapper>
  )
}

interface DialogProps {
  contact?: DirectoryMemberResult
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

const MessageDialog: React.FC<DialogProps> = (props) => {
  const { contact, isOpen, onClose, onOpen } = props
  const cancelRef = React.useRef<any>()

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text my={2}>
              Message {contact?.first_contact ? 'sent to' : 'from'}{' '}
              <Text as="span" fontWeight={'bold'}>
                {contact?.member.user.name}
              </Text>
            </Text>
            <Text textStyle={'italic'}>{contact?.message}</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            {/* <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme='red' ml={3}>
              Yes
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
