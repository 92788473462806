import {
  Box,
  Flex,
  Link,
  Stack,
  Text,
  useDisclosure,
  useTheme
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { useMyUserProfile } from '../../../utils/apiHooks'
import { USER_SUPPORT_LINK } from '../../../utils/constants'
import { WifiPasswordDisplay } from '../../../components/WifiPasswordDisplay'
import { RegenerateWifiPasswordModal } from '../Modals/RegenerateWifiPasswordModal'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { useAuth } from '../../../context/auth-context'
import { Button } from '../../../components/Button'
import { usePlatformTenant } from '../../../context/platform-tenant-context'

export const EditWifi: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  // const { data: userProfile, mutate } = useUserProfile()
  const auth = useAuth()
  const { data: userProfile, mutate } = useMyUserProfile(
    auth.me?.user.user_profile.id
  )
  const { supportUrl } = usePlatformTenant()
  const theme: any = useTheme()
  const { editWifiEnabled, coworkingSettingsEnabled } = useFeatureFlags()

  useEffect(() => {
    if (!isOpen) {
      mutate()
    }
  }, [isOpen])

  return (
    <Box my="3" maxWidth="650px">
      <Flex mb={3} flexDir="column">
        <Text mb={3} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Spacecubed WiFi Access
        </Text>
        <Stack
          bg="white"
          w="100%"
          boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
          rounded="md"
          px={8}
          py={5}
          position="relative"
          color="eastBay09"
          justifyContent="space-between"
        >
          {userProfile === undefined && <PrimarySpinner />}
          {userProfile?.wifi_password !== undefined ? (
            <>
              <Stack spacing={5}>
                <Box>
                  <Text mb={1}>
                    Your member account has access to WiFi at Spacecubed
                    locations. Your password is shown below.
                  </Text>
                  <WifiPasswordDisplay password={userProfile.wifi_password} />
                </Box>
                {editWifiEnabled && (
                  <Box>
                    <Button type="submit" onClick={onOpen}>
                      Regenerate WiFi Password
                    </Button>
                  </Box>
                )}
              </Stack>
              {isOpen && (
                <RegenerateWifiPasswordModal
                  isOpen={isOpen}
                  onClose={onClose}
                  userProfileId={userProfile.id}
                />
              )}
            </>
          ) : (
            <Text>
              Your account hasn't been set up for Spacecubed WiFi. Please{' '}
              <Link
                href={coworkingSettingsEnabled ? supportUrl : USER_SUPPORT_LINK}
              >
                contact support
              </Link>{' '}
              for more information.
            </Text>
          )}
        </Stack>
      </Flex>
    </Box>
  )
}
