import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { requestAccountDeletion, updateCommunityProfile } from '../../../api'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { ICommunityProfile } from '../../../types'
import { useCommunityProfile } from '../../../utils/apiHooks'
import { Button } from '../../../components/Button'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { GenericAlertDialog } from '../../AdminDashboard/Components/GenericAlertDialog'
import { useAuth } from '../../../context/auth-context'

export const EditSettings: React.FC = () => {
  const { me } = useAuth()
  const { data: communityProfile } = useCommunityProfile()
  const { userDeletionRequestsEnabled } = useFeatureFlags()
  const { dailyFiveEnabled } = useFeatureFlags()
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [isProcessing, setIsProcessing] = useState(false)
  const [status, setStatus] = useState<boolean>()

  useEffect(() => {}, [])

  const sendRequest = () => {
    if (isProcessing || !me) return
    setIsProcessing(true)
    onClose()
    requestAccountDeletion(me.user.id)
      .then((res) => {
        const { message } = res.data
        setIsProcessing(false)
        toast({
          title: 'Request sent',
          description: message,
          status: 'info',
          duration: 5000,
          isClosable: true
        })
      })
      .catch((err) => {
        setIsProcessing(false)
      })
  }

  if (!communityProfile) {
    return <PrimarySpinner />
  }

  return (
    <Box my="3" maxWidth="650px">
      {dailyFiveEnabled && (
        <Flex mb={3} flexDir="column">
          <Text
            mb={3}
            fontWeight="semibold"
            color="headingPrimary"
            fontSize="lg"
          >
            Notification Settings
          </Text>
          <Flex
            bg="white"
            w="100%"
            boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
            rounded="md"
            px={8}
            py={5}
            position="relative"
            color="eastBay09"
            align="center"
            justifyContent="space-between"
          >
            <Formik
              initialValues={{
                new_comment_added_email_notifications:
                  communityProfile[0].new_comment_added_email_notifications,
                new_comment_added_push_notifications:
                  communityProfile[0].new_comment_added_push_notifications,
                new_daily_five_ready_push_notifications:
                  communityProfile[0].new_daily_five_ready_push_notifications,
                new_daily_five_ready_email_notifications:
                  communityProfile[0].new_daily_five_ready_email_notifications,
                is_zoom_connected: communityProfile[0].is_zoom_connected
              }}
              validate={(values): ICommunityProfile => {
                const errors: any = {}

                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)

                const data: ICommunityProfile = {
                  new_comment_added_email_notifications:
                    values.new_comment_added_email_notifications,
                  new_comment_added_push_notifications:
                    values.new_comment_added_push_notifications,
                  new_daily_five_ready_push_notifications:
                    values.new_daily_five_ready_push_notifications,
                  new_daily_five_ready_email_notifications:
                    values.new_daily_five_ready_email_notifications,
                  is_zoom_connected: values.is_zoom_connected
                }

                if (communityProfile[0].id) {
                  updateCommunityProfile(data, communityProfile[0].id).then(
                    (res) => {
                      if (res.status === 200) {
                        setSubmitting(false)
                        setStatus(true)
                      } else {
                        setSubmitting(false)
                        setStatus(false)
                      }
                    }
                  )
                }
              }}
            >
              {({ values, setFieldValue, isSubmitting, touched, errors }) => (
                <Form>
                  <Stack spacing={8}>
                    <Stack>
                      <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                        New Comment
                      </FormLabel>

                      <Checkbox
                        isChecked={values.new_comment_added_email_notifications}
                        onChange={(e) => {
                          setFieldValue(
                            'new_comment_added_email_notifications',
                            e.target.checked
                          )
                        }}
                        color="#255CE1"
                      >
                        Email notification
                      </Checkbox>
                      <Checkbox
                        isChecked={values.new_comment_added_push_notifications}
                        onChange={(e) => {
                          setFieldValue(
                            'new_comment_added_push_notifications',
                            e.target.checked
                          )
                        }}
                        color="#255CE1"
                      >
                        Mobile push notification
                      </Checkbox>
                      <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                        New Daily Five
                      </FormLabel>

                      <Checkbox
                        isChecked={
                          values.new_daily_five_ready_email_notifications
                        }
                        onChange={(e) => {
                          setFieldValue(
                            'new_daily_five_ready_email_notifications',
                            e.target.checked
                          )
                        }}
                        color="#255CE1"
                      >
                        Email notification
                      </Checkbox>
                      <Checkbox
                        isChecked={
                          values.new_daily_five_ready_push_notifications
                        }
                        onChange={(e) => {
                          setFieldValue(
                            'new_daily_five_ready_push_notifications',
                            e.target.checked
                          )
                        }}
                        color="#255CE1"
                      >
                        Mobile push notification
                      </Checkbox>
                      <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                        Zoom connection
                      </FormLabel>

                      <Checkbox
                        isChecked={values.is_zoom_connected}
                        // onChange={(e) => {
                        //   setFieldValue('is_zoom_connected', e.target.checked)
                        // }}
                        color="#255CE1"
                      >
                        Zoom connected to platform
                      </Checkbox>
                    </Stack>
                    {status === true && (
                      <Text color="green.500">Profile Updated</Text>
                    )}
                    {status === false && (
                      <Text color="red.500">Failed to update profile</Text>
                    )}
                    <Box>
                      <Button type="submit" disabled={isSubmitting}>
                        Update settings
                      </Button>
                    </Box>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Flex>
        </Flex>
      )}
      {userDeletionRequestsEnabled && (
        <Flex my={10} flexDir="column">
          <Text
            mb={3}
            fontWeight="semibold"
            color="headingPrimary"
            fontSize="lg"
          >
            Account Settings
          </Text>
          <Flex
            bg="white"
            w="100%"
            boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
            rounded="md"
            px={8}
            py={5}
            position="relative"
            color="eastBay09"
            align="center"
            justifyContent="space-between"
          >
            <Flex
              justifyContent={'space-between'}
              w={'100%'}
              alignItems={'center'}
            >
              <Box maxW={'60%'}>
                <Text fontWeight="bold" fontSize="lg">
                  Account Deletion
                </Text>
                <Text fontSize="xs" mt={3}>
                  This process needs to be reviewed and approved by a Space
                  Admin to ensure there are no outstanding payments or
                  obligations.
                </Text>
              </Box>
              <Button
                variant="secondary"
                onClick={onOpen}
                isDisabled={isProcessing}
              >
                Send Request
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
      <GenericAlertDialog
        onClose={onClose}
        isConfirming={isProcessing}
        isOpen={isOpen}
        title={'Request Account Deletion'}
        description={
          'By clicking confirm, you will send a request to the Space Admins to delete your account. You will be notified once the request has been processed.'
        }
        confirmButtonText={'Confirm'}
        onConfirm={sendRequest}
      />
    </Box>
  )
}
