import React from 'react'
import { IStatusBoxDict } from '../types'
import { Tag } from './Tag'

interface StatusBoxProps {
  status: string
  mapping: IStatusBoxDict
}

export const StatusBox: React.FC<StatusBoxProps> = (props) => {
  const status = props.status

  const mapEntry = props.mapping[status as any]

  const tagStyle = mapEntry?.color

  const params = {}

  if (tagStyle) {
    params[tagStyle] = true
  }

  return (
    <>
      <Tag {...params}>{mapEntry?.label || status}</Tag>
    </>
  )
}
