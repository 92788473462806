import { Box, Heading, Image, useTheme } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../components/Button'
import { routeStrings } from '../../routeStrings'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { Roles, useRole } from '../../utils/rbac'
import { useAuth } from '../../context/auth-context'

export const CompleteLanding: React.FC = () => {
  const me = useAuth()
  const navigate = useNavigate()
  const theme: any = useTheme()
  const { role, isExternalMember } = useRole()
  const isAllendaleUser =
    role === Roles.FernAllendaleTenantTeamAdmin ||
    role === Roles.FernAllendaleTenantActiveMember
  const isIlumaUser =
    role === Roles.IlumaTenantTeamAdmin ||
    role === Roles.IlumaTenantActiveMember

  return (
    <OnboardingWrapper>
      <Box p={3}>
        <Image
          src={theme.logos.logo_long}
          width="128px"
          className="location-logo"
        />
        <Box mt={6}>
          <Box my={3}>
            <svg
              className="teams-complete"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 16.5L12.5 20.5L22.5 10.5"
                stroke="#24A148"
                strokeMiterlimit="10"
                stroke-linecap="square"
              />
              <path
                d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21573 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21573 0.5 15.5C0.5 23.7843 7.21573 30.5 15.5 30.5Z"
                stroke="#24A148"
                strokeMiterlimit="10"
                stroke-linecap="square"
              />
            </svg>
          </Box>
          <Heading className="teams-complete" fontSize={'md'}>
            {me &&
              me.currentOrgUnit &&
              `Welcome to ${me.currentOrgUnit?.organization.parent_platform_tenant_name}, ${me.me?.user.name}!`}
          </Heading>
          <br />
          {isAllendaleUser && (
            <h3>
              Please complete your profile to start booking the Allendale
              Business Lounge
            </h3>
          )}
          {isIlumaUser && (
            <h3>Please complete your profile to start booking at Work Zone</h3>
          )}

          {role && !isAllendaleUser && !isIlumaUser && !isExternalMember && (
            <h3>
              {me &&
                me.currentOrgUnit &&
                `Your membership application to ${me.currentOrgUnit?.organization.parent_platform_tenant_name} was accepted! We need to
              complete your profile, get your billing details and book an
              onboarding time to give you a tour of the workspace.`}
            </h3>
          )}
          {isExternalMember && (
            <h3>
              {me &&
                me.currentOrgUnit &&
                `We need to complete your profile and you will be ready to book meeting room here and other spaces across this coworking network.`}
            </h3>
          )}
        </Box>
        <br />
        <br />
        <br />

        <Button
          data-testid="complete-next"
          onClick={() => {
            navigate(routeStrings.completeProfile1)
          }}
        >
          Next
        </Button>
      </Box>
    </OnboardingWrapper>
  )
}
