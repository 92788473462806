import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getContent } from '../../api'
import { IDailyFiveContent } from '../../types'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'
import { ContentDisplay } from './ContentDisplay'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { routeStrings } from '../../routeStrings'
import { useNavigate } from 'react-router-dom'
export const ContentDetail: React.FC = () => {
  //const [saveIcon, setSaveIcon] = useState<string>()

  const { contentId } = useParams<{ contentId?: string }>()
  const [content, setContent] = useState<IDailyFiveContent>()
  const navigate = useNavigate()

  useEffect(() => {
    contentId &&
      getContent(parseInt(contentId))
        .then(({ data }) => {
          setContent(data as IDailyFiveContent)
        })
        .catch((err) => {
          console.log(err)
          navigate(routeStrings.memberDashboardHome)
        })
  }, [])

  return (
    // Added flex-shink=0 in different sections of the page in order to avoid averlapping text in Safari/IOS.
    // It doesn't interfere with other browers.
    <LayoutMemberDashboard>
      {contentId && content ? (
        <Box overflowY="scroll">
          <ContentDisplay content={content} isPreview={false} />
        </Box>
      ) : (
        <CenteredSpinner />
      )}
    </LayoutMemberDashboard>
  )
}
