import React from 'react'
import { useBookingContext } from '../../../context/BookingContext'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { MeetingRooms } from './MeetingRooms'
import { MeetingRoomsMobile } from './mobile/MeetingRoomsMobile'

export const MeetingRoomWrapper: React.FC = () => {
  const { showMobileVersion, loading } = useBookingContext()
  if (loading) {
    return (
      <LayoutMemberDashboard showBack={true}>
        <CenteredSpinner />
      </LayoutMemberDashboard>
    )
  }
  if (showMobileVersion) {
    return (
      <LayoutMemberDashboard showBack={true}>
        <MeetingRoomsMobile />
      </LayoutMemberDashboard>
    )
  }
  return (
    <LayoutMemberDashboard showBack={true}>
      <MeetingRooms />
    </LayoutMemberDashboard>
  )
}
