import React from 'react'
import {
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  Textarea
} from '@chakra-ui/react'

interface FormInputProps {
  id?: string
  label?: string
  info?: string | JSX.Element
  error?: string
  [htmlAttribute: string]: any
}

export const TextAreaInput: React.FunctionComponent<FormInputProps> = ({
  id,
  label,
  error,
  info,
  field,
  isInvalid,
  ...rest
}) => {
  delete rest.form // this is used to fix a formik bug where Enter wont submit
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel fontWeight="bold" fontSize="sm" htmlFor={id} mb={1}>
        {label}
      </FormLabel>
      <Textarea
        variant="flushed"
        bg="#FAFAFA"
        fontSize="md"
        borderColor="eastbay07"
        id={id}
        resize="vertical"
        size="sm"
        {...field}
        {...rest}
        _invalid={{ border: '2px solid', borderColor: '#E1202A' }}
      />
      {error !== undefined && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  )
}
