// import { InputWarning } from '../styled/InputWarning'

import { Box, Skeleton, Text, useTheme } from '@chakra-ui/react'

import { jsx, css } from '@emotion/react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import Select, { SelectInstance } from 'react-select'
import { GroupedOption, SelectOption } from '../types'

interface Props {
  items: Array<SelectOption>
  name?: string
  initialValues?: Array<number>
  value?: Array<number>
  onSelect: (values: Array<number>) => any
  dataTestId?: string
  isClearable?: boolean
  onInputChange?: (value: string) => void
  placeholder?: string
  onMenuScrollToBottom?: () => void
  _ref?: React.Ref<any>
  closeMenuOnSelect?: boolean
  className?: string
}
export const FilterableMultiSelect: React.FC<Props> = forwardRef((props, _) => {
  const {
    items,
    name,
    placeholder,
    value,
    onSelect,
    initialValues,
    onInputChange,
    dataTestId,
    onMenuScrollToBottom,
    isClearable,
    closeMenuOnSelect,
    _ref
  } = props
  const theme: any = useTheme()

  const [defaultInputValues, setDefaultInputValues] =
    useState<Array<SelectOption>>()
  const [isReady, setIsReady] = useState(
    initialValues === undefined ||
      initialValues.length === 0 ||
      items.length === 0
  )
  const [hasSearched, setHasSearched] = useState(isReady)

  const refSelect = useRef<SelectInstance>(null)

  useImperativeHandle(_ref, () => ({
    clear() {
      refSelect.current?.clearValue()
    },
    rerender() {
      refSelect.current?.render()
    }
  }))

  useEffect(() => {
    if (!isReady && initialValues && initialValues.length > 0 && items) {
      let foundItems: Array<SelectOption> = []

      items.forEach((item: SelectOption | GroupedOption, index: number) => {
        if (item['options'] !== undefined) {
          let group = item as GroupedOption

          group.options.forEach((sub_item) => {
            let itemFound = initialValues.find((val) => sub_item.value === val)
            if (itemFound) {
              foundItems.push(sub_item)
              return
            }
          })
        } else {
          let optionItem = item as SelectOption
          let itemFound = initialValues.find((val) => optionItem.value === val)
          if (itemFound) {
            foundItems.push(optionItem)
            return
          }
        }
      })
      setDefaultInputValues(foundItems)
      setHasSearched(true)
    }
  }, [])

  useEffect(() => {
    if (defaultInputValues) {
      setIsReady(true)
    } else {
      if (hasSearched) setIsReady(true)
    }
  }, [defaultInputValues, hasSearched])

  const labelStyles = css`
    font-family: Open Sans;
    color: #000;
    font-weight: bold;
    margin-bottom: 4px;
  `

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#ffffff',
      padding: '3px',
      border: '1px solid #e2e8f0',
      color: '#4f4f4f'
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: theme.colors.brandPrimary,
        color: 'white'
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: '#f9f9f9',
      ':hover': {
        backgroundColor: 'white',
        color: theme.colors.brandPrimary
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? theme.colors.brandPrimary
          : isFocused
          ? '#4cfff90'
          : undefined,
        cursor: 'cursor',
        ':active': {
          borderColor: '#888888'
        },

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected
            ? theme.colors.brandPrimary
            : !isDisabled
            ? '#4cfff90a'
            : undefined
        }
      }
    },
    dropdownIndicator: () => ({
      color: '#888888'
    })
  }

  if (!isReady)
    return (
      <Box mt={6}>
        <Skeleton h={10} />
      </Box>
    )

  return (
    <Box data-testid={dataTestId}>
      <Text
        css={labelStyles}
        onClick={() => {
          refSelect.current?.clearValue()
        }}
      >
        {name}
      </Text>
      <Select
        value={value ? items.filter((i) => value.indexOf(i.value) >= 0) : []}
        closeMenuOnSelect={closeMenuOnSelect === true}
        isMulti
        ref={refSelect}
        styles={customStyles}
        onInputChange={(newValue, actionMeta) => {
          if (onInputChange !== undefined) onInputChange(newValue ?? '')
        }}
        // css={inputStyles}
        className={'basic-single'.concat(props.className ?? '')}
        classNamePrefix="select"
        placeholder={placeholder ?? 'Select an option'}
        isClearable={isClearable}
        defaultValue={defaultInputValues}
        isSearchable={true}
        name="color"
        noOptionsMessage={() => 'No results found'}
        options={items}
        onChange={(selected, type) => {
          let _selected = selected as SelectOption[]
          if (_selected || isClearable) {
            try {
              onSelect(_selected.map((s) => s.value))
            } catch (error) {}
          }
        }}
        onMenuScrollToBottom={(event) => {
          let element = event.target as HTMLElement
          if (
            onMenuScrollToBottom !== undefined &&
            element.scrollHeight - element.scrollTop <
              element.clientHeight * 1.2
          ) {
            onMenuScrollToBottom()
          }
        }}
      />
    </Box>
  )
})
