import {
  NumberParam,
  DateTimeParam,
  DateParam,
  BooleanParam,
  StringParam,
  NumericArrayParam
} from 'use-query-params'

export const bookingQueryParams = {
  room: NumberParam,
  startTime: DateTimeParam,
  endTime: DateTimeParam,
  organizationalUnit: NumberParam
}
export const viewBookingQueryParams = {
  newBooking: BooleanParam
}

export const userDeletionReqFilterQueryParams = {
  type: StringParam,
  tenant: NumberParam,
  page: NumberParam,
  status: StringParam,
  filter: StringParam
}

export const filterDateQueryParams = {
  from: DateParam,
  to: DateParam,
  excludeCancelled: BooleanParam,
  fetchMeetingRooms: BooleanParam,
  fetchWorkspaces: BooleanParam
}

export const filterInvoicesQueryParams = {
  page: NumberParam,
  date: StringParam,
  organization_filter: StringParam,
  invoiceId: StringParam,
  tenant: NumberParam,
  location: NumberParam,
  status: StringParam
}

export const filterOrgUnitBookingsQueryParams = {
  page: NumberParam,
  filter: StringParam,
  resource: StringParam
}

export const filterOrganizationQueryParams = {
  page: NumberParam,
  page_size: NumberParam,
  tenant: NumberParam,
  name: StringParam,
  location: StringParam,
  plan: NumberParam
}

export const filterMyConnectionsQueryParams = {
  page: NumberParam,
  page_size: NumberParam,
  orgUnit: NumberParam,
  filter: StringParam
}

export const filterMemberInvitationsQueryParams = {
  tenant: NumberParam,
  date_from: DateParam
}

export const filterOrganizationalUnitQueryParams = {
  page: NumberParam,
  page_size: NumberParam,
  tenant: NumberParam,
  name: StringParam,
  location: StringParam,
  plan: NumberParam,
  organization: NumberParam
}

export const filterOfficesQueryParams = {
  page: NumberParam,
  page_size: NumberParam,
  tenant: NumberParam,
  name: StringParam
}

export const planChangesReportQueryParams = {
  start: DateParam,
  end: DateParam,
  type: StringParam,
  tenant: NumberParam,
  page: NumberParam,
  page_size: NumberParam
}

export const filterMeetingRoomTimeline = {
  date: DateParam,
  location: NumberParam,
  organization: NumberParam
}

export const filterLocationsQueryParams = {
  page: NumberParam,
  tenantId: NumberParam,
  public_filter: BooleanParam,
  nameFilter: StringParam
}

export const filterCalendarEventsQueryParams = {
  tenantId: NumberParam,
  location: NumberParam,
  interests: NumericArrayParam
}
