import React from 'react'
import { FilterableMultiSelect } from '../../components/FilterableMultiSelect'
import { OrganizationIndustry } from '../../types'

interface Props {
  value?: Array<number>

  industriesList: Array<OrganizationIndustry> | undefined
  initialValues: Array<number> | undefined
  placeholder?: string
  onSelectionChanges: (interests: Array<number>) => void
}

export const FilterableIndustries: React.FC<Props> = (props) => {
  const {
    value,
    initialValues,
    placeholder,
    industriesList,
    onSelectionChanges
  } = props

  if (!industriesList) return null

  return (
    <FilterableMultiSelect
      value={value}
      placeholder={placeholder}
      initialValues={initialValues}
      isClearable={false}
      items={industriesList?.map((interest) => ({
        value: interest.id,
        label: interest.name ?? ''
      }))}
      onSelect={(ids) => {
        onSelectionChanges(ids)
      }}
    />
  )
}
