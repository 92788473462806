import {
  Box,
  Center,
  Flex,
  Image,
  Link,
  Text,
  useToast
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { createNewUser, getTermsAndConditions } from '../../api'
import { PrimaryButton } from '../../components/chakra-v2/PrimaryButton'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { FormInput } from '../../components/FormInput'
import { routeStrings } from '../../routeStrings'
import {
  SinglePageSignupComponent,
  useUserDetails
} from '../../context/user-onboard-context'
import { analyticsIdentify } from '../../utils/analytics'
import { OnboardingHeading } from '../Onboarding/OnboardingComponents'

import { usePlatformTenant } from '../../context/platform-tenant-context'

export const ExternalOnboardingAcceptTC: React.FC<SinglePageSignupComponent> = (
  props
) => {
  const { isComplete, onCompleteCallback } = props
  const { userDetails } = useUserDetails()
  const { platformTheme } = usePlatformTenant()
  const [tcId, setTcId] = useState(0)
  const [formErrorMessage, setFormErrorMessage] = useState({})
  const toast = useToast()
  useEffect(() => {
    getTermsAndConditions().then(({ data }: any) => setTcId(data[0].id))
  }, [])

  return (
    <Box margin={'0 auto'} w={'100%'} maxW="450px">
      <Center mb={1}>
        <Image
          src={platformTheme?.logo_long}
          w={'10rem'}
          alt={`${platformTheme?.business_name} Logo`}
        />
      </Center>
      <OnboardingHeading
        size={'xs'}
        color={'gray.400'}
        mb={8}
        textAlign="center"
      >
        Book a space at {platformTheme?.business_name}
      </OnboardingHeading>
      <OnboardingHeading size={'md'} color={'gray.800'} textAlign="center">
        Set your password
      </OnboardingHeading>
      <Formik
        initialValues={{ password: '' }}
        validate={(values) => {
          const errors: any = {}
          if (!values.password) {
            errors.password = 'Required'
            // setPasswordErrorMessage(errors.password)
          }
          if (/^\d+$/.test(values.password)) {
            errors.password = "Your password can't be entirely numeric."
            // setPasswordErrorMessage(errors.password)
          }
          if (values.password.length > 0 && values.password.length < 8) {
            errors.password =
              'This password is too short. It must contain at least 8 characters.'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!platformTheme) {
            toast({
              title: 'Error',
              description: 'Please, confirm your personal details',
              status: 'error',
              isClosable: true
            })
            setSubmitting(false)
            return
          }

          setSubmitting(true)

          const startDate = format(new Date(), 'yyyy-MM-dd')

          // gather all required details
          createNewUser({
            email: userDetails.email,
            username: userDetails.email,
            password1: values.password,
            password2: values.password,
            first_name: userDetails!.firstName,
            last_name: userDetails!.lastName,
            postcode: userDetails!.postcode,
            mobile_number: userDetails!.mobileNumber,
            start_date: startDate,
            agreed_tc: tcId,
            location_id: userDetails.location_id,
            plan: undefined,
            is_external_signup: true,
            platform_tenant: Number.parseInt(platformTheme.id)
          })
            .then(({ data: { key, userId } }) => {
              window.localStorage.setItem('Token', key)

              analyticsIdentify(String(userId), {
                email: userDetails!.email,
                name: userDetails!.firstName + userDetails!.lastName
              })
              setSubmitting(false)
              onCompleteCallback()
            })
            .catch((e) => {
              setSubmitting(false)
              setFormErrorMessage(e.response.data)
            })
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Flex direction="column" align="center">
              <Box mb={5} w={'350px'}>
                <Field
                  type="password"
                  name="password"
                  isInvalid={touched.password && errors.password !== undefined}
                  data-testid="onboarding-password-field"
                  placeholder="Password"
                  component={FormInput}
                />
                <Text fontSize={10} my={1}>
                  It must have at least 8 characters and must include letters
                  and numbers.
                </Text>
                <ErrorMessage name="password" component={FormErrorMessage} />
              </Box>

              {/* Form error message */}
              {Object.keys(formErrorMessage).length > 0 && (
                <Text>Please check the following fields</Text>
              )}

              {Object.entries(formErrorMessage).map((error) => {
                let errorFields = ''

                try {
                  errorFields += 'Invalid ' + error[0] + ': ' + error[1]
                } catch (err) {}
                return (
                  <Text color="red.500" fontWeight="medium" mb={2}>
                    {errorFields.slice(0, -1)}
                  </Text>
                )
              })}
              <Flex>
                <PrimaryButton
                  type="submit"
                  px={6}
                  flexGrow={0}
                  loadingText="Signing up"
                  isLoading={isSubmitting}
                  data-testid="onboarding-accept-tcs-next"
                  // Button is disabled if form is submitting or error message exists
                  isDisabled={
                    (!isComplete && isSubmitting) ||
                    errors.password !== undefined
                  }
                >
                  Finish sign up
                </PrimaryButton>
              </Flex>
              <Text mt={4} fontSize="xs" textAlign="center">
                By pressing Sign up , you are agreeing to the{' '}
                <Link
                  color="blue"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={routeStrings.termsAndConditions}
                >
                  Terms and Conditions{' '}
                </Link>
              </Text>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
