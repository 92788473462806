import { Box, Flex, FlexProps, Text, Wrap } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../context/auth-context'
import { useContentExplorer } from '../../../context/content-explorer-context'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { useMyUserProfile } from '../../../utils/apiHooks'
import { BigDailyFive } from '../../DailyFive/BigDailyFive'
import { PromptAddInterest } from './PromptAddInterest'
import { Interest } from '../../../types'

interface Props extends FlexProps {}

export const ContentExplorerPosts: React.FC<Props> = ({ ...rest }) => {
  const { me } = useAuth()
  const { data: userProfile, mutate } = useMyUserProfile(
    me?.user?.user_profile.id
  )

  const { loadingPosts, content, selectedTags } = useContentExplorer()
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false)

  useEffect(() => {
    if (selectedTags.length === 0) {
      setIsPromptOpen(false)
      return
    }
    if (userProfile?.interests === undefined) return
    let interests: Interest[] = []
    try {
      interests = userProfile?.interests
      if (interests.find((i) => i.id === selectedTags[0].id) === undefined) {
        const timer = setTimeout(() => {
          setIsPromptOpen(true)
        }, 1000)
        return () => clearTimeout(timer)
      } else {
        setIsPromptOpen(false)
      }
    } catch (error) {
      setIsPromptOpen(false)
      return
    }
  }, [selectedTags])

  return (
    <Flex
      flexDirection="column"
      // h="100%"
      w="100%"
      align="stretch"
      {...rest}
    >
      {' '}
      {selectedTags ? (
        <PromptAddInterest
          isOpen={isPromptOpen}
          interest={selectedTags[0]}
          toggle={(interestAdded?: boolean) => {
            if (interestAdded) mutate()
            setIsPromptOpen(!isPromptOpen)
          }}
        />
      ) : null}
      <Wrap spacing={6} paddingBottom="32px" align="bottom">
        {content &&
          content.map((c, i) => (
            <BigDailyFive
              w={['100%', '100%', '100%', '45%']}
              key={i}
              contentObject={c}
              imageHeight="280px"
            />
          ))}

        {!loadingPosts && content?.length === 0 ? NoPostsFound() : null}
      </Wrap>
      {loadingPosts ? <CenteredSpinner /> : null}
    </Flex>
  )
}

const NoPostsFound = () => {
  return (
    <Box p="20px" textAlign="center">
      <Text fontSize={'16px'} color="grey">
        No Records Found. Try with a different filter.
      </Text>
    </Box>
  )
}
