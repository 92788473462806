import { StackProps, VStack } from '@chakra-ui/react'
import React, { ReactNode, useState } from 'react'

interface Props extends StackProps {
  children?: ReactNode
}

export const CardPanel: React.FC<StackProps> = (props) => {
  return (
    <VStack
      bg="white"
      w="100%"
      boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
      rounded="md"
      px={8}
      py={5}
      position="relative"
      color="eastBay09"
      align="center"
      justifyContent="space-between"
      {...props}
    >
      {props?.children}
    </VStack>
  )
}
