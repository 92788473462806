import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  Box,
  Text,
  Image,
  Skeleton,
  BoxProps,
  Flex,
  Stack,
  Textarea,
  DrawerFooter,
  Divider,
  useToast,
  useDisclosure,
  UnorderedList,
  ListItem
} from '@chakra-ui/react'
import { faMapMarkerAlt, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInSeconds } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz/esm'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { encodeQueryParams } from 'use-query-params'
import { getMeetingRoomTimeline, previewBookingCost } from '../../../../api'
import { BookingCostsComponent } from '../../../../components/BookingCostsComponent'
import { Button } from '../../../../components/Button'
import { CalendarFormInput } from '../../../../components/CalendarFormInput'
import { MeetingRoomTimeline } from '../../../../components/MeetingRoomTimeline'
import { BookingConfirmModal } from '../../../../components/modals/BookingConfirmModal'
import { NumberInputComponent } from '../../../../components/NumberInputComponent'
import { useAuth } from '../../../../context/auth-context'
import { useBookingContext } from '../../../../context/BookingContext'
import { routeStrings } from '../../../../routeStrings'
import {
  ICreateBookingRequest,
  IInterval,
  IMeetingRoom,
  IOrganizationalUnit,
  IPreviewBookingCost,
  IPublicMeetingRoom
} from '../../../../types'
import { viewBookingQueryParams } from '../../../../utils/queryParams'
import { useRole } from '../../../../utils/rbac'
import { secondsToTimeString } from '../meetingRoomUtils'
import { FeatureComponent } from './PublicMeetingRoom'

interface Props {
  meetingRoom?: IPublicMeetingRoom
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
  onToggle: () => void
}

export const PublicBookingDrawer: React.FC<Props> = (props) => {
  const { me, currentOrgUnit } = useAuth()
  const { isExternalMember } = useRole()
  const navigate = useNavigate()

  const { locations: bookableLocations, makeRoomBooking } = useBookingContext()
  const toast = useToast()
  const { meetingRoom, isOpen, onClose, onOpen } = props
  const [notes, setNotes] = useState<string>('')
  const [numberAttendees, setNumberAttendees] = useState(1)
  const [isBooking, setIsBooking] = useState(false)

  const isExternalBooking =
    isExternalMember ||
    !bookableLocations.find((l) => l.id === meetingRoom?.location)
  const canBeBooked =
    !isExternalBooking ||
    (isExternalBooking &&
      meetingRoom?.public_booking &&
      !currentOrgUnit?.is_space_super_admin)
  const {
    isOpen: isOpenBookingModal,
    onClose: onCloseBookingModal,
    onOpen: onOpenBookingModal
  } = useDisclosure()
  const [chosenDate, setChosenDate] = useState(new Date())
  const [bookingTimes, setBookingTimes] = useState<IInterval | undefined>()
  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [bookingCost, setBookingCost] = useState<IPreviewBookingCost>()
  const [roomTimeline, setRoomTimeline] = useState<IMeetingRoom>()
  const [isGettingTimeline, setIsGettingTimeline] = useState(false)
  const [isError, setIsError] = useState<boolean>(false)
  const dayBusinessHours =
    meetingRoom?.roomLocation.business_hours &&
    meetingRoom?.roomLocation.business_hours.find(
      (hours) => hours.day === chosenDate.getDay() - 1
    )
  const duration = bookingTimes
    ? secondsToTimeString(
        differenceInSeconds(bookingTimes.end, bookingTimes.start)
      )
    : undefined

  useEffect(() => {
    if (!isOpen) {
      setBookingCost(undefined)
      setBookingTimes(undefined)
      setIsBooking(false)
    }
  }, [isOpen])

  useEffect(() => {
    handleChangeDate(chosenDate)
  }, [chosenDate])

  const handleChangeDate = (newDate) => {
    if (!meetingRoom || !chosenDate || isGettingTimeline) return
    setIsGettingTimeline(true)
    getMeetingRoomTimeline(meetingRoom.id, newDate)
      .then((res) => {
        setIsGettingTimeline(false)
        setRoomTimeline(res.data)
      })
      .catch((err) => {
        setIsGettingTimeline(false)
      })
  }

  const selectTimes = (timeSlot: IInterval | undefined) => {
    if (isCalculating) return
    setBookingTimes(timeSlot)
    if (!timeSlot || !meetingRoom) return
    if (currentOrgUnit?.is_space_super_admin) return
    setIsCalculating(true)
    previewBookingCost({
      meeting_room: meetingRoom.id,
      organizational_unit: currentOrgUnit?.id ?? 0,
      start_time: zonedTimeToUtc(
        timeSlot.start,
        meetingRoom.timezone
      ).toISOString(),
      end_time: zonedTimeToUtc(timeSlot.end, meetingRoom.timezone).toISOString()
    })
      .then(({ data }) => {
        setBookingCost(data)
        setIsCalculating(false)
      })
      .catch((err) => {
        setIsCalculating(false)
      })
  }

  const makeExternalBooking = async () => {
    if (!bookingTimes || !me || !currentOrgUnit) return

    // setIsBooking(true)
    onOpenBookingModal()
  }

  const confirmExternalBooking = () => {
    return new Promise<any>((resolve, reject) => {
      if (!currentOrgUnit || !bookingTimes || !meetingRoom) {
        reject('')
        return
      }
      const bookingRequest = getBookingRequestObject(
        bookingTimes,
        meetingRoom,
        currentOrgUnit
      )

      resolve(
        makeRoomBooking(bookingRequest)
          .then((booking) => {
            const params = encodeQueryParams(viewBookingQueryParams, {
              newBooking: true
            })

            const url = routeStrings.getMemberDashboardBooking(booking.id)
            navigate(url)
            return ''
          })
          .catch((err) => {
            toast({
              description:
                'The booking could not be confirmed. please try again later.',
              status: 'error'
            })
            return ''
          })
      )
    })
  }

  const validateBookingFields = () => {
    if (!numberAttendees) {
      toast({
        title: 'Missing information',
        description: 'Number of attendees',
        status: 'warning'
      })
      return false
    }
    if (!notes || notes.length < 5) {
      toast({
        title: 'Missing information',
        description: 'Information about the booking',
        status: 'warning'
      })
      return false
    }
    return true
  }

  const makeBooking = () => {
    if (!currentOrgUnit || !bookingTimes || !meetingRoom || isBooking) return

    if (!validateBookingFields()) return

    const bookingRequest = getBookingRequestObject(
      bookingTimes,
      meetingRoom,
      currentOrgUnit
    )
    setIsBooking(true)
    makeRoomBooking(bookingRequest)
      ?.then((booking) => {
        const params = encodeQueryParams(viewBookingQueryParams, {
          newBooking: true
        })
        setIsBooking(false)

        const url = routeStrings.getMemberDashboardBooking(booking.id)
        navigate(url)
      })
      .catch((err) => {
        setIsBooking(false)
        toast({
          description:
            'The booking could not be confirmed. please try again later.',
          status: 'error'
        })
      })
  }
  const getBookingRequestObject = (
    bookingTimes: IInterval,
    meetingRoom: IMeetingRoom,
    currentOrgUnit: IOrganizationalUnit
  ) => {
    let start_time = zonedTimeToUtc(
      bookingTimes.start.toISOString(),
      meetingRoom.timezone
    ).toISOString()
    let end_time = zonedTimeToUtc(
      bookingTimes.end.toISOString(),
      meetingRoom.timezone
    ).toISOString()
    const bookingRequest = {
      start_time,
      end_time,
      organizational_unit: currentOrgUnit.id,
      meeting_room: meetingRoom.id,
      number_attendees: numberAttendees,
      notes: notes
    } as ICreateBookingRequest
    return bookingRequest
  }
  const bookingConfirmed = (status: boolean, data: any) => {
    if (status) {
      toast({ description: 'Booking created', status: 'success' })
    }
  }

  return (
    <Drawer placement={'right'} onClose={onClose} isOpen={isOpen} size={'md'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          Book a room at {meetingRoom?.roomLocation.name}
        </DrawerHeader>
        <DrawerBody>
          <MeetingRoomCard
            name={meetingRoom?.name}
            location={meetingRoom?.roomLocation.name}
            image_url={meetingRoom?.display_url}
            duration={duration}
          />

          {canBeBooked && (
            <Box>
              <Box w={['100%', '80%', '50%']}>
                <CalendarFormInput
                  dateProps={{
                    chosenDate,
                    handleChangeDate: setChosenDate
                  }}
                  closeOnSelect={true}
                />
              </Box>
              <Divider my={2} />
              <Box pt={1}>
                <Text fontWeight={'bold'}>Select the times</Text>
              </Box>
              {isGettingTimeline && <Skeleton h={'16'} />}
              {!isGettingTimeline && (
                <Box py={8}>
                  {roomTimeline && (
                    <MeetingRoomTimeline
                      chosenDate={chosenDate}
                      setTimeSlot={selectTimes}
                      meetingRoom={roomTimeline}
                      setError={setIsError}
                      existingBooking={undefined}
                      businessHours={dayBusinessHours}
                      isExternal={isExternalBooking}
                    />
                  )}
                </Box>
              )}
              <Box h={8}></Box>
              <Divider my={2} />
              <Stack spacing={0}>
                <Text fontSize="md">Number of Attendees</Text>
                <Flex justifyContent={'space-between'}>
                  <Text fontSize={'xs'} color={'#8c8c8c'}>
                    Please confirm the number of people attending your booking.
                    <Text
                      fontSize={'xs'}
                      as={'span'}
                      fontWeight={'bold'}
                      color={'#444'}
                    >
                      {' '}
                      Up to {roomTimeline?.capacity} attendees
                    </Text>
                  </Text>
                  <Box>
                    <NumberInputComponent
                      max={roomTimeline?.capacity}
                      min={1}
                      value={numberAttendees}
                      onNumberChange={(val: number) => {
                        setNumberAttendees(val)
                      }}
                    />
                  </Box>
                </Flex>
              </Stack>

              <Divider my={2} />
              <Flex mb={2}>
                <Stack spacing={0}>
                  <Text fontSize="md">Info about this event</Text>
                  <Text fontSize={'xs'} color={'#8c8c8c'}>
                    Please, tell us a little more about your booking.
                  </Text>
                  <UnorderedList pl={[2, 4, 4]}>
                    <ListItem fontSize={'xs'}>What is it about?</ListItem>
                    <ListItem fontSize={'xs'}>
                      Are you ordering catering?
                    </ListItem>
                  </UnorderedList>
                </Stack>
              </Flex>
              <Textarea
                id="desc"
                placeholder="My event is about..."
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                resize={'vertical'}
                size="md"
                maxLength={2000}
              />
            </Box>
          )}
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px" py={1}>
          <BookingCostsComponent costs={bookingCost} />
        </DrawerFooter>
        <DrawerFooter borderTopWidth="1px">
          {canBeBooked ? (
            <Button
              disabled={!duration || isBooking}
              isLoading={isBooking}
              loadingText={'Booking Meeting Room'}
              colorScheme="blue"
              onClick={() => {
                if (isExternalBooking) {
                  if (validateBookingFields()) {
                    onOpenBookingModal()
                  }
                } else {
                  makeBooking()
                }
              }}
            >
              Book this Space
            </Button>
          ) : (
            <Text>Unavailable room for this user</Text>
          )}
        </DrawerFooter>
      </DrawerContent>
      {meetingRoom && bookingTimes && bookingCost && (
        <BookingConfirmModal
          isOpen={isOpenBookingModal}
          onClose={() => {
            // setIsBooking(false)
            onCloseBookingModal()
          }}
          confirmBooking={confirmExternalBooking}
          resource={'MeetingRoom'}
          meeting_room={meetingRoom}
          workspace={undefined}
          location={meetingRoom?.roomLocation}
          start_time={bookingTimes?.start}
          end_time={bookingTimes?.end}
          bookingCost={bookingCost}
        />
      )}
    </Drawer>
  )
}

interface CardProps extends BoxProps {
  name?: string
  location?: string
  image_url?: string
  duration?: string
}

const MeetingRoomCard: React.FC<CardProps> = (props) => {
  const { name, location, image_url, duration, ...rest } = props
  return (
    <Box position={'relative'} {...rest}>
      <Image
        src={props.image_url}
        h={'150px'}
        w={'100%'}
        objectFit="cover"
        // layout={'fill'}
      />
      {duration && (
        <Box position={'absolute'} bottom={0} bg="white" opacity={0.9} pr={2}>
          <FeatureComponent
            icon={<FontAwesomeIcon icon={faStopwatch} size={'1x'} />}
            iconBg={'green.100'}
            text={duration}
          />
        </Box>
      )}
      <Box
        position={'absolute'}
        bottom={0}
        right={0}
        p={2}
        background={'white'}
        opacity={0.9}
        minW={'100px'}
      >
        <Stack>
          <Text fontWeight={'bold'} fontSize={'md'}>
            {name}
          </Text>
          <Flex align={'center'}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <Text ml={1}>{location}</Text>
          </Flex>
        </Stack>
      </Box>
    </Box>
  )
}
