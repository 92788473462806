import { Box, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { WifiPasswordDisplay } from '../../../components/WifiPasswordDisplay'
import { useOrganizationalUnit } from '../../../utils/apiHooks'
import { RegenerateWifiPasswordModal } from '../../MemberDashboard/Modals/RegenerateWifiPasswordModal'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'

interface Props {}

export const AdminEditMemberWifi: React.FC<Props> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const params = useParams<{ ouId: string }>()

  const { data, mutate } = useOrganizationalUnit(params.ouId)
  const { editWifiEnabled } = useFeatureFlags()

  useEffect(() => {
    if (!isOpen) {
      mutate()
    }
  }, [isOpen])

  const userProfile = data?.user.user_profile

  if (userProfile === undefined) {
    return <PrimarySpinner />
  }

  return (
    <Box my="3" maxWidth="650px">
      <Flex mb={3} flexDir="column">
        <Text mb={3} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Spacecubed WiFi Access
        </Text>
        <Stack
          bg="white"
          w="100%"
          boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
          rounded="md"
          px={8}
          py={5}
          position="relative"
          color="eastBay09"
          justifyContent="space-between"
        >
          <Stack spacing={5}>
            {userProfile.wifi_password ? (
              <Box>
                <Text mb={1}>
                  This member account has access to WiFi at Spacecubed
                  locations. The password is shown below.
                </Text>
                <WifiPasswordDisplay password={userProfile.wifi_password} />
              </Box>
            ) : (
              <Text>This member has no active WiFi password.</Text>
            )}
            {editWifiEnabled && (
              <Box>
                <Button type="submit" onClick={onOpen}>
                  Regenerate WiFi Password
                </Button>
              </Box>
            )}
          </Stack>

          {isOpen && (
            <RegenerateWifiPasswordModal
              isOpen={isOpen}
              onClose={onClose}
              userProfileId={userProfile.id}
            />
          )}
        </Stack>
      </Flex>
    </Box>
  )
}
