import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
  useTheme,
  Alert,
  AlertIcon,
  Textarea
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import {
  faCogs,
  faInfoCircle,
  faMoneyBill,
  faTags
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { createMeetingRoom, updateMeetingRoom } from '../../../api'
import { Button } from '../../../components/Button'
import { CopyURLComponent } from '../../../components/CopyURLComponent'
import { FilterableOfficesSelect } from '../../../components/FilterableOfficesSelect'
import { ImageUploadWithPreview } from '../../../components/ImageUploadWithPreview'
import { NumberInputComponent } from '../../../components/NumberInputComponent'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'

import { useFeatureFlags } from '../../../context/feature-flag-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { routeStrings } from '../../../routeStrings'
import {
  IBasicOffice,
  IMeetingRoomSuperAdmin,
  INewMeetingRoom,
  Plan
} from '../../../types'
import {
  useAccountCodes,
  useBasicMeetingRooms,
  usePlans
} from '../../../utils/apiHooks'
import {
  FERN_ALLENDALE_PLAN_UUID,
  ILUMA_PLAN_UUID
} from '../../../utils/constants'
import { StackedSelect } from './InitializeTeamModal'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  meetingRoomToEdit?: IMeetingRoomSuperAdmin
}

interface OverridePlan extends Plan {
  overrides: { creditsPerHour: number; pricePerHour: number }
}
export const CreateMeetingRoomModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  meetingRoomToEdit
}) => {
  const toast = useToast()
  const theme: any = useTheme()

  const { publicBookingsEnabled } = useFeatureFlags()
  const [selectedPlans, setSelectedPlans] = useState<Plan[]>([])
  const [selectedOverridePlans, setSelectedOverridePlans] = useState<
    OverridePlan[]
  >([])
  const { platformTenant } = usePlatformTenant()
  const { data: accountCodes } = useAccountCodes(platformTenant?.id)
  const [selectedOffices, setSelectedOffices] = useState<IBasicOffice[]>([])

  const [tabIndex, setTabIndex] = useState(0)

  const { plans } = usePlans(platformTenant?.id)

  const { mutate } = useBasicMeetingRooms(platformTenant?.id)
  const [removeImage, setRemoveImage] = useState<boolean>(false)
  const [confirmSave, setConfirmSave] = useState(true)
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: #666;
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  useEffect(() => {
    if (isOpen) {
      setRemoveImage(false)
      setTabIndex(0)
      setConfirmSave(meetingRoomToEdit === undefined)
    }
  }, [isOpen])

  function addSelectedOverridePlan(id: number) {
    if (id) {
      const selectedPlan = plans.find((plan) => plan.id === id)

      if (selectedPlan) {
        setSelectedOverridePlans((currentValue) => [
          ...currentValue,
          { ...selectedPlan, overrides: { pricePerHour: 0, creditsPerHour: 0 } }
        ])
      }
    }
  }
  function addSelectedPlan(id: number) {
    if (id) {
      const selectedPlan = plans.find((plan) => plan.id === id)

      if (selectedPlan) {
        setSelectedPlans((currentValue) => [...currentValue, selectedPlan])
      }
    }
  }
  function addSelectedOffice(id: number, value: IBasicOffice) {
    if (value) {
      if (selectedOffices.findIndex((val) => val.id === value.id) < 0) {
        setSelectedOffices((currentValue) => [...currentValue, value])
      }
    }
  }

  useEffect(() => {
    if (meetingRoomToEdit && plans) {
      const a = meetingRoomToEdit.plan_override_pricing
        .map((overridePricing) => {
          //there is some safeguarding around plans not showing up here
          const p = plans.find((p) => p.id === overridePricing.plan.id)
          if (p) {
            return {
              ...p,
              overrides: {
                creditsPerHour: overridePricing.override_credits_per_hour,
                pricePerHour: overridePricing.override_price_per_hour
              }
            }
          } else {
            return null
          }
        })
        .filter((v) => v !== null) as OverridePlan[]
      setSelectedOverridePlans(a)
      setSelectedPlans(
        plans.filter((plan) =>
          meetingRoomToEdit?.allowed_plans.find(
            (allowedPlanId) => allowedPlanId.id === plan.id
          )
        )
      )
    }
  }, [plans, meetingRoomToEdit])

  function removePlan(planIndex) {
    const temp = [...selectedPlans]
    temp.splice(planIndex, 1)
    setSelectedPlans(temp)
  }
  const removeOffice = (officeId) => {
    const index = selectedOffices.findIndex((val) => val.id === officeId)
    if (index >= 0) {
      setSelectedOffices((currentValue) => [...currentValue.splice(index, 1)])
    }
  }
  function removeOverridePlan(planIndex) {
    const temp = [...selectedOverridePlans]
    temp.splice(planIndex, 1)
    setSelectedOverridePlans(temp)
  }

  function validateRate(value) {
    let error
    if (value === '' || value === undefined) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>
          {meetingRoomToEdit ? 'Edit ' : 'Create '}Meeting room{' '}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            xero_account_code: meetingRoomToEdit?.xero_account_code ?? -1,
            credits_per_hour: meetingRoomToEdit?.credits_per_hour ?? 0,
            location: meetingRoomToEdit?.location ?? 1,
            about: meetingRoomToEdit?.about ?? '',
            member_price_per_hour:
              meetingRoomToEdit?.member_price_per_hour ?? 0,
            name: meetingRoomToEdit?.name ?? '',
            room_slug: meetingRoomToEdit?.room_slug ?? '',
            non_member_price_per_hour:
              meetingRoomToEdit?.non_member_price_per_hour ?? 0,
            public_booking: meetingRoomToEdit?.public_booking ?? false,
            private_room: meetingRoomToEdit?.private_room ?? false,
            display_image_file: undefined,
            display_url: meetingRoomToEdit?.display_url,
            remove_image: removeImage,
            has_videoconference:
              meetingRoomToEdit?.has_videoconference ?? false,
            has_screen_mirroring:
              meetingRoomToEdit?.has_screen_mirroring ?? false,
            capacity: meetingRoomToEdit?.capacity ?? 2
          }}
          onSubmit={(values, actions) => {
            const newMeetingRoom: INewMeetingRoom = values

            // If the remove image flag is set, we set the display_url to undefined so that we clear it in the backend when we save
            if (removeImage) {
              newMeetingRoom.display_url = undefined
              newMeetingRoom.remove_image = removeImage
            }
            if (newMeetingRoom.public_booking) {
              newMeetingRoom.allowed_plans = []
              newMeetingRoom.allowed_offices = []
              newMeetingRoom.private_room = false
            } else {
              newMeetingRoom.allowed_plans = selectedPlans.map(
                (plan) => plan.id
              )

              newMeetingRoom.allowed_offices = selectedOffices.map(
                (office) => office.id
              )
            }

            newMeetingRoom.plan_override_pricing = selectedOverridePlans.map(
              (p) => {
                return {
                  plan: { id: p.id, uuid: p.uuid },
                  override_credits_per_hour: p.overrides.creditsPerHour,
                  override_price_per_hour: p.overrides.pricePerHour
                }
              }
            )

            if (meetingRoomToEdit) {
              // update
              updateMeetingRoom(meetingRoomToEdit.id, newMeetingRoom)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Meeting Room Updated'
                  })
                  onClose()
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    description: 'Failed to update meeting room'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                  mutate()
                })
            } else {
              // create

              createMeetingRoom(newMeetingRoom)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Meeting Room Created'
                  })
                  onClose()
                })
                .catch((e) => {
                  console.log({ e })
                  toast({
                    status: 'error',
                    description: 'Failed to create meeting room'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                  mutate()
                })
            }
          }}
        >
          {({
            values,
            submitForm,
            setFieldValue,
            isSubmitting,
            touched,
            errors,
            validateForm,
            validateField
          }) => (
            <Form>
              <ModalBody flexWrap="wrap">
                <Tabs
                  isFitted
                  variant="enclosed"
                  onChange={(index) => {
                    setTabIndex(index)
                    if (index === 1 && confirmSave) {
                      setConfirmSave(false)
                    }
                  }}
                  index={tabIndex}
                >
                  <TabList mb="1em">
                    <StyledTab
                    // _selected={{ color: theme.colors.brandPrimary, bg: 'blue.500' }}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ marginRight: '4' }}
                        color={theme.colors.brandPrimary ?? '#8c8c8c'}
                      />
                      Basic Info
                    </StyledTab>
                    <StyledTab>
                      <FontAwesomeIcon
                        icon={faMoneyBill}
                        style={{ marginRight: '4' }}
                        color={theme.colors.brandPrimary ?? '#8c8c8c'}
                      />
                      Costs
                    </StyledTab>
                    <StyledTab>
                      <FontAwesomeIcon
                        icon={faTags}
                        style={{ marginRight: '4' }}
                        color={theme.colors.brandPrimary ?? '#8c8c8c'}
                      />
                      Room Extras
                    </StyledTab>
                    <StyledTab>
                      <FontAwesomeIcon
                        icon={faCogs}
                        style={{ marginRight: '4' }}
                        color={theme.colors.brandPrimary ?? '#8c8c8c'}
                      />
                      Other Settings
                    </StyledTab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Stack px={2} spacing={3}>
                        <Flex
                          flexDir={['column', 'row']}
                          justifyContent="space-evenly"
                        >
                          <Stack w={['100%', '100%', '50%']} mr={[0, 2]}>
                            {/* Name Field */}
                            <Field
                              name="name"
                              render={({ field, form }) => (
                                <FormControl
                                  isRequired
                                  isInvalid={
                                    form.errors.name && form.touched.name
                                  }
                                >
                                  <FormLabel
                                    fontWeight={'bold'}
                                    htmlFor="name"
                                    fontSize="md"
                                  >
                                    Name
                                  </FormLabel>
                                  <StyledInput {...field} id="name" w="100%" />

                                  <FormErrorMessage>
                                    {form.errors.name}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                            {/* END: Name Field */}
                            {/* Location Field */}
                            <Field
                              name="location"
                              render={({ field, form }) => (
                                <FormControl
                                  isDisabled={!!meetingRoomToEdit}
                                  isRequired
                                  isInvalid={
                                    form.errors.location &&
                                    form.touched.location
                                  }
                                >
                                  <FormLabel
                                    fontWeight={'bold'}
                                    mb={1}
                                    fontSize="md"
                                  >
                                    Location
                                  </FormLabel>
                                  <StyledSelect
                                    {...field}
                                    placeholder="Select a location"
                                    value={form.values.location}
                                    name="location"
                                    onChange={(e) => {
                                      setFieldValue(
                                        'location',
                                        parseInt(e.target.value)
                                      )
                                    }}
                                  >
                                    {platformTenant?.locations?.map(
                                      (location) => (
                                        <option
                                          key={location.id}
                                          value={location.id}
                                        >
                                          {location.name}
                                        </option>
                                      )
                                    )}
                                  </StyledSelect>

                                  <FormErrorMessage>
                                    {form.errors.category}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                            {/* END Location Field */}
                            {/* Xero Account Code Field */}
                            <Field
                              name="xero_account_code"
                              render={({ field, form }) => (
                                <FormControl
                                  mt="32px"
                                  // isDisabled={!!meetingRoomToEdit}
                                  isRequired
                                  isInvalid={
                                    form.errors.xero_account_code &&
                                    form.touched.xero_account_code
                                  }
                                >
                                  <FormLabel
                                    fontWeight={'bold'}
                                    mb={1}
                                    fontSize="md"
                                  >
                                    XERO account code
                                  </FormLabel>
                                  <StyledSelect
                                    placeholder="Select the XERO account code"
                                    {...field}
                                  >
                                    {accountCodes?.map((accountCode) => (
                                      <option
                                        key={accountCode.code}
                                        value={accountCode.id}
                                      >
                                        {`${accountCode.title} (${accountCode.code})`}
                                      </option>
                                    ))}
                                  </StyledSelect>

                                  <FormErrorMessage>
                                    {form.errors.xero_account_code}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                            {/* END Xero Account Code Field */}
                          </Stack>
                          <Box w={['100%', '50%']}>
                            {/* Image field */}
                            <Field
                              name="display_image_file"
                              render={({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.display_image_file &&
                                    form.touched.display_image_file
                                  }
                                  pb={8}
                                >
                                  <FormLabel htmlFor="display_image_file">
                                    Display image
                                  </FormLabel>
                                  <Text
                                    mb="16px"
                                    fontSize="xs"
                                    color="eastBay07"
                                  >
                                    Only .jpg and .png files. Recommended
                                    dimensions of 1200 x 628px. 10 MB max file
                                    size.
                                  </Text>
                                  <ImageUploadWithPreview
                                    initialUrl={values.display_url}
                                    uploadedImage={values.display_image_file}
                                    setUploadedImage={(image) => {
                                      setFieldValue('display_image_file', image)
                                    }}
                                    setRemoveImage={(state) =>
                                      setRemoveImage(state)
                                    }
                                    removeImage={removeImage}
                                  />

                                  <FormErrorMessage>
                                    {form.errors.display_image_file}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          </Box>
                        </Flex>
                        <Divider />
                        {publicBookingsEnabled && (
                          <Box pt={3}>
                            <Text fontSize={'md'} fontWeight="bold">
                              URL Slug
                            </Text>

                            {meetingRoomToEdit &&
                            meetingRoomToEdit.room_slug ? (
                              <Stack>
                                <CopyURLComponent
                                  label={routeStrings.fullExternalMeetingRoomURL(
                                    meetingRoomToEdit.room_slug
                                  )}
                                  url={routeStrings.fullExternalMeetingRoomURL(
                                    meetingRoomToEdit.room_slug
                                  )}
                                />
                              </Stack>
                            ) : (
                              <Box>
                                <Text fontWeight={'medium'}>
                                  A URL slug will be assigned upon creation
                                </Text>
                              </Box>
                            )}
                          </Box>
                        )}
                        <Divider />
                        <Box pt={3}>
                          {!values.private_room ? (
                            <Field
                              name="public_booking"
                              render={({ field, form }) => (
                                <FormControl>
                                  <Flex flexDir={['column', 'row']}>
                                    <Text
                                      fontWeight="bold"
                                      mr={8}
                                      fontSize="md"
                                    >
                                      Public Booking
                                    </Text>

                                    <Checkbox
                                      size="md"
                                      colorScheme="green"
                                      id="public_booking"
                                      isChecked={form.values.public_booking}
                                      onChange={(value) => {
                                        setFieldValue(
                                          'public_booking',
                                          value.target.checked
                                        )
                                      }}
                                      data-testid="checkbox-public-bookings"
                                      style={{ fontSize: 'xs' }}
                                    >
                                      {form.values.public_booking
                                        ? 'Enabled'
                                        : 'Disabled'}
                                    </Checkbox>
                                  </Flex>
                                  <Box>
                                    <FormLabel fontSize="md">
                                      <Text fontSize={'xs'}>
                                        Enable room to be booked across the
                                        Spacecubed network?. Members without
                                        current plans and external users would
                                        be able to book this room
                                      </Text>
                                    </FormLabel>
                                  </Box>
                                </FormControl>
                              )}
                            />
                          ) : (
                            <Alert status="info">
                              <AlertIcon />
                              This room is{' '}
                              <Text ml={1} as="span" fontWeight={'bold'}>
                                Private.
                              </Text>
                            </Alert>
                          )}
                        </Box>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack px={2} w={['100%', '60%']}>
                        <Box pr={[0, 4, 10]}>
                          <Text fontWeight="bold" fontSize="md">
                            Hourly rates
                          </Text>
                          <Divider my={2} />

                          <Stack spacing={3}>
                            <Field
                              name="member_price_per_hour"
                              validate={validateRate}
                              render={({ field, form }) => (
                                <FormControl
                                  isReadOnly={!!meetingRoomToEdit}
                                  isRequired
                                  isInvalid={
                                    form.errors.member_price_per_hour &&
                                    form.touched.member_price_per_hour
                                  }
                                >
                                  <Flex
                                    display="flex"
                                    justifyContent={'space-between'}
                                  >
                                    <Stack spacing={0}>
                                      <FormLabel
                                        htmlFor="member_price_per_hour"
                                        fontSize="md"
                                      >
                                        Member rate
                                      </FormLabel>
                                      <Text fontSize={'xs'} color={'#8c8c8c'}>
                                        Active memberships or office tenants
                                      </Text>
                                    </Stack>
                                    <Box>
                                      <InputGroup>
                                        <InputLeftElement
                                          children="$"
                                          pr="1.5rem"
                                          color="eastBay05"
                                        />
                                        <NumberInputComponent
                                          maxW="100px"
                                          min={0}
                                          value={
                                            form.values.member_price_per_hour ??
                                            0
                                          }
                                          onNumberChange={(val: number) => {
                                            form.setFieldValue(
                                              'member_price_per_hour',
                                              val
                                            )
                                          }}
                                        />
                                      </InputGroup>
                                    </Box>
                                  </Flex>
                                  <FormErrorMessage>
                                    {form.errors.member_price_per_hour}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />

                            <Field
                              name="non_member_price_per_hour"
                              validate={validateRate}
                              render={({ field, form }) => (
                                <FormControl
                                  isReadOnly={!!meetingRoomToEdit}
                                  isRequired
                                  isInvalid={
                                    form.errors.non_member_price_per_hour &&
                                    form.touched.non_member_price_per_hour
                                  }
                                >
                                  <Flex
                                    display="flex"
                                    justifyContent={'space-between'}
                                  >
                                    <Stack spacing={0}>
                                      <FormLabel
                                        htmlFor="non_member_price_per_hour"
                                        fontSize="md"
                                      >
                                        Non-member rate
                                      </FormLabel>
                                      <Text fontSize={'xs'} color={'#8c8c8c'}>
                                        External users or inactive memberships
                                      </Text>
                                    </Stack>
                                    <Box>
                                      <InputGroup>
                                        <InputLeftElement
                                          children="$"
                                          pr="1.5rem"
                                          color="eastBay05"
                                        />
                                        <NumberInputComponent
                                          maxW="100px"
                                          min={0}
                                          value={
                                            form.values
                                              .non_member_price_per_hour ?? 0
                                          }
                                          onNumberChange={(val: number) => {
                                            form.setFieldValue(
                                              'non_member_price_per_hour',
                                              val
                                            )
                                          }}
                                        />
                                      </InputGroup>
                                    </Box>
                                  </Flex>
                                  <FormErrorMessage>
                                    {form.errors.non_member_price_per_hour}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                            <Field
                              name="credits_per_hour"
                              validate={validateRate}
                              render={({ field, form }) => (
                                <FormControl
                                  isReadOnly={!!meetingRoomToEdit}
                                  isRequired
                                  isInvalid={
                                    form.errors.credits_per_hour &&
                                    form.touched.credits_per_hour
                                  }
                                  pb={5}
                                >
                                  <Flex
                                    display="flex"
                                    justifyContent={'space-between'}
                                  >
                                    <Stack spacing={0}>
                                      <FormLabel
                                        htmlFor="credits_per_hour"
                                        fontSize="md"
                                      >
                                        Billing Credits
                                      </FormLabel>
                                      <Text fontSize={'xs'} color={'#8c8c8c'}>
                                        Credits per hour
                                      </Text>
                                    </Stack>
                                    <Box>
                                      <NumberInputComponent
                                        maxW="100px"
                                        min={0}
                                        value={
                                          form.values.credits_per_hour ?? 0
                                        }
                                        onNumberChange={(val: number) => {
                                          form.setFieldValue(
                                            'credits_per_hour',
                                            val
                                          )
                                        }}
                                      />
                                    </Box>
                                  </Flex>
                                  <FormErrorMessage>
                                    {form.errors.credits_per_hour}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />
                          </Stack>
                          <Divider my={2} />
                          <Field
                            name="name"
                            render={({ field, form }) => (
                              <>
                                <StackedSelect
                                  header="Make room free for these plans"
                                  //feature only enabled for fern allendale plan until feature is expanded
                                  options={plans
                                    .filter(
                                      (p) =>
                                        [
                                          FERN_ALLENDALE_PLAN_UUID,
                                          ILUMA_PLAN_UUID
                                        ].indexOf(p.uuid) >= 0
                                    )
                                    .map((plan) => {
                                      return {
                                        id: plan.id,
                                        title: plan.name
                                      }
                                    })}
                                  autoAddEnabled={true}
                                  addSelectedOption={addSelectedOverridePlan}
                                ></StackedSelect>
                                {selectedOverridePlans.map((plan, i) => {
                                  return (
                                    <Stack key={i} ml={5} spacing={2} isInline>
                                      <Tag
                                        key={plan.name}
                                        color="#ffffff"
                                        rounded="full"
                                        variant="solid"
                                        backgroundColor="eastBay09"
                                        mr="4px"
                                        marginBottom="4px"
                                      >
                                        <TagLabel pl="8px" pr="10px">
                                          {plan.name}
                                        </TagLabel>
                                        <TagCloseButton
                                          pr="8px"
                                          color="#ffffff"
                                          onClick={() => removeOverridePlan(i)}
                                        />
                                      </Tag>
                                    </Stack>
                                  )
                                })}
                              </>
                            )}
                          />
                        </Box>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack px={2} w={['100%', '60%']}>
                        <Box pr={[0, 4, 10]}>
                          <Text fontWeight="bold" fontSize="md">
                            Features
                          </Text>
                          <Divider my={2} />
                          <Stack spacing={3}>
                            <Field
                              name="capacity"
                              render={({ field, form }) => (
                                <FormControl
                                  isRequired
                                  isInvalid={
                                    form.errors.capacity &&
                                    form.touched.capacity
                                  }
                                >
                                  <Flex
                                    display="flex"
                                    justifyContent={'space-between'}
                                  >
                                    <Stack spacing={0}>
                                      <Text fontSize="md">Room capacity</Text>
                                      <Text fontSize={'xs'} color={'#8c8c8c'}>
                                        Maximum number of attendees
                                      </Text>
                                    </Stack>

                                    <Box>
                                      <NumberInputComponent
                                        maxW="100px"
                                        min={0}
                                        value={form.values.capacity ?? 0}
                                        onNumberChange={(val: number) => {
                                          form.setFieldValue('capacity', val)
                                        }}
                                      />
                                    </Box>
                                  </Flex>

                                  <FormErrorMessage>
                                    {form.errors.capacity}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            />

                            <Field
                              name="has_videoconference"
                              render={({ field, form }) => (
                                <FormControl
                                  display="flex"
                                  justifyContent={'space-between'}
                                >
                                  <Stack spacing={0}>
                                    <Text fontSize="md">Video Conference</Text>
                                    <Text fontSize={'xs'} color={'#8c8c8c'}>
                                      Is suitable for video conferencing
                                    </Text>
                                  </Stack>
                                  <Checkbox
                                    size="lg"
                                    colorScheme="gray"
                                    id="has_videoconference"
                                    isChecked={form.values.has_videoconference}
                                    onChange={(value) => {
                                      setFieldValue(
                                        'has_videoconference',
                                        value.target.checked
                                      )
                                    }}
                                  />
                                </FormControl>
                              )}
                            />
                            <Field
                              name="has_screen_mirroring"
                              render={({ field, form }) => (
                                <FormControl
                                  display="flex"
                                  justifyContent={'space-between'}
                                >
                                  <Stack spacing={0}>
                                    <Text fontSize="md">Screen Mirroring</Text>
                                    <Text fontSize={'xs'} color={'#8c8c8c'}>
                                      Screen mirroring available
                                    </Text>
                                  </Stack>
                                  <Checkbox
                                    size="lg"
                                    colorScheme="gray"
                                    id="has_screen_mirroring"
                                    isChecked={form.values.has_screen_mirroring}
                                    onChange={(value) => {
                                      setFieldValue(
                                        'has_screen_mirroring',
                                        value.target.checked
                                      )
                                    }}
                                  />
                                </FormControl>
                              )}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack px={2} w={['100%', '60%']}>
                        <Text fontWeight="bold" fontSize="md">
                          Room Profile
                        </Text>
                        <Box borderTopWidth={1} pt={2}>
                          <Text fontWeight="bold" fontSize="sm" mb={1}>
                            About this room
                          </Text>
                          <Box color="#4F4F4F" fontSize="xs" mb={1}>
                            <Text mb={1}>
                              Tell members and non-members what this room is
                              about.
                            </Text>

                            <Textarea
                              variant="flushed"
                              bg="#FAFAFA"
                              maxLength={2000}
                              fontSize="md"
                              borderBottomColor=" #E0E0E0"
                              resize="vertical"
                              size="sm"
                              value={values.about}
                              onChange={(e) => {
                                setFieldValue('about', e.target.value)
                              }}
                            />
                          </Box>
                        </Box>

                        <Text fontWeight="bold" fontSize="md">
                          Room Privacy
                        </Text>
                        <Divider my={2} />
                        {!values.public_booking ? (
                          <>
                            <Field
                              name="private_room"
                              render={({ field, form }) => (
                                <FormControl
                                  display="flex"
                                  justifyContent={'space-between'}
                                >
                                  <Stack spacing={0}>
                                    <Text fontSize="md">Private Room</Text>
                                    <Text fontSize={'xs'} color={'#8c8c8c'}>
                                      Make this room available to members with
                                      specific memberships or teams in specific
                                      offices
                                    </Text>
                                  </Stack>
                                  <Checkbox
                                    size="lg"
                                    colorScheme={'blue'}
                                    id="private_room"
                                    isChecked={form.values.private_room}
                                    onChange={(value) => {
                                      setFieldValue(
                                        'private_room',
                                        value.target.checked
                                      )
                                    }}
                                  />
                                </FormControl>
                              )}
                            />
                            {/* Plans input */}
                            {values.private_room && (
                              <StackedSelect
                                header="Plans"
                                options={plans.map((plan) => {
                                  return {
                                    id: plan.id,
                                    title: plan.name
                                  }
                                })}
                                addSelectedOption={addSelectedPlan}
                                autoAddEnabled={true}
                              >
                                {selectedPlans.map((plan, i) => {
                                  return (
                                    <Stack key={i} ml={5} spacing={2} isInline>
                                      <Tag
                                        key={plan.name}
                                        color="#ffffff"
                                        rounded="full"
                                        variant="solid"
                                        backgroundColor="eastBay09"
                                        mr="4px"
                                        marginBottom="4px"
                                      >
                                        <TagLabel pl="8px" pr="10px">
                                          {plan.name}
                                        </TagLabel>
                                        <TagCloseButton
                                          pr="8px"
                                          color="#ffffff"
                                          onClick={() => removePlan(i)}
                                        />
                                      </Tag>
                                    </Stack>
                                  )
                                })}
                                <FilterableOfficesSelect
                                  onSelect={addSelectedOffice}
                                  name={'Select an office'}
                                />
                                {selectedOffices.map((office, i) => {
                                  return (
                                    <Stack>
                                      <Stack
                                        key={i}
                                        ml={5}
                                        spacing={2}
                                        isInline
                                      >
                                        <Tag
                                          key={office.name}
                                          color="#ffffff"
                                          rounded="full"
                                          variant="solid"
                                          backgroundColor="eastBay09"
                                          mr="4px"
                                          marginBottom="4px"
                                        >
                                          <TagLabel pl="8px" pr="10px">
                                            {office.location_name} -{' '}
                                            {office.name}
                                          </TagLabel>
                                          <TagCloseButton
                                            pr="8px"
                                            color="#ffffff"
                                            onClick={() =>
                                              removeOffice(office.id)
                                            }
                                          />
                                        </Tag>
                                      </Stack>
                                    </Stack>
                                  )
                                })}
                              </StackedSelect>
                            )}
                          </>
                        ) : (
                          <Alert status="info">
                            <AlertIcon />
                            <Text>
                              {' '}
                              This room accepts public bookings, therefore it
                              cannot be marked as a{' '}
                              <Text ml={1} as="span" fontWeight={'bold'}>
                                Private Room.
                              </Text>{' '}
                              <Text
                                ml={1}
                                as="span"
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  setFieldValue('public_booking', false)
                                }}
                                fontWeight={'bold'}
                              >
                                Disable Public booking
                              </Text>
                            </Text>
                          </Alert>
                        )}
                        <FormErrorMessage>
                          {errors.private_room}
                        </FormErrorMessage>
                      </Stack>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button
                  onClick={() => {
                    if (confirmSave) {
                      setTabIndex(1)
                      toast({
                        title: 'Before saving',
                        description: 'Confirm booking fees',
                        status: 'info',
                        duration: 3000
                      })
                    } else {
                      submitForm()
                    }
                  }}
                  disabled={isSubmitting}
                  mr={3}
                  data-testid="buton-save"
                >
                  Save
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
