import React, { useEffect, useState } from 'react'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'
import { useAuth } from '../../context/auth-context'
import { useParams } from 'react-router-dom'
import { useOrganization } from '../../utils/apiHooks'
import { Actions, useRBAC } from '../../utils/rbac'
import {
  useDisclosure,
  Box,
  Text,
  Stack,
  VStack,
  Flex,
  Spacer,
  Switch,
  Spinner,
  Select,
  Heading,
  Divider,
  useTheme
} from '@chakra-ui/react'

import { Button } from '../../components/Button'
import {
  creditCurrentCharges,
  setOrganizationEFTPermitted,
  updateOrganizationHomeLocation,
  updateOrganizationName
} from '../../api'
import { useCustomToast } from '../../context/toast-context'
import { CancelFullTeamModal } from './Modals/CancelFullTeamModal'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { CreditChargesModal } from './Modals/CreditChargesModal'
import { EditableInputText } from '../../components/EditableInputText'
import { RemovedOrganizationAlert } from '../../components/RemovedOrganizationAlert'
import { SettingsItem } from '../../components/SettingsItems'

interface SettingsItemProps {
  id?: string | number
  title: string
  description?: string
  children?: React.ReactNode
}

export const AdminTeamSettings: React.FC = () => {
  //used to enable/disable the billing update modal if user is an admin
  const auth = useAuth()
  const canCancelFullTeam = useRBAC(Actions.CancelFullTeam)
  const canCreditTeamCharges = useRBAC(Actions.CreditTeamCharges)

  const { newToast: toast } = useCustomToast()
  const theme: any = useTheme()

  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id

  const { data: organization, mutate } = useOrganization(orgId)
  const { platformTenant } = usePlatformTenant()

  const [updatingEFTPermitted, setUpdatingEFTPermitted] = useState(false)
  const [updatingHomeLocation, setUpdatingHomeLocation] = useState(false)
  const [updatingOrganizationName, setUpdatingOrganizationName] =
    useState(false)
  const [isEFTPermitted, setIsEFTPermitted] = useState<boolean>()
  const { onOpen: onOpenCancelFullTeamModal, onClose, isOpen } = useDisclosure()
  const {
    onOpen: onOpenCreditModal,
    onClose: onCloseCreditModal,
    isOpen: isOpenCreditModal
  } = useDisclosure()

  const handleToggleEFTPermitted = (value) => {
    if (!organization) return
    setUpdatingEFTPermitted(true)
    setOrganizationEFTPermitted(
      organization.id,
      !organization.eft_permitted,
      auth.token
    ).then(() => {
      setIsEFTPermitted(!organization.eft_permitted)
      setUpdatingEFTPermitted(false)
    })
  }

  const changeHomeLocationAction = async (event) => {
    if (!organization) return
    let location = platformTenant?.locations.find(
      (loc) => `${loc.id}` === event.target.value
    )
    if (
      location &&
      (!organization?.home_location ||
        organization.home_location.id != location.id)
    ) {
      setUpdatingHomeLocation(true)
      updateOrganizationHomeLocation(organization?.id, location)
        .then((value) => {
          mutate().then(() => {
            setUpdatingHomeLocation(false)
          })
        })
        .catch((err) => {
          console.log(err)
          setUpdatingHomeLocation(false)
        })
    }
  }

  const clearCurrentChargesAction = () => {
    if (!organization) return
    creditCurrentCharges(organization.id)
      .then(() => {
        toast({ description: 'Credit Applied', status: 'success' })
        onCloseCreditModal()
      })
      .catch(() =>
        toast({ description: 'Failed to Apply Credit', status: 'error' })
      )
  }

  const cancelTeamAction = () => {
    if (!organization) return
    onOpenCancelFullTeamModal()
  }
  useEffect(() => {
    if (organization) {
      setIsEFTPermitted(organization.eft_permitted === true)
    }
    if (organization && auth?.adminChangeOrganization) {
      auth.adminChangeOrganization(organization)
    }
  }, [organization])

  const onUpdateOrgsName = (newName: string) => {
    if (!organization || updatingOrganizationName) return

    if (newName.trim() === organization.name) return
    setUpdatingOrganizationName(true)
    updateOrganizationName(organization?.id, newName.trim())
      .then((res) => {
        setUpdatingOrganizationName(false)
      })
      .catch((error) => {
        setUpdatingOrganizationName(false)
        toast({ description: 'Unsuccessful update', status: 'error' })
      })
  }
  let iconActions: React.ReactNode[] = []

  return (
    <LayoutMemberDashboard>
      <RemovedOrganizationAlert removed={organization?.is_deleted} />
      <GroupingHeaderLayout
        basicTitle={organization?.name}
        iconAction={iconActions}
      />
      <AdminBodyWrapper pl={4}>
        <Flex mb={3} flexDir="column">
          <VStack
            bg="white"
            w={['100%', '100%', '100%', '60%']}
            boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
            rounded="md"
            px={3}
            py={2}
            position="relative"
            color="eastBay09"
            align="left"
            justifyContent="space-between"
          >
            <Box mb={4}>
              <Heading as="h4" size="md">
                Team Settings
              </Heading>
            </Box>
            {organization && !organization.is_deleted && (
              <Box mt={2}>
                <Stack spacing={1} divider={<Divider />}>
                  <SettingsItem id="1" title="Team's Name" description={''}>
                    <EditableInputText
                      defaultValue={organization.name}
                      onSubmitValue={onUpdateOrgsName}
                      isUpdating={updatingOrganizationName}
                    />
                  </SettingsItem>
                  <SettingsItem id="1" title="Home Location" description={''}>
                    {updatingHomeLocation ? (
                      <Spinner />
                    ) : (
                      <Select
                        width={['150px', '250px']}
                        onChange={changeHomeLocationAction}
                        value={organization.home_location.id + ''}
                      >
                        {platformTenant?.locations.map((option) => {
                          return (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        })}
                      </Select>
                    )}
                  </SettingsItem>
                  <SettingsItem
                    id="2"
                    title="Toggle EFT permitted"
                    description=""
                  >
                    {updatingEFTPermitted ? (
                      <Spinner />
                    ) : (
                      <Switch
                        isChecked={isEFTPermitted}
                        disabled={updatingEFTPermitted}
                        onChange={handleToggleEFTPermitted}
                        color={theme.colors.brandPrimary}
                        size="lg"
                        data-testid="toggle-eft-permitted"
                      />
                    )}
                  </SettingsItem>
                  {canCreditTeamCharges && (
                    <SettingsItem
                      id="3"
                      title="Credit Current Charges"
                      description=""
                    >
                      <Button
                        onClick={() => {
                          onOpenCreditModal()
                        }}
                      >
                        Apply credit
                      </Button>
                    </SettingsItem>
                  )}
                  {canCancelFullTeam && (
                    <SettingsItem
                      id="4"
                      title="Cancel all team's plans"
                      description=""
                    >
                      <Button onClick={cancelTeamAction}> Cancel team</Button>
                    </SettingsItem>
                  )}
                </Stack>
              </Box>
            )}
          </VStack>
        </Flex>
        <CancelFullTeamModal
          onClose={onClose}
          isOpen={isOpen}
          org={organization}
        />
        <CreditChargesModal
          onClose={onCloseCreditModal}
          isOpen={isOpenCreditModal}
          organization={organization}
          onConfirm={clearCurrentChargesAction}
        />
      </AdminBodyWrapper>
    </LayoutMemberDashboard>
  )
}
