import { Box, Link } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, TableDataProp } from '../../../components/table/Table'
import { useAuth } from '../../../context/auth-context'
import { routeStrings } from '../../../routeStrings'
import { IOrganization } from '../../../types'

function RenderCheck(check: boolean) {
  if (check === true) {
    return (
      <svg
        width="16"
        height="14"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="eft-permitted"
      >
        <path
          d="M9.4001 1.99961L8.0001 0.599609L4.0001 4.59961L2.0001 2.59961L0.600098 3.99961L4.0001 7.39961L9.4001 1.99961Z"
          fill="#757575"
        />
      </svg>
    )
  } else return ''
}

interface Props {
  orgs: IOrganization[] | undefined
}

export const Teams: React.FC<Props> = ({ orgs }) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const teamActions = [
    {
      text: 'Team settings',

      testIdPrefix: 'go-to-team-settings',
      action: (orgId) => {
        if (!orgId) return
        navigate(routeStrings.adminDashboardTeamSettings(orgId))
      }
    }
  ]

  function TeamLink(org: IOrganization) {
    if (org) {
      return (
        <Link
          color="#255CE1"
          mb={0}
          onClick={() => {
            auth.adminChangeOrganization(org)
            navigate(routeStrings.adminTeamDetailView(org.id))
          }}
        >
          {org.name}
        </Link>
      )
    }
  }

  const orgsFiltered = orgs

  const tableData =
    orgsFiltered &&
    orgsFiltered.map((org) => {
      const cleanedData = {
        id: TableDataProp(org.id),
        test_prefix: TableDataProp(org.name),
        name: TableDataProp(TeamLink(org) || '', org.name),
        home_location: TableDataProp(
          org.home_location ? org.home_location.name : '',
          org.home_location ? org.home_location.name : ''
        ),
        eft_permitted: TableDataProp(
          RenderCheck(org.eft_permitted ? true : false)
        ),
        actions: TableDataProp(org.is_space_admin ? [] : teamActions)
      }

      return cleanedData
    })

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Table
          pageSize={900}
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'test_prefix', accessor: 'test_prefix', show: false },
            {
              header: 'Name',
              accessor: 'name',
              show: true,
              width: 4 / 12,
              enableFilter: false
            },
            {
              header: 'Default Location',
              accessor: 'home_location',
              show: true,
              width: 2 / 12,
              enableFilter: false
            },
            {
              header: 'EFT Permitted',
              accessor: 'eft_permitted',
              show: true,
              width: 2 / 12
            },
            { header: '', accessor: 'actions', show: true, width: 1 / 12 }
          ]}
          data={tableData}
          loading={orgs === undefined}
        />
      </Box>
    </div>
  )
}
