/** @jsxImportSource @emotion/react */
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react'

import styled from '@emotion/styled'
import React, { useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { css } from '@emotion/react'

export const DropdownFormInput = ({
  items,
  name,
  selectedIndex,
  onSelect,
  additionalActions,
  dataTestId
}: {
  items: Array<{ itemName: string; itemId: number }> | undefined
  name: string
  selectedIndex: number
  onSelect: (n: number) => any
  additionalActions?:
    | Array<{ itemName: string | JSX.Element; itemAction: (arg: any) => void }>
    | undefined
  dataTestId?: string
}) => {
  const [actionText, setActionText] = useState<string | JSX.Element>()

  const inputStyles = css`
    transition: all ease 0.2s;
    border-radius: 0;
    border: 0px none;
    background: #fafafa;
    font-family: Open Sans;
    padding: 11px;
    align-items: center;
    color: #4f4f4f;
    &:hover {
      cursor: pointer;
      p {
        text-decoration: underline;
      }
      svg {
        /* transform: rotate(-90deg); */
      }
    }
    p,
    svg {
      margin-bottom: 0px;
      transition: all ease 0.2s;
    }
    border-bottom: #757575 1px solid;
  `
  const labelStyles = css`
    font-family: Open Sans;
    color: #2f4858;
    font-weight: bold;
    margin-bottom: 4px;
  `

  const Item = styled(Box)`
    &:hover {
      background: #4cfff90a;
      cursor: pointer;
    }
  `

  // If there's more than 13 list items, render the list at the top of page
  const longListOfItems = (items && items?.length > 13) || false
  return (
    <Box mb={3}>
      <Text css={labelStyles}>{name}</Text>
      <Menu>
        <MenuButton css={inputStyles} width="100%" data-testid={dataTestId}>
          <Flex justify="space-between">
            <Text overflowX="hidden">
              {items?.find((item) => item.itemId === selectedIndex)?.itemName ||
                actionText ||
                'Select an option'}
            </Text>
            <ChevronDownIcon
              color="brandPrimary"
              cursor="pointer"
              float="right"
              margin="0 8px 0 auto"
            />
          </Flex>
        </MenuButton>

        <MenuList
          zIndex={4}
          width="80%"
          minW="300px"
          maxH="600px"
          bg="white"
          boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
          overflowY="scroll"
          px={0}
          position={longListOfItems ? 'absolute' : undefined}
          top={60}
        >
          {/* Maps over the additional menu items, placed above the divider */}
          {additionalActions?.map((e, i) => (
            <MenuItem
              as={Item}
              px={3}
              key={i}
              onClick={() => {
                e.itemAction(i)
                setActionText(e.itemName)
              }}
            >
              {e.itemName}
            </MenuItem>
          ))}
          {/* Render a divider after actions if they exist */}
          {additionalActions && (
            <Box borderBottom="1px solid #e2e8f0" my="10px" />
          )}
          {/* Maps over the regular menu "items" */}
          {items?.map((e, i) => (
            <MenuItem
              as={Item}
              px={3}
              key={e.itemId}
              onClick={() => {
                onSelect(e.itemId)
              }}
            >
              {e.itemName}
            </MenuItem>
          )) || '...'}
        </MenuList>
      </Menu>
    </Box>
  )
}
