import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps
} from '@chakra-ui/react'
import React from 'react'
import {
  Link,
  LinkProps as RouterLinkProps,
  NavLink,
  NavLinkProps
} from 'react-router-dom'

type LinkProps = ChakraLinkProps & RouterLinkProps

/**
 * `RouterLink` is a custom component which composes the `react-router` <Link /> and Chakra's <Link /> components.
 * It allows us to use Chakra's styling props but works with react-router.
 *
 * Example usage:
 * ```
 * <RouterLink to="/a-new-page" mt={2} mr={2}>This link has both react-router and Chakra props!</Link>
 * ```
 */
export const RouterLink: React.FC<LinkProps> = React.forwardRef(
  (props: LinkProps, ref: React.Ref<any>) => {
    return <ChakraLink ref={ref} as={Link as any} {...props} />
  }
)

type RouterNavLinkProps = ChakraLinkProps & NavLinkProps

export const RouterNavLink: React.FC<RouterNavLinkProps> = React.forwardRef(
  (props: RouterNavLinkProps, ref: React.Ref<any>) => {
    return <ChakraLink ref={ref} as={NavLink} {...props} />
  }
)
