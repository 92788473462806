import { Alert, Box, Stack, Text } from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { acceptExistingMemberInvite, validateInvite } from '../../api'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { useAuth } from '../../context/auth-context'
import { routeStrings } from '../../routeStrings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { IInvitation } from '../../types'
import { useNavigate } from 'react-router-dom'

export const OnboardingAcceptInvite: React.FC = () => {
  const navigate = useNavigate()
  let { token } = useParams<{ token: any }>()
  const [invitation, setInvitation] = useState<IInvitation>()
  const [error, setError] = useState<string>()
  const { getAndSetMe } = useAuth()

  useEffect(() => {
    token &&
      validateInvite(token)
        .then(({ data }: { data: IInvitation }) => setInvitation(data))
        .catch((err) => setError(err))
  }, [token])

  if (error) {
    return (
      <OnboardingWrapper>
        <Stack p={3} spacing="8px">
          <Alert status="error">
            Someting went wrong fetching your invite, please ask your team admin
            to invite you again, or contact support.
          </Alert>
          <Button
            onClick={() => {
              navigate(routeStrings.memberDashboardHome)
            }}
          >
            Return to home
          </Button>
        </Stack>
      </OnboardingWrapper>
    )
  }

  if (!invitation) {
    return (
      <OnboardingWrapper>
        <Stack p={3} spacing="8px">
          <CenteredSpinner />

          <Button
            onClick={() => {
              navigate(routeStrings.memberDashboardHome)
            }}
          >
            Return to home
          </Button>
        </Stack>
      </OnboardingWrapper>
    )
  }
  return (
    <OnboardingWrapper>
      <Stack p={3} spacing="8px">
        <Text>
          Enter your job title then click join team to accept your invite
        </Text>
        <Box>
          <Formik
            initialValues={{
              job_title: '',
              company_name: ''
            }}
            validate={(values): { job_title: string; company_name: string } => {
              const errors: any = {}
              if (!values.job_title) {
                errors.job_title = 'Required'
              }
              if (invitation.is_team_admin && !values.company_name) {
                errors.company_name = 'Required'
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              let v: any = { job_title: values.job_title }
              if (invitation.is_team_admin) {
                v = { ...v, company_name: values.company_name }
              }
              acceptExistingMemberInvite(token, v)
                .then((res) => {
                  if (getAndSetMe != undefined) {
                    const token = window.localStorage.getItem('Token')
                    getAndSetMe(token).then((res) => {
                      setSubmitting(false)
                      navigate(routeStrings.memberDashboardHome)
                    })
                  }
                })
                .catch((err) => {
                  setSubmitting(false)
                  setError(err)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  type="text"
                  name="job_title"
                  data-testid="complete-job_title-field"
                  placeholder="Enter your job title"
                  label="Job Title"
                  component={FormInput}
                />
                <ErrorMessage name="job_title" component="div" />
                {invitation.is_team_admin && (
                  <>
                    <Field
                      type="text"
                      name="company_name"
                      data-testid="complete-company-field"
                      placeholder="Enter your company"
                      label="Company"
                      component={FormInput}
                      // required for users to be able to see members dashboard
                      required
                    />
                    <ErrorMessage name="company_name" component="div" />
                  </>
                )}
                <br />
                {invitation.is_team_admin && (
                  <>
                    <Text>
                      You will be prompted to setup your billing account after
                      accepting your invitation
                    </Text>
                    <br></br>
                  </>
                )}
                <Button
                  w="100%"
                  data-testid="accept-invite"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  loadingText={'Joining team'}
                >
                  {invitation.invitation_type === 'ExistingUserInvitation'
                    ? `Start your membership at ${invitation.tenant_name}`
                    : `Join your team at ${invitation.tenant_name}`}
                </Button>
              </Form>
            )}
          </Formik>
        </Box>

        <Button
          variant="secondary"
          onClick={() => {
            navigate(routeStrings.memberDashboardHome)
          }}
        >
          Return to home
        </Button>
      </Stack>
    </OnboardingWrapper>
  )
}
