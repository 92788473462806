import { Select, SelectProps } from '@chakra-ui/react'
import React from 'react'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useAccountCodes } from '../../utils/apiHooks'

type Props = {
  /**
   * orgId - optional id to be passed to the dropdown, used to select the xero account
   * default value is taken from useAuth context
   */
  orgId?: number
} & SelectProps

/**
 * XeroAccountDropdown
 * Composable chakra dropdown to render xero accoutns,
 * Optional var to specify orgId (defaults to auth.currentOrganization)
 *
 * Use defaultValue prop to specify the initial value (Formik will do this automatically)
 */
const XeroAccountDropdown = ({ orgId, ...props }: Props) => {
  const { platformTenant } = usePlatformTenant()
  const { data: accountCodes } = useAccountCodes(platformTenant?.id)

  return (
    <Select {...props}>
      {/* This should initially select it (as placeholder) but prevent subsequent selection */}
      <option value="">Select an account code</option>
      {accountCodes?.map((ac) => (
        <option value={ac.id} key={ac.id}>{`${ac.title} (${ac.code})`}</option>
      ))}
    </Select>
  )
}

export default XeroAccountDropdown
