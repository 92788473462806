import React, { useState, useEffect } from 'react'
import { IDailyFiveContent } from '../../types'
import { BoxProps, Box, useToast, useTheme, Tooltip } from '@chakra-ui/react'
import save_post from '../../assets/save_post.svg'
import saved_post from '../../assets/saved_post.svg'
import { useCommunityProfile } from '../../utils/apiHooks'
import { useCommunity } from '../../context/community-context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBookmark,
  faCheckCircle,
  faRibbon
} from '@fortawesome/free-solid-svg-icons'
import { useCustomToast } from '../../context/toast-context'
interface Props extends BoxProps {
  contentObject?: IDailyFiveContent
}
export const SaveButton: React.FC<Props> = ({
  contentObject: content,
  ...rest
}) => {
  const [saved, setSaved] = useState<boolean>(false)
  const toast = useCustomToast()
  const theme = useTheme()
  const { data: communityProfile } = useCommunityProfile()
  const { handleUnSave, handleSave, isSaved } = useCommunity()
  useEffect(() => {
    setSaved(isSaved(content))
  }, [communityProfile, content])

  const showToast = (saved: boolean) => {
    toast.newToast({
      description: saved ? 'Post saved' : 'Post unsaved',
      duration: 2000,
      status: 'info'
    })
  }
  return (
    <Tooltip label={saved ? 'Unsave post' : 'Save post'}>
      <Box
        cursor="pointer"
        {...rest}
        color={saved ? theme.colors.brandPrimary : '#999'}
      >
        {saved ? (
          <FontAwesomeIcon
            icon={faBookmark}
            onClick={() => {
              handleUnSave(content)
              showToast(false)
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faBookmark}
            onClick={() => {
              handleSave(content)
              showToast(true)
            }}
          />
        )}
      </Box>
    </Tooltip>
  )
}
