import { Box, Stack, StackProps, VStack } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { ContentExplorerPosts } from './ContentExplorerPosts'
import { TagsFilterBar } from './TagsFilterBar'
import { useContentExplorer } from '../../../context/content-explorer-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'

interface Props extends StackProps {}

export const ExplorerWrapper: React.FC<Props> = ({ ...rest }) => {
  const { platformTenant } = usePlatformTenant()
  const { fetchContent } = useContentExplorer()

  useEffect(() => {
    if (platformTenant != null) fetchContent()
  }, [platformTenant])

  return (
    <VStack spacing="8px">
      <TagsFilterBar></TagsFilterBar>
      <ContentExplorerPosts />
    </VStack>
  )
}
