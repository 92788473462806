import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

export const SecondaryButton: React.FC<ButtonProps> = React.forwardRef(
  (props: ButtonProps, ref: React.Ref<any>) => (
    <Button
      ref={ref}
      variant="outline"
      borderRadius="100px"
      borderColor="brandPrimary"
      color="brandPrimary"
      {...props}
    />
  )
)
