import {
  Box,
  Flex,
  Heading,
  Input,
  SkeletonCircle,
  SkeletonText,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  fetchPaginatedOrgsInMembersDirectory,
  fetchPaginatedMembersInMembersDirectory,
  createMemberDirectoryConnection
} from '../../../../api'
import { AdminBodyWrapper } from '../../../../components/AdminBodyWrapper'
import { useAuth } from '../../../../context/auth-context'
import { useMembersDirectoryContext } from '../../../../context/members-directory-context'
import { usePlatformTenant } from '../../../../context/platform-tenant-context'
import { CenteredSpinner } from '../../../../styled/CenteredSpinner'
import {
  DirectoryOrganizationalUnitResult,
  DirectoryOrganizationResult,
  Skill
} from '../../../../types'
import { FilterableSkills } from '../../../DailyFive/FilterableSkills'
import { MemberConnectionModal } from '../components/MemberConnectionModal'
import { OrganizationProfileModal } from '../components/OrganizationProfileModal'
import { DirectoryResultOrganizationalUnitProfile } from './DirectoryResultOrganizationalUnitProfile'
import { DirectoryResultOrganizationProfile } from './DirectoryResultOrganizationProfile'

export const DirectorySearchDashboard: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const { currentOrgUnit } = useAuth()
  const {
    skills,
    logAddContact,
    logViewCompanyExtraDetail,
    logBrowseDirectory
  } = useMembersDirectoryContext()
  const page_size = 10
  const [page, setPage] = useState(1)
  const [pageMembers, setPageMembers] = useState(1)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [orgFilter, setOrgFilter] = useState<string>('')
  const [total, setTotal] = useState(-1)
  const [totalMembers, setTotalMembers] = useState(-1)
  const [debouncedTerm, setDebouncedTerm] = useState('')
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [hasMoreResultsMembers, setHasMoreResultsMembers] = useState(true)
  const [isFetching, setIsFeching] = useState(false)
  const [isFetchingMembers, setIsFechingMembers] = useState(false)
  const [skillsFilter, setSkillsFilter] = useState<Skill[]>([])

  const {
    isOpen: isOpenOrganizationModal,
    onClose: onCloseOrganizationModal,
    onOpen: onOpenOrganizationModal
  } = useDisclosure()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedMember, setSelectedMember] =
    useState<DirectoryOrganizationalUnitResult>()
  const [organizations, setOrganizations] = useState<
    DirectoryOrganizationResult[]
  >([])
  const [members, setMembers] = useState<DirectoryOrganizationalUnitResult[]>(
    []
  )
  const [selectedOrganization, setSelectedOrganization] =
    useState<DirectoryOrganizationResult>()

  const toast = useToast()

  const fetchOrgs = (input_page: number, textFilter: string | undefined) => {
    if (isFetching) return
    if (currentOrgUnit) {
      setIsFeching(true)
      return fetchPaginatedOrgsInMembersDirectory(
        currentOrgUnit.id,
        textFilter,
        input_page,
        page_size
      )
        .then((res) => {
          if (res && res.data) {
            input_page === 1 && logBrowseDirectory('Organization', false)
            let results = res.data.results ?? []
            setOrganizations(
              input_page > 1 ? organizations.concat(results) : results
            )

            setTotal(res.data.count ?? 0)
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }

  const fetchOrgUnits = (
    input_page: number,
    textFilter: string | undefined
  ) => {
    if (isFetchingMembers) return
    if (currentOrgUnit) {
      setIsFechingMembers(true)
      return fetchPaginatedMembersInMembersDirectory(
        currentOrgUnit.id,
        textFilter,
        skillsFilter.map((skill) => skill.id),
        input_page,
        page_size
      )
        .then((res) => {
          if (res && res.data) {
            input_page === 1 &&
              logBrowseDirectory('Person', skillsFilter.length > 0)
            let results = res.data.results ?? []
            setMembers(input_page > 1 ? members.concat(results) : results)

            setTotalMembers(res.data.count ?? 0)
            setHasMoreResultsMembers(res.data.next != null)
            setPageMembers(input_page + 1)
            setIsFechingMembers(false)
          }
        })
        .catch((error) => {
          setIsFechingMembers(false)
        })
    }
  }

  const handleConnect = (orgUnitID: number) => {
    if (!currentOrgUnit) return
    const member = members.find((m) => m.id === orgUnitID)
    if (member) {
      setSelectedMember(member)
      onOpen()
    }
  }

  useEffect(() => {
    if (platformTenant && (orgFilter || skillsFilter.length > 0)) {
      fetchOrgs(1, orgFilter)
      fetchOrgUnits(1, orgFilter)
    }
  }, [orgFilter, skillsFilter])
  useEffect(() => {
    setOrgFilter('')
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => setOrgFilter(debouncedTerm), 600)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  if (!platformTenant) {
    return <CenteredSpinner />
  }
  return (
    <AdminBodyWrapper
      onScroll={(event) => {
        let element = event.target as HTMLElement
        if (
          element.scrollHeight - element.scrollTop <
          element.clientHeight * 1.2
        ) {
          if (hasMoreResults && !isFetching) {
            fetchOrgs(page, orgFilter)
          }
          if (hasMoreResultsMembers && !isFetchingMembers) {
            fetchOrgUnits(pageMembers, orgFilter)
          }
        }
      }}
    >
      <Box bg="white" w={'100%'} boxShadow="sm" rounded="md" px={8} py={5}>
        <VStack w="100%" alignItems="left">
          <Flex flexDir={['column', 'column', 'column', 'row']} w="100%">
            <Box w={['100%', '100%', '40%', '30%']} mr={2}>
              <Text fontWeight={'bold'} fontSize={'sm'} mb={2}>
                {'Search for members or teams'}
              </Text>
              <Input
                bg={'white'}
                placeholder={'Filter by names'}
                onChange={(e) => setDebouncedTerm(e.target.value)}
              />
            </Box>
            {/* <Box w={['100%', '100%', '70%', '30%']} mr={2}>
              <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                Industries
              </Text>
              <FilterableIndustries
                industriesList={industries}
                placeholder={''}
                initialValues={undefined}
                onSelectionChanges={(industries) => {
                  setIndustriesFilter(industries)
                }}
              />
            </Box> */}
            <Box w={['100%', '100%', '70%', '30%']} mr={2}>
              <Text fontWeight={'bold'} fontSize={'sm'} color={'#777'} mb={2}>
                Skills
              </Text>
              <FilterableSkills
                value={skillsFilter}
                skillsList={skills}
                placeholder={'Filter by one or more skills'}
                initialValues={undefined}
                onSelectionChanges={(value) => {
                  setSkillsFilter(
                    skills?.filter((skill) => value.includes(skill.id)) ?? []
                  )
                }}
              />
            </Box>
          </Flex>
        </VStack>
      </Box>
      <Flex flexDir={['column', 'column', 'row']}>
        <Box w={['100%', '100%', '60%']} pr={3}>
          {totalMembers >= 0 && (
            <Flex p={3}>
              <Heading size={'xs'}>Members</Heading>
              <Text ml={3} fontSize={'xs'}>
                {totalMembers} Total
              </Text>
            </Flex>
          )}
          {members.length > 0 &&
            members.map((member) => {
              return (
                <DirectoryResultOrganizationalUnitProfile
                  key={member.id}
                  member={member}
                  handleConnect={handleConnect}
                  displayOrganizationProfile={() => {
                    setSelectedOrganization(member.organization)
                    setTimeout(onOpenOrganizationModal, 0)
                    logViewCompanyExtraDetail(member.organization.id, 'Modal')
                  }}
                />
              )
            })}
          {isFetchingMembers && (
            <Box padding="6" mt={3} boxShadow="lg" bg="white">
              <SkeletonCircle size="10" />
              <SkeletonText
                mt="4"
                noOfLines={4}
                spacing="4"
                skeletonHeight="2"
              />
            </Box>
          )}
        </Box>
        <Box w={['100%', '100%', '40%']}>
          {total >= 0 && (
            <Flex p={3}>
              <Heading size={'xs'}>Teams</Heading>
              <Text ml={3} fontSize={'xs'}>
                {total} Total
              </Text>
            </Flex>
          )}
          {organizations.length > 0 &&
            organizations.map((org) => {
              return (
                <DirectoryResultOrganizationProfile
                  key={org.id}
                  organization={org}
                />
              )
            })}
          {isFetching && (
            <Box padding="6" mt={3} boxShadow="lg" bg="white">
              <SkeletonCircle size="10" />
              <SkeletonText
                mt="4"
                noOfLines={4}
                spacing="4"
                skeletonHeight="2"
              />
            </Box>
          )}
        </Box>
      </Flex>
      <MemberConnectionModal
        isSubmitting={isSubmitting}
        isOpen={isOpen}
        closeModal={onClose}
        selectedOu={selectedMember}
        handleCreateConnection={(message: string) => {
          if (!currentOrgUnit || !selectedMember || isSubmitting) {
            onClose()
            return
          }
          setIsSubmitting(true)
          createMemberDirectoryConnection(
            currentOrgUnit?.id,
            selectedMember.id,
            message
          )
            .then((res) => {
              logAddContact(message.length)
              setIsSubmitting(false)
              if (res.status === 200) {
                toast({
                  description: res.data,
                  status: 'success'
                })
              }
              onClose()
              setSelectedMember(undefined)
            })
            .catch((err) => {
              setIsSubmitting(false)
              toast({
                description: 'An error occurred. Try again later',
                status: 'error'
              })
            })
        }}
      />
      <OrganizationProfileModal
        isOpen={isOpenOrganizationModal}
        onClose={onCloseOrganizationModal}
        orgID={selectedOrganization?.id}
      />
    </AdminBodyWrapper>
  )
}
