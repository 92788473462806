import { Box, Link } from '@chakra-ui/react'
import React from 'react'
import { IAction } from '../../../components/table/ActionsDropdown'
import { Table, TableDataProp } from '../../../components/table/Table'
import { Plan } from '../../../types'
import { CheckIcon } from '@chakra-ui/icons'
interface IInputParams {
  plans: Plan[]
  loading: boolean
  editPlanAction: IAction
}

export const Plans: React.FC<IInputParams> = (input) => {
  const { plans, loading, editPlanAction } = input

  function RenderPlanLink(plan: Plan) {
    return (
      <Link color="#255CE1" onClick={() => editPlanAction.action(plan.id)}>
        {plan.name}
      </Link>
    )
  }

  const tableData = plans.map((plan) => {
    const actions: IAction[] = []

    const cleanedData = {
      id: TableDataProp(plan.id),
      test_prefix: TableDataProp(`plan-row-${plan.id}`),
      name: TableDataProp(RenderPlanLink(plan), plan.name),
      rate: TableDataProp(plan.rate_string),
      meeting_room_credits_included: TableDataProp(
        plan.meeting_room_credits_included
      ),
      plan_category: TableDataProp(plan?.category?.name),
      // when a plan is not hidden it's going to appear as "show to members" to make it more user friendly
      // when a plan is not hidden to admin is going to appear as "show to admin"
      hidden: TableDataProp(!plan.hidden && <CheckIcon />),
      hidden_to_admin: TableDataProp(!plan.hidden_to_admin && <CheckIcon />),
      approve_on_sign_up: TableDataProp(
        plan.approve_on_sign_up && <CheckIcon />
      ),
      addons: TableDataProp(plan.addon_plan && <CheckIcon />),
      actions: TableDataProp([])
    }

    // Define actions for the row
    actions.push(editPlanAction)

    cleanedData.actions = TableDataProp(actions)

    return cleanedData
  })

  return (
    <Box my="3" w="100%" minWidth="955px">
      <Table
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },
          {
            header: 'Plan',
            accessor: 'name',
            show: true,
            width: 5 / 12,
            enableFilter: true
          },
          {
            header: 'Rate',
            accessor: 'rate',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Meeting Credits',
            accessor: 'meeting_room_credits_included',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Add-ons',
            accessor: 'addons',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Plan Category',
            accessor: 'plan_category',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Show to members',
            accessor: 'hidden',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Show to admin',
            accessor: 'hidden_to_admin',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Approve on Sign Up',
            accessor: 'approve_on_sign_up',
            show: true,
            width: 1 / 12
          },

          { header: '', accessor: 'actions', show: true, width: 1 / 12 }
        ]}
        data={tableData}
        loading={loading}
      />
    </Box>
  )
}
