import { FormLabel, Text, Input, VStack, Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getSetupIntent } from '../../api'
import { Button } from '../../components/Button'

import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { IPlatformTenant } from '../../types'

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env

const stripeKey =
  REACT_APP_STRIPE_PUBLIC_KEY !== undefined ? REACT_APP_STRIPE_PUBLIC_KEY : ''
// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '18px',
        color: '#8b96a3',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#c23d4b'
      }
    }
  }
}

interface Props {
  platformTenant?: IPlatformTenant
  handleResult: (
    {
      paymentMethodId,
      setupIntent
    }: { paymentMethodId: string; setupIntent: string },
    isEft: boolean
  ) => void
  isSubmitting: boolean
  buttonText?: string
  infoText?: string
  errorText?: string
}

export const StripeConnectCardForm: React.FC<Props> = ({
  platformTenant,
  handleResult,
  isSubmitting,
  buttonText,
  infoText,
  errorText
}) => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [billingName, setBillingName] = useState<string>()
  const [stripe, setStripe] = useState<any | undefined>()
  const [elements, setElements] = useState<any | undefined>()
  const [cardElement, setCardElement] = useState<any | undefined>()
  const [clientSecret, setClientSecret] = useState<String>()
  const [isConfirmingCardSetup, setIsConfirmingCardSetup] = useState(false)
  const handleChange = ({ error }: any, e: any) => {
    if (error) {
      error.message && setErrorMessage(error.message)
    } else {
      setBillingName(billingName)
    }
  }
  useEffect(() => {
    platformTenant &&
      getSetupIntent(platformTenant.id).then((res) => {
        setClientSecret(res.data.client_secret)
        const strp = window.Stripe(stripeKey, {
          stripeAccount: res.data.tenant_id
        })
        setStripe(strp)
        const elem = strp.elements()
        setElements(elem)
        const cardElem = elem.create('card', {
          style: createOptions(),
          hidePostalCode: true
        })
        setCardElement(cardElem)
      })
  }, [platformTenant])

  useEffect(() => {
    if (cardElement) {
      //could cause a race condition here if teh html element hasn't loaded before this effect fires
      cardElement.mount('#card-element')
    }
  }, [cardElement])

  if (!(clientSecret && stripe && elements && cardElement)) {
    return <CenteredSpinner />
  }

  const handleSubmit = async (evt: any) => {
    setIsConfirmingCardSetup(true)
    evt.preventDefault()
    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: billingName
        }
      }
    })
    setIsConfirmingCardSetup(false)
    if (result && result.error) {
      // Inform the customer that there was an error.
      setErrorMessage(result.error.message)
    } else if (result && result.setupIntent) {
      // Redirect the customer to the authorization URL.
      handleResult(
        {
          paymentMethodId: result.setupIntent.payment_method,
          setupIntent: result.setupIntent.id
        },
        false
      )
    } else {
      setErrorMessage('An error has occurred')
    }
  }

  return (
    <div className="CardDemo">
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <VStack align="left">
          <Box>
            <FormLabel mb={0} htmlFor="billingName">
              Billing Name
            </FormLabel>
            <Input
              variant="flushed"
              type="text"
              name="billingName"
              placeholder="Billing Name"
              value={billingName}
              autoFocus={true}
              onChange={(e) => {
                handleChange({ error: '' }, e)
              }}
              data-testid="complete-billingName-field"
            />
          </Box>
          <Box>
            <FormLabel>Card Details</FormLabel>
            <div id="card-element">
              {/* <!-- A Stripe Element will be inserted here. --> */}
            </div>
          </Box>

          {infoText && <Text>{infoText}</Text>}
          <br />
          <Box>
            <Button
              data-testid="complete-next-card"
              disabled={isSubmitting || isConfirmingCardSetup}
              type="submit"
              size={'sm'}
              loadingText="Loading"
              isLoading={isSubmitting || isConfirmingCardSetup}
            >
              {buttonText}
            </Button>
          </Box>
        </VStack>
      </form>
      {errorMessage && (
        <Text color={'red'} lineHeight={20}>
          {errorMessage}. Please update your card details and submit your
          payment again.
        </Text>
      )}
    </div>
  )
}

export default StripeConnectCardForm
