import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminBodyWrapper } from '../../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../../components/GroupingHeaderLayout'
import { MeetingRoomBookingGroup } from '../../../components/MeetingRoomBookingGroup'
import { useAuth } from '../../../context/auth-context'
import { useBookingContext } from '../../../context/BookingContext'
import { routeStrings } from '../../../routeStrings'
import { useOrganizationalUnitBookings } from '../../../utils/apiHooks'
import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { FutureBookings } from './FutureBookings'

export const Bookings: React.FC = () => {
  const { orgUnitMeetingRoomBookings: orgUnitBookings, revalOrgUnitBookings } =
    useBookingContext()
  const { currentOrgUnit } = useAuth()
  const navigate = useNavigate()

  const [bookingFilter, setBookingFilter] = useState('upcoming')

  const futureBookings = orgUnitBookings
    ? orgUnitBookings.filter((booking) => {
        return (
          new Date(Date.parse(booking.end_time.toString())) > new Date() &&
          !booking.cancelled_at
        )
      })
    : []
  useEffect(() => {
    revalOrgUnitBookings()
  }, [])

  const { data: dataPastBookings, mutate: revalidatePastBookings } =
    useOrganizationalUnitBookings('past', currentOrgUnit?.id)

  const pastBookings = dataPastBookings?.filter(
    (b) => b.meeting_room != undefined
  )

  const headerGroups = [
    {
      text: 'Upcoming',
      active: bookingFilter === 'upcoming',
      selectAction: () => {
        setBookingFilter('upcoming')
      }
    },
    {
      text: 'Past',
      active: bookingFilter === 'past',
      selectAction: () => {
        setBookingFilter('past')
        revalidatePastBookings()
      }
    }
  ]

  const headerAction = {
    text: 'New Booking',
    variant: 'primary',
    onClick: () => {
      navigate({ pathname: routeStrings.memberDashboardMeetingRooms })
    }
  }

  return (
    <LayoutMemberDashboard>
      <GroupingHeaderLayout
        basicTitle="Bookings"
        action={headerAction}
        tabs={headerGroups}
      />
      <AdminBodyWrapper>
        {bookingFilter === 'upcoming' && (
          <FutureBookings bookings={futureBookings} />
        )}
        {bookingFilter === 'past' && (
          <Box bg="#F4F5F5" width="100%" height="100%">
            <MeetingRoomBookingGroup title="" bookings={pastBookings ?? []} />
          </Box>
        )}
      </AdminBodyWrapper>
    </LayoutMemberDashboard>
  )
}
