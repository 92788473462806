import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

interface SettingsItemProps {
  id?: string | number
  title: string
  description?: string
  children?: React.ReactNode
}

export const SettingsItem: React.FC<SettingsItemProps> = (
  settings: SettingsItemProps
) => {
  return (
    <Flex
      key={settings.id}
      flexDir={['column', 'column', 'row']}
      justifyContent={'space-between'}
    >
      <Box py={settings.description ? 0 : 2}>
        <Text fontSize={{ base: 'md' }} textAlign={'left'}>
          {settings.title}
        </Text>
        {settings.description && (
          <Text fontSize={{ base: 'xs' }}>{settings.description}</Text>
        )}
      </Box>

      <Box p="2" ml="30px" overflowX={'hidden'}>
        {settings.children}
      </Box>
    </Flex>
  )
}
