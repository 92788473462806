import React from 'react'
import { FilterableMultiSelect } from '../../components/FilterableMultiSelect'
import { Skill } from '../../types'

interface Props {
  skillsList: Array<Skill> | undefined
  initialValues: Array<number> | undefined
  placeholder?: string
  onSelectionChanges: (interests: Array<number>) => void
  value?: Array<Skill>
}

export const FilterableSkills: React.FC<Props> = (props) => {
  const { initialValues, placeholder, skillsList, onSelectionChanges } = props

  if (!skillsList) return null

  return (
    <FilterableMultiSelect
      className="filterable-skills"
      dataTestId="skills-field"
      value={props.value?.map((interest) => interest.id)}
      placeholder={placeholder}
      initialValues={initialValues}
      isClearable={false}
      items={skillsList?.map((interest) => ({
        value: interest.id,
        label: interest.name ?? ''
      }))}
      onSelect={(ids) => {
        onSelectionChanges(ids)
      }}
    />
  )
}
