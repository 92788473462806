import { Alert, AlertIcon, Box, Image, Stack, useTheme } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { zoomCallback } from '../../api'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { routeStrings } from '../../routeStrings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { Button } from '../../components/Button'
export const ZoomCodeRegistration: React.FC = () => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [response, setResponse] = useState<boolean>(false)
  const theme: any = useTheme()
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')

    try {
      if (code) {
        const data = { code: code }
        setSubmitting(true)
        zoomCallback(data).then((res) => {
          if (res.status === 200) {
            setResponse(true)
          }

          setSubmitting(false)
        })
      } else {
        setSubmitting(false)
        setResponse(false)
      }
    } catch (e: any) {
      console.log(e.message)
    }
  }, [])

  if (submitting) {
    return (
      <Box>
        <CenteredSpinner />
      </Box>
    )
  }
  return (
    <OnboardingWrapper>
      <Stack p={3} spacing="8px">
        <Image
          src={theme.logos.logo_long}
          width="128px"
          className="location-logo"
        />
        {response ? (
          <Alert status="success">
            <AlertIcon />
            Zoom successfully connected to the platform, You can now return to
            creating your post.
          </Alert>
        ) : (
          <Alert status="error">
            <AlertIcon />
            There was an error connecting your Zoom account to the platform.
          </Alert>
        )}
        <Button
          onClick={() => {
            navigate(routeStrings.memberDashboardHome)
          }}
        >
          Return to home
        </Button>
      </Stack>
    </OnboardingWrapper>
  )
}
