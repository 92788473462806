import { Flex, Stack, StackProps, Text } from '@chakra-ui/react'
import { differenceInDays, format } from 'date-fns'
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { BillingIcon } from '../../../../components/icons/icons'
import { RouterLink } from '../../../../components/Link'
import { routeStrings } from '../../../../routeStrings'
import { tourTargetStrings } from '../../../../tourStepsArrays'
import { OverviewBox } from './OverviewBoxBase'
import { IInvoiceSummary } from './types'
import { ReactNodeWithProps } from '../../../../component.types'

interface Props extends StackProps {
  overdue?: IInvoiceSummary[]
  nextInvoice?: IInvoiceSummary
  loading?: boolean
}

const InvoiceSummaryText: React.FC<
  {
    title: string
    titleColor: string
    subtitle: string
    amount: number
  } & ReactNodeWithProps
> = ({ title, titleColor, subtitle, amount, children }) => (
  <Flex alignContent="center" justifyContent="space-between">
    <Flex flexDir="column" justifyContent="center" fontSize="sm">
      <Text color={titleColor} fontWeight="bold">
        {title}
      </Text>
      <Text color="#757575">{subtitle}</Text>
      {children}
    </Flex>
    <Text fontWeight="bold">
      {amount.toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0
      })}
    </Text>
  </Flex>
)

export const BillingOverview: React.FC<Props> = ({
  overdue,
  nextInvoice,
  loading,
  ...rest
}) => {
  return (
    <SkeletonTheme baseColor="#b7e1f1" highlightColor="#93c8dc">
      <OverviewBox
        manageLink={routeStrings.memberDashboardBilling}
        bg="#E4F5FB"
        className={tourTargetStrings.dashboardBilling}
        header="Billing"
        headerIcon={<BillingIcon color="#1D99C8" pt="1px" />}
        {...rest}
      >
        {loading ? (
          <Text>
            <Skeleton />
          </Text>
        ) : (
          <Stack spacing={3} shouldWrapChildren>
            {overdue &&
              overdue.map((overdueInvoice, i) => {
                const numberOfDaysOverdue = differenceInDays(
                  overdueInvoice.dueDate,
                  new Date()
                )
                return (
                  <InvoiceSummaryText
                    key={i}
                    title="Invoice overdue"
                    subtitle={
                      numberOfDaysOverdue > 0
                        ? `Due 
        ${numberOfDaysOverdue} days
        ago`
                        : `Due today`
                    }
                    amount={overdueInvoice.amount}
                    titleColor="#E1202A"
                  >
                    <RouterLink
                      color="#255CE1"
                      mt={1}
                      fontWeight="bold"
                      to={routeStrings.memberDashboardBilling}
                    >
                      Pay now
                    </RouterLink>
                  </InvoiceSummaryText>
                )
              })}
            {nextInvoice && (
              <InvoiceSummaryText
                title="Next invoice"
                titleColor="black"
                subtitle={`On ${format(nextInvoice.dueDate, 'd MMMM, yyyy')}`}
                amount={nextInvoice.amount}
              />
            )}
          </Stack>
        )}
      </OverviewBox>
    </SkeletonTheme>
  )
}
