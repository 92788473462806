import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { Location } from '../../../types'
import { IndvBookingLocationSettings } from './IndvBookingLocationSettings'

interface Props {
  locations: Location[]
}

export const BookingLocationSettings: React.FC<Props> = (props) => {
  const { locations } = props

  return (
    <Box pt={3}>
      {locations &&
        locations.map((location, index) => (
          <IndvBookingLocationSettings key={index} location={location} />
        ))}
    </Box>
  )
}
