import styled from 'styled-components'

export const PhoneWrapper = styled.div`
  input::-webkit-input-placeholder {
    margin-left: 6px;
  }

  .PhoneInput {
    padding: 4px 8px 8px 8px !important;
    font-size: 16px;
    background: #e5e5e5;
    color: black;
  }
  .PhoneInput--focus {
    border-color: #3182ce !important;
  }

  .PhoneInputInput {
    outline: 0;
    border-radius: 0;
  }

  .react-tags__suggestions {
    z-index: 99;
    left: unset;
    width: 100%;
    max-height: 260px;
    overflow-y: scroll;

    mark {
      padding: 0;
    }
  }
`
