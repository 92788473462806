import {
  Button,
  Divider,
  Flex,
  Link,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { differenceInDays, format, isToday } from 'date-fns'
import React, { useState, useEffect } from 'react'
import { viewConnection } from '../../../api'
import { useAuth } from '../../../context/auth-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { MembershipStates } from '../../../types'
import { analyticsTrack } from '../../../utils/analytics'
import {
  useLatestConnection,
  useMyUserProfile,
  useNewConnection,
  useOrganizationBookingCredits,
  useOrganizationInvoicePreview,
  useOrgInvoices
} from '../../../utils/apiHooks'
import { USER_SUPPORT_LINK } from '../../../utils/constants'
import { getFirstOfNextMonth } from '../../../utils/getFirstOfNextMonth'
import { invoiceMapping } from '../../../utils/invoiceMapping'
import { Actions, useRBAC, useRole } from '../../../utils/rbac'
import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { ConnectionModal } from './ConnectionModal'
import { HomePageDailyFiveDisplay } from './HomePageDailyFiveDisplay'
import { HomePageDisplay, NoticeWrapper } from './HomePageDisplay'
import {
  IArticle,
  IConnectionDisplay,
  IInvoiceSummary
} from './homePageDisplay/types'
import { useContentExplorer } from '../../../context/content-explorer-context'
import { useBookingContext } from '../../../context/BookingContext'
import { useMembersDirectoryContext } from '../../../context/members-directory-context'
import { useAppTourContextProvider } from '../../../context/app-tour-context'

export const Notice = () => {
  const { me, logoutUser } = useAuth()
  const { platformTenant } = usePlatformTenant()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: connection, mutate } = useNewConnection()
  const { coworkingSettingsEnabled } = useFeatureFlags()
  const { supportUrl } = usePlatformTenant()
  if (me === undefined) return null
  const { membershipState } = me

  if (membershipState === MembershipStates.PendingAdminApproval)
    return (
      <NoticeWrapper>
        <Text>
          Your membership application is awaiting approval from an admin. If you
          have any questions, please{' '}
          <Link
            href={coworkingSettingsEnabled ? supportUrl : USER_SUPPORT_LINK}
          >
            contact support.
          </Link>
        </Text>
        <Divider mt={3} mb={1} />
        <Text fontSize="xs">
          <Link onClick={logoutUser} href="#">
            Log out
          </Link>
        </Text>
      </NoticeWrapper>
    )
  if (membershipState === MembershipStates.PendingStartingMembership)
    return (
      <NoticeWrapper>
        <Text>
          Your {platformTenant?.business_name} membership is scheduled to begin
          on {format(me.startDate, 'do MMMM')}. Once your membership starts,
          you'll be able to make room bookings and view your WiFi password
          through the Spacecubed Platform.
        </Text>
        <Divider mt={3} mb={1} />
        <Text fontSize="xs">
          <Link onClick={logoutUser} href="#">
            Log out
          </Link>
        </Text>
      </NoticeWrapper>
    )

  if (connection && me) {
    const openModal = () => {
      analyticsTrack('Open connection modal', { from: 'New Connection Alert' })
      viewConnection(connection.id)
      onOpen()
    }

    const closeModal = () => {
      // mark as viewed?
      mutate()
      onClose()
    }

    const otherUser = connection?.members?.find((u) => u.id !== me.user.id)
    if (otherUser === undefined) return null

    return (
      <>
        <NoticeWrapper>
          <Stack p={5} spacing={3} align="center" justify="center">
            <Text>You've received a new member connection.</Text>
            <Button onClick={openModal}>View</Button>
          </Stack>
        </NoticeWrapper>
        <ConnectionModal
          isOpen={isOpen}
          onClose={closeModal}
          otherUserName={otherUser.name}
          otherUserEmail={otherUser.email}
          message={connection.message}
        />
      </>
    )
  }

  return null
}

export const MemberHomepageV2: React.FC = () => {
  const auth = useAuth()

  const canViewInvoices = useRBAC(Actions.ViewBillingForOwnTeam)
  const canBookMeetings = useRBAC(Actions.BookMeetingRooms)
  const canBookWorkspaces = useRBAC(Actions.BookWorkspaces)
  const { isExternalMember } = useRole()
  const canViewWifi = useRBAC(Actions.ViewPersonalWifiDetails)
  const { dailyFiveEnabled, membersDirectoryEnabled } = useFeatureFlags()
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { isNextPage, fetchContent, activeTab } = useContentExplorer()
  const { data: latestConnection } = useLatestConnection()
  const { data: newConnection } = useNewConnection()
  const { orgUnitBookings: allBookings } = useBookingContext()
  const { data: meetingRoomQuota } = useOrganizationBookingCredits(
    auth.currentOrganization?.id
  )

  const { platformTenant, blogPosts } = usePlatformTenant()
  // only load invoices for team admins not space admins or non team admin members
  const { data: invoices } = useOrgInvoices(
    canViewInvoices && !auth.me?.user.is_space_admin
      ? auth?.currentOrganization?.id
      : undefined,
    platformTenant?.id,
    1
  )
  const { shouldShowMemberTour, run, setRun, setTourActive } =
    useAppTourContextProvider()

  useEffect(() => {
    if (shouldShowMemberTour) {
      setRun(true)
      setTourActive(true)
    }
  }, [shouldShowMemberTour])

  const { data: userProfile } = useMyUserProfile(auth.me?.user.user_profile.id)
  const { data: invoicePreview } = useOrganizationInvoicePreview(
    !auth.me?.user.is_space_admin &&
      auth.currentOrganization &&
      auth.isTeamAdmin
      ? auth.currentOrganization.id
      : undefined
  )
  const canViewMembersDirectory = useRBAC(Actions.ViewMembersDirectory)
  const [mappedBlogPosts, setMappedBlogPosts] = useState<IArticle[]>([])
  const [overdueInvoices, setOverdueInvoices] = useState<IInvoiceSummary[]>([])
  const [latestConnectionToDisplay, setLatestConnectionToDisplay] =
    useState<IConnectionDisplay>()

  const { latestConnections: latestDirectoryConnections } =
    useMembersDirectoryContext()
  useEffect(() => {
    setMappedBlogPosts(
      blogPosts?.map((p) => ({
        title: p.title,
        date: new Date(Date.parse(p.date)),
        category: 'Post',
        link: p.link,
        image: p.image
      })) || []
    )
  }, [blogPosts])

  useEffect(() => {
    setOverdueInvoices(
      invoices?.results
        ?.filter((i) => invoiceMapping.unpaid.includes(i.status))
        .map((i) => ({
          dueDate: new Date(Date.parse(i.issued_on)),
          amount: parseFloat(i.amount_charged)
        })) || []
    )
  }, [invoices])

  useEffect(() => {
    const otherUser = latestConnection?.members?.find(
      (u) => u.id !== auth?.me?.user.id
    )

    // if we have a new connection that is unread, it shouldn't be displayed in the regular
    // overview menu as we are already displaying it at the very top of the page
    if (newConnection === '' && otherUser !== undefined) {
      setLatestConnectionToDisplay({
        otherUserEmail: otherUser.email,
        otherUserName: otherUser.name,
        message: latestConnection?.message
      })
    }
  }, [latestConnection])

  const props = {
    notice: <Notice />,
    articles: {
      content: mappedBlogPosts,
      loading: blogPosts === undefined
    },
    meetings: {
      hide: !canBookMeetings && !canBookWorkspaces,
      bookedToday:
        allBookings?.filter((booking) => {
          return (
            new Date(Date.parse(booking.end_time.toString())) > new Date() &&
            isToday(new Date(Date.parse(booking.end_time.toString())))
          )
        }) || [],
      creditsRemaining:
        meetingRoomQuota && parseFloat(meetingRoomQuota.credits_remaining),
      daysUntilCreditsExpire: differenceInDays(
        getFirstOfNextMonth(),
        new Date()
      ),
      loading: allBookings === undefined || meetingRoomQuota === undefined
    },
    invoices: {
      hide: !canViewInvoices && auth.me?.user.is_space_admin,
      overdue: overdueInvoices,
      nextInvoice: invoicePreview && {
        amount: parseFloat(invoicePreview.invoice.amount_charged),
        dueDate: getFirstOfNextMonth()
      }
    },
    wifi: {
      hide: !canViewWifi,
      wifiPassword: userProfile?.wifi_password,
      loading: userProfile === undefined
    },
    connection: latestConnectionToDisplay,
    canViewMembersDirectory: canViewMembersDirectory && membersDirectoryEnabled,
    directoryConnections: canViewMembersDirectory
      ? latestDirectoryConnections
      : undefined
  }

  return (
    <LayoutMemberDashboard>
      <Flex>{/* <Heading>Show Tutorial</Heading> */}</Flex>
      <Flex
        className="main"
        height="100vh"
        width="100%"
        overflowY="scroll"
        p={5}
        // pt={5}
        // pl={[5, 5]}
        // pr={[0, 5]}
        justifyContent="center"
        onScroll={(event) => {
          let element = event.target as HTMLElement
          if (
            element.scrollHeight - element.scrollTop <
            element.clientHeight * 1.4
          ) {
            if (isNextPage && !isFetching && activeTab === 'EXPLORER') {
              setIsFetching(true)
              fetchContent()
                .then((data) => {})
                .finally(() => {
                  setIsFetching(false)
                })
            }
          }
        }}
      >
        {dailyFiveEnabled && !isExternalMember ? (
          <HomePageDailyFiveDisplay {...props} />
        ) : (
          <HomePageDisplay {...props} />
        )}
      </Flex>
    </LayoutMemberDashboard>
  )
}
