import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Link,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { sendEmailForPasswordReset } from '../api'

import { OnboardingWrapper } from '../components/OnboardingWrapper'
import { EMAIL_REGEX, USER_SUPPORT_LINK } from '../utils/constants'
import { Button } from '../components/Button'
import { useFeatureFlags } from '../context/feature-flag-context'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { StyledInput } from '../components/StyledInputs'

export const PasswordReset: React.FC = () => {
  const [emailSentTo, setEmailSentTo] = useState<string>()
  const [, setError] = useState<string>('')
  const theme: any = useTheme()
  const { supportUrl } = usePlatformTenant()
  const { coworkingSettingsEnabled } = useFeatureFlags()

  return (
    <OnboardingWrapper>
      <Stack align="center" spacing={8} mt={['10px', '100px', '20vh']}>
        <Image
          src={theme.logos.logo_long}
          w={['65%', '14rem']}
          alt="Spacecubed Logo"
        />
        {emailSentTo ? (
          <Stack spacing={3} textAlign="center">
            <Text>
              Check your{' '}
              <Text as="span" fontWeight="bold">
                {emailSentTo}
              </Text>{' '}
              inbox for instructions from us on how to reset your password.
            </Text>
            <Divider />
            <Text>
              Unsure if that email address was correct?{' '}
              <Link
                as="a"
                textDecor="underline"
                color="#255CE1"
                href={coworkingSettingsEnabled ? supportUrl : USER_SUPPORT_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                We can help
              </Link>
              .
            </Text>
          </Stack>
        ) : (
          <Box>
            <Text mb={3} fontSize={'md'}>
              To reset your password, enter the email address you used to sign
              in.
            </Text>
            <Formik
              initialValues={{
                email: ''
              }}
              validate={(values): any => {
                const errors: any = {}

                if (!values.email) {
                  errors.email = 'Required'
                } else if (!EMAIL_REGEX.test(values.email.trim())) {
                  errors.email = 'Invalid email address'
                }
                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  await sendEmailForPasswordReset(values.email.toLowerCase())
                  setEmailSentTo(values.email.trim())
                } catch (error: any) {
                  if (
                    error.response &&
                    error.response.status === 400 &&
                    error.response.data.email !== undefined
                  ) {
                    setError(error.response.data.email)
                  } else {
                    console.log(error.response)
                  }
                }
              }}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form>
                  <Box mb={5}>
                    <Field
                      name="email"
                      render={({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel
                            fontWeight={'bold'}
                            htmlFor="email"
                            fontSize="md"
                          >
                            Email
                          </FormLabel>
                          <Box>
                            <StyledInput
                              {...field}
                              id="email"
                              w="100%"
                              data-testid="password-reset-field"
                            />
                          </Box>
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  </Box>
                  <Button
                    type="submit"
                    data-testid="complete-next"
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    w={['100%', 'initial']}
                  >
                    Get Reset Link
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </Stack>
    </OnboardingWrapper>
  )
}
