import React from 'react'
import { IBookingDisplay } from './types'
import { Text } from '@chakra-ui/react'
import { differenceInMinutes, isSameDay } from 'date-fns'

const differenceInMinutesAndHours = (
  dateLeft: Date,
  dateRight: Date
): string => {
  const minutesDiff = differenceInMinutes(dateLeft, dateRight)
  const hours = Math.floor(minutesDiff / 60)
  const min = minutesDiff % 60
  let differenceString = ''

  if (hours > 0) {
    differenceString += `${hours} hour${hours > 1 ? 's' : ''}, `
  }
  differenceString += `${min} minute${min > 1 ? 's' : ''}`

  return differenceString
}

interface Props {
  bookings: IBookingDisplay[]
  currentTime: Date
}

/**
 * Displays the availability for a meeting room based on it's list of bookings.
 * Handle filtering of bookings based on `currentTime` prop.
 *
 * Renders text such as "Available for 1 hour, 23 minutes", or "Booked for 42 minutes"
 */
export const AvailableFor: React.FC<Props> = ({ bookings, currentTime }) => {
  const currentBooking = bookings.find(
    booking => booking.startTime <= currentTime && booking.endTime > currentTime
  )

  let availabilityText = 'Available'

  if (currentBooking !== undefined) {
    // const upcomingBookings = bookings.filter(
    //   booking => booking.endTime > currentTime
    // )
    // const endTime = getNextAvailableTime(upcomingBookings)

    availabilityText =
      'Booked for ' +
      differenceInMinutesAndHours(currentBooking.endTime, currentTime)
  } else {
    // assumes that `bookings` is sorted
    const nextBooking = bookings
      .filter(booking => isSameDay(booking.startTime, currentTime))
      .find(booking => booking.startTime > currentTime)

    if (nextBooking) {
      availabilityText =
        'Available for ' +
        differenceInMinutesAndHours(nextBooking.startTime, currentTime)
    }
  }

  return <Text fontSize="2rem">{availabilityText}</Text>
}
