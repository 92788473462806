import React from 'react'
import styled, { css } from 'styled-components'
import { CustomForwardChevronIcon } from '../components/icons/icons'


export const Card = ({
  children,
  title,
  onPress,
  arrow = false,
  padding = true,
  testID,
  outline,
  hover = false
}: {
  children: React.ReactChild | React.ReactChild[]
  title?: string
  padding?: boolean
  arrow?: boolean
  onPress?: () => void
  testID?: string
  outline?: boolean
  hover?: boolean
}): JSX.Element => (
  <Spacer>
    <OutSideContainer
      onClick={onPress}
      data-testid={testID}
      padding={padding}
      hover={hover}
      outline={outline}
    >
      {arrow ? <CustomForwardChevronIcon /> : <></>} <Title>{title}</Title>
      {children}
    </OutSideContainer>
  </Spacer>
)

const Spacer = styled.div`
  width: 100%;
  padding: 8px;
`

const OutSideContainer = styled.div<{
  padding: boolean
  outline?: boolean
  hover?: boolean
}>`
  position: relative;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  color: #555555;
  ${(props: any) =>
    props.padding === false
      ? css`
          padding: 0px;
        `
      : css`
          padding: 16px;
        `}
  ${(props: any) =>
    props.outline === true &&
    css`
      border: 3px solid #ca599c;
    `}
  ${(props: any) =>
    props.hover === true &&
    css`
      transition: all ease 0.2s;

      :hover {
        cursor: pointer;
        transform: scale(1.008);
        box-shadow: 0px 22px 22px rgba(0, 0, 0, 0.05);
      }
    `}
  ${(props: any) =>
    props.onClick !== undefined &&
    css`
      transition: all ease 0.2s;

      :hover {
        cursor: pointer;
        transform: scale(1.008);
        box-shadow: 0px 22px 22px rgba(0, 0, 0, 0.05);
      }
    `}
  `

const Icon = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 14px;
  bottom: 14px;
`

const Title = styled.div`
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ca599c;
`
