import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React from 'react'
import { updateAndRemoveAccountCode } from '../../../api'
import { AlertBox } from '../../../components/AlertBox'
import { Button } from '../../../components/Button'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IAccountCode, IXeroAccountCode } from '../../../types'
import { useAccountCodes } from '../../../utils/apiHooks'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  accountCode: IAccountCode | undefined
}
export const DeleteAccountCodeModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  accountCode
}) => {
  const { platformTenant } = usePlatformTenant()
  const { data: accountCodes } = useAccountCodes(platformTenant?.id)
  const toastPopup = useToast()
  if (!(platformTenant && accountCode)) {
    return null
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>Remove and Reassign Account Code</ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={{
            xero_account_to_replace_with_id: ''
          }}
          onSubmit={async (values, actions) => {
            if (values.xero_account_to_replace_with_id !== undefined) {
              try {
                await updateAndRemoveAccountCode(platformTenant.id, {
                  xero_account_to_remove_id: '' + accountCode.id,
                  xero_account_to_replace_with_id:
                    values.xero_account_to_replace_with_id
                })
              } catch (e) {
                toastPopup({
                  position: 'top',
                  duration: 5000,
                  render: (onClose) => (
                    <AlertBox onClose={onClose}>Something went wrong</AlertBox>
                  ),
                  isClosable: true
                })
              } finally {
                onClose()
              }
            }
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <Text>
                  Please select a default code to replace this code (
                  {accountCode.title} {accountCode.code}) prior to deleting it
                  from the platform
                </Text>
                <Field
                  name="xero_account_to_replace_with_id"
                  render={({ field, form }) => (
                    <FormControl
                      mt="32px"
                      // isDisabled={!!meetingRoomToEdit}
                      isRequired
                      isInvalid={
                        form.errors.xero_account_to_replace_with_id &&
                        form.touched.xero_account_to_replace_with_id
                      }
                    >
                      <FormLabel color="#4F4F4F" mb={1}>
                        XERO account code
                      </FormLabel>
                      <StyledSelect
                        placeholder="Select the XERO account code"
                        {...field}
                      >
                        {accountCodes?.map((accountCode) => (
                          <option key={accountCode.code} value={accountCode.id}>
                            {`${accountCode.title} (${accountCode.code})`}
                          </option>
                        ))}
                      </StyledSelect>

                      <FormErrorMessage>
                        {form.errors.xero_account_to_replace_with_id}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button disabled={props.isSubmitting} type="submit" mr={3}>
                  Save
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  )
}
