import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Switch,
  Text,
  useToast
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React from 'react'
import { createWorkspace, updateWorkspace } from '../../../api'
import { Button } from '../../../components/Button'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { IWorkspaceSuperAdmin, INewWorkspace } from '../../../types'
import { useAccountCodes } from '../../../utils/apiHooks'

interface IModalInput {
  isOpen: boolean
  onClose: (status?: boolean) => void
  workspaceToEdit?: IWorkspaceSuperAdmin
}

export const CreateWorkspaceModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  workspaceToEdit
}) => {
  const toast = useToast()
  const { platformTenant } = usePlatformTenant()
  const { data: accountCodes } = useAccountCodes(platformTenant?.id)

  const isMainSpace = workspaceToEdit?.is_main_space ?? false

  function validateRate(value) {
    let error
    if (!value) {
      error = 'This rate is required'
    } else if (isNaN(value)) {
      error = 'This rate must be numeric'
    } else if (value < 0) {
      error = 'This rate cannot be negative'
    }
    return error
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>
          {workspaceToEdit ? 'Edit ' : 'Create '}Workspace{' '}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            id: -1,
            name: workspaceToEdit?.name ?? '',
            location: workspaceToEdit?.location ?? 1,
            is_main_space: workspaceToEdit?.is_main_space ?? false,
            is_bookable: workspaceToEdit?.is_bookable ?? true,
            xero_account_code: workspaceToEdit?.xero_account_code ?? -1,
            member_price_half_day_price:
              workspaceToEdit?.member_price_half_day_price ?? 0,
            member_price_full_day_price:
              workspaceToEdit?.member_price_full_day_price ?? 0,
            non_member_price_half_day_price:
              workspaceToEdit?.non_member_price_half_day_price ?? 0,
            non_member_price_full_day_price:
              workspaceToEdit?.non_member_price_full_day_price ?? 0
          }}
          onSubmit={(values, actions) => {
            const newWorkspace: INewWorkspace = values
            if (workspaceToEdit) {
              // update
              updateWorkspace(workspaceToEdit.id, newWorkspace)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Workspace Updated'
                  })
                  onClose(true)
                })
                .catch((error) => {
                  toast({
                    status: 'error',
                    description: 'Failed to update workspace'
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                })
            } else {
              // create

              createWorkspace(newWorkspace)
                .then(() => {
                  toast({
                    status: 'success',
                    description: 'Workspace Created'
                  })
                  onClose(true)
                })
                .catch((e) => {
                  console.log({ e })
                  toast({
                    status: 'error',
                    description: e.response.data
                  })
                })
                .finally(() => {
                  actions.setSubmitting(false)
                })
            }
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <SimpleGrid mt="32px" columns={[1, 2]} spacing={6}>
                  <Field
                    name="location"
                    render={({ field, form }) => (
                      <FormControl
                        isDisabled={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.location && form.touched.location
                        }
                      >
                        <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                          Location
                        </FormLabel>
                        <StyledSelect
                          {...field}
                          placeholder="Select a location"
                          value={form.values.location}
                          name="location"
                          onChange={(e) => {
                            props.setFieldValue(
                              'location',
                              parseInt(e.target.value)
                            )
                          }}
                        >
                          {platformTenant?.locations?.map((location) => (
                            <option key={location.id} value={location.id}>
                              {location.name}
                            </option>
                          ))}
                        </StyledSelect>

                        <FormErrorMessage>
                          {form.errors.category}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="xero_account_code"
                    render={({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.xero_account_code &&
                          form.touched.xero_account_code
                        }
                      >
                        <FormLabel color="#4F4F4F" mb={1}>
                          XERO account code
                        </FormLabel>
                        <StyledSelect
                          placeholder="Select the XERO account code"
                          {...field}
                        >
                          {accountCodes?.map((accountCode) => (
                            <option
                              key={accountCode.code}
                              value={accountCode.id}
                            >
                              {`${accountCode.title} (${accountCode.code})`}
                            </option>
                          ))}
                        </StyledSelect>

                        <FormErrorMessage>
                          {form.errors.xero_account_code}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
                <Field
                  name="name"
                  render={({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.name && form.touched.name}
                      pt={2}
                    >
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <StyledInput {...field} id="name" w="100%" />

                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                />

                <SimpleGrid mt="32px" columns={[1, 2]} spacing={6}>
                  {!workspaceToEdit ? (
                    <Field
                      name="is_main_space"
                      render={({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.is_main_space &&
                            form.touched.is_main_space
                          }
                        >
                          <FormLabel color="#4F4F4F" mb={1}>
                            Is this the main space in the location
                            <Switch
                              ml={2}
                              size="sm"
                              colorScheme="red"
                              id="is_main_space"
                              isChecked={form.values.is_main_space}
                              {...field}
                            />
                          </FormLabel>

                          <FormErrorMessage>
                            {form.errors.is_main_space}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    />
                  ) : (
                    <>
                      {isMainSpace ? (
                        <FormLabel color="#4F4F4F" mb={1}>
                          This is the main space
                        </FormLabel>
                      ) : (
                        <Text> </Text>
                      )}
                    </>
                  )}

                  <Field
                    name="is_bookable"
                    render={({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.is_bookable && form.touched.is_bookable
                        }
                      >
                        <FormLabel color="#4F4F4F" mb={1}>
                          Enable to be booked
                          <Switch
                            ml={2}
                            size="sm"
                            colorScheme="red"
                            id="is_bookable"
                            isChecked={form.values.is_bookable}
                            {...field}
                          />
                        </FormLabel>

                        <FormErrorMessage>
                          {form.errors.is_bookable}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="member_price_half_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isReadOnly={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.member_price_half_day_price &&
                          form.touched.member_price_half_day_price
                        }
                      >
                        <FormLabel htmlFor="member_price_half_day_price">
                          Member price per Half Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="member_price_half_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.member_price_half_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="member_price_full_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isReadOnly={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.member_price_full_day_price &&
                          form.touched.member_price_full_day_price
                        }
                      >
                        <FormLabel htmlFor="member_price_full_day_price">
                          Member price Full Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="member_price_full_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.member_price_full_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="non_member_price_half_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isReadOnly={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.non_member_price_half_day_price &&
                          form.touched.non_member_price_half_day_price
                        }
                      >
                        <FormLabel htmlFor="non_member_price_half_day_price">
                          Non-member Price per Half Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="non_member_price_half_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.non_member_price_half_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                  <Field
                    name="non_member_price_full_day_price"
                    validate={validateRate}
                    render={({ field, form }) => (
                      <FormControl
                        isReadOnly={!!workspaceToEdit}
                        isRequired
                        isInvalid={
                          form.errors.non_member_price_full_day_price &&
                          form.touched.non_member_price_full_day_price
                        }
                      >
                        <FormLabel htmlFor="non_member_price_full_day_price">
                          Non-member price per Full Day:
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement
                            children="$"
                            pr="1.5rem"
                            color="eastBay05"
                          />
                          <StyledInput
                            placeholder="0"
                            {...field}
                            id="non_member_price_full_day_price"
                            w="100%"
                          />
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.non_member_price_full_day_price}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button disabled={props.isSubmitting} type="submit" mr={3}>
                  Save
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onClose()
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  )
}
