import { Tag as BaseTag, TagProps } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React from 'react'

interface tagProps extends TagProps {
  positive?: boolean
  negative?: boolean
  yellow?: boolean
  blue?: boolean
  gray?: boolean
}

export const Tag: React.FC<tagProps> = (props) => {
  const { positive, negative, yellow, blue, gray, ...rest } = props

  const mutualStyles = css`
    border-radius: 24px;
    line-height: 24px;
    height: 24px;
    padding: 0 12px;
    font-size: 12px;
    min-width: min-content;
  ` as any

  const positiveStyles = css`
    background: #e9f6ed;
    color: #24a148;
  ` as any

  const negativeStyles = css`
    background: #fce9ea;
    color: #e1202a;
  ` as any

  const blueStyles = css`
    background: #dfecff;
    color: #247fff;
  ` as any

  const yellowStyles = css`
    background: #fff8e0;
    color: #f1c21b;
  ` as any

  const grayStyles = css`
    background: #e3e3e3;
    color: #606060;
  ` as any

  const composedStylesFromProps = [
    mutualStyles,
    positive ? positiveStyles : null,
    negative ? negativeStyles : null,
    blue ? blueStyles : null,
    yellow ? yellowStyles : null,
    gray ? grayStyles : null
  ]

  return (
    <BaseTag css={composedStylesFromProps} {...rest}>
      {props.children}
    </BaseTag>
  )
}
