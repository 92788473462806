import { Flex, Spinner } from '@chakra-ui/react'
import { addHours } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams
} from 'react-router-dom'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import {
  useBasicMeetingRooms,
  useMeetingRoomBookings
} from '../../utils/apiHooks'
import { KioskCard } from './kioskApp/KioskCard'
import { KioskSelectRoom } from './kioskApp/KioskSelectRoom'
import { IMeetingRoomDisplay } from './kioskApp/types'

const KioskCardDisplay: React.FC<{ meetingRooms: IMeetingRoomDisplay[] }> = ({
  meetingRooms
}) => {
  const params = useParams<{ meetingRoomId: string }>()
  const navigate = useNavigate()
  const [oneHourAgo, setOneHourAgo] = useState<Date>()
  const [endTime, setEndTime] = useState<Date>()

  // every ten hours, it will update the fetching Bookings Interval
  const RESET_PERIOD = 4 * 60 * 60 * 1000

  useEffect(() => {
    resetInterval()
    const interval = setInterval(() => {
      console.log('reset Interval Exec')
      resetInterval()
    }, RESET_PERIOD)
    return () => clearInterval(interval)
  }, [])

  const resetInterval = () => {
    let now_interval = getTodayInterval()
    setOneHourAgo(now_interval.start)
    setEndTime(now_interval.end)
  }

  const getTodayInterval = () => {
    let now = new Date()
    now.setHours(0)
    now.setMinutes(0)
    now.setSeconds(0)
    return {
      start: now,
      end: addHours(now, 24)
    }
  }

  const meetingRoomId = params?.meetingRoomId
    ? parseInt(params.meetingRoomId, 10)
    : undefined

  const { data } = useMeetingRoomBookings(meetingRoomId, oneHourAgo, endTime, {
    refreshInterval: 60000,
    refreshWhenHidden: true
  })
  if (params === null) {
    console.log('params is null')
    return <Navigate to="/kiosk" />
  }

  const meetingRoom = meetingRooms.find(
    (mr) => mr.id === parseInt(`${params.meetingRoomId}`, 10)
  )

  if (meetingRoom === undefined) {
    console.log('meetingRoom is undefined')
    return <Navigate to="/kiosk" />
  }

  if (data === undefined) {
    return (
      <Flex flexGrow={1} bg="rgb(3,154,80)" align="center" justify="center">
        <Spinner />
      </Flex>
    )
  }

  if (!oneHourAgo || !endTime) return <CenteredSpinner />

  return (
    <KioskCard
      interval={{ start: oneHourAgo, end: endTime }}
      bookings={data.map((booking) => ({
        startTime: new Date(Date.parse(booking.start_time)),
        endTime: new Date(Date.parse(booking.end_time))
      }))}
      selectedRoom={meetingRoom}
      onSetupButtonPressed={() => navigate('/kiosk')}
    />
  )
}

export const KioskApp: React.FC = () => {
  const SPACECUBED_ID = 1
  const { data } = useBasicMeetingRooms(SPACECUBED_ID)
  const navigate = useNavigate()

  if (data === undefined) {
    return (
      <Flex flexGrow={1} bg="rgb(3,154,80)" align="center" justify="center">
        <Spinner />
      </Flex>
    )
  }

  const meetingRooms: IMeetingRoomDisplay[] = data
    .map((mr) => ({
      id: mr.id,
      label: mr.name,
      image: mr.display_url
    }))
    .sort((a: IMeetingRoomDisplay, b: IMeetingRoomDisplay) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    )

  return (
    <Flex flexGrow={1} bg="rgb(3,154,80)" align="center" justify="center">
      <Routes>
        <Route
          path="/"
          element={
            <KioskSelectRoom
              meetingRooms={meetingRooms}
              onSelectRoom={(roomId) => navigate(`/kiosk/${roomId}`)}
            />
          }
        ></Route>
        <Route
          path=":meetingRoomId"
          element={<KioskCardDisplay meetingRooms={meetingRooms} />}
        />
      </Routes>
    </Flex>
  )
}
