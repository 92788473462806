import React from 'react'
import styled, { css } from 'styled-components'

const WarningDiv = styled.div`
  background-color: #ffdd57;
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  align-items: center;
  margin: 0.5em 0;
  margin-bottom: 0;

  span {
    margin-bottom: 0;
    padding: 4px 2px;
  }

  ${(props: any) =>
    props.red &&
    css`
      background: #ff3860;
    `}
  ${(props: any) =>
    props.green &&
    css`
      background: #4cd18e;
    `};
`
export const InputWarning: React.FC<any> = ({ children, ...rest }) => {
  return (
    children && (
      <WarningDiv {...rest}>
        <span>{children}</span>
      </WarningDiv>
    )
  )
}
