import { Box, Heading, Image, Text, useTheme } from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { OnboardingUser } from '../../interfaces'
import { routeStrings } from '../../routeStrings'
import { InputWarning } from '../../styled/InputWarning'
import { useUserDetails } from '../../context/user-onboard-context'
import { EMAIL_REGEX } from '../../utils/constants'
import { usePlatformTenant } from '../../context/platform-tenant-context'

export const Onboarding1Email: React.FC = () => {
  const { platformTheme } = usePlatformTenant()
  const { setUserDetails, userDetails } = useUserDetails()
  const navigate = useNavigate()
  const theme: any = useTheme()
  function lowerEmail(input) {
    input.email = input.email.toLowerCase()
    return input
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{ email: userDetails!.email }}
      validate={(values): OnboardingUser => {
        const errors: any = {}

        if (!values.email) {
          errors.email = 'Required'
        } else if (!EMAIL_REGEX.test(values.email)) {
          errors.email = 'Invalid email address'
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setUserDetails((currentValues: any) => ({
          ...currentValues,
          ...lowerEmail(values)
        }))
        navigate(routeStrings.onboarding2Name)
      }}
    >
      {({ isSubmitting }) => (
        <Box p={10}>
          <Image
            src={theme.logos.logo_long}
            width="128px"
            className="location-logo"
          />
          <Box mt={6} bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
            <Heading size="xs" color="gray.400" my={2}>
              Invitation
            </Heading>
            <Box py={3}>
              <Heading size="sm" mb={2}>
                Hi,
              </Heading>
              <Text fontSize={['sm', 'sm', 'md']}>
                you have been invited to join {platformTheme?.business_name} by
                a team admin.
              </Text>
            </Box>

            <Text color="gray.600">Let’s start with your email address</Text>
            <br />
            <Form>
              <Field
                type="email"
                name="email"
                // id="email"
                // autoFocus={true}
                placeholder="johnsmith@mail.com"
                label="Email"
                component={FormInput}
              />
              <ErrorMessage name="email" component={InputWarning} />
              <br />
              <Button
                ml="auto"
                type="submit"
                data-testid="onboarding-next"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
