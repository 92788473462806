import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Flex, Heading, Image, useTheme } from '@chakra-ui/react'

import { BackButton } from '../../components/BackButton'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { useUserDetails } from '../../context/user-onboard-context'
import { OnboardingUser } from '../../interfaces'
import { routeStrings } from '../../routeStrings'
import { InputWarning } from '../../styled/InputWarning'

export const Onboarding2Name: React.FC = () => {
  const { setUserDetails, userDetails } = useUserDetails()
  const navigate = useNavigate()
  const theme: any = useTheme()
  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: userDetails!.firstName,
        lastName: userDetails?.lastName
      }}
      validate={(values): OnboardingUser => {
        const errors: any = {}
        if (!values.firstName) {
          errors.firstName = 'Required'
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        setUserDetails((currentValues: any) => ({
          ...currentValues,
          ...values
        }))
        navigate(routeStrings.onboarding3Details)
      }}
    >
      {({ isSubmitting }) => (
        <Box p={3}>
          <Image
            src={theme.logos.logo_long}
            width="128px"
            className="location-logo"
          />
          <Box mt={6} bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
            <Heading size="xs" color="gray.400" my={6}>
              Step 2 of 6
            </Heading>
            <Box py={3}>
              <Heading size="sm" mb={2}>
                Personal Info
              </Heading>
            </Box>
            <Form>
              <Flex flexDir={['column', 'row']}>
                <Box width={['auto', '50%']} mr={2}>
                  <Field
                    type="text"
                    name="firstName"
                    id="first-name"
                    placeholder="John"
                    label="First Name"
                    component={FormInput}
                    autoFocus={true}
                    data-testid="first-name"
                  />
                </Box>

                <Box width={['auto', '50%']}>
                  <Field
                    type="text"
                    name="lastName"
                    id="last-name"
                    placeholder="Smith"
                    label="Last Name"
                    component={FormInput}
                    data-testid="last-name"
                  />
                </Box>
              </Flex>

              <ErrorMessage name="firstName" component={InputWarning} />
              <br />
              <Button
                ml="auto"
                type="submit"
                data-testid="onboarding-next"
                style={{ float: 'right', display: 'inline-block' }}
                disabled={isSubmitting}
              >
                Next
              </Button>
              <BackButton>Back</BackButton>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
