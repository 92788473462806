import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IBookingDisplay, IMeetingRoomDisplay } from './types'

import { AvailableFor } from './AvailableFor'
import { KioskClock } from './KioskClock'
import { KioskTimeline } from './KioskTimeline'
import { format, isSameDay } from 'date-fns'
import { SettingsIcon } from '@chakra-ui/icons'
import { routeStrings } from '../../../routeStrings'

import { QRCodeSVG } from 'qrcode.react'

interface Props {
  interval?: {
    start: Date
    end: Date
  }
  selectedRoom: IMeetingRoomDisplay
  bookings: IBookingDisplay[]
  onSetupButtonPressed?: () => void
}

export const KioskCard: React.FC<Props> = ({
  selectedRoom,
  bookings,
  interval,
  onSetupButtonPressed
}) => {
  const theme: any = useTheme()
  const [currentTime, setCurrentTime] = useState<Date>(new Date())

  const isBooked =
    bookings.find(
      (booking) =>
        booking.startTime <= currentTime && booking.endTime > currentTime
    ) !== undefined

  const EVERY_MINUTE = 60 * 1000

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), EVERY_MINUTE)
    return () => clearInterval(interval)
  }, [])

  return (
    <Flex
      flexGrow={1}
      bg={isBooked ? 'red.700' : 'rgb(3, 154, 80)'}
      color="white"
      direction="row"
      wrap="nowrap"
      h="100vh"
      align="stretch"
      // className="kiosk"
      alignContent="space-between"
      justifyContent="space-between"
    >
      <Box p={8}>
        <Box w="100%">
          <Box w="300px">
            <img src={theme.logos.logo_long_white} alt="spacecubed-logo" />
          </Box>
          <Heading color="white" mt={5}>
            {selectedRoom ? selectedRoom.label : 'Select a room'}
          </Heading>

          <AvailableFor bookings={bookings} currentTime={currentTime} />
        </Box>

        <Box position="absolute" bottom="10px" fontWeight="bold">
          <QRCodeSVG
            size={200}
            value={
              window.location.host + routeStrings.memberDashboardMeetingRooms
            }
          />
          <Text fontSize="2rem" color="white">
            Scan to book
          </Text>
        </Box>
      </Box>

      <Flex
        w="30%"
        flexShrink={0}
        backgroundColor={isBooked ? 'red.900' : 'rgb(2, 46, 22)'}
        p={5}
        direction="column"
        justifyContent="space-between"
      >
        <Stack textAlign="center" spacing={8} shouldWrapChildren>
          <KioskClock time={currentTime} />
          <KioskTimeline
            bookings={bookings.filter((b) =>
              isSameDay(b.startTime, currentTime)
            )}
            currentTime={currentTime}
          />
        </Stack>

        <Popover>
          <PopoverTrigger>
            <IconButton
              position="absolute"
              bottom="10px"
              flexGrow={0}
              alignSelf="flex-end"
              justifySelf="flex-end"
              variant="outline"
              aria-label="Setup"
              icon={<SettingsIcon />}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Kiosk App</PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                Interval fetched
                <Text>{interval && format(interval.start, 'Pp')}</Text>
                <Text>{interval && format(interval.end, 'Pp')}</Text>
              </PopoverBody>
              <PopoverFooter>
                <Button
                  variant={'outline'}
                  onClick={() => onSetupButtonPressed && onSetupButtonPressed()}
                >
                  Show All meeting Rooms
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    </Flex>
  )
}
