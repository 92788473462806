import { Flex } from '@chakra-ui/react'
import React from 'react'

export const TableRow: React.FC<any> = props => {
  const {
    disabled,
    children,
    row_id,
    dataTestid,
    row,
    ...rest
  } = props

  return (
    <Flex
      bg="white"
      w="100%"
      boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
      rounded="md"
      px="3"
      position="relative"
      color="eastBay09"
      align="center"
      justifyContent="space-between"
      data-testid={dataTestid}
      {...rest}
    >
      {children}
    </Flex>
  )
}
