import { CopyIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  BoxProps,
  IconButton,
  Image,
  Text,
  Spacer,
  useToast
} from '@chakra-ui/react'
import React from 'react'
import { useCustomToast } from '../context/toast-context'
import { routeStrings } from '../routeStrings'
import { Location } from '../types'

interface Props extends BoxProps {
  location: Location
}

export const LocationListElement: React.FC<Props> = ({
  location,
  children,
  ...rest
}) => {
  const placeholder = require('../assets/placeholder.svg')
  const toast = useToast()

  return (
    <Box
      bg="white"
      w="100%"
      position="relative"
      background="#fff"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
      borderRadius="lg"
      padding="5"
      mx={2}
      mb={3}
      cursor="pointer"
    >
      <Flex>
        <Box pl={8}>
          <Image
            src={location.thumbnail_url}
            fallbackSrc={placeholder.default}
            boxSize="70px"
            objectFit="cover"
            borderRadius="full"
            mr="3"
          />
        </Box>
        <Box pt={'3'} ml={3}>
          <Text fontWeight={600} fontSize={'xl'} mr="10px">
            {location.name}
          </Text>
          <Text fontSize="sm"></Text>
        </Box>
        <Spacer />
        {/* <IconButton
          variant="outline"
          aria-label="Copy link"
          size="lg"
          icon={<CopyIcon />}
        >
          Copy
        </IconButton> */}
      </Flex>
      <Box>{children}</Box>
    </Box>
  )
}
