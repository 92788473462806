import { Stack, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { Row } from 'react-table'
import { ActionsDropdown } from './ActionsDropdown'
import { Cell } from './Cell'
import { StackCell } from './StackCell'
import { TableRow } from './TableRow'

interface TableBodyProps {
  rows: any
  isLoading: boolean
  prepareRow: (row: Row) => void
}

export const TableBody: React.FC<TableBodyProps> = (props) => {
  const rows = props.rows

  // If no results are found, render ellipsis by default
  if (rows.length === 0) {
    return (
      <Box p="20px" textAlign="center">
        <Text fontSize={'16px'} color="grey">
          No Records Found{' '}
        </Text>
      </Box>
    )
  }

  return (
    <Stack spacing="10px" mt={4}>
      {rows.map((row, rowIndex) => {
        // Has a pre built row been passed through?
        if (typeof row.original.type === 'function') {
          return row.original
        } else {
          props.prepareRow(row)

          const rowId = row.values.id ? row.values.id.value : undefined

          const rowTestId = row.values.test_prefix
            ? row.values.test_prefix.value
            : undefined

          const rowData = row.values.rowData ? row.values.rowData : undefined

          return (
            <TableRow key={rowIndex} dataTestid={'tablerow-' + rowTestId}>
              {row.cells.map((cell, cellIndex) => {
                const value = cell.value ? cell.value : ' '

                if (cell.column.id !== 'actions') {
                  if (Array.isArray(value)) {
                    return (
                      <StackCell
                        key={cellIndex}
                        values={value}
                        width={cell.column.width}
                        minWidth={cell.column.minWidth}
                      />
                    )
                  } else {
                    return (
                      <Cell
                        width={cell.column.width}
                        minWidth={cell.column.minWidth}
                        key={cellIndex}
                        value={value}
                      />
                    )
                  }
                } else {
                  return (
                    <ActionsDropdown
                      width={cell.column.width}
                      minWidth={cell.column.minWidth}
                      actions={value.value}
                      rowId={rowId}
                      rowData={rowData}
                      key={cellIndex}
                      dataTestid={'expand-' + rowTestId}
                    />
                  )
                }
              })}
            </TableRow>
          )
        }
      })}
    </Stack>
  )
}
