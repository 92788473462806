import React, { useContext, useState, useEffect } from 'react'
import { OnboardingUser } from '../interfaces'
import { Interest, Skill } from '../types'
import { useEditableTheme } from './editable-theme-context'
import { usePlatformTenant } from './platform-tenant-context'
import { useAuth } from './auth-context'

interface IUserDetailsContext {
  userDetails: ITeamUserDetails
  setUserDetails: React.Dispatch<React.SetStateAction<OnboardingUser>>
}

interface ITeamUserDetails {
  id?: number
  email: string
  emailVerified?: boolean
  personalEmail?: string
  plan?: string
  plan_name?: string
  plan_rate?: string
  plan_inclusions_url?: string
  location_id?: number
  firstName: string
  lastName: string
  mobileNumber: string
  postcode?: number
  startDate?: Date
  jobTitle?: string
  company?: string
  skills: Skill[]
  interests: Interest[]
  aboutYou?: string
  billingName?: string
  profilePictureUrl?: string
  locationName?: string
  is_migrated_from_nexudus?: boolean
  inviteToken: string
  is_team_admin: boolean
  is_new_tenant_invite?: boolean
}

export interface SinglePageSignupComponent {
  isActive: boolean
  isComplete: boolean
  onCompleteCallback: () => void
  setComplete?: (complete: boolean) => void
  setIsActive: () => void
  goBack?: () => void
}
export const UserDetailsContext = React.createContext<
  IUserDetailsContext | undefined
>(undefined)

export const UserDetailsProvider = (props: any) => {
  const [userDetails, setUserDetails] = useState<ITeamUserDetails>({
    email: '',
    emailVerified: false,
    personalEmail: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    company: '',
    skills: [],
    interests: [],
    aboutYou: '',
    profilePictureUrl: '',
    mobileNumber: '',
    startDate: new Date(),
    inviteToken: '',
    is_team_admin: true
  })
  const auth = useAuth()

  return (
    <UserDetailsContext.Provider
      value={{ userDetails, setUserDetails }}
      {...props}
    />
  )
}

export const useUserDetails = () => {
  const context = React.useContext(UserDetailsContext)
  if (context === undefined) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider')
  }

  return context
}
