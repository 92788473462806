import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  BoxProps
} from '@chakra-ui/react'
import React from 'react'

interface Props extends BoxProps {
  onConfirm: () => void
  isConfirming: boolean
  isOpen: boolean
  onClose: () => void
  title?: string
  description?: string

  confirmButtonText?: string
}

export const GenericAlertDialog: React.FC<Props> = (props) => {
  const {
    isOpen,
    onConfirm,
    onClose,
    isConfirming,
    title,
    description,
    confirmButtonText,
    children
  } = props
  const cancelRef = React.useRef<any>()

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title ?? 'Confirmation'}
            </AlertDialogHeader>

            <AlertDialogBody>
              {description ?? 'Are you sure to perform this action?'}
              {children}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onConfirm}
                ml={3}
                isLoading={isConfirming}
              >
                {confirmButtonText ?? 'Delete'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
