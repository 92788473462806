import {
  Box,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { updateProfilePicture } from '../api'
import { useAuth } from '../context/auth-context'
import { routeStrings } from '../routeStrings'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { IOrganization } from '../types'
import { useOrganizationalUnit } from '../utils/apiHooks'
import { useRole } from '../utils/rbac'
import { MailIcon, TelIcon } from './icons/icons'
import { ProfilePictureUpdateModal } from './modals/ProfilePictureUpdateModal'
import { StatusBox } from './StatusBox'
import { UserDisplay } from './UserDisplay'
import { ReactNodeWithProps } from '../component.types'

interface MemberProfileBarProps {
  ouId: string
}

export const MemberProfileBar: React.FC<MemberProfileBarProps> = (props) => {
  const { currentOrgUnit, adminChangeOrganization } = useAuth()
  const { isInAdminRole } = useRole()
  const navigate = useNavigate()
  const { data, mutate } = useOrganizationalUnit(props && props.ouId)
  const [
    updateProfilePictureModalIsVisible,
    setUpdateProfilePictureModalIsVisible
  ] = useState<boolean>(false)
  function RenderStatus(status: string) {
    return (
      <StatusBox
        status={status}
        mapping={{
          awaiting_approval: { color: 'yellow', label: 'Awaiting Approval' },
          awaiting_onboarding: {
            color: 'yellow',
            label: 'Awaiting Onboarding'
          },
          approved_and_onboarded: { color: 'positive', label: 'Approved' },
          user_inactive: { color: 'blue', label: 'Inactive' }
        }}
      />
    )
  }
  function RenderOrgLink(org: IOrganization) {
    if (org) {
      return (
        <Link
          color="#255CE1"
          mb={0}
          onClick={() => {
            if (isInAdminRole) {
              adminChangeOrganization(org)
              navigate(routeStrings.adminTeamDetailView(org.id))
            }
          }}
        >
          {org.name}
        </Link>
      )
    }
  }

  if (data) {
    let skillsText = data.user.user_profile.skills
      .map((skill) => skill.name)
      .join(', ')
    let interestsText = data.user.user_profile.interests
      .map((interest) => interest.name)
      .join(', ')

    return (
      <Flex
        flexDir="column"
        align="center"
        p="19px"
        borderRight="1px solid #D8DDE4"
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        flexGrow={1}
        overflow="scroll"
      >
        <Flex
          align="center"
          direction="column"
          cursor="pointer"
          justify="center"
          onClick={() => setUpdateProfilePictureModalIsVisible(true)}
          w="100%"
        >
          <UserDisplay
            size="2xl"
            name={data.user.name}
            src={data.user.user_profile.profile_picture?.image}
          />
          <Text mt="8px">Update profile picture</Text>
        </Flex>
        <Title>{data.user.name}</Title>
        <SubTitle>{data.job_title ?? 'No Job title registered'}, </SubTitle>
        <SubTitle>{RenderOrgLink(data.organization)}</SubTitle>

        <Flex direction="row" w="80%" justifyContent="space-evenly">
          <Tooltip
            hasArrow
            label={data.user.user_profile.mobile_number}
            aria-label="User Phone Number"
            placement="bottom"
          >
            <Link
              isExternal
              href={'tel:' + data.user.user_profile.mobile_number}
            >
              {/* TEST NODE */}
              <TelIcon boxSize={6} />
            </Link>
          </Tooltip>
          <Tooltip
            hasArrow
            label={data.user.email}
            aria-label="User Email"
            placement="bottom"
          >
            <Link isExternal href={'mailto:' + data.user.email}>
              {/* TEST NODE */}

              <MailIcon boxSize={6} />
            </Link>
          </Tooltip>
        </Flex>
        <Stack spacing="23px" textAlign="left">
          <BodyContent></BodyContent>
          <BodyContent>
            <MemberHeading>Membership</MemberHeading>
            <span>{data.ongoing_plan?.name || <span>Inactive</span>}</span>
          </BodyContent>
          <BodyContent>
            <MemberHeading>Status</MemberHeading>
            {RenderStatus(
              data.user.tenant_profiles.find(
                (tp) =>
                  tp.platform_tenant ===
                  currentOrgUnit?.organization.parent_platform_tenant
              )?.signup_status ?? ''
            )}
          </BodyContent>
          <BodyContent>
            <MemberHeading>Interests</MemberHeading>
            {interestsText !== '' ? interestsText : 'No interests registered'}
          </BodyContent>
          <BodyContent>
            <MemberHeading>Skills</MemberHeading>
            {skillsText !== '' ? skillsText : 'No Skills registered'}
          </BodyContent>
          <BodyContent>
            <MemberHeading>Email</MemberHeading>
            {data.user.email}
          </BodyContent>
          <BodyContent>
            <MemberHeading>Phone Number</MemberHeading>
            {data.user.user_profile.mobile_number}
          </BodyContent>
          <BodyContent></BodyContent>
        </Stack>
        {updateProfilePictureModalIsVisible && (
          <ProfilePictureUpdateModal
            revalidate={mutate}
            handleSave={(image) => {
              return updateProfilePicture({ image }, data.user.user_profile.id)
                .then((res) => {
                  return 'Picture updated'
                })
                .catch((error) => {
                  let description = 'Something went wrong, failed to update'

                  if (error.response) {
                    description = error.response.data
                  }

                  return 'Image update failed'
                })
            }}
            visibility={{
              modalIsVisible: updateProfilePictureModalIsVisible,
              setModalIsVisible: setUpdateProfilePictureModalIsVisible
            }}
          />
        )}
      </Flex>
    )
  } else return <CenteredSpinner />
}

type MemberHeadingProps = {} & ReactNodeWithProps

const MemberHeading: React.FC<MemberHeadingProps> = ({ children }) => (
  <Heading as="h5">{children}</Heading>
)

const Title = styled(Text)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */
  text-align: center;
  margin-top: 24px;

  /* heading / primary */
  color: #212121;
`

const SubTitle = styled(Text)`
  /* body / 02 / normal */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  text-align: center;

  /* identical to box height, or 150% */

  /* east bay / 07 */

  color: #757575;
`

//   const BodyHeader = styled(Text)`
//   `
const BodyContent = styled(Box)`
  h4 {
    /* body / 02 / normal */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    color: #000000;
    span {
      color: grey;
    }
  }
  h5 {
    /* data / label */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    /* east bay / 07 */
    color: #757575;
  }
`
