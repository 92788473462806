import React, { useContext, useEffect, useState } from 'react'
import { useIndividualFeatureFlagsAPI } from '../utils/apiHooks'
import { useAuth } from './auth-context'

export interface IndividualFeatureFlagProps {
  acuityEnabled?: boolean
  demoEnabled?: boolean
  phase4milestone14Enabled?: boolean
  loading: boolean
}

const defaultProps: IndividualFeatureFlagProps = {
  acuityEnabled: undefined,
  demoEnabled: undefined,
  phase4milestone14Enabled: undefined,
  loading: true
}

export const IndividualFeatureFlagContext = React.createContext<IndividualFeatureFlagProps>(
  defaultProps
)

export const IndividualFeatureFlagProvider = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [demoEnabled, setDemoEnabled] = useState<boolean>()
  const [
    phase4milestone14Enabled,
    setPhase4milestone14Enabled
  ] = useState<boolean>()

  const { data: flags } = useIndividualFeatureFlagsAPI()
  const { me } = useAuth()
  useEffect(() => {
    // ensure flags is an array
    if (flags && flags.length > 0 && typeof flags === 'object') {
      const demoFlag = flags.find((flag) => flag.name === 'demo_flag')
      if (demoFlag) setDemoEnabled(true)

      const phase4milestone14flag = flags.find(
        (flag) => flag.name === 'phase_4_milestone_14'
      )
      if (phase4milestone14flag) setPhase4milestone14Enabled(true)

      setLoading(false)
    }
  }, [flags, me?.user.is_beta_tester])

  return (
    <IndividualFeatureFlagContext.Provider
      value={{
        demoEnabled,
        phase4milestone14Enabled,
        loading
      }}
    >
      {children}
    </IndividualFeatureFlagContext.Provider>
  )
}

export const useFeatureFlags = () => {
  const context = useContext(IndividualFeatureFlagContext)
  if (context === undefined) {
    throw new Error(
      'useIndividualFeatureFlags must be used within a IndividualFeatureFlagProvider'
    )
  }
  return context
}
