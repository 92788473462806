import React from 'react'
import {
  Box,
  Heading,
  Stack,
  Text,
  Button,
  Flex,
  FlexProps
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { IArticle } from './types'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { analyticsTrack } from '../../../../utils/analytics'

interface Props extends FlexProps {
  article?: IArticle
}

export const BigNewsArticle: React.FC<Props> = ({ article, ...rest }) => {
  return (
    <Flex
      background={
        article
          ? `linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%), url("${article.image}")`
          : 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%), #dadada'
      }
      backgroundSize="cover"
      h="100%"
      w="100%"
      py={5}
      px={4}
      justifyContent="space-between"
      flexDir="column"
      boxShadow="sm"
      {...rest}
    >
      <SkeletonTheme baseColor="#888" highlightColor="#aaa">
        <Stack color="white" spacing={1}>
          <Heading size="md" color="white">
            {article ? article.title : <Skeleton />}
          </Heading>
          {article ? (
            <Stack isInline spacing={2}>
              <Text fontSize="sm">
                {format(article.date, 'd MMM').toUpperCase()}
              </Text>
              <Text as="span" fontSize="sm">
                •
              </Text>
              <Text as="span" fontSize="sm">
                {article.category}
              </Text>
            </Stack>
          ) : (
            <Skeleton width={80} />
          )}
        </Stack>
      </SkeletonTheme>
      {article?.link && (
        <a
          href={article.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            analyticsTrack('View Blog Post', { featureArcticle: true })
          }
        >
          <Button borderRadius={0} size="sm">
            Read More
          </Button>
        </a>
      )}
    </Flex>
  )
}
