import {
  Box,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useTheme
} from '@chakra-ui/react'
import React from 'react'
import { IMeetingRoomDisplay } from './types'
import { ChevronDownIcon } from '@chakra-ui/icons'
interface Props {
  meetingRooms: IMeetingRoomDisplay[]
  onSelectRoom?: (roomId: number) => void
}

export const KioskSelectRoom: React.FC<Props> = ({
  meetingRooms,
  onSelectRoom
}) => {
  const theme: any = useTheme()
  return (
    <Flex
      flexGrow={1}
      bg="rgb(3, 154, 80)"
      h="100vh"
      color="white"
      direction="row"
      wrap="nowrap"
      align="stretch"
      alignContent="space-between"
      justifyContent="space-between"
    >
      <Flex p={8} maxW="50%" flexDir="column" justifyContent="space-between">
        <Box>
          <Box w="300px">
            <img src={theme.logos.logo_long_white} alt="spacecubed-logo" />
          </Box>
          <Stack mt={5} isInline spacing={3} alignItems="center">
            <Heading color="white">Select a room</Heading>
            <Box color="black">
              <Menu placement="auto-end">
                <MenuButton
                  color="white"
                  size="sm"
                  // @ts-ignore
                  as={IconButton}
                  // @ts-ignore
                  variant="outline"
                  // @ts-ignore
                  icon={<ChevronDownIcon />}
                />
                <MenuList maxH="100vh" overflowY="scroll" top={0}>
                  {meetingRooms.map((meetingRoom) => {
                    return (
                      <MenuItem
                        key={meetingRoom.id}
                        onClick={() =>
                          onSelectRoom && onSelectRoom(meetingRoom.id)
                        }
                      >
                        {meetingRoom.label}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            </Box>
          </Stack>
        </Box>
      </Flex>

      <Stack
        float="right"
        w="30%"
        pt="3%"
        ml="0"
        mt="0"
        mb="0"
        backgroundColor="rgb(2, 46, 22)"
        textAlign="center"
        p={5}
        spacing={8}
        shouldWrapChildren
      ></Stack>
    </Flex>
  )
}
