import {
  Flex,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Button } from '../../components/Button'
import { IDailyFiveContent, Interest, ApprovalStatusChoices } from '../../types'
import { ContentDetailBar } from './ContentDetailBar'

import { format } from 'date-fns'

import { UserDisplay } from '../../components/UserDisplay'
import { FilterableInterestTags } from './FilterableInterestTags'

interface Props {
  interests: Interest[]
  content?: IDailyFiveContent
  removeFromCirculation?: boolean
  handleApprove: (content: IDailyFiveContent) => void
  handleDecline: (content: IDailyFiveContent) => void
  handleActivation: (content: IDailyFiveContent) => void
}

interface TagRendererProps {
  tag: {
    value: number | string
    label: string
  }
  onClick: () => void
}

export const CustomTag: React.FC<TagRendererProps> = (
  props: TagRendererProps
) => {
  return (
    <Tag
      size="sm"
      key={props.tag.value?.toString()}
      rounded="full"
      variant="solid"
      backgroundColor="brandPrimary"
      mr="4px"
      marginBottom="4px"
    >
      <TagLabel>{props.tag.label}</TagLabel>
      <TagCloseButton onClick={props.onClick} />
    </Tag>
  )
}
export const AdminDashboardContentCard: React.FC<Props> = ({
  interests,
  content,
  removeFromCirculation,
  handleApprove,
  handleDecline,
  handleActivation
}) => {
  const [chosenInterests, setChosenInterest] = useState(
    content?.interests ?? []
  )

  /** This submit fn updates the content's interests before being submitted */
  const validateInterestsAndApprove = () => {
    if (content && chosenInterests.length > 0) {
      content.interests = chosenInterests
      handleApprove(content)
    }
  }
  return (
    <Flex
      width="100%"
      justify="space-between"
      bg="#FFFFFF"
      borderRadius="4px"
      padding="22px"
    >
      <Stack w="100%">
        <Stack>
          <Text fontSize="s" color="#303B40" fontWeight="bold" mb="8px">
            {content?.title}
          </Text>
          <Stack isInline spacing={2} align="center">
            <Text as="span" fontSize="xs" color="#255CE1" fontWeight="bold">
              {content?.content_type}
            </Text>
            <Text as="span" fontSize="sm">
              •
            </Text>
            <Text fontSize="sm">
              {/* {content?.created_at} */}
              {content?.created_at &&
                format(
                  new Date(Date.parse(content?.created_at)),
                  'h:mm aa, d MMM '
                )}
            </Text>
          </Stack>

          <Flex align="center">
            <UserDisplay
              name={content?.author}
              src={content?.user_profile_image}
              size="xs"
              mr="8px"
            />

            <Text fontSize="xs" color="#4F4F4F" fontWeight="bold">
              {content?.author}
            </Text>
          </Flex>
          <ContentDetailBar contentObject={content} />
        </Stack>
        {/*  */}
        {content?.approval_status === ApprovalStatusChoices.APPROVED ? (
          <FilterableInterestTags
            value={chosenInterests.map((interest) => interest.id)}
            placeholder={'Select some interests'}
            initialValues={undefined}
            onSelectionChanges={(value) => {}}
          />
        ) : (
          <FilterableInterestTags
            value={chosenInterests.map((interest) => interest.id)}
            placeholder={'Select some interests'}
            initialValues={undefined}
            onSelectionChanges={(value) => {
              setChosenInterest(
                interests.filter((interest) => value.includes(interest.id))
              )
            }}
          />
        )}

        {/*  */}
        <Flex w="100%">
          <Text>{content?.summary}</Text>
        </Flex>
      </Stack>
      {content?.approval_status === ApprovalStatusChoices.NONE && (
        <Stack align="center">
          <Button
            disabled={chosenInterests.length === 0}
            onClick={() => {
              validateInterestsAndApprove()
            }}
          >
            Approve
          </Button>
          <Text
            as="button"
            color="#255CE1"
            onClick={() => {
              content && handleDecline(content)
            }}
          >
            Decline
          </Text>
        </Stack>
      )}
      {removeFromCirculation &&
        content?.active_to_show_in_future_daily_five && (
          <Stack align="center">
            <Button
              onClick={() => {
                content && handleActivation(content)
              }}
            >
              Remove from circulation
            </Button>
          </Stack>
        )}
      {removeFromCirculation &&
        !content?.active_to_show_in_future_daily_five && (
          <Stack align="center">
            <Button
              onClick={() => {
                content && handleActivation(content)
              }}
            >
              Add in to circulation
            </Button>
          </Stack>
        )}
    </Flex>
  )
}
