import { CopyIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  BoxProps,
  IconButton,
  Image,
  Text,
  Spacer
} from '@chakra-ui/react'
import React from 'react'
import { useCustomToast } from '../context/toast-context'
import { routeStrings } from '../routeStrings'
import { Location } from '../types'

interface Props extends BoxProps {
  location: Location
  overrideURL?: string
  description?: string
}

export const LocationURL: React.FC<Props> = ({
  location,
  overrideURL,
  description,
  ...rest
}) => {
  const placeholder = require('../assets/placeholder.svg')
  const toast = useCustomToast()
  const linkText = overrideURL
    ? overrideURL
    : window.location.host + routeStrings.locationPlans(location.id)
  const copyLink = () => {
    try {
      navigator.clipboard.writeText(linkText)
      toast.newToast({
        description: 'Link copied!',
        status: 'success',
        duration: 2000
      })
    } catch (error) {}
  }

  const selectContents = (elementId) => {
    let el = document.getElementById(elementId)
    if (el) {
      var range = document.createRange()
      range.selectNodeContents(el)
      var sel = window.getSelection()
      if (sel) {
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  }
  return (
    <Flex
      bg="white"
      {...rest}
      w="100%"
      position="relative"
      background="#fff"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      padding="15px"
      margin="5px"
      cursor="pointer"
    >
      <Box>
        <Image
          src={location.thumbnail_url}
          fallbackSrc={placeholder.default}
          boxSize="70px"
          objectFit="cover"
          borderRadius="full"
          mr="10px"
        />
      </Box>
      <Box>
        <Text fontWeight={600} fontSize={'18px'} mr="10px">
          {location.name}
        </Text>
        <Text fontSize="sm">
          {description ?? 'Copy and Share this link with potential clients'}
        </Text>
        <Text
          color={'blue'}
          textDecoration={'underline'}
          id={'id-url-' + location.name}
          as="kbd"
          onClick={() => selectContents('id-url-' + location.name)}
        >
          {linkText}
        </Text>
      </Box>
      <Spacer />
      <IconButton
        variant="outline"
        aria-label="Copy link"
        size="lg"
        onClick={copyLink}
        icon={<CopyIcon />}
      >
        Copy
      </IconButton>
    </Flex>
  )
}
