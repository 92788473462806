import React from 'react'
import { IDailyFiveContent, INewContent } from '../../types'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'
import { MemberDashboardCreatePostForm } from './MemberDashboardCreatePostForm'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'

interface Props {
  content?: IDailyFiveContent
  handleSave?: (content: INewContent) => Promise<{
    success: boolean
    message: string
    content: IDailyFiveContent
  }>
}

export const MemberDashboardCreatePost: React.FC<Props> = ({
  content,
  handleSave
}) => {
  const isEdition = content !== undefined
  return (
    <LayoutMemberDashboard>
      <GroupingHeaderLayout
        compact={true}
        basicTitle={isEdition ? 'Edit Post' : 'New Post'}
      />
      <MemberDashboardCreatePostForm
        content={content}
        handleSave={handleSave}
      />
    </LayoutMemberDashboard>
  )
}
