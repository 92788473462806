import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Container,
  Flex,
  FormLabel,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Button } from '../../components/Button'
import { PrimarySpinner } from '../../components/chakra-v2/PrimarySpinner'
import { StyledInput } from '../../components/StyledInputs'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { InputWarning } from '../../styled/InputWarning'
import { IPlatformTenant, IPlatformTenantUpdate } from '../../types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useCustomToast } from '../../context/toast-context'
import { EMAIL_REGEX } from '../../utils/constants'

export const AdminDashboardPlatformTenantEmailSettings: React.FC = () => {
  const { platformTenant, adminUpdatePlatformTenant, testWelcomeEmail } =
    usePlatformTenant()
  const toast = useCustomToast()
  const [canSendEmail, setCanSendEmail] = useState<boolean>(true)

  const HTMLEditorProps = {
    modules: {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image'],
        ['clean']
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
      }
    },

    formats: [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image'
    ]
  }

  if (!platformTenant) {
    return <CenteredSpinner />
  }
  return (
    <Container maxW="full" centerContent>
      <VStack
        bg="white"
        w="100%"
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        rounded="md"
        px={8}
        py={5}
        position="relative"
        color="eastBay09"
        align="center"
        justifyContent="space-between"
      >
        {platformTenant ? (
          <Formik
            initialValues={{
              email_new_notification_banner: undefined,
              email_member_accepted_banner: undefined,
              email_meeting_room_booking_banner: undefined,
              email_highlight_theme_color:
                platformTenant.email_highlight_theme_color,
              email_company_name: platformTenant.email_company_name,
              email_welcome_message: platformTenant.email_welcome_message,
              social_account_facebook: platformTenant.social_account_facebook,
              social_account_instagram: platformTenant.social_account_instagram,
              social_account_linkedin: platformTenant.social_account_linkedin,
              social_account_twitter: platformTenant.social_account_twitter,

              email_signature: platformTenant.email_signature,
              email_from_address: platformTenant.email_from_address
            }}
            validate={(values): IPlatformTenant => {
              const errors: any = {}
              if (
                values.email_highlight_theme_color &&
                values.email_highlight_theme_color.length > 7
              )
                errors.email_highlight_theme_color = 'Hex colour code invalid'

              if (
                values.email_from_address &&
                !EMAIL_REGEX.test(values.email_from_address)
              )
                errors.email_from_address = 'Invalid email address'

              if (!values.email_company_name)
                errors.email_company_name = 'Company name cannot be empty'
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)

              const data: IPlatformTenantUpdate = {
                email_signature: values.email_signature,
                email_highlight_theme_color: values.email_highlight_theme_color,
                emailMemberAcceptedBannerImageFile:
                  values.email_member_accepted_banner,
                emailNewNotificationBannerImageFile:
                  values.email_new_notification_banner,
                emailMeetingRoomBookingBannerImageFile:
                  values.email_meeting_room_booking_banner,
                email_company_name: values.email_company_name,
                email_welcome_message: values.email_welcome_message,
                social_account_facebook: values.social_account_facebook,
                social_account_instagram: values.social_account_instagram,
                social_account_linkedin: values.social_account_linkedin,
                social_account_twitter: values.social_account_twitter,
                email_from_address: values.email_from_address
              }
              try {
                const response = await adminUpdatePlatformTenant(data)
                if (response.status === 200) {
                  setSubmitting(false)
                  toast.newToast({
                    status: 'success',
                    description: 'Email Settings updated'
                  })
                } else {
                  setSubmitting(false)
                  toast.newToast({
                    status: 'error',
                    description: 'An error occurred'
                  })
                }
              } catch (e) {
                setSubmitting(false)
                toast.newToast({
                  status: 'error',
                  description: 'An error occurred'
                })
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, touched, errors }) => (
              <Form>
                <Stack spacing={8}>
                  <Stack>
                    <Text fontWeight="bold" fontSize="26px">
                      Email branding settings
                    </Text>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Email Highlight Colour
                    </FormLabel>
                    <Flex>
                      <Flex
                        backgroundColor={
                          values.email_highlight_theme_color || '#FAFAFA'
                        }
                        width="60px"
                        h="40px"
                        justify="center"
                        align="center"
                        color="white"
                      ></Flex>
                      <StyledInput
                        placeholder="#FFFFFF"
                        ml="16px"
                        value={values.email_highlight_theme_color || ''}
                        onChange={(e) => {
                          setFieldValue(
                            'email_highlight_theme_color',
                            e.target.value
                          )
                        }}
                        w="100px"
                      ></StyledInput>
                    </Flex>

                    <Flex>
                      <ErrorMessage
                        name="email_highlight_theme_color"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Email Signature
                    </FormLabel>
                    <StyledInput
                      w="400px"
                      placeholder="-The Spacecubed team"
                      value={values.email_signature || ''}
                      onChange={(e) => {
                        setFieldValue('email_signature', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="email_signature"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Company Name
                    </FormLabel>
                    <StyledInput
                      w="200px"
                      placeholder="Spacecubed"
                      value={values.email_company_name || ''}
                      onChange={(e) => {
                        setFieldValue('email_company_name', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="email_company_name"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Welcome Message to new members
                    </FormLabel>
                    <ReactQuill
                      theme="snow"
                      onChange={(e) => {
                        setFieldValue('email_welcome_message', e)
                      }}
                      value={values.email_welcome_message}
                      modules={HTMLEditorProps.modules}
                      formats={HTMLEditorProps.formats}
                      placeholder={'Welcome your new members!'}
                    />
                    <Text fontSize="xs">
                      Save your changes first to see the message updated
                    </Text>

                    <Button
                      width="300px"
                      disabled={!canSendEmail}
                      onClick={() => {
                        if (values.email_welcome_message) {
                          setCanSendEmail(false)
                          setTimeout(() => setCanSendEmail(true), 10000)
                          testWelcomeEmail()
                        }
                      }}
                    >
                      {canSendEmail ? 'Send me a Test Email' : 'Email Sent'}
                    </Button>

                    <hr></hr>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Facebook
                    </FormLabel>
                    <StyledInput
                      w="100%"
                      value={values.social_account_facebook || ''}
                      onChange={(e) => {
                        setFieldValue('social_account_facebook', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="social_account_facebook"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Instagram
                    </FormLabel>
                    <StyledInput
                      w="100%"
                      value={values.social_account_instagram || ''}
                      onChange={(e) => {
                        setFieldValue(
                          'social_account_instagram',
                          e.target.value
                        )
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="social_account_instagram"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Linkedin
                    </FormLabel>
                    <StyledInput
                      w="100%"
                      value={values.social_account_linkedin || ''}
                      onChange={(e) => {
                        setFieldValue('social_account_linkedin', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="social_account_linkedin"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Twitter
                    </FormLabel>
                    <StyledInput
                      w="100%"
                      value={values.social_account_twitter || ''}
                      onChange={(e) => {
                        setFieldValue('social_account_twitter', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="social_account_twitter"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      From email address
                    </FormLabel>
                    <StyledInput
                      w="100%"
                      placeholder={`noreply@${
                        platformTenant.business_name?.toLowerCase() ??
                        'spacecubed'
                      }.com`}
                      value={values.email_from_address || ''}
                      onChange={(e) => {
                        setFieldValue('email_from_address', e.target.value)
                      }}
                    ></StyledInput>
                    <Flex>
                      <ErrorMessage
                        name="email_from_address"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Email "New Notification" banner
                    </FormLabel>
                    <Flex
                      position="relative"
                      background=" #EEEEEE"
                      backgroundImage={
                        values.email_new_notification_banner
                          ? `url("${URL.createObjectURL(
                              values.email_new_notification_banner
                            )}")`
                          : `url("${platformTenant.email_new_notification_banner}")`
                      }
                      backgroundSize="cover"
                      h="200px"
                      w="600px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {!values.email_new_notification_banner &&
                        !platformTenant.email_new_notification_banner && (
                          <AddIcon color="#BDBDBD" />
                        )}

                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          height: '200px',
                          width: '600px',
                          borderRadius: '0px',
                          background: '#757575',
                          cursor: 'pointer'
                        }}
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setFieldValue(
                            'email_new_notification_banner',
                            e.target.files![0]
                          )
                        }}
                      />
                    </Flex>
                    <Flex>
                      <ErrorMessage
                        name="email_new_notification_banner"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Email "Member Accepted Welcome Banner"
                    </FormLabel>
                    <Flex
                      position="relative"
                      background=" #EEEEEE"
                      backgroundImage={
                        values.email_member_accepted_banner
                          ? `url("${URL.createObjectURL(
                              values.email_member_accepted_banner
                            )}")`
                          : `url("${platformTenant.email_member_accepted_banner}")`
                      }
                      backgroundSize="cover"
                      h="200px"
                      w="600px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {!values.email_member_accepted_banner &&
                        !platformTenant.email_member_accepted_banner && (
                          <AddIcon color="#BDBDBD" />
                        )}

                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          height: '200px',
                          width: '600px',
                          borderRadius: '0px',
                          background: '#757575',
                          cursor: 'pointer'
                        }}
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setFieldValue(
                            'email_member_accepted_banner',
                            e.target.files![0]
                          )
                        }}
                      />
                    </Flex>
                    <Flex>
                      <ErrorMessage
                        name="email_member_accepted_banner"
                        component={InputWarning}
                      />
                    </Flex>
                    <FormLabel
                      fontWeight="bold"
                      color="#4F4F4F"
                      fontSize="sm"
                      mb={1}
                    >
                      Email "Meeting Room Booking Banner"
                    </FormLabel>
                    <Flex
                      position="relative"
                      background=" #EEEEEE"
                      backgroundImage={
                        values.email_meeting_room_booking_banner
                          ? `url("${URL.createObjectURL(
                              values.email_meeting_room_booking_banner
                            )}")`
                          : `url("${platformTenant.email_meeting_room_booking_banner}")`
                      }
                      backgroundSize="cover"
                      h="200px"
                      w="600px"
                      justify="center"
                      align="center"
                      cursor="pointer"
                    >
                      {!values.email_meeting_room_booking_banner &&
                        !platformTenant.email_meeting_room_booking_banner && (
                          <AddIcon color="#BDBDBD" />
                        )}

                      <input
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          height: '200px',
                          width: '600px',
                          borderRadius: '0px',
                          background: '#757575',
                          cursor: 'pointer'
                        }}
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          setFieldValue(
                            'email_meeting_room_booking_banner',
                            e.target.files![0]
                          )
                        }}
                      />
                    </Flex>
                    <Flex>
                      <ErrorMessage
                        name="email_meeting_room_booking_banner"
                        component={InputWarning}
                      />
                    </Flex>
                  </Stack>
                  <Box>
                    <Button type="submit" disabled={isSubmitting}>
                      Update settings
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        ) : (
          <PrimarySpinner />
        )}
      </VStack>
    </Container>
  )
}
