import { Box, Flex, Heading, useTheme } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Image } from '@chakra-ui/react'
import { Button } from '../../components/Button'
import { useUserDetails } from '../../context/user-onboard-context'
import { routeStrings } from '../../routeStrings'
import { OnBoardingIframe } from '../CompleteProfile/CompleteBookOnboarding'
import { useAuth } from '../../context/auth-context'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'

export const Onboarding7Completed: React.FC = ({}: // setUserDetails
any) => {
  const { acuityEnabled } = useFeatureFlags()
  const { userDetails } = useUserDetails()
  const { platformTheme } = usePlatformTenant()

  const navigate = useNavigate()
  const auth = useAuth()
  const theme: any = useTheme()
  return (
    <Box p={3}>
      <Image
        src={theme.logos.logo_long}
        width="128px"
        className="location-logo"
      />
      <Box mt={6} bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
        <Box my={3}>
          <svg
            className="teams-complete"
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 16.5L12.5 20.5L22.5 10.5"
              stroke="#24A148"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
            <path
              d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21573 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21573 0.5 15.5C0.5 23.7843 7.21573 30.5 15.5 30.5Z"
              stroke="#24A148"
              strokeMiterlimit="10"
              strokeLinecap="square"
            />
          </svg>
        </Box>
        <Heading className="teams-complete" fontSize="md">
          {userDetails.firstName
            ? `Welcome to ${platformTheme?.business_name}, ${userDetails.firstName}!`
            : `Welcome to ${platformTheme?.business_name}!`}
        </Heading>
        <br />
        <h3>
          You will start your membership on{' '}
          <strong>
            {userDetails.startDate &&
              format(userDetails.startDate, 'do MMMM yyyy')}
          </strong>
        </h3>
        <h3>
          {acuityEnabled
            ? 'You can now book an onboarding session, and access your account.'
            : 'You can now access your account.'}
        </h3>
        <br />
        {acuityEnabled && (
          <OnBoardingIframe
            locationName={userDetails.locationName?.toLowerCase()}
          />
        )}

        <br />
        <br />
        <Flex>
          <Button
            ml="auto"
            data-testid="onboarding-next"
            onClick={() => {
              auth.getAndSetMe!(window.localStorage.getItem('Token')).then(() =>
                navigate(routeStrings.memberDashboardHome)
              )
            }}
            // disabled={isSubmitting}
          >
            Go to dashboard
          </Button>
        </Flex>
      </Box>
    </Box>
  )
}
