import { Box, BoxProps, HStack, Skeleton, Text } from '@chakra-ui/react'
import React from 'react'
import { IInterval, IPreviewBookingCost } from '../types'

interface CostsProps extends BoxProps {
  costs?: IPreviewBookingCost
  timeSlot?: IInterval
  isWorkspace?: boolean
  isCalculating?: boolean
}

export const BookingCostsComponent: React.FC<CostsProps> = (props) => {
  const { costs, isCalculating, isWorkspace } = props

  if (isCalculating) {
    return (
      <Box>
        <Skeleton h={'5'} mb={1} />
        <Skeleton h={'5'} mb={1} />
        <Skeleton h={'8'} mb={1} />
      </Box>
    )
  }

  if (!costs) return null

  const tax_charged = Number.parseFloat(costs.tax_charged ?? '0')
  const total = Number.parseFloat(costs.charges) + tax_charged

  return (
    <Box position={'relative'} flexDir="row" p={0}>
      {/* {isWorkspace && (
      <HStack py={0} justifyContent="space-between">
        <Text>Credits</Text>
          <Text fontSize="14px">{`${Number.parseFloat(costs.credits).toFixed(
            1
          )} `}</Text>

          <Text color={'gray.500'}>Don't apply</Text>
      </HStack>
        )} */}
      <HStack py={0} justifyContent="space-between">
        <Text>Credits</Text>
        {!isWorkspace ? (
          <Text fontSize="14px">{`${Number.parseFloat(costs.credits).toFixed(
            1
          )} `}</Text>
        ) : (
          <Text color={'gray.500'}>Don't apply</Text>
        )}
      </HStack>
      <HStack py={0} justifyContent="space-between">
        <Text>Subtotal</Text>
        <Text fontSize="14px">{`$${Number.parseFloat(costs.charges).toFixed(
          2
        )} `}</Text>
      </HStack>
      <HStack py={0} justifyContent="space-between">
        <Text>GST</Text>
        <Text fontSize="14px">{`$${tax_charged.toFixed(2)} `}</Text>
      </HStack>
      <HStack py={1} justifyContent="space-between">
        <Text fontWeight={'bold'}>Total</Text>
        <Text fontSize="xl" fontWeight={'bold'}>
          $ {total.toFixed(2)}
        </Text>
      </HStack>
    </Box>
  )
}
