import React from 'react'
import { Flex, Image, Box, SimpleGrid, useTheme, Text } from '@chakra-ui/react'
import { BurgerMenu } from '../../styled/BurgerMenu'
import { TeamsSidebar } from '../../components/TeamsSidebar'
import { useMedia } from '../../hooks/useMedia'
import { routeStrings } from '../../routeStrings'
import { Link, useNavigate } from 'react-router-dom'
import { useMobileEmbed } from '../../context/mobile-embed-context'
import { ChevronLeftIcon } from '@chakra-ui/icons'

export const LayoutMemberDashboard: React.FC<{
  showBack?: boolean
  backButtonAction?: () => void
  children: React.ReactNode
}> = ({ showBack, backButtonAction, children }) => {
  const showBurger = useMedia(['(min-width: 575px)'], [false], true)
  const navigate = useNavigate()
  const theme: any = useTheme()
  const { isMobileEmbed } = useMobileEmbed()

  // uncomment the bottom if you want to use a media query to dynamically set
  // the default collapsed state of the TeamsSidebar on smaller screens
  // such as tablets
  // const collapseSidebarNav = useMedia(['(min-width: 840px)'], [false], true)
  return (
    <Flex direction="column" overflow="hidden" flexGrow={1}>
      {(showBurger || isMobileEmbed) && (
        <Flex
          minH={isMobileEmbed ? (showBack ? '40px' : 0) : '80px'}
          boxShadow="sm"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          {showBack ? (
            <SimpleGrid columns={3} w="100%">
              <Box
                cursor="pointer"
                onClick={() => {
                  if (backButtonAction) navigate(-1)
                }}
                height={'14'}
                pt={4}
              >
                <Text>
                  <ChevronLeftIcon color="pink.600" /> Back
                </Text>
              </Box>
              {isMobileEmbed && theme.logos.logo_long && (
                <Box pt={3}>
                  <Image
                    src={theme.logos.logo_long}
                    width={200}
                    alignSelf="center"
                  />
                </Box>
              )}
              {!isMobileEmbed && (
                <>
                  {/* TEST NODE */}
                  <Link to={routeStrings.memberDashboardHome}>
                    <Image
                      src={theme.logos.logo_long}
                      width={200}
                      alignSelf="center"
                    />
                  </Link>
                  {/* TEST NODE */}

                  <Link to={routeStrings.memberDashboardHome} />

                  <BurgerMenu />
                </>
              )}
            </SimpleGrid>
          ) : (
            <>
              {!isMobileEmbed ? (
                <>
                  <Link to={routeStrings.memberDashboardHome}>
                    <Image
                      src={theme.logos.logo_long}
                      width={200}
                      alignSelf="center"
                    />
                  </Link>

                  <BurgerMenu />
                </>
              ) : null}
            </>
          )}
        </Flex>
      )}

      <Flex direction="row" flexGrow={1} overflow="hidden">
        {showBurger === false && !isMobileEmbed && (
          <TeamsSidebar defaultCollapsed={true} />
        )}
        <Flex
          flexGrow={1}
          flexDir="column"
          w="100%"
          h={'100vh'}
          overflow="hidden"
          position="relative"
        >
          {children}
          {!isMobileEmbed && (
            <Flex w="100%" justify="center" bottom={0} position="absolute">
              <Text as="span">
                Powered by{' '}
                <Text as="span" color="#EB4969">
                  Spacecubed
                </Text>
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
