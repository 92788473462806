import { format } from 'date-fns'
import React from 'react'
import Calendar from 'react-calendar'
import {
  SinglePageSignupComponent,
  useUserDetails
} from '../../context/user-onboard-context'
import { Divider, Stack, Text } from '@chakra-ui/react'

import { OnboardingHeading } from './OnboardingComponents'

export const OnboardingStartDate: React.FC<SinglePageSignupComponent> = (
  props
) => {
  const { onCompleteCallback, isActive, setIsActive } = props
  const { setUserDetails, userDetails } = useUserDetails()
  const startDate = (userDetails && userDetails.startDate) || undefined

  return (
    <Stack
      spacing={4}
      align="center"
      ml="auto"
      mr="auto"
      w="450px"
      my={5}
      onClick={setIsActive}
    >
      <OnboardingHeading size={'md'} color={isActive ? 'gray.800' : 'gray.400'}>
        Choose your start date
      </OnboardingHeading>
      <Calendar
        view="month"
        minDetail="month"
        onChange={(date) => {
          setUserDetails((state: any) => ({
            ...state,
            startDate: date as Date
          }))
          onCompleteCallback()
        }}
        value={startDate}
        minDate={new Date()}
      />
      <Divider />
      {startDate && (
        <Text fontSize="lg">
          Your start date is <b>{format(startDate, 'do MMMM yyyy')}</b>
        </Text>
      )}
    </Stack>
  )
}
