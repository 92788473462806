import { Box, BoxProps, Flex, Skeleton, Text } from '@chakra-ui/react'
import { faCalendarDay, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInSeconds, format } from 'date-fns'
import React from 'react'
import { secondsToTimeString } from '../pages/MemberDashboard/MeetingRooms/meetingRoomUtils'
import { IInterval } from '../types'

interface CostsProps extends BoxProps {
  timeSlot?: IInterval
  halfDayDuration?: number
  isCalculating?: boolean
}

export const BookingDurationComponent: React.FC<CostsProps> = (props) => {
  const { timeSlot, isCalculating } = props

  if (isCalculating) {
    return (
      <Box>
        <Skeleton h={'5'} mb={1} />
        <Skeleton h={'5'} mb={1} />
        <Skeleton h={'8'} mb={1} />
      </Box>
    )
  }

  if (!timeSlot) return null

  const duration = timeSlot
    ? secondsToTimeString(differenceInSeconds(timeSlot.end, timeSlot.start))
    : undefined

  return (
    <Box
      position={'relative'}
      display={['block', 'flex']}
      justifyContent={'space-evenly'}
      p={0}
    >
      <Flex opacity={0.9} align={'center'} mb={1}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={'purple.100'}
        >
          <FontAwesomeIcon icon={faCalendarDay} size={'1x'} />
        </Flex>

        <Text
          ml={2}
          fontSize="xs"
          fontWeight={[100, 100, 600]}
          letterSpacing="0.25px"
        >
          {format(timeSlot.start, 'PP')}
        </Text>
        <Text ml={2} as="span" fontSize="xs" letterSpacing="0.25px">
          ({format(timeSlot.start, 'h:mm b')} - {format(timeSlot.end, 'h:mm b')}
          )
        </Text>
      </Flex>
      <Flex opacity={0.9} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={'green.100'}
        >
          <FontAwesomeIcon icon={faStopwatch} size={'1x'} />
        </Flex>
        <Text ml={2} fontSize={'xs'} fontWeight={[100, 100, 600]}>
          {duration}
        </Text>
      </Flex>
    </Box>
  )
}
