import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import * as Sentry from '@sentry/browser'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'

import { FormErrorMessage } from '../components/FormErrorMessage'
import { FormInput } from '../components/FormInput'
import { RouterLink } from '../components/Link'
import { OnboardingWrapper } from '../components/OnboardingWrapper'
import { useAuth } from '../context/auth-context'
import { routeStrings } from '../routeStrings'
import { EMAIL_REGEX } from '../utils/constants'

interface Props {
  logo_url?: string
  externalSignUp?: {
    tenantID: number
  }
}

export const LoginComponent: React.FC<Props> = (props) => {
  const { logo_url, externalSignUp } = props
  const [errorMessage, setErrorMessage] = useState('')
  const auth = useAuth()
  const theme: any = useTheme()
  if (auth.loading) {
    return null
  }

  return (
    <Stack
      align="center"
      spacing={8}
      mt={['100px', '20vh']}
      ml="auto"
      mr="auto"
      maxW="450px"
    >
      <Image
        src={logo_url ?? theme.logos.logo_long}
        w={'14rem'}
        alt="Spacecubed Logo"
      />
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validate={(values) => {
          const errors: any = {}
          if (!values.email) {
            errors.email = 'Email is required'
          } else if (!EMAIL_REGEX.test(values.email)) {
            errors.email = 'Invalid email address'
          }
          if (!values.password) {
            errors.password = 'Password is required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          if (auth.loginUser) {
            try {
              let email = values.email ?? ''
              email = email.trim().toLowerCase()
              await auth.loginUser(email, values.password)
            } catch (e: any) {
              if (e.response?.status === 400) {
                setErrorMessage('Incorrect Username or Password')
              } else {
                setErrorMessage(
                  'Something went wrong - our team has been notified'
                )
                Sentry.captureException(e)
              }
            }
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, errors, touched, submitCount }) => (
          <Form>
            <Box mb={5}>
              <Field
                type="email"
                name="email"
                data-testid="login-email-field"
                label="Email"
                component={FormInput}
                autoFocus={true}
                isInvalid={
                  submitCount > 0 && touched.email && errors.email !== undefined
                }
              />
              {submitCount > 0 && (
                <ErrorMessage name="email" component={FormErrorMessage} />
              )}
            </Box>
            <Box mb={5}>
              <Field
                type="password"
                name="password"
                data-testid="login-password-field"
                label="Password"
                component={FormInput}
                isInvalid={
                  submitCount > 0 &&
                  touched.password &&
                  errors.password !== undefined
                }
              />
              {submitCount > 0 && (
                <ErrorMessage name="password" component={FormErrorMessage} />
              )}
            </Box>
            <Flex justifyContent="space-between">
              <Button
                type="submit"
                display="block"
                bg="brandPrimary"
                color="white"
                borderRadius="100px"
                isLoading={isSubmitting}
                data-testid="login-button"
                mt={16}
                size="lg"
              >
                Login
              </Button>
              <Box>
                <RouterLink
                  to="/reset"
                  data-testid="reset-password"
                  textAlign="right"
                  color="#255CE1"
                  textDecor="underline"
                  float="right"
                >
                  Forgot password
                </RouterLink>
              </Box>
            </Flex>
            <Box minH={5} mt={5}>
              {errorMessage !== '' && (
                <FormErrorMessage textAlign="center">
                  {errorMessage}
                </FormErrorMessage>
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <Text mt={16} fontSize="md">
        Don't have an account?{' '}
        <RouterLink
          fontWeight="bold"
          color="#212121"
          to={
            !externalSignUp
              ? routeStrings.location
              : routeStrings.getStartExternalSignup(externalSignUp.tenantID)
          }
          data-testid="login-create-account"
        >
          Sign up
        </RouterLink>
      </Text>
    </Stack>
  )
}
