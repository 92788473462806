import {
  Box,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text
} from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { faGlobe, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { UserDisplay } from '../../../../components/UserDisplay'
import { useAuth } from '../../../../context/auth-context'
import { useMembersDirectoryContext } from '../../../../context/members-directory-context'

import { useOrganizationProfile } from '../../../../utils/apiHooks'

interface Props {
  isOpen: boolean
  orgID?: number
  onClose: () => void
}

export const OrganizationProfileModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, orgID } = props

  const { data: organization, isValidating } = useOrganizationProfile(orgID)

  const { logViewCompanyExtraDetail } = useMembersDirectoryContext()
  const { currentOrgUnit } = useAuth()
  const theme: any = useTheme()

  const linkStyle = {
    fontSize: '11px',
    color: theme.colors.primary ?? '#777',
    marginTop: '0px',
    fontWeight: 'bold' as 'bold'
  }

  const disabledLink = {
    fontSize: '11px',
    color: '#777',
    cursor: 'not-allowed',
    opacity: '0.5',
    textDecoration: 'none'
  }

  if (!currentOrgUnit) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Create a connection</ModalHeader> */}
        <ModalCloseButton />
        {isValidating ? (
          <Box padding="6" my={1} boxShadow="lg" bg="white">
            <SkeletonCircle size="10" />
            <SkeletonText mt="4" noOfLines={5} spacing="4" skeletonHeight="2" />
          </Box>
        ) : (
          <ModalBody pb={2}>
            <Box background={'white'} p={4} my={3}>
              <Flex>
                <Box w={['100%']}>
                  <Flex>
                    <Box mr={4}>
                      <UserDisplay
                        my="5px"
                        size="lg"
                        name={organization?.name}
                        src={organization?.organization_profile.logo_url}
                      />
                    </Box>
                    <Box>
                      {/* COntact Data */}
                      <Stack px={2}>
                        <Heading my={0} lineHeight={'1'} size={'md'}>
                          {organization?.organization_profile.organization.name}
                        </Heading>

                        <Stack spacing={0}>
                          <Link
                            href={organization?.organization_profile.website}
                            style={
                              organization?.organization_profile.website
                                ? linkStyle
                                : disabledLink
                            }
                            color={theme.colors.primary}
                            isExternal
                            onClick={() => {
                              if (organization?.organization_profile.website) {
                                logViewCompanyExtraDetail(
                                  organization.id,
                                  'Website'
                                )
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faGlobe} /> Website
                          </Link>

                          <Text color={'#444'} fontSize="sm">
                            {organization?.organization_profile.mobile_number}
                          </Text>
                          <Link
                            href={
                              organization?.organization_profile.linkedin_url
                            }
                            style={
                              organization?.organization_profile.linkedin_url
                                ? linkStyle
                                : disabledLink
                            }
                            isExternal
                            onClick={() => {
                              if (
                                organization?.organization_profile.linkedin_url
                              ) {
                                logViewCompanyExtraDetail(
                                  organization.id,
                                  'LinkedIn'
                                )
                              }
                            }}
                          >
                            LinkedIn profile
                          </Link>
                        </Stack>
                      </Stack>
                    </Box>
                  </Flex>
                  <Box w={['100%']} py={1}>
                    <Box>
                      {/* Home */}
                      {organization?.home_location_name && (
                        <Text
                          fontWeight={'bold'}
                          fontSize="xx-small"
                          color="#666"
                          lineHeight={'1'}
                        >
                          <FontAwesomeIcon icon={faHome} />{' '}
                          {organization.home_location_name}
                          <Text
                            as="span"
                            color={'#777'}
                            fontSize="xx-small"
                            fontWeight={'normal'}
                          >
                            {', ' + organization.home_location_address}
                          </Text>
                        </Text>
                      )}
                    </Box>
                    <Flex my={2} flexWrap="wrap">
                      {organization?.organization_profile.industries?.map(
                        (tag, index) => (
                          <Text
                            border={'1px'}
                            borderColor={'#757575'}
                            borderRadius="md"
                            color={'#777'}
                            px={1}
                            my={1}
                            mr={1}
                            fontSize="xx-small"
                            maxW="100%"
                            overflow={'hidden'}
                            textOverflow="ellipsis"
                            whiteSpace={'nowrap'}
                            key={index}
                          >
                            {tag.name}
                          </Text>
                        )
                      )}
                    </Flex>
                  </Box>
                  <Box mt={3} overflow={'hidden'}>
                    {/* ABOUT */}

                    {organization?.organization_profile.overview &&
                      organization?.organization_profile.overview !== '' && (
                        <>
                          <Heading size={'sm'} color="#444">
                            Overview
                          </Heading>
                          <Text fontSize={'sm'} color={'#777'}>
                            {organization?.organization_profile.overview}
                          </Text>
                        </>
                      )}
                  </Box>
                </Box>
              </Flex>
            </Box>
          </ModalBody>
        )}

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
