import {
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  useDisclosure,
  IconButton
} from '@chakra-ui/react'

import React, { useRef } from 'react'

import { Button } from './Button'
import { QuestionIcon } from '@chakra-ui/icons'

export const CancellationPolicyComponent: React.FC = ({ ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Alert
        status="info"
        style={{ background: '#d9d9d9', cursor: 'pointer' }}
        onClick={onOpen}
      >
        Have a look at our&nbsp;
        <Text as={'span'} textDecoration={'underline'}>
          Cancellation Policy
        </Text>
        <IconButton
          size={'xs'}
          variant="ghost"
          colorScheme="info"
          aria-label="Know more"
          icon={<QuestionIcon />}
        />
      </Alert>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        size={'lg'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancellation Policy
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                In case of a booking cancellation, we will refund the amount to
                the credit card with which the payment was done. <br />
                The amount to be refunded varies acording to how close the
                cancellation and the booking's start times are.
                <Text fontWeight={'bold'} mt={2}>
                  24 hours or less:
                  <Text as="span" fontWeight={'normal'}>
                    {' '}
                    No refund.{' '}
                  </Text>
                </Text>
                <Text fontWeight={'bold'}>
                  Up to 48 hours (2 days):
                  <Text as="span" fontWeight={'normal'}>
                    {' '}
                    50%{' '}
                  </Text>
                </Text>
                <Text fontWeight={'bold'}>
                  Up to 96 hours (4 days):
                  <Text as="span" fontWeight={'normal'}>
                    {' '}
                    75%{' '}
                  </Text>
                </Text>
                <Text fontWeight={'bold'}>
                  Full refund for cancellations more than four days before the
                  booking's start time.
                </Text>
              </Text>
              <Text fontWeight={'bold'} mt={3}>
                By confirming your booking, you are agreeing to this
                cancellation policy.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>Dismiss</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
