import React from 'react'
import { Flex, Text, VStack } from '@chakra-ui/react'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AccountCodeCellProps {
  accountCode?: string
  accountLabel?: string
}

export const AccountCodeCell: React.FC<AccountCodeCellProps> = (props) => {
  const { accountCode, accountLabel } = props

  return (
    <VStack spacing={0} px={2} justify="center" align="start">
      <Flex alignItems={'left'}>
        <FontAwesomeIcon
          size={'1x'}
          icon={faCalculator}
          style={{ marginTop: 5, marginRight: 4 }}
        />
        <Text fontSize={'md'}>{accountCode}</Text>
      </Flex>
      {accountLabel && <Text fontSize={'sm'}>{accountLabel}</Text>}
    </VStack>
  )
}
