import { Flex, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { ChakraModal } from '../../../components/ChakraModal'
import { Button } from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { routeStrings } from '../../../routeStrings'

interface IModalInput {
  isOpen: boolean
  closeModal: () => void
}

export const StripeConnectRequiredModal: React.FC<IModalInput> = (input) => {
  const navigate = useNavigate()

  const setUp = () => {
    navigate(
      routeStrings.adminDashboardPlatformTenantSettings + '?tab=integration'
    )
  }
  return (
    <ChakraModal
      title="Complete onboarding"
      isOpen={input.isOpen}
      onClose={input.closeModal}
    >
      <Box p={2}>
        <Text>You must complete your Payments Configuration first</Text>
      </Box>

      <Flex flexDirection="row" mt={10}>
        <Button key="submit" data-testid="assign" onClick={setUp} mr="8px">
          Set up now
        </Button>
        <Button
          key="submit"
          data-testid="cancel"
          variant="secondary"
          onClick={input.closeModal}
        >
          Cancel
        </Button>
      </Flex>
    </ChakraModal>
  )
}
