import { Alert, AlertIcon, Box, Image, Stack, useTheme } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { tenantXeroRegistration } from '../../api'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { routeStrings } from '../../routeStrings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { Button } from '../../components/Button'
import { useAuth } from '../../context/auth-context'

export const XeroCallbackCodeRegistration: React.FC = () => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [response, setResponse] = useState<boolean>(false)
  const theme: any = useTheme()

  const { me, setOrganizationalUnit } = useAuth()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')
    const state = searchParams.get('state')?.replace('state=', '')
    try {
      if (code && state) {
        if (me) {
          const ou = me?.organizational_units.find(
            (ou) => ou.id === parseInt(state)
          )
          // the OU ID is set on the state when calling xero, that way, when it comes back, we match it with the users OUs and ensure we have the correct tenant
          if (ou) {
            setSubmitting(true)
            tenantXeroRegistration(ou.organization.parent_platform_tenant, code)
              .then((res) => {
                if (res.status === 200) {
                  setResponse(true)
                }
                setOrganizationalUnit(me, ou)
                setSubmitting(false)
              })
              .catch(() => {
                setSubmitting(false)
                setResponse(false)
              })
          }
        }
      } else {
        setSubmitting(false)
        setResponse(false)
      }
    } catch (e: any) {
      console.log(e.message)
    }
  }, [me])

  if (submitting) {
    return (
      <Box>
        <CenteredSpinner />
      </Box>
    )
  }
  return (
    <OnboardingWrapper>
      <Stack p={3} spacing="8px">
        <Image
          src={theme.logos.logo_long}
          width="128px"
          className="location-logo"
        />
        {response ? (
          <>
            <Alert status="success">
              <AlertIcon />
              Xero was successfully connected to the platform.
            </Alert>
            <Button
              onClick={() => {
                navigate(routeStrings.adminDashboardPlatformTenantSettings)
              }}
            >
              Configure Xero integration
            </Button>
          </>
        ) : (
          <>
            <Alert status="error">
              <AlertIcon />
              There was an error authorizing your Xero account with the
              platform.
            </Alert>
            <Button
              onClick={() => {
                navigate(routeStrings.adminDashboard)
              }}
            >
              Return to dashboard
            </Button>
          </>
        )}
      </Stack>
    </OnboardingWrapper>
  )
}
// http://localhost:3000/admin/dashboard/xero-callback?code=35651ebdbb81525a67798bdd68ad6d78905a33ac1bb2e177de445c7946e8be1f&scope=openid%20accounting.contacts%20accounting.transactions%20accounting.settings%20offline_access&session_state=oNwSTp77f68Szry5pa-67-DIFrZtHsG1W3juFzJuED8.1ad5fbae64fefee1559d065cf1e556ca
