import React, { useContext } from 'react'
import { AlertBox } from '../components/AlertBox'
import { useToast, Icon, Flex, Text, Center, HStack } from '@chakra-ui/react'
import { NotAllowedIcon, CheckCircleIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
export interface RevalidationProps {
  newToast: (props: customToastProps) => void
}

const defaultProps: RevalidationProps = {
  newToast: (props: any) => console.log('toast not yet initialized')
}

interface customToastProps {
  status?: 'error' | 'success' | 'warning' | 'info'
  description?: string
  duration?: number
}

export const ToastContext = React.createContext<RevalidationProps>(defaultProps)

export const ToastProvider = ({ children }) => {
  const defaultToast = useToast()

  const newToast = (props: customToastProps) => {
    const { status, description, duration } = props

    // Map of status's to iconName and color values
    const AlertMap = {
      error: { icon: <NotAllowedIcon color='red.300' /> },
      success: { icon: <CheckCircleIcon color='green.300' /> },
      warning: { icon: <WarningTwoIcon color='orange.300' /> },
      info: { icon: <WarningIcon color='blue.300' /> }
    }

    // Use the the status as a key and render composed children
    const AlertBoxChildren = (
      <Center flexShrink={0}>
        <HStack>
          {AlertMap[status || 'info'].icon}
          <Text marginBottom=" 0px"
            fontFamily=" Open Sans"
            fontSize=" 14px"
            lineHeight=" 143%"
            color=" #2f4858">{description}</Text>
        </HStack>
      </Center>



    )

    defaultToast({
      position: 'top',
      duration: duration || 5000,
      render: onClose => (
        <AlertBox onClose={onClose}>{AlertBoxChildren}</AlertBox>
      ),
      isClosable: true
    })
  }

  return (
    <ToastContext.Provider
      value={{
        newToast
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

export const useCustomToast = () => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useCustomToast must be used within an ToastProvider')
  }
  return context
}
