import {
  Box,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { InvoiceStatus, RefundStatus, updateInvoiceStatus } from '../api'
import { useAuth } from '../context/auth-context'
import { routeStrings } from '../routeStrings'
import { IBillingAccountDetail, IInvoice } from '../types'
import {
  useOrganization,
  useOrgBillingAccounts,
  useOrgInvoices
} from '../utils/apiHooks'
import { Actions, useRBAC } from '../utils/rbac'
import { ExternalPaymentDetails } from './ExternalPaymentDetails'
import { DotsIcon } from './icons/icons'
import { TablePaginator } from './table/TablePaginator'
import { TableRow } from './TableRow'
import { Tag } from './Tag'
import { useNavigate } from 'react-router-dom'

export const BillingHistory: React.FC<any> = () => {
  const navigate = useNavigate()

  const auth = useAuth()
  const [page, setPage] = useState(1)
  const canAdministerInvoiceActions = useRBAC(Actions.AdministerInvoicesActions)
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id
  const { data: organization } = useOrganization(orgId)

  const [selectedBillingAccount, setSelectedBillingAccount] = useState<
    IBillingAccountDetail | undefined
  >()

  const { data: invoiceData, mutate } = useOrgInvoices(
    organization?.id,
    selectedBillingAccount
      ? selectedBillingAccount.platform_tenant
      : organization?.parent_platform_tenant,
    page
  )
  const { data: billingAccounts } = useOrgBillingAccounts(orgId, {
    refreshInterval: 0,
    revalidateOnFocus: false
  })

  useEffect(() => {
    if (billingAccounts && billingAccounts.length > 0) {
      setSelectedBillingAccount(billingAccounts[0])
    }
  }, [billingAccounts])

  const InvoiceListElement = (
    index: number,
    invoice: IInvoice
  ): JSX.Element => {
    return (
      <TableRow key={index} justify="space-between" align="start" p={3}>
        <Stack width={'33%'} spacing={0}>
          <Text color="#777" fontWeight="bold" fontSize="xs" my={0}>
            {invoice.platform_tenant_name}
          </Text>
          <Text color="textPrimary" fontWeight="bold" fontSize="sm" my={0}>
            {format(new Date(invoice.issued_on), 'dd/MM/yyyy')}
          </Text>
        </Stack>
        <Box mr="auto">
          <Stack isInline align="center" mb={1}>
            {/* <Tag positive>Paid</Tag> */}
            <Text color="textPrimary" fontSize="sm" my={0} mr={2}>
              ${invoice.amount_charged}
            </Text>
            <InvoiceStatusTag text={invoice.status} />
          </Stack>
          <Text color="eastBay07" fontSize="sm" my={0}>
            {invoice.status === InvoiceStatus.Paid ? (
              invoice.paid_on && <>Paid {invoice.paid_on}</>
            ) : (
              <>Due {format(new Date(invoice.issued_on), 'dd/MM/yyyy')}</>
            )}
          </Text>
          {invoice.refund && (
            <Box mr="auto" mt={2}>
              <Stack isInline align="center" mb={1}>
                {/* <Tag positive>Paid</Tag> */}
                <Text color="textPrimary" fontSize="sm" my={0} mr={2}>
                  ${invoice.refund.value}
                </Text>
                <RefundStatusTag text={invoice.refund.status} />
              </Stack>
              <Text color="eastBay07" fontSize="sm" my={0}>
                {invoice.refund.status === RefundStatus.Refunded ? (
                  invoice.refund.refunded_on && (
                    <>
                      Refunded{' '}
                      {format(
                        new Date(invoice.refund.refunded_on),
                        'dd/MM/yyyy HH:mm'
                      )}
                    </>
                  )
                ) : (
                  <>
                    Refund attempted on{' '}
                    {format(new Date(invoice.issued_on), 'dd/MM/yyyy HH:mm')}
                  </>
                )}
              </Text>
            </Box>
          )}
        </Box>

        <Menu>
          <MenuButton as={Button} aria-label="invoice-menu" variant="ghost">
            <DotsIcon></DotsIcon>
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() =>
                navigate(
                  routeStrings.memberDashboardBillingInvoice.replace(
                    ':invoiceId',
                    invoice.id + ''
                  )
                )
              }
            >
              View PDF
            </MenuItem>
            {canAdministerInvoiceActions && (
              <>
                <Text px={2} fontWeight="bold">
                  Only visible to super admins
                </Text>
                <MenuItem
                  onClick={async () => {
                    await updateInvoiceStatus(invoice.id, InvoiceStatus.Paid)
                    mutate()
                  }}
                >
                  Mark as paid
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    await updateInvoiceStatus(
                      invoice.id,
                      InvoiceStatus.Cancelled
                    )
                    mutate()
                  }}
                >
                  Mark as cancelled
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      </TableRow>
    )
  }

  const BillingAccountsSelector = () => {
    if (!billingAccounts) return null
    return (
      <>
        {billingAccounts.length > 1 && (
          <Box rounded="lg" py={2} mb={2}>
            <Text fontWeight={'bold'} fontSize={'sm'} my={1}>
              Select another space
            </Text>
            <Select
              style={{ background: 'white' }}
              value={selectedBillingAccount?.id + ''}
              onChange={(value) => {
                let account = billingAccounts.find(
                  (a) => a.id + '' === value.target.value
                )
                if (account) {
                  setSelectedBillingAccount(account)
                  setPage(1)
                  mutate()
                }
              }}
              // defaultValue={billingAccounts[0].id + ''}
            >
              {billingAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.platform_tenant_name}
                </option>
              ))}
            </Select>
          </Box>
        )}

        {selectedBillingAccount &&
          organization?.parent_platform_tenant !=
            selectedBillingAccount.platform_tenant && (
            <ExternalPaymentDetails billingAccount={selectedBillingAccount} />
          )}
      </>
    )
  }

  return (
    <Box my="3" maxWidth={['650px']}>
      <Box dir="flex" mb={3} alignItems="center">
        <Text mb={0} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Billing History
        </Text>
      </Box>
      {<BillingAccountsSelector />}
      {/* Billing history */}
      {invoiceData &&
        invoiceData.results?.map((invoice, index) =>
          InvoiceListElement(index, invoice)
        )}
      {invoiceData &&
        invoiceData.results &&
        invoiceData.results.length === 0 && (
          <Heading size={'xs'} color="#ccc">
            No history recorded so far
          </Heading>
        )}
      {invoiceData && invoiceData.results && invoiceData.results.length > 0 && (
        <TablePaginator
          pageSize={10}
          setPageSize={() => ({})}
          pageOptions={[]}
          pageIndex={page}
          canNextPage={invoiceData.next !== undefined}
          nextPage={() => {
            setPage(page + 1)
            // mutate()
            return {}
          }}
          canPreviousPage={page > 1}
          previousPage={() => {
            setPage(page - 1)
            // mutate()
            return {}
          }}
          gotoPage={(pageNumber) => {
            setPage(pageNumber + 1)
            // mutate()
            return {}
          }}
        />
      )}
    </Box>
  )
}

interface statusProps {
  text: string | InvoiceStatus
}

export const InvoiceStatusTag: React.FC<statusProps> = ({ text }) => {
  return (
    <Tag
      positive={text === InvoiceStatus.Paid}
      negative={text === InvoiceStatus.Declined}
      yellow={text === InvoiceStatus.Pending}
      blue={text === InvoiceStatus.Preview}
    >
      {text}
    </Tag>
  )
}
export const RefundStatusTag: React.FC<statusProps> = ({ text }) => {
  return (
    <Tag
      blue={text === RefundStatus.Refunded}
      negative={text !== RefundStatus.Refunded}
    >
      {text}
    </Tag>
  )
}
