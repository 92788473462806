import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'

const backgroundImages = [
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/flux.jpeg',
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/flux-watson-meeting-room.jpg',
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/flux-deck-empty.jpg',
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/KeaneBourke_IMG_7274.png',
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/KeaneBourke_IMG_7320.png',
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/KeaneBourke_IMG_7473.png',
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/KeaneBourke_IMG_7869.png',
  'https://spacecubed-public.s3-ap-southeast-2.amazonaws.com/login-images/KeaneBourke_IMG_7873.png'
]

const randomBackgroundImage =
  backgroundImages[Math.floor(Math.random() * backgroundImages.length)]

export const OnboardingWrapper = ({ children }) => {
  return (
    <Flex width="100%" overflowY="auto" height={'100%'}>
      <Box
        p={3}
        width={['100%', '80%', '65%', '50%']}
        h="100%"
        position="relative"
      >
        <Box maxW="600px" margin="0 auto">
          {children}
        </Box>

        <Flex
          w={['100%', '50%']}
          justify="center"
          bottom="0px"
          position="fixed"
        >
          <Text as="span">
            Powered by{' '}
            <Text as="span" color="#EB4969">
              Spacecubed
            </Text>
          </Text>
        </Flex>
      </Box>
      <Box
        p={3}
        display={['none', 'inherit', 'inherit', 'inherit']}
        width={[0, '20%', '35%', '50%']}
        backgroundColor="#F5F5F5"
        backgroundImage={`url('${randomBackgroundImage}')`}
        h="100vh"
        boxShadow="inset 0 0 20px #000000"
        backgroundSize="cover"
        backgroundPosition="center"
      />
    </Flex>
  )
}
