import { Center, Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { SecondaryButton } from '../../components/chakra-v2/SecondaryButton'
import { useAuth } from '../../context/auth-context'

import { useUserDetails } from '../../context/user-onboard-context'
import { OnboardingHeading } from '../Onboarding/OnboardingComponents'
import { CheckCustomIcon } from '../../components/icons/icons'
import { usePlatformTenant } from '../../context/platform-tenant-context'

export const ExternalOnboardingThankyou: React.FC = () => {
  const { userDetails } = useUserDetails()
  const { platformTheme } = usePlatformTenant()
  const { getAndSetMe } = useAuth()
  const tenantName = platformTheme ? platformTheme.business_name : 'Spacecubed'

  return (
    <Center alignContent="center">
      <Stack
        spacing={8}
        maxW="350px"
        shouldWrapChildren
        alignItems="center"
        justifyContent="center"
      >
        <CheckCustomIcon color="alertSuccess" height="2rem" width="2rem" />

        <Stack spacing={8} justify="center" align="center">
          <OnboardingHeading>Thank you</OnboardingHeading>
          {userDetails.startDate !== undefined && (
            <Text font-size={18} textAlign="center">
              You have signed up to book meeting rooms, workspaces and more in
              different locations
            </Text>
          )}
          <Text font-size={18} textAlign="center">
            A {tenantName} administrator will be in touch via email to confirm
            your membership.
          </Text>
          <SecondaryButton
            flexGrow={0}
            onClick={() => {
              const token = window.localStorage.getItem('Token') ?? ''
              getAndSetMe!(token)
            }}
          >
            Home
          </SecondaryButton>
        </Stack>
      </Stack>
    </Center>
  )
}
