import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { routeStrings } from '../../routeStrings'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'

export const TenantOnboardingCompleted: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const navigate = useNavigate()
  return (
    <OnboardingWrapper>
      {' '}
      <Box p={5} pl={10} w="500px">
        <Box mt={6}>
          <Text className="teams-complete" fontSize="20px">
            <strong>{platformTenant?.business_name}</strong> is on board!
            <br />
          </Text>
          <br />
          <h3>You can now access your account.</h3>
        </Box>
        <br />

        <br />
        <br />
        <Button
          ml="auto"
          data-testid="onboarding-next"
          style={{ float: 'right', display: 'inline-block' }}
          onClick={() => {
            navigate(routeStrings.adminDashboard)
          }}
          // disabled={isSubmitting}
        >
          Go to dashboard
        </Button>
      </Box>
    </OnboardingWrapper>
  )
}
