// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'
import React, { useEffect, useState } from 'react'
import { filterSelectOrganizationalUnits } from '../api'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { IOrganizationalUnitFilterableSelect } from '../types'
import { FilterableMultiSelect } from './FilterableMultiSelect'
export const FilterableOrganizationalUnitsMultiSelect = ({
  name,
  onSelect,
  dataTestId,
  initialValue,
  hiddenIds,
  organizationId,
  _ref
}: {
  name: string
  initialValue?: number
  hiddenIds?: number[]
  onSelect: (values: IOrganizationalUnitFilterableSelect[]) => any
  additionalActions?:
    | Array<{ label: string | JSX.Element; itemAction: (arg: any) => void }>
    | undefined
  dataTestId?: string
  organizationId?: number
  _ref: React.Ref<any>
}) => {
  const { platformTenant } = usePlatformTenant()

  const [value, setValue] = useState<string | undefined>()
  const [debouncedTerm, setDebouncedTerm] = useState('')

  const page_size = 10

  const [items, setItems] = useState<IOrganizationalUnitFilterableSelect[]>([])

  const [page, setPage] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFeching] = useState(false)
  const fetchOrgs = (input_page: number, value: string) => {
    if (isFetching) return
    if (platformTenant) {
      setIsFeching(true)
      return filterSelectOrganizationalUnits(
        platformTenant.id,
        value,
        organizationId,
        input_page,
        page_size
      )
        .then((res) => {
          if (res && res.data) {
            let results = res.data.results ?? []
            if (hiddenIds) {
              results = results.filter(
                (r) => !hiddenIds.find((i) => i === r.id)
              )
            }
            results = input_page > 1 ? items.concat(results) : results
            // let resOrgUnits = input_page > 1 ? orgUnits.concat(res.data?.results ?? []) : res.data?.results ?? []
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
            setItems(results)
            // setOrgUnits(resOrgUnits)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }

  useEffect(() => {
    if (platformTenant && value != undefined) {
      fetchOrgs(1, value)
    }
  }, [hiddenIds])

  useEffect(() => {
    if (platformTenant && value != undefined) {
      fetchOrgs(1, value)
    }
  }, [value])

  useEffect(() => {
    const timer = setTimeout(() => setValue(debouncedTerm), 600)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  return (
    <FilterableMultiSelect
      // isLoading={isFetching}
      onInputChange={(newValue: string) => {
        setDebouncedTerm(newValue)
      }}
      dataTestId={dataTestId}
      isClearable={false}
      items={
        items
          ? items.map((org) => {
              return {
                label: `${org.user.name} ${
                  organizationId ? '' : '@' + org.organization.name
                }`,
                value: org.id
              }
            })
          : []
      }
      name={name}
      onSelect={(ids) => {
        let selected: IOrganizationalUnitFilterableSelect[] = []
        ids.forEach((id) => {
          let item = items.find((item) => item.id === id)
          if (item) {
            selected.push(item)
          }
        })

        onSelect(selected)
      }}
      onMenuScrollToBottom={() => {
        if (hasMoreResults) fetchOrgs(page, value ?? '')
      }}
      _ref={_ref}
    />
  )
}
