import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalFooter,
  useToast,
  VStack,
  Alert,
  Stack,
  Text
} from '@chakra-ui/react'

import { format } from 'date-fns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { Dispatch, SetStateAction, useState } from 'react'
import Calendar from 'react-calendar'
import { newMemberInvite } from '../../api'
import { useAuth } from '../../context/auth-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { Plan } from '../../interfaces'
import { InputWarning } from '../../styled/InputWarning'
import { Location } from '../../types'
import { usePlans } from '../../utils/apiHooks'
import { AlertBox } from '../AlertBox'
import { Button } from '../Button'
import { CalendarButton } from '../CalendarButton'
import { ChakraModal } from '../ChakraModal'
import { FormInput } from '../FormInput'
import { validateEmail } from '../../utils/formUtils'

enum PlanType {
  NEW_PLAN = 'new_plan',
  FIRST_SUB_NON_CHARGEABLE = 'first_sub_non_chargeable',
  NON_CHARGABLE_PLAN = 'non_chargable_plan',
  ERROR = 'error'
}

export const AdminDashboardInviteMemberModal = ({
  visibility,
  revalidate
}: {
  visibility: {
    modalIsVisible: boolean
    setModalIsVisible: Dispatch<SetStateAction<boolean>>
  }
  revalidate: () => void
}) => {
  // State to determing membership type, unique keys are
  const [planType, setPlanType] = useState<PlanType>()

  // Toast info module from chakra
  const toastPopup = useToast()
  const { currentOrgUnit } = useAuth()

  // Custome invite state
  const [chosenLocation, setChosenLocation] = useState<Location>()
  const [chosenPlan, setChosenPlan] = useState<Plan>()
  const [chosenStartDate, setChosenStartDate] = useState<any>()
  const [filteredPlans, setFilteredPlans] = useState<Plan[]>([])
  const [showCalendar, setShowCalendar] = useState(false)
  const [error, setError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  // State for invite modal
  const [inviteEmail, setInviteEmail] = useState('')

  //  State to hold new values.
  const [showRateAdjustment, setShowRateAdjustment] = useState<boolean>(false)
  const [rateAdjustmentReason, setRateAdjustmentReason] = useState<string>('')
  const [rateAdjustment, setRateAdjustment] = useState<number | ''>('')
  const [isNonTaxable, setIsNonTaxable] = useState<boolean>(false)
  const { platformTenant } = usePlatformTenant()
  const modalTitles: Map<PlanType, string> = new Map([
    [
      PlanType.NEW_PLAN,
      'Are you sure you want to approve this plan as a new, chargeable plan?'
    ],
    [
      PlanType.FIRST_SUB_NON_CHARGEABLE,
      'Are you sure you want to approve this plan as a with first subscription non-chargeable?'
    ],
    [
      PlanType.NON_CHARGABLE_PLAN,
      'Are you sure you want to approve this plan as a non-chargeable plan?'
    ],
    [PlanType.ERROR, 'Error processing request']
  ])

  const modalContent: Map<PlanType, string> = new Map([
    [PlanType.NEW_PLAN, 'They will be charged a prorated fee for this month'],
    [
      PlanType.FIRST_SUB_NON_CHARGEABLE,
      'They will be charged at the end of their first subscription billing cycle'
    ],
    [PlanType.NON_CHARGABLE_PLAN, 'They will not be billed at all'],
    [
      PlanType.ERROR,
      'There has been an issue whilst trying to process this request. please contact the administrators'
    ]
  ])

  const { plans } = usePlans(
    currentOrgUnit?.organization.parent_platform_tenant
  )

  const handleLocationMenuClick = (location: Location | undefined) => {
    if (location) {
      setChosenLocation(location)

      const plansToShow = plans.filter(
        (p) => p.location === null || p.location?.id === location.id
      )
      setFilteredPlans(plansToShow)
      location !== chosenLocation && setChosenPlan(undefined)
    }
  }
  const handlePlanMenuClick = (plan) => {
    if (plan) {
      setChosenPlan(plan)
      setShowRateAdjustment(true)
    }
  }
  const isEmailValid = (email: string) => {
    return validateEmail(email)
  }

  const handleOk = async () => {
    if (!chosenLocation) {
      setError('Choose a location')
      return
    }
    if (!chosenPlan) {
      setError('Choose a plan')
      return
    }
    if (!chosenStartDate) {
      setError('Pick a Start date')
      return
    }
    if (!isEmailValid(inviteEmail)) {
      setError('Invalid email address')
      return
    }
    if (isSubmitting) return

    const isFirstSubscriptionNonChargeable =
      planType === PlanType.FIRST_SUB_NON_CHARGEABLE

    const overrideValue = rateAdjustment !== '' ? rateAdjustment : undefined
    const overrideReason =
      rateAdjustment !== '' ? rateAdjustmentReason : undefined

    if (chosenPlan && chosenPlan.id && chosenStartDate && chosenLocation) {
      if (
        overrideValue === undefined ||
        (rateAdjustment !== undefined &&
          overrideReason !== undefined &&
          overrideReason.length < 100 &&
          overrideReason.length > 0)
      ) {
        const data = {
          plan: chosenPlan.id,
          location_id: chosenLocation.id,
          invitation_email: inviteEmail.toLowerCase(),
          start_date: format(chosenStartDate, 'yyyy-MM-dd'),
          override_value: overrideValue,
          is_first_subscription_non_chargeable:
            isFirstSubscriptionNonChargeable,
          override_reason: overrideReason
        }

        try {
          setIsSubmitting(true)
          await newMemberInvite(data).then((res) => {
            revalidate && revalidate()
            toastPopup({
              position: 'top',
              duration: 5000,
              render: (onClose) => (
                <AlertBox onClose={onClose}>Invite Sent</AlertBox>
              ),
              isClosable: true
            })

            // }
            clearState()
            setIsSubmitting(false)
          })
        } catch (e: any) {
          setIsSubmitting(false)
          if (
            e.response &&
            e.response.data &&
            e.response.data.non_field_errors
          ) {
            setError(e.response.data.non_field_errors)
          }
        }
      }
    }
  }

  // clear all state associated with modal
  const clearState = () => {
    visibility.setModalIsVisible(false)
    // setAdminApprovedRequest(null)
    setPlanType(undefined)
    setChosenLocation(undefined)
    setChosenPlan(undefined)
    setChosenStartDate(undefined)
    setInviteEmail('')
    setRateAdjustment('')
    setShowRateAdjustment(false)
    setError('')
    setRateAdjustmentReason('')
  }

  return (
    <ChakraModal
      padding={1}
      isOpen={visibility.modalIsVisible}
      onClose={clearState}
    >
      <ModalBody>
        <h1>Invite a member</h1>
        <h4>Select a membership type</h4>
        <VStack align="left">
          <HStack>
            <Button
              small
              data-testid="new_member"
              onClick={() => {
                setPlanType(PlanType.NEW_PLAN)
              }}
            >
              New member
            </Button>
            <Button
              small
              data-testid="is_first_subscription_non_chargeable"
              onClick={() => {
                setPlanType(PlanType.FIRST_SUB_NON_CHARGEABLE)
              }}
            >
              First Subscription Non-Chargeable
            </Button>
          </HStack>
          <HStack>
            {planType && (
              <Menu closeOnBlur={true}>
                <MenuButton as={Button} data-testid="location-button">
                  Choose a location
                </MenuButton>
                <MenuList>
                  {platformTenant?.locations.map((location, i) => (
                    <MenuItem
                      key={location.id}
                      onClick={() => handleLocationMenuClick(location)}
                    >
                      <div
                        data-testid={`location-${location.name.toLowerCase()}`}
                      >
                        {location.name}
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}

            {chosenLocation && (
              <Menu closeOnBlur={true}>
                <MenuButton as={Button} data-testid="plan-button" ml={2}>
                  Choose a plan
                </MenuButton>
                <MenuList mt={1} maxH="200px" overflowY="scroll">
                  {filteredPlans.map((plan, i) => (
                    <MenuItem
                      key={plan.id}
                      onClick={() => handlePlanMenuClick(plan)}
                    >
                      <div data-testid={`plan-${plan.name.toLowerCase()}`}>
                        {plan.name}
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}

            {chosenLocation && chosenPlan && (
              <CalendarButton
                variant="primary"
                onClick={() => setShowCalendar(!showCalendar)}
                small
              >
                Select a start date
              </CalendarButton>
            )}
          </HStack>
          {showCalendar && (
            <Box mt={3}>
              <Calendar
                onChange={(date) => {
                  date && setChosenStartDate(date as Date)
                  // setShowCalendar(false)
                }}
                value={chosenStartDate}
                minDate={new Date()}
              />
            </Box>
          )}
        </VStack>

        <br />
        <br />

        <FormInput
          type="email"
          name="email"
          label="Email address"
          onChange={(e) => {
            e.preventDefault()
            setInviteEmail(e.target.value)
          }}
          value={inviteEmail}
          placeholder="johnsmith@mail.com"
        />

        {showRateAdjustment && (
          <Formik
            enableReinitialize
            initialValues={{
              rate: rateAdjustment
            }}
            validate={(values): any => {
              const errors: any = {}
              setRateAdjustment(values.rate)

              if (values.rate && values.rate < 0) {
                errors.rate = "Altered rate can't be below zero"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ isSubmitting }) => (
              <Box p={1}>
                <Box>
                  <Form>
                    <Flex>
                      <Box width="100%">
                        <Field
                          type="number"
                          name="rate"
                          id="rate"
                          label="Alter rate value ($AUD per month)"
                          data-testid="admin-rate-field"
                          placeholder="Enter a rate value ($AUD per month)"
                          component={FormInput}
                        />
                        <ErrorMessage name="rate" component={InputWarning} />
                      </Box>
                    </Flex>
                  </Form>
                </Box>
              </Box>
            )}
          </Formik>
        )}
        <br />
        {rateAdjustment !== '' && rateAdjustment >= 0 && (
          <Formik
            enableReinitialize
            initialValues={{
              rateReason: ''
            }}
            validate={(values): any => {
              const errors: any = {}
              setRateAdjustmentReason(values.rateReason)

              if (values.rateReason === '') {
                errors.rateReason = 'Reason for rate change required'
              } else if (values.rateReason.length > 100) {
                errors.rateReason =
                  'Reason for rate change must be less than 100 characters'
              }

              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {}}
          >
            {({ isSubmitting }) => (
              <Box p={1}>
                <Box>
                  <Form>
                    <Flex>
                      <Box width={['100%', '100%', '100%', '50%']}>
                        <Field
                          type="text"
                          name="rateReason"
                          id="rateReason"
                          label="Enter a reason for the rate change"
                          data-testid="admin-rate-reason-field"
                          placeholder="Reason for rate change"
                          component={FormInput}
                        />
                        <ErrorMessage
                          name="rateReason"
                          component={InputWarning}
                        />
                      </Box>
                    </Flex>
                  </Form>
                </Box>
              </Box>
            )}
          </Formik>
        )}

        <br />
        {chosenLocation && (
          <Box mt={3}>
            <h4>Location:</h4>
            <p>{chosenLocation.name}</p>
          </Box>
        )}
        {chosenPlan && (
          <Box>
            <h4>Chosen plan:</h4>
            <p>{chosenPlan.name}</p>
            <p
              style={{
                textDecoration: rateAdjustment !== '' ? 'line-through' : 'none'
              }}
            >
              {chosenPlan.rate_string}
            </p>
            {rateAdjustment !== '' && <p>Overriden to ${rateAdjustment}</p>}
          </Box>
        )}
        {chosenStartDate && (
          <Box>
            <h4>Chosen start date:</h4>
            <p>{format(chosenStartDate, 'do MMMM yyyy')}</p>
          </Box>
        )}
        {error && <InputWarning>{error}</InputWarning>}

        <br />
        {planType && (
          <Alert status="warning" mt={2}>
            {/* TODO */}
            {/* <AlertIcon /> */}
            <Stack>
              <Text>{modalTitles.get(planType)}</Text>
              <Text>{modalContent.get(planType)}</Text>
            </Stack>
          </Alert>
        )}
        {/*
        @TODO: fix error display
        {error && <InputWarning>{error}</InputWarning>}{' '} */}
      </ModalBody>
      <ModalFooter>
        <Button key="back" onClick={clearState} variant="secondary">
          Cancel
        </Button>
        <Button
          key="submit"
          onClick={handleOk}
          ml="auto"
          data-testid={`confirm_change_request`}
          disabled={planType === null || isSubmitting}
          isLoading={isSubmitting}
          loadingText={'Processing'}
        >
          Continue
        </Button>
      </ModalFooter>
    </ChakraModal>
  )
}
